import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Radio, RadioGroup, Switch as LyraSwitch, Text } from "@new-black/lyra";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

import RadioButtonList from "~/components/suite-ui/radio-button-list";
import RadioButtonLabel from "~/components/suite-ui/radio-button-list/radio-button-label";
import { BooleanSegmentedButton } from "~/components/suite-ui/segmented-button/boolean-segmented-button";
import { Switch } from "~/components/ui/switch";

type ICustomBoolFieldConsumerProps = ICustomFieldConsumerProps<boolean | undefined> & {
  variant?: "switch" | "segmented" | "radio";
};

export const CustomBoolFieldConsumer = ({
  componentVariant,
  error,
  value,
  variant = "switch",
  ...props
}: ICustomBoolFieldConsumerProps) => {
  const intl = useIntl();

  const options = useMemo(
    () => ({
      trueOption: {
        label: intl.formatMessage({
          id: "generic.label.yes",
          defaultMessage: "Yes",
        }),
        value: "true",
      },
      falseOption: {
        label: intl.formatMessage({
          id: "generic.label.no",
          defaultMessage: "No",
        }),
        value: "false",
      },
      undefinedOption: {
        label: intl.formatMessage({
          id: "generic.label.na",
          defaultMessage: "N/A",
        }),
        value: "undefined",
      },
    }),
    [intl],
  );

  if (componentVariant === "lyra") {
    return (
      <div>
        {variant === "switch" ? (
          <LyraSwitch
            isSelected={value ?? false}
            {...props}
            onChange={(newValue) => props.onChange(newValue)}
          >
            {props.label ?? <FormattedMessage id="generic.label.value" defaultMessage="Value" />}
          </LyraSwitch>
        ) : null}
        {variant === "segmented" ? (
          <BooleanSegmentedButton
            name={props.name}
            label={props.label}
            value={value}
            setValue={(newValue) => props.onChange(newValue)}
            trueLabel={options.trueOption.label}
            falseLabel={options.falseOption.label}
            undefinedLabel={options.undefinedOption.label}
          />
        ) : null}
        {variant === "radio" ? (
          <RadioGroup
            {...props}
            value={Object.values(options).find((option) => option.value === `${value}`)?.value}
            onChange={(newValue) =>
              props.onChange(newValue === "undefined" ? undefined : newValue === "true")
            }
            name={props.name}
            label={props.label ?? ""}
            hideHintLabel
          >
            {Object.values(options).map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        ) : null}

        {error ? (
          <Text variant="body-small" color="error">
            {error}
          </Text>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      {variant === "switch" ? (
        <Switch
          checked={value ?? false}
          error={!!error}
          helperText={error}
          {...props}
          onChange={(newValue) => props.onChange(newValue)}
        />
      ) : null}
      {variant === "segmented" ? (
        <BooleanSegmentedButton
          name={props.name}
          label={props.label}
          value={value}
          setValue={(newValue) => props.onChange(newValue)}
          trueLabel={options.trueOption.label}
          falseLabel={options.falseOption.label}
          undefinedLabel={options.undefinedOption.label}
        />
      ) : null}
      {variant === "radio" ? (
        <RadioButtonList
          name={props.name}
          label={props.label}
          selectedValue={value?.toString() ?? "undefined"}
          handleSelectedValueChange={(_, newValue) =>
            props.onChange(newValue === "undefined" ? undefined : newValue === "true")
          }
        >
          {Object.values(options).map((option) => (
            <RadioButtonLabel
              label={option.label}
              key={option.value ?? ""}
              value={option.value ?? ""}
              activeValue={value?.toString() ?? "undefined"}
            />
          ))}
        </RadioButtonList>
      ) : null}
    </div>
  );
};
