import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { cva } from "class-variance-authority";

import { MenuProps, MenuState } from "./menu.types";
import { MenuFooter } from "./menu-footer";
import { MenuHeader } from "./menu-header";
import { MenuItemLink } from "./menu-item-link";
import { SecondaryMenu, SecondaryMenuWithWorkspaces } from "./secondary-menu";

import { OrganizationUnitSelectorProvider } from "~/components/suite-composite/organization-unit-selector";
import { useChapterFinderData } from "~/features/chapter-finder/use-chapter-finder-data";
import useDebounce from "~/hooks/suite-react-hooks/use-debounce";
import useLocalStorage from "~/hooks/suite-react-hooks/use-local-storage";
import { useScreenIsNarrowerThan } from "~/hooks/use-screen-width";
import { useSwipe } from "~/hooks/use-swipe";
import { routesWithOUSelector } from "~/routes/root/root.types";
import { localStorageKeys } from "~/types/local-storage";

const menuWrapperStyle = cva(
  [
    "h-[100vh]",
    "bg-surface-tertiary",
    "border-l-0",
    "border-y-0",
    "border-r",
    "border-solid",
    "border-default",
    "overflow-hidden",
    "absolute",
    "transition-all",
    "duration-200",
    "z-40",
  ],
  {
    variants: {
      state: {
        open: ["w-80"],
        closed: ["w-[64px]", "mr-[64px] z-10"],
        hovered: ["w-80", "mr-[64px]", "z-10"],
      },
    },
  },
);

const spacerDivStyle = cva(["transition-all", "duration-200"], {
  variants: {
    state: {
      open: ["mr-80"],
      closed: ["mr-[64px]"],
      hovered: ["mr-[64px]"],
    },
  },
});

const Menu = <TWorkspace,>({ moduleCode, routes, workspace }: MenuProps<TWorkspace>) => {
  const screenIsSmall = useScreenIsNarrowerThan(768);

  const [mouseEntered, setMouseEntered] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const [menuState, setMenuState] = useLocalStorage<MenuState>(
    localStorageKeys.SidebarMenuOpenState,
    screenIsSmall ? "closed" : "open",
  );

  // automatically close menu if screen is narrower than 768 px on mount
  useEffect(() => {
    if (screenIsSmall) {
      setMenuState("closed");
    }
    // only run this effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modules = useChapterFinderData();

  const module = useMemo(() => {
    return modules?.find((module) => module.code === moduleCode);
  }, [moduleCode, modules]);

  const location = useLocation();
  // use the location pathname and routesWithOUSelector list to determine if the OU selector should be shown
  const showOUSelector = useMemo(() => {
    return routesWithOUSelector.some((route) => location.pathname.startsWith(route));
  }, [location.pathname]);
  const SecondaryMenuWrapper = useMemo(
    () => (showOUSelector ? OrganizationUnitSelectorProvider : Fragment),
    [showOUSelector],
  );

  const debouncedIsHovered = useDebounce(mouseEntered, 200);

  useEffect(() => {
    if (debouncedIsHovered) {
      setMenuState(menuState !== "open" ? "hovered" : menuState);
    } else {
      setMenuState(menuState !== "open" ? "closed" : menuState);
    }
  }, [debouncedIsHovered, menuState, setMenuState]);

  useEffect(() => {
    if (isFocused) {
      setMenuState(menuState !== "open" ? "hovered" : menuState);
    } else if (!debouncedIsHovered) {
      setMenuState(menuState !== "open" ? "closed" : menuState);
    }
  }, [isFocused, menuState, setMenuState, debouncedIsHovered]);

  const swipeProps = useSwipe({
    onSwipeLeft: () => {
      if (menuState === "open" || menuState === "hovered") {
        setMenuState("closed");
      }
    },
    onSwipeRight: () => {
      if (menuState === "closed") {
        setMenuState("hovered");
      }
    },
  });
  return (
    <>
      <div
        className={menuWrapperStyle({ state: menuState })}
        onMouseLeave={() => {
          if (menuState === "hovered" && mouseEntered) {
            setMouseEntered(false);
          }
        }}
        {...swipeProps}
      >
        <div className="flex h-full flex-col">
          <MenuHeader
            moduleColor={module?.moduleColor ?? "#077aff"}
            ModuleLogo={module?.icon}
            moduleName={module?.title ?? ""}
            menuState={menuState}
            setMouseEntered={setMouseEntered}
            setIsFocused={setFocused}
          />

          <div className="flex h-full flex-col gap-y-1 overflow-y-auto overflow-x-hidden px-4 pb-4 pt-1">
            {routes.map((route) => (
              <MenuItemLink
                key={route.routeDefinition.path}
                routeDefinition={route.routeDefinition}
                label={route.label}
                icon={route.icon}
                count={route.count}
                menuState={menuState}
                setMouseEntered={setMouseEntered}
                setFocused={setFocused}
              />
            ))}
          </div>

          <div className="flex flex-col">
            <SecondaryMenuWrapper>
              {workspace ? (
                <SecondaryMenuWithWorkspaces
                  menuState={menuState}
                  setMouseEntered={setMouseEntered}
                  setIsFocused={setFocused}
                  workspace={workspace as any}
                  showOUSelector={showOUSelector}
                />
              ) : (
                <SecondaryMenu
                  menuState={menuState}
                  setMouseEntered={setMouseEntered}
                  setIsFocused={setFocused}
                  showOUSelector={showOUSelector}
                />
              )}
            </SecondaryMenuWrapper>

            <MenuFooter
              menuState={menuState}
              setMenuState={setMenuState}
              setMouseEntered={setMouseEntered}
              setIsFocused={setFocused}
            />
          </div>
        </div>
      </div>
      <div className={spacerDivStyle({ state: menuState })} />
    </>
  );
};

export default Menu;
