import { atom } from "recoil";

type TOrderStatus = "open" | "completed" | "paid" | "shipped";

type OrderSearchResultItem = EVA.Core.OrderSearchResultItem & {
  OrderStatus?: TOrderStatus;
};

export type OrderSearchResultItemKeys =
  | Exclude<
      keyof OrderSearchResultItem,
      | "Data"
      | "ReturnToSupplierRequest"
      | "IsSuspended"
      | "Status"
      | "SoldToOrganizationUnit"
      | "ShipToOrganizationUnit"
      | "OrganizationUnits"
      | "OriginatingOrganizationUnit"
      | "ConfirmationDate"
      | "IsConfirmed"
      | "CustomStatus"
      | "CustomOrderTypeID"
      | "PriceListIDs"
      | "IsOffer"
      | "ProductImageUrl"
      | "DisplayID"
    >
  | "HasReturns"
  | "HasHiddenPrices"
  | "OpenInNewTab"
  | "CreatedBy";

export const DefaultOrderListColumns: OrderSearchResultItemKeys[] = [
  "ID",
  "CreationTime",
  "SoldFromOrganizationUnit",
  "Customer",
  "TotalAmountInTax",
  "OrderStatus",
];

const ordersListColumnsState = atom<OrderSearchResultItemKeys[]>({
  key: "ordersListColumnsState",
  default: DefaultOrderListColumns,
});

export default ordersListColumnsState;
