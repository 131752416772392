export enum InvoiceLedgerType {
  InvoiceDescription = 1,
  InvoiceNumber = 2,
  InvoiceDate = 3,
  OrganizationUnit = 4,
  SupplierOrganizationUnit = 5,
  CreditorOrganizationUnit = 6,
  DebtorOrganizationUnit = 7,
  OriginalTotalAmount = 8,
  ExpectedTotalAmount = 9,
  TotalAmount = 10,
  Status = 11,
  HoldStatus = 12,
  PaymentTermDueDate = 13,
  PaymentTermStartDate = 14,
  TaxReverseCharge = 15,
  Printed = 16,
  HasUnresolvedDisputes = 17,
  Amount = 18,
  Reason = 19,
  IsResolved = 20,
  Type = 21,
  TaxRate = 22,
  TaxCode = 23,
  UnitCost = 24,
  UnitPrice = 25,
  ExpectedQuantity = 26,
  Quantity = 27,
  TotalAmountInvoicedInTax = 28,
  DiscountAmount = 29,
  DisputeCreated = 30,
  BlobCreated = 31,
  Deleted = 32,
  UserFirstName = 33,
  UserLastName = 34,
  UserEmailAddress = 35,
  UserPhoneNumber = 36,
  UserFiscalID = 37,
  BillingAddress1 = 38,
  BillingAddressHouseNumber = 39,
  BillingAddressZipCode = 40,
  BillingAddressCity = 41,
  BillingAddressSubdistrict = 42,
  BillingAddressDistrict = 43,
  BillingAddressState = 44,
  BillingAddressCountryID = 45,
  CompanyName = 46,
  CompanyVatNumber = 47,
  CompanyFiscalID = 48,
  UserDataCreated = 49,
}
