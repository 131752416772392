export enum Functionalities {
  ACCOUNTS = "Accounts",
  APP_SETTINGS = "AppSettings",
  APPROVE_PAYMENT = "ApprovePayment",
  ASSORTMENTS = "Assortments",
  ASSORTMENT_CONTENT_CULTURES = "AssortmentContentCultures",
  ANONYMOUS_TOKENS = "AnonymousTokens",
  AUDITS = "Audits",
  API_KEYS = "ApiKeys",
  API_USERS = "ApiUsers",
  BLOCK_USER_SUBSCRIPTION = "BlockUserSubscription",
  CANCEL_PAYMENT = "CancelPayment",
  CASH_CORRECTION_REASONS = "CashCorrectionReasons",
  CASH_EXPENSES = "CashExpenses",
  CASH_EXPENSE_TYPES = "CashExpenseTypes",
  CASH_DEPOSITS = "CashDeposits",
  CASH_HANDLERS = "CashHandlers",
  COMPANIES = "Companies",
  CONTENT_CULTURE_MAPPING = "ContentCultureMapping",
  COOKBOOK = "Cookbook",
  CREATE_DISCOUNT_FROM_TEMPLATE = "CreateDiscountFromTemplate",
  CURRENCIES = "Currencies",
  CUSTOM_FIELDS = "CustomFields",
  ERROR_QUEUE = "ErrorQueue",
  CUSTOM_FIELD_TYPES = "CustomFieldTypes",
  CUSTOM_USER_TASKS = "CustomUserTasks",
  RECURRING_TASKS = "RecurringTasks",
  CHANGE_USER_LOGIN_TYPE = "ChangeUserLoginType",
  CUSTOMERS = "Customers",
  CYCLE_COUNTS = "CycleCounts",
  CYCLE_COUNT_ZONE_GROUPS = "CycleCountZoneGroups",
  COST_PRICES = "CostPrices",
  DEPOSIT_LOYALTY_POINTS = "DepositLoyaltyPoints",
  DISCOUNTS = "Discounts",
  DISCOUNT_COUPONS = "DiscountCoupons",
  DISCOUNTS_CAMPAIGNS = "DiscountCampaigns",
  DISCOUNT_BUDGET_MANAGEMENT = "DiscountBudgetManagement",
  DISCOUNTS_LAYERS = "DiscountLayers",
  DISCOUNTS_TEMPLATES = "DiscountTemplates",
  EMPLOYEES = "Employees",
  EVENT_LEDGER_AUDITING = "EventLedgerAuditing",
  SUBSCRIPTIONS = "Subscriptions",
  EMPLOYEES_UPLOAD = "UploadEmployees",
  ENTITY_FIELD_VALIDATORS = "EntityFieldValidators",
  EVENT_EXPORT_CONFIGURATION = "EventExportConfiguration",
  FINANCIAL_PERIODS = "FinancialPeriods",
  FINANCIAL_PERIOD_EXPORTS = "FinancialPeriodExports",
  FINANCIAL_PERIOD_ARCHIVE = "FinancialPeriodArchive",
  FINANCIAL_EVENTS = "FinancialEvents",
  FLIGHTS = "Flights",
  FORCE_CANCEL_ORDER = "ForceCancelOrder",
  FUNCTIONALITIES = "Functionalities",
  FULL_STOCK_COUNT = "FullStockCount",
  FULL_STOCK_COUNT_LABEL = "FullStockCountLabel",
  GENERATE_TEMPORARY_PASSWORD = "GenerateTemporaryPassword",
  GENERAL_LEDGERS = "GeneralLedgers",
  GIFT_CARD_CONFIGURATIONS = "GiftCardConfigurations",
  INFRASTRUCTURE = "Infrastructure",
  FRAUD_ADDRESSES = "FraudAddresses",
  ADDRESSES = "Addresses",
  INVOICES = "Invoices",
  INVOICE_COMPANY_MAPPING = "InvoiceCompanyMapping",
  INVOICE_ADDITIONAL_AMOUNTS = "InvoiceAdditionalAmounts",
  INVOICE_DISPUTES = "InvoiceDisputes",
  INVOICE_DISPUTE_RESOLVE_ACTIONS = "InvoiceDisputeResolveActions",
  IGNORE_NEGATIVE_STOCK_IN_FINANCIAL_PERIOD_CLOSING = "IgnoreNegativeStockInFinancialPeriodClosing",
  INVOICE_EXPORTS = "InvoiceExports",
  INQUIRIES = "Inquiries",
  INITIAL_INVENTORY = "InitialInventory",
  CHECKOUT_OPTIONS = "CheckoutOptions",
  LAYER = "Layer",
  LIVE_GUARD = "LiveGuard",
  LOGIN = "Login",
  MASS_STOCK_UPDATE = "MassStockUpdate",
  MODULE_ROLES = "ModuleRoles",
  MONITORS = "Monitors",
  OPEN_ID_PROVIDERS = "OpenIDProviders",
  OPENING_HOURS_TEMPLATES = "OpeningHoursTemplates",
  OPENING_HOURS_TYPES = "OpeningHoursTypes",
  ORDER_FULFILLMENTS = "OrderFulfillments",
  ORDERS = "Orders",
  ORDER_STATUS = "OrderStatus",
  ORDER_TYPES = "OrderTypes",
  ORDER_CUSTOM_STATUS = "OrderCustomStatus",
  ORDER_CUSTOM_TYPES = "OrderCustomTypes",
  ORDER_LINE_PRICE_CORRECTION = "OrderLinePriceCorrection",
  ORDER_VERIFICATION_REASONS = "OrderVerificationReasons",
  ORGANIZATION_UNIT_NOTES = "OrganizationUnitNotes",
  ORGANIZATION_UNIT_OPENING_HOURS = "OrganizationUnitOpeningHours",
  ORGANIZATION_UNIT_SET_PAYMENT_TYPES = "OrganizationUnitSetPaymentTypes",
  ORGANIZATION_UNIT_SETS = "OrganizationUnitSets",
  ORGANIZATION_UNIT_SUPPLIERS = "OrganizationUnitSuppliers",
  ORGANIZATION_UNIT_SETTINGS = "OrganizationUnitSettings",
  ORGANIZATION_UNITS = "OrganizationUnits",
  ORGANIZATION_UNIT_LANGUAGES = "OrganizationUnitLanguages",
  ORGANIZATION_UNIT_COUNTRIES = "OrganizationUnitCountries",
  ORGANIZATION_UNIT_CURRENCIES = "OrganizationUnitCurrencies",
  PAYMENT_METHODS = "PaymentMethods",
  PAYMENT_TRANSACTIONS = "PaymentTransactions",
  PAYMENT_TYPES = "PaymentTypes",
  PAYMENT_SETTLEMENTS = "PaymentSettlements",
  PRICING = "Pricing",
  PRINT_TASKS = "PrintTask",
  PRODUCT = "Product",
  PRODUCT_COMPONENTS = "ProductComponents",
  PRODUCT_BUNDLES = "ProductBundles",
  PRODUCT_SEARCHES = "ProductSearches",
  PRODUCT_SEARCH_CONFIGURATION = "ProductSearchConfiguration",
  PRODUCT_CAPACITY_PLANS = "ProductCapacityPlans",
  PRODUCT_GIFT_CARDS = "ProductGiftCards",
  PURCHASE_ORDERS = "PurchaseOrders",
  PRODUCT_RELATION_TYPES = "ProductRelationTypes",
  PRODUCT_REQUIREMENTS = "ProductRequirements",
  RECEIVE_SHIPMENTS = "ReceiveShipments",
  REFUND_CORRECTION_REASONS = "RefundCorrectionReasons",
  RELEASE_PAID_PICK_UP_ORDERS = "ReleasePaidPickupOrders",
  REPAIRS = "Repairs",
  REPLENISHMENTS = "Replenishments",
  RESEND_USER_INTERACTIONS = "ResendUserInteractions",
  USER_GROUPS = "UserGroups",
  RETRY_ORDER_EXPORTS = "RetryOrderExports",
  RETRY_FINANCIAL_PERIOD_EXPORT = "RetryFinancialPeriodExport",
  RETURN_ORDER = "ReturnOrder",
  RETURN_REASONS = "ReturnReasons",
  RETURN_TO_SUPPLIER_REQUESTS = "ReturnToSupplierRequests",
  INITIAL_CYCLE_COUNTS = "InitialCycleCounts",
  SUPPLIER_PRODUCTS = "SupplierProducts",
  ROLES = "Roles",
  ROLE_SETS = "RoleSets",
  SCRIPTS = "Scripts",
  SETTINGS = "Settings",
  SERIAL_NUMBERS = "SerialNumbers",
  SHIPMENTS = "Shipments",
  SHIPPING_COSTS = "ShippingCosts",
  SHIPPING_METHODS = "ShippingMethods",
  CARRIERS = "Carriers",
  SHIPPING_RESTRICTIONS = "ShippingRestrictions",
  SHIPMENT_IDENTIFIERS = "ShipmentIdentifiers",
  STOCK = "Stock",
  STOCK_MUTATIONS = "StockMutations",
  STOCK_LABELS = "StockLabels",
  STOCK_ALLOCATION_RULES = "StockAllocationRules",
  SYMPHONY_SHEETS = "SymphonySheets",
  TAX_RATES = "TaxRates",
  TAX_PROVIDERS = "TaxProviders",
  USER_BOUGHT_PRODUCTS = "UserBoughtProducts",
  TRANSLATIONS = "Translations",
  TRANSPUT_JOBS = "TransputJobs",
  UNTYPED_SETTINGS = "UntypedSettings",
  UNIT_PRICE_CORRECTION_REASONS = "UnitPriceCorrectionReasons",
  USER_FIELDS = "UserFields",
  USER_INTERACTIONS = "UserInteractions",
  USER_REQUIREMENTS = "UserRequirements",
  USER_ROLES = "UserRoles",
  UNBLOCK_USER_SUBSCRIPTION = "UnblockUserSubscription",
  VISIBILITY_GROUPS = "VisibilityGroups",
  VIEW_ORDER_EXPORTS = "ViewOrderExports",
  CYCLE_COUNT_ZONES = "CycleCountZones",
  ZONED_CYCLE_COUNTS = "ZonedCycleCounts",
  ZONED_CYCLE_COUNTS_SCHEDULE = "ZonedCycleCountSchedule",
  SYNCHRONIZATION = "SynchronizationFiles",
  CASE_STATUSES = "CaseStatuses",
  CASE_TOPICS = "CaseTopics",
  CASES = "Cases",
  PACKAGES = "Packages",
  DOWNLOAD_FULL_STOCK_COUNT_SUMMARY = "DownloadFullStockCountSummary",
  MESSAGE_TEMPLATES = "MessageTemplates",
  SHARED_WORKSPACES = "SharedWorkspaces",
  LOYALTY_PROGRAMS = "LoyaltyPrograms",
  LOYALTY_PROGRAM_GROUPS = "LoyaltyProgramGroups",
  POINT_AWARDING_RULES = "PointAwardingRules",
  UNIT_OF_MEASURES = "UnitOfMeasures",
  PRODUCT_UNIT_OF_MEASURES = "ProductUnitOfMeasures",
  PERSONALIZED_PROMOTIONS = "PersonalizedPromotions",
  USER_TASK_NOT_COMPLETED_REASONS = "UserTaskNotCompletedReasons",
  RECOVER_EMPLOYEE_ACCOUNT = "RecoverEmployeeAccount",
  TAX_EXEMPTION_REASON = "TaxExemptionReason",
  WATCHTOWERS = "Watchtowers",
  ENDPOINT_CONFIGURATIONS = "EndpointConfigurations",
  STOCK_MUTATION_REASON = "StockMutationReason",
  SURVEYS = "Surveys",
  PUBLISHSURVEY = "PublishSurvey",
}

export { FunctionalityScope } from "~/types/eva-core";

export type SupportedFunctionalitiesMap = {
  [key: string]: Omit<
    EVA.Core.Management.GetSupportedFunctionalitiesResponse.FunctionalityDefinition,
    "Name"
  >;
};
