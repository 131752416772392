import { useCallback } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { useMutation } from "@tanstack/react-query";

import { mutate } from "~/util/mutate";

const mutateGenerateIdentificationCodeForEmployee = () =>
  mutate({
    service: CoreManagement.GenerateIdentificationCodeForEmployee,
  });

export const useGenerateQRCode = (onSuccess?: (identificationCode: string) => void) => {
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: mutateGenerateIdentificationCodeForEmployee(),
  });

  const callback = useCallback(() => {
    mutateAsync(undefined).then((result) => {
      if (onSuccess && result?.success && result.response?.Result?.IdentificationCode) {
        onSuccess(result.response.Result?.IdentificationCode);
      }
    });
  }, [mutateAsync, onSuccess]);

  return { isLoading, callback };
};
