import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router";

import { contextGenerator } from "~/util/context-generator";

/**
 * Utility that creates a context provider with built-in search params state controlled only by the given route path.
 * Use this to wrap a route branch for easy access to search params state within any sub-route.
 * @param route the route path that is in charge of controlling the search params state
 * @returns a context provider that can be rendered as a wrapper route element and a hook for accessing the context value
 */
export const searchParamsContextGenerator = (route: string) => {
  const { Provider, useContext } = contextGenerator<{ searchParams: string | undefined }>();

  const SearchParamsProvider = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useState<string>();

    // Update search params state when changed on the given route path.
    useEffect(() => {
      if (location.pathname === route) {
        setSearchParams(location.search);
      }
    }, [location.pathname, location.search]);

    const value = useMemo(() => ({ searchParams }), [searchParams]);

    return (
      <Provider value={value}>
        <Outlet />
      </Provider>
    );
  };

  return {
    SearchParamsProvider,
    useSearchParamsContext: useContext,
  };
};
