import { PIM } from "@springtree/eva-services-pim";

import { listProductPropertyCategoriesQuery } from "~/models/product-search-properties";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateProductPropertyCategoriesSearchListField = () =>
  SearchListFieldGenerator<
    PIM.ListProductPropertyCategories,
    EVA.PIM.ListProductPropertyCategoriesDto,
    string
  >({
    getItemFromResponse: (response) => response?.Result?.Page,
    getItemId: (item) => item.ID,
    getLabel: (item) => item.DisplayName,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.category",
      defaultMessage: "Category",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listProductPropertyCategoriesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
