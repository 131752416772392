import { ReactNode, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { isRouteErrorResponse, useRouteError } from "react-router";

import { Dialog } from "@material-ui/core";
import { Button } from "@new-black/lyra";

import { Card } from "../card";
import { MenuLogo } from "../menu/menu-logo";
import { Page } from "../page";
import PageCenter from "../page-center";

import { DefinedRouteLink, useNavigate } from "~/components/routing";
import Text from "~/components/suite-ui/text";
import routeDefinitions from "~/routes/route-definitions";

const ErrorFallBackContainer = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  return (
    <div className="p-12">
      {children}
      <div className="mt-8">
        <Button fullWidth variant="primary" onPress={() => navigate(-1)}>
          <FormattedMessage
            id="generic.label.back-to-previous-page"
            defaultMessage="Back to previous page"
          />
        </Button>
      </div>
    </div>
  );
};

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <PageCenter>
      <div className="w-[400px] max-w-full">
        <Card>
          <ErrorFallBackContainer>{children}</ErrorFallBackContainer>
        </Card>
      </div>
    </PageCenter>
  );
};

const FullPage = ({ children }: { children: ReactNode }) => (
  <Page>
    <FullPageFallbackHeader />
    {children}
  </Page>
);

export const ErrorFallback = ({
  checkNonRouteErrorResponse,
  fullPage,
  inModal,
}: {
  fullPage?: boolean;
  inModal?: boolean;
  checkNonRouteErrorResponse?: boolean;
}) => {
  const error = useRouteError();
  const navigate = useNavigate();

  // Make sure we log the error to know what's going on
  console.error(error);

  const renderErrorCard = useCallback(() => {
    if (isRouteErrorResponse(error) || (checkNonRouteErrorResponse && error instanceof Response)) {
      if (error.status === 401) {
        return (
          <>
            <Text variant="h1">
              <FormattedMessage id="page.401.title" defaultMessage="Unauthorized" />
            </Text>
            <Text variant="body1">
              <FormattedMessage
                id="page.401.description"
                defaultMessage="You don't have access to this module"
              />
            </Text>
          </>
        );
      }
      if (error.status === 403) {
        return (
          <>
            <Text variant="h1">
              <FormattedMessage id="page.403.title" defaultMessage="Forbidden" />
            </Text>
            <Text variant="body1">
              <FormattedMessage
                id="page.403.description"
                defaultMessage="You don't have access to this page"
              />
            </Text>
          </>
        );
      }
      if (error.status === 404) {
        return (
          <>
            <Text variant="h1">
              <FormattedMessage id="page.404.title" defaultMessage="Page not found" />
            </Text>
            <Text variant="body1">
              <FormattedMessage
                id="page.404.description"
                defaultMessage="This is not the page you are looking for"
              />
            </Text>
          </>
        );
      }

      return (
        <Text variant="h2">
          <FormattedMessage
            id="generic.message.something-went-wrong"
            defaultMessage="Something went wrong"
          />
        </Text>
      );
    }

    return (
      <Text variant="h2">
        <FormattedMessage
          id="generic.message.something-went-wrong"
          defaultMessage="Something went wrong"
        />
      </Text>
    );
  }, [checkNonRouteErrorResponse, error]);

  if (fullPage) {
    return (
      <FullPage>
        <Wrapper>{renderErrorCard()}</Wrapper>
      </FullPage>
    );
  }

  if (inModal) {
    return (
      <Dialog open onClose={() => navigate(-1)} maxWidth="sm">
        <ErrorFallBackContainer>{renderErrorCard()}</ErrorFallBackContainer>
      </Dialog>
    );
  }

  return <Wrapper>{renderErrorCard()}</Wrapper>;
};

const FullPageFallbackHeader = () => (
  <header className="relative z-10 flex h-[70px] flex-nowrap items-center justify-between gap-5 overflow-x-auto border-0 border-b border-solid border-b-default px-5">
    <div className="-ml-1 mt-[1px]">
      <DefinedRouteLink routeDefinition={routeDefinitions.dashboard.search}>
        <MenuLogo />
      </DefinedRouteLink>
    </div>
  </header>
);
