import { DetailedHTMLProps } from "react";

import {
  DisplayProps,
  FlexboxProps,
  PaletteProps,
  SizingProps,
  SpacingProps,
} from "@material-ui/system";

import { sanitizeHTMLString } from "~/util/sanitize-html-string";

export interface InteractionLogRawEmailBoxProps
  extends SpacingProps,
    SizingProps,
    DisplayProps,
    FlexboxProps,
    PaletteProps {
  rawHtml?: string;
}

const InteractionLogRawEmailContainer = (
  props: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => <div className="max-h-[55vh] overflow-y-auto bg-surface-tertiary p-4" {...props} />;

export const InteractionLogRawEmailBox = ({
  rawHtml,
  ...props
}: InteractionLogRawEmailBoxProps) => (
  <InteractionLogRawEmailContainer {...props}>
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizeHTMLString(rawHtml ?? ""),
      }}
    />
  </InteractionLogRawEmailContainer>
);
