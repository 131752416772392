import { useCallback, useEffect } from "react";
import { useRevalidator } from "react-router";

import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import { helpers, hooks, state } from "@springtree/eva-sdk-react-recoil";
import { defer } from "lodash";

import { useLatestSelectedOrganizationUnitContext } from "./latest-selected-ou-context";

import { OrganizationUnitSearchListFieldItem } from "~/components/suite-composite/organization-unit-lyra-search-list-field";
import { useSelectedOrganizationUnit } from "~/components/suite-composite/organization-unit-selector";
import useOrganizationUnitStructure from "~/components/suite-composite/organization-unit-selector/hooks/use-organization-unit-structure";
import { queryClient } from "~/util/query-client";

const { storage } = helpers;

export const useOUSelector = () => {
  const currentUser = hooks.useGetState(state.currentUser.currentUserState.response);

  const requestedOUID = hooks.useGetState(state.core.requestedOrganizationUnitIdState);
  //use value form context
  const { latestSelectedOrganizationUnitID, setLatestSelectedOrganizationUnitID } =
    useLatestSelectedOrganizationUnitContext();

  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();
  const { selectedOrganizationUnit, setSelectedOrganizationUnitById } =
    useSelectedOrganizationUnit();

  const revalidator = useRevalidator();

  // Initialize selected OU with the latest selected OU, otherwise with the current user OU
  useEffect(() => {
    if (latestSelectedOrganizationUnitID !== undefined) {
      setSelectedOrganizationUnitById(latestSelectedOrganizationUnitID);

      storage.setItem(
        "evaRequestedOrganizationUnitID",
        latestSelectedOrganizationUnitID.toString(),
      );

      queryClient.removeQueries();
      defer(() => revalidator.revalidate());
    } else if (currentUser?.User?.CurrentOrganizationID && flattenedOrganizationUnits) {
      setSelectedOrganizationUnitById(requestedOUID ?? currentUser.User.CurrentOrganizationID);

      queryClient.removeQueries();
      defer(() => revalidator.revalidate());
    }
    // not include dependency to revalidator on purpose, because it's changing its refference and causing an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser?.User.CurrentOrganizationID,
    flattenedOrganizationUnits,
    latestSelectedOrganizationUnitID,
    setSelectedOrganizationUnitById,
  ]);

  const handleOUSelection = useCallback(
    (item: OrganizationUnitSearchListFieldItem) => {
      if (flattenedOrganizationUnits?.some((ou) => ou.ID === item.ID)) {
        storage.setItem("evaRequestedOrganizationUnitID", "");
        if (item.ID !== selectedOrganizationUnit?.ID) {
          storage.setItem("evaRequestedOrganizationUnitID", item.ID?.toString() ?? "");
          if (item.ID !== undefined) {
            setSelectedOrganizationUnitById(item.ID);
          }
          //remember latest selected OU in order to act as the initial OU when returning to a page with an OU selector
          setLatestSelectedOrganizationUnitID(item.ID);
        }
      }
    },
    [
      flattenedOrganizationUnits,
      selectedOrganizationUnit?.ID,
      setSelectedOrganizationUnitById,
      setLatestSelectedOrganizationUnitID,
    ],
  );

  // Execute callback whenever selected organization unit changes
  useEffect(() => {
    if (selectedOrganizationUnit) {
      setServiceSetting("requestedOrganizationUnitID", selectedOrganizationUnit?.ID);
    }
  }, [selectedOrganizationUnit]);

  return {
    selectedOrganizationUnit,
    handleOUSelection,
  };
};
