export enum Paazl {
  /**
   * Specified `Carrier` is not configured as Shipping Method
   */
  ShippingMethodNotConfigured = "Paazl:ShippingMethodNotConfigured",
  /**
   * Missing `ZipCode` that is required to retrieve shipping options
   */
  MissingZipCode = "Paazl:MissingZipCode",
  /**
   * Missing `CountryID` that is required to retrieve shipping options
   */
  MissingCountryID = "Paazl:MissingCountryID",
  /**
   * Invalid shipping option
   */
  InvalidShippingOption = "Paazl:InvalidShippingOption",
  /**
   * Please select one address
   */
  SelectAddress = "Paazl:SelectAddress",
  /**
   * Invalid shipping option address
   */
  InvalidAddress = "Paazl:InvalidAddress",
  /**
   * Please select one delivery date
   */
  SelectDeliveryDate = "Paazl:SelectDeliveryDate",
  /**
   * Invalid shipping delivery date
   */
  InvalidDeliveryDate = "Paazl:InvalidDeliveryDate",
}
export enum ZipBlobs {
  /**
   * Duplicate path '{0:string}' is not allowed
   */
  DuplicatePaths = "ZipBlobs:DuplicatePaths",
}
export enum Symphony {
  /**
   * The sheet could not be saved due to compile errors: {0:string}
   */
  CannotSaveSheetDueToCompileErrors = "Symphony:CannotSaveSheetDueToCompileErrors",
  /**
   * Sheet {id:number} cannot be compiled, errors: {errors:string}
   */
  SheetNotCompiling = "Symphony:SheetNotCompiling",
}
export enum Stripe {
  /**
   * Missing BackendID on payment {0:number}
   */
  MissingBackendID = "Stripe:MissingBackendID",
  /**
   * Failed make payment: '{0:string}'
   */
  PaymentFailed = "Stripe:PaymentFailed",
  /**
   * ApiKey is not yet configured
   */
  MissingApiKey = "Stripe:MissingApiKey",
  /**
   * Failed to setup webhooks: {0:string}
   */
  WebhooksFailed = "Stripe:WebhooksFailed",
}
export enum MergePdfBlobs {
  /**
   * Too many blobs
   */
  TooManyBlobs = "MergePdfBlobs:TooManyBlobs",
}
export enum BlobMerger {
  /**
   * Blob {blobID:string} has invalid mime type {mimeType:string}
   */
  InvalidMimeType = "BlobMerger:InvalidMimeType",
  /**
   * Merging PDFs failed
   */
  MergeFailed = "BlobMerger:MergeFailed",
}
export enum AsyncResult {
  /**
   * JobID cannot be null
   */
  JobIDRequired = "AsyncResult:JobIDRequired",
}
export enum VisibilityGroups {
  /**
   * The default visibility group cannot be mutated.
   */
  DefaultIsImmutable = "VisibilityGroups:DefaultIsImmutable",
  /**
   * Name '{0}' already exists.
   */
  NameAlreadyExists = "VisibilityGroups:NameAlreadyExists",
  /**
   * The selected visibility group is not visible for the current user.
   */
  SelectedVisibilityGroupIsNotVisibleForUser = "VisibilityGroups:SelectedVisibilityGroupIsNotVisibleForUser",
  /**
   * There is already a User with the same EmailAddress in the selected visibility group.
   */
  EmailAddressAlreadyInUse = "VisibilityGroups:EmailAddressAlreadyInUse",
  /**
   * There is already a User with the same Nickname in the selected visibility group.
   */
  NicknameAlreadyInUse = "VisibilityGroups:NicknameAlreadyInUse",
}
export enum FullStockCount {
  /**
   * There is currently a Full Stock Count in progress.
   */
  CountInProgress = "FullStockCount:CountInProgress",
  /**
   * An active/uncompleted (External) FullStockCount already exists.
   */
  FullStockCountAlreadyExists = "FullStockCount:FullStockCountAlreadyExists",
  /**
   * Full Stock Count is not available for this action.
   */
  CountNotAvailable = "FullStockCount:CountNotAvailable",
  /**
   * Full Stock Count label is not available for this action.
   */
  LabelNotAvailable = "FullStockCount:LabelNotAvailable",
  /**
   * Specify a FullStockCountID or FullStockCountLabelID.
   */
  SpecifyCountOrLabel = "FullStockCount:SpecifyCountOrLabel",
  /**
   * Please recount the specific product and that product only.
   */
  IncorrectProduct = "FullStockCount:IncorrectProduct",
  /**
   * Recount must be performed by another user.
   */
  RecountMustBePerformedByAnotherUser = "FullStockCount:RecountMustBePerformedByAnotherUser",
  /**
   * The is no shelf planning present.
   */
  NoShelfPlanningPresent = "FullStockCount:NoShelfPlanningPresent",
  /**
   * Full stock count hasn't started yet.
   */
  FullStockCountHasntStartedYet = "FullStockCount:FullStockCountHasntStartedYet",
  /**
   * Label {0:string} cannot be used for full stock count.
   */
  LabelCannotBeUsedForFullStockCount = "FullStockCount:LabelCannotBeUsedForFullStockCount",
  /**
   * Cannot recount products when there are still labels open.
   */
  CannotRecountProductsWhenThereAreUncompletedLabels = "FullStockCount:CannotRecountProductsWhenThereAreUncompletedLabels",
  /**
   * Cannot recount StockLabels {0:string} because there a no FullStockCountLabels present for these StockLabels.
   */
  CannotRecountStockLabelsWithoutFullStockCountLabels = "FullStockCount:CannotRecountStockLabelsWithoutFullStockCountLabels",
  /**
   * Cannot recount uncompleted label
   */
  CannotRecountUncompletedLabel = "FullStockCount:CannotRecountUncompletedLabel",
  /**
   * Cannot recount label with uncompleted recounts
   */
  CannotRecountLabelWithUncompletedRecounts = "FullStockCount:CannotRecountLabelWithUncompletedRecounts",
  /**
   * Recount label {labelID:number} refers to non exisisting origin label {originLabelID:number}
   */
  RecountLabelRefersToANonExisistingOriginLabel = "FullStockCount:RecountLabelRefersToANonExisistingOriginLabel",
  /**
   * Label only accepts counts from predefined products within the label.
   */
  LabelOnlyAcceptsPredefinedProducts = "FullStockCount:LabelOnlyAcceptsPredefinedProducts",
  /**
   * Cannot add label to an external full stock count. Use either the 'SetExternalFullStockCountResult' or 'UploadExternalFullStockCountResult' service to set the results of the external full stock count.
   */
  CannotAddLabelToExternalFullStockCount = "FullStockCount:CannotAddLabelToExternalFullStockCount",
  /**
   * External full stock counts cannot be recounted. Use either the 'SetExternalFullStockCountResult' or 'UploadExternalFullStockCountResult' service to set the results of the external full stock count.
   */
  ExternalFullStockCountsCannotBeRecounted = "FullStockCount:ExternalFullStockCountsCannotBeRecounted",
  /**
   * The external full stock count reached the completion threshold. Result will no longer be accepted and task can only be cancelled.
   */
  FullStockCountCompletionThresholdReached = "FullStockCount:FullStockCountCompletionThresholdReached",
  /**
   * External full stock counts cannot be started.
   */
  ExternalFullStockCountsCannotBeStarted = "FullStockCount:ExternalFullStockCountsCannotBeStarted",
  /**
   * Can only upload results to external full stock counts.
   */
  CannotUploadResultToANonExternalFullStockCount = "FullStockCount:CannotUploadResultToANonExternalFullStockCount",
  /**
   * External full stock count already completed.
   */
  ExternalFullStockCountAlreadyCompleted = "FullStockCount:ExternalFullStockCountAlreadyCompleted",
  /**
   * External full stock count cancelled.
   */
  ExternalFullStockCountCancelled = "FullStockCount:ExternalFullStockCountCancelled",
  /**
   * External full stock count results still processing.
   */
  ExternalFullStockCountDataStillProcessing = "FullStockCount:ExternalFullStockCountDataStillProcessing",
  /**
   * Cannot complete FullStockCount with active FullStockCountLabel tasks.
   */
  CannotCompleteFullStockCountWithActiveFullStockCountLabelTasks = "FullStockCount:CannotCompleteFullStockCountWithActiveFullStockCountLabelTasks",
  /**
   * Either `RecountItems` or `ProductIDs` is required.
   */
  EitherRecountItemsOrProductIDsIsRequired = "FullStockCount:EitherRecountItemsOrProductIDsIsRequired",
  /**
   * Full stock count {fullStockCountID:number} stock snapshot blob '{blobID:string}' was no longer present. Could not process the full stock count.
   */
  StockSnapshotExpired = "FullStockCount:StockSnapshotExpired",
  /**
   * Negative quantity is forbidden
   */
  NegativeQuantityForbidden = "FullStockCount:NegativeQuantityForbidden",
}
export enum UserFieldRequirements {
  /**
   * Requirements for field are not met.
   */
  RequirementsNotMet = "UserFieldRequirements:RequirementsNotMet",
}
export enum Subscriptions {
  /**
   * A subscription with this name already exists
   */
  DuplicateName = "Subscriptions:DuplicateName",
  /**
   * A subscription with this BackendID already exists
   */
  DuplicateBackendID = "Subscriptions:DuplicateBackendID",
  /**
   * User '{0:number}' is not or no longer subscribed to subscription {1:number}
   */
  UserIsNotSubscribed = "Subscriptions:UserIsNotSubscribed",
  /**
   * Cannot delete subscription because there are active subscriptions on it.
   */
  CannotDeleteBecauseOfActiveSubscriptions = "Subscriptions:CannotDeleteBecauseOfActiveSubscriptions",
  /**
   * Loyalty program is required for loyalty subscription
   */
  LoyaltyProgramIsRequiredForLoyaltySubscription = "Subscriptions:LoyaltyProgramIsRequiredForLoyaltySubscription",
  /**
   * Loyalty program is invalid
   */
  InvalidLoyaltyProgramProvidedForSubscription = "Subscriptions:InvalidLoyaltyProgramProvidedForSubscription",
  /**
   * Can only set loyalty program when handler is loyalty handler
   */
  CanOnlySetLoyaltyProgramWhenHandlerIsLoyaltyHandler = "Subscriptions:CanOnlySetLoyaltyProgramWhenHandlerIsLoyaltyHandler",
  /**
   * Subscription handler does not allow user identifier input
   */
  SubscriptionHandlerDoesNotAllowUserIdentifierInput = "Subscriptions:SubscriptionHandlerDoesNotAllowUserIdentifierInput",
  /**
   * Subscription does not allow user identifier input
   */
  SubscriptionDoesNotAllowUserIdentifierInput = "Subscriptions:SubscriptionDoesNotAllowUserIdentifierInput",
  /**
   * Loyalty handler does not allow user identifier input
   */
  LoyaltyHandlerDoesNotAllowUserIdentifierInput = "Subscriptions:LoyaltyHandlerDoesNotAllowUserIdentifierInput",
  /**
   * Unknown loyalty program
   */
  UnknownLoyaltyProgram = "Subscriptions:UnknownLoyaltyProgram",
  /**
   * Subscription requires either a manual user identifier or a policy to generate one
   */
  SubscriptionRequiresEitherAManualUserIdentifierOrAPolicyToGenerateOne = "Subscriptions:SubscriptionRequiresEitherAManualUserIdentifierOrAPolicyToGenerateOne",
  /**
   * Cannot combine manual user identifier and user identifier generation
   */
  CannotCombineManualUserIdentifierAndUserIdentifierGeneration = "Subscriptions:CannotCombineManualUserIdentifierAndUserIdentifierGeneration",
  /**
   * Start number is required for user identifier generation
   */
  StartNumberIsRequiredForUserIdentifierGeneration = "Subscriptions:StartNumberIsRequiredForUserIdentifierGeneration",
  /**
   * The user should have an EmailAddress to subscribe to a newsletter.
   */
  EmailAddressRequired = "Subscriptions:EmailAddressRequired",
  /**
   * Subscription handler does not allow confirmation
   */
  SubscriptionHandlerDoesNotAllowConfirmation = "Subscriptions:SubscriptionHandlerDoesNotAllowConfirmation",
  /**
   * {0:string} is not a supported confirmation method.
   */
  UnsupportedSubscriptionConfirmationMethod = "Subscriptions:UnsupportedSubscriptionConfirmationMethod",
  /**
   * A loyalty program cannot be attached to multiple subscriptions
   */
  ALoyaltyProgramCannotBeAttachedToMultipleSubscriptions = "Subscriptions:ALoyaltyProgramCannotBeAttachedToMultipleSubscriptions",
}
export enum LoyaltyPrograms {
  /**
   * Handler for subscription {0:number} is unknown.
   */
  UnknownSubscriptionHandler = "LoyaltyPrograms:UnknownSubscriptionHandler",
  /**
   * Subscription is fully external and cannot be pushed.
   */
  ExternalSubscriptionsCannotBePushed = "LoyaltyPrograms:ExternalSubscriptionsCannotBePushed",
  /**
   * Subscription is not a loyalty subscription so it cannot accept Balance.
   */
  SubscriptionIsNotALoyaltySubscription = "LoyaltyPrograms:SubscriptionIsNotALoyaltySubscription",
  /**
   * Unable to unsubscribe user.
   */
  UnableToUnsubscribeUser = "LoyaltyPrograms:UnableToUnsubscribeUser",
  /**
   * Only loyalty programs with the `EVALOYALTY` handler can be provided with a balance.
   */
  OnlyEVALoyaltyHandlerAcceptsBalance = "LoyaltyPrograms:OnlyEVALoyaltyHandlerAcceptsBalance",
  /**
   * Cannot deposit negative balance.
   */
  CannotDepositNegativeBalance = "LoyaltyPrograms:CannotDepositNegativeBalance",
  /**
   * Handler is required
   */
  MissingHandler = "LoyaltyPrograms:MissingHandler",
  /**
   * The given handler doesn't exist
   */
  UnknownHandler = "LoyaltyPrograms:UnknownHandler",
  /**
   * The data is not in a valid format for the selected handler
   */
  InvalidHandlerData = "LoyaltyPrograms:InvalidHandlerData",
  /**
   * Loyalty program is not set to 'Awarding'.
   */
  UsageTypeIsNotSetToAwarding = "LoyaltyPrograms:UsageTypeIsNotSetToAwarding",
  /**
   * Handler does not allow deposits.
   */
  HandlerDoesNotAllowDeposits = "LoyaltyPrograms:HandlerDoesNotAllowDeposits",
  /**
   * Handler does not allow withdrawals.
   */
  HandlerDoesNotAllowWithdrawals = "LoyaltyPrograms:HandlerDoesNotAllowWithdrawals",
  /**
   * Point deposit failed.
   */
  PointDepositFailed = "LoyaltyPrograms:PointDepositFailed",
  /**
   * Point withdraw failed.
   */
  PointWithdrawFailed = "LoyaltyPrograms:PointWithdrawFailed",
  /**
   * Cannot delete loyalty program because it still has active subscriptions and the deletion isn't forced.
   */
  CannotDeleteLoyaltyProgramBecauseOfActiveSubscriptions = "LoyaltyPrograms:CannotDeleteLoyaltyProgramBecauseOfActiveSubscriptions",
  /**
   * Cannot change budget currency.
   */
  CannotChangeBudgetCurrency = "LoyaltyPrograms:CannotChangeBudgetCurrency",
  /**
   * Cannot have multiple price list of the same currency.
   */
  CannotHaveMultiplePriceListOfTheSameCurrency = "LoyaltyPrograms:CannotHaveMultiplePriceListOfTheSameCurrency",
  /**
   * Can only connect a payment type once.
   */
  CanOnlyConnectAPaymentTypeOnce = "LoyaltyPrograms:CanOnlyConnectAPaymentTypeOnce",
  /**
   * Only payment types bound to the loyalty program payment method are allowed.
   */
  OnlyPaymentTypesBoundToTheLoyaltyProgramPaymentMethodAreAllowed = "LoyaltyPrograms:OnlyPaymentTypesBoundToTheLoyaltyProgramPaymentMethodAreAllowed",
  /**
   * Condition {0:string} already present. Can only add a condition once.
   */
  LoyaltyProgramConditionAlreadyPresent = "LoyaltyPrograms:LoyaltyProgramConditionAlreadyPresent",
  /**
   * Condition {ConditionID:number} (type '{Name:string}') for loyalty program {ProgramID:number} was malformed or its build-up resulted in an exception.
   */
  InvalidLoyaltyProgramCondition = "LoyaltyPrograms:InvalidLoyaltyProgramCondition",
  /**
   * Data for condition {ConditionID:number} (type '{Name:string}') for loyalty program {ProgramID:number} was not initialized.
   */
  LoyaltyProgramConditionDataNotInitialized = "LoyaltyPrograms:LoyaltyProgramConditionDataNotInitialized",
  /**
   * Condition {conditionID:number} for loyalty point awarding rule {ruleID:number} was malformed or its build-up resulted in an exception.
   */
  InvalidPointAwardingCondition = "LoyaltyPrograms:InvalidPointAwardingCondition",
  /**
   * No products are provided
   */
  NoProductsGiven = "LoyaltyPrograms:NoProductsGiven",
  /**
   * Value of property `{0:string}` must be higher than or equal to 0.
   */
  ValueMustBePositive = "LoyaltyPrograms:ValueMustBePositive",
  /**
   * Value of property {PropertyNameX:string} must be equal to or larger than property {PropertyNameY:string}
   */
  ValueOfXMustBeLargerThanOrEqualToValueOfY = "LoyaltyPrograms:ValueOfXMustBeLargerThanOrEqualToValueOfY",
  /**
   * Value of property {PropertyNameX:string} must be larger than property {PropertyNameY:string}
   */
  ValueOfXMustBeLargerThanValueOfY = "LoyaltyPrograms:ValueOfXMustBeLargerThanValueOfY",
  /**
   * Can only set one product condition on a rule.
   */
  CanOnlySetOnePointAwardingProductCondition = "LoyaltyPrograms:CanOnlySetOnePointAwardingProductCondition",
  /**
   * The requested BackendID is already in use
   */
  DuplicateBackendID = "LoyaltyPrograms:DuplicateBackendID",
  /**
   * Either property ApplyToPriceList, ProductIDs, ProductSearchID, or Filters is required.
   */
  RequireAtLeaseOneProductLimitationProperty = "LoyaltyPrograms:RequireAtLeaseOneProductLimitationProperty",
  /**
   * Properties ApplyToPriceList, ProductIDs, ProductSearchID, and Filters cannot be combined.
   */
  ProductLimitationPropertiesAreMutuallyExclusive = "LoyaltyPrograms:ProductLimitationPropertiesAreMutuallyExclusive",
  /**
   * Can only create one product limitation for now.
   */
  CanOnlyCreateOneProductLimitationForNow = "LoyaltyPrograms:CanOnlyCreateOneProductLimitationForNow",
  /**
   * Cannot create a limitless limitation when pricelists are applied.
   */
  CannotCreateALimitlessLimitationWhenPriceListsAreApplied = "LoyaltyPrograms:CannotCreateALimitlessLimitationWhenPriceListsAreApplied",
  /**
   * Can only use cached product search templates (static or periodically refreshed).
   */
  CanOnlyUseCachedProductSearchTemplates = "LoyaltyPrograms:CanOnlyUseCachedProductSearchTemplates",
  /**
   * `UserID` is required when providing the `UserIdentifier`.
   */
  UserIDIsRequiredWhenProvidingTheUserIdentifier = "LoyaltyPrograms:UserIDIsRequiredWhenProvidingTheUserIdentifier",
  /**
   * Can only attach this loyalty program to an order when either the order already has a customer or the customer is provided in the request.
   */
  LoyaltyProgramRequiresCustomerToBeAttachedToTheOrder = "LoyaltyPrograms:LoyaltyProgramRequiresCustomerToBeAttachedToTheOrder",
  /**
   * Cannot attach loyalty program to (partially) paid or completed order.
   */
  CannotAttachLoyaltyProgramToPartiallyPaidOrCompletedOrder = "LoyaltyPrograms:CannotAttachLoyaltyProgramToPartiallyPaidOrCompletedOrder",
  /**
   * Cannot detach loyalty program from (partially) paid or completed order.
   */
  CannotDetachLoyaltyProgramFromPartiallyPaidOrCompletedOrder = "LoyaltyPrograms:CannotDetachLoyaltyProgramFromPartiallyPaidOrCompletedOrder",
  /**
   * Can only attach this loyalty program to an order when that has a customer that is subscribed to the loyalty program.
   */
  LoyaltyProgramsRequiresSubscribedCustomerToBeAttachedToTheOrder = "LoyaltyPrograms:LoyaltyProgramsRequiresSubscribedCustomerToBeAttachedToTheOrder",
  /**
   * Order does not meet the condition `{0:string}` of the loyalty program.
   */
  OrderDoesNotMeetConditionOfTheLoyaltyProgram = "LoyaltyPrograms:OrderDoesNotMeetConditionOfTheLoyaltyProgram",
  /**
   * Either LoyaltyProgramID or SubscriptionID is required
   */
  EitherLoyaltyProgramIDOrSubscriptionIDIsRequired = "LoyaltyPrograms:EitherLoyaltyProgramIDOrSubscriptionIDIsRequired",
  /**
   * LoyaltyProgram does not match given SubscriptionID
   */
  LoyaltyProgramDoesNotMatchGivenSubscriptionID = "LoyaltyPrograms:LoyaltyProgramDoesNotMatchGivenSubscriptionID",
  /**
   * LoyaltyProgram has required payment types that need to be selected/used.
   */
  LoyaltyProgramHasRequiredPaymentTypes = "LoyaltyPrograms:LoyaltyProgramHasRequiredPaymentTypes",
  /**
   * Cannot update the type of a loyalty program condition.
   */
  CannotUpdateConditionType = "LoyaltyPrograms:CannotUpdateConditionType",
  /**
   * Can only require order custom fields.
   */
  CanOnlyRequireOrderCustomFields = "LoyaltyPrograms:CanOnlyRequireOrderCustomFields",
  /**
   * Push loyalty programs limit exceeded.
   */
  PushLoyaltyProgramsLimitExceeded = "LoyaltyPrograms:PushLoyaltyProgramsLimitExceeded",
  /**
   * Unknown error while pushing loyalty program with BackendID `{0:string}`
   */
  UnknownPushLoyaltyProgramError = "LoyaltyPrograms:UnknownPushLoyaltyProgramError",
}
export enum UserSubscriptions {
  /**
   * User identifier required.
   */
  UserIdentifierRequired = "UserSubscriptions:UserIdentifierRequired",
  /**
   * Cannot provide user identifier when user identifier generation is enabled.
   */
  CannotProvideUserIdentifierWhenUserIdentifierGenerationIsEnabled = "UserSubscriptions:CannotProvideUserIdentifierWhenUserIdentifierGenerationIsEnabled",
  /**
   * UserIdentifier already exists.
   */
  UserIdentifierAlreadyExists = "UserSubscriptions:UserIdentifierAlreadyExists",
  /**
   * User already subscribed.
   */
  UserAlreadySubscribed = "UserSubscriptions:UserAlreadySubscribed",
  /**
   * User has pending subscription awaiting confirmation
   */
  UserHasPendingSubscriptionAwaitingConfirmation = "UserSubscriptions:UserHasPendingSubscriptionAwaitingConfirmation",
  /**
   * Cannot resubscribe to this subscription.
   */
  CannotResubscribeToThisSubscription = "UserSubscriptions:CannotResubscribeToThisSubscription",
  /**
   * Can only resubscribe with original user identifier.
   */
  CanOnlyResubscribeWithOriginalUserIdentifier = "UserSubscriptions:CanOnlyResubscribeWithOriginalUserIdentifier",
  /**
   * Can only resubscribe with new user identifier.
   */
  CanOnlyResubscribeWithNewUserIdentifier = "UserSubscriptions:CanOnlyResubscribeWithNewUserIdentifier",
  /**
   * Subscription does not allow unsubscribing.
   */
  SubscriptionDoesNotAllowUnsubscribing = "UserSubscriptions:SubscriptionDoesNotAllowUnsubscribing",
  /**
   * Unsubscribe can only be done by subscribed user.
   */
  UnsubscribeCanOnlyBeDoneBySubscribedUser = "UserSubscriptions:UnsubscribeCanOnlyBeDoneBySubscribedUser",
  /**
   * Unsubscribe can only be done by employee or api user.
   */
  UnsubscribeCanOnlyBeDoneByEmployeeOrAPIUser = "UserSubscriptions:UnsubscribeCanOnlyBeDoneByEmployeeOrAPIUser",
  /**
   * Unsubscribe from handler '{0:string}' failed.
   */
  UnsubscribeFromHandlerFailed = "UserSubscriptions:UnsubscribeFromHandlerFailed",
  /**
   * Subscribe to handler '{0:string}' failed.
   */
  SubscribeToHandlerFailed = "UserSubscriptions:SubscribeToHandlerFailed",
}
export enum ExternalUserProvider {
  /**
   * Failed to execute the call to the external user provider
   */
  CallFailed = "ExternalUserProvider:CallFailed",
  /**
   * The user could not be found in the external user provider
   */
  UnknownUser = "ExternalUserProvider:UnknownUser",
}
export enum UserCards {
  /**
   * UserCards with Type Discount can only be created internally by the Discount engine.
   */
  DiscountTypeNotAllowed = "UserCards:DiscountTypeNotAllowed",
  /**
   * UserCards with Type Loyalty can only be managed internally.
   */
  LoyaltyTypeNotAllowed = "UserCards:LoyaltyTypeNotAllowed",
  /**
   * UserCards with Type Loyalty can only be mutated internally.
   */
  LoyaltyTypeMutationsNotAllowed = "UserCards:LoyaltyTypeMutationsNotAllowed",
  /**
   * CurrencyID is required when creating Currency UserCards.
   */
  CurrencyRequired = "UserCards:CurrencyRequired",
  /**
   * UserCards with Type Discount can't create paymenttransactions.
   */
  DiscountTypePayment = "UserCards:DiscountTypePayment",
}
export enum UserBoughtProduct {
  /**
   * Product is already registered.
   */
  AlreadyRegistered = "UserBoughtProduct:AlreadyRegistered",
  /**
   * Unknown user bought product.
   */
  UnknownUserBoughtProduct = "UserBoughtProduct:UnknownUserBoughtProduct",
  /**
   * Unknown user bought product detail.
   */
  UnknownUserBoughtProductDetail = "UserBoughtProduct:UnknownUserBoughtProductDetail",
  /**
   * The given OrderID/OrderLineID is an invalid combination.
   */
  InvalidOrderAndLineCombination = "UserBoughtProduct:InvalidOrderAndLineCombination",
  /**
   * User limit of {0:number} exceeded.
   */
  PushBoughtProductForUsersLimitExceeded = "UserBoughtProduct:PushBoughtProductForUsersLimitExceeded",
}
export enum UserBorrowedProducts {
  /**
   * User cannot register borrowed products on it's own account.
   */
  UserCannotBorrowProductsForHimSelf = "UserBorrowedProducts:UserCannotBorrowProductsForHimSelf",
  /**
   * User cannot return register borrowed products from it's own account.
   */
  UserCannotReturnBorrowedProductsForHimSelf = "UserBorrowedProducts:UserCannotReturnBorrowedProductsForHimSelf",
  /**
   * User cannot return purchase borrowed products from it's own account.
   */
  UserCannotPurchaseBorrowedProductsForHimSelf = "UserBorrowedProducts:UserCannotPurchaseBorrowedProductsForHimSelf",
  /**
   * User doesn't have this product registerd as borrowed.
   */
  UserDoesNotHaveThisProductRegisterdAsBorrowed = "UserBorrowedProducts:UserDoesNotHaveThisProductRegisterdAsBorrowed",
  /**
   * Cannot purchase more products then that are still borrowed
   */
  CannotReturnMoreProductsThenBorrowed = "UserBorrowedProducts:CannotReturnMoreProductsThenBorrowed",
  /**
   * Cannot purchase more products then that are still borrowed
   */
  CannotPurchaseMoreProductsThenBorrowed = "UserBorrowedProducts:CannotPurchaseMoreProductsThenBorrowed",
  /**
   * Cannot purchase borrowed products for multiple users on the same order.
   */
  CannotPurchaseBorrowedItemsForMultipleUsersOnTheSameOrder = "UserBorrowedProducts:CannotPurchaseBorrowedItemsForMultipleUsersOnTheSameOrder",
}
export enum TaxCodeMapping {
  /**
   * An entry for the given tax code and tax provider already exists.
   */
  InvalidMapping = "TaxCodeMapping:InvalidMapping",
}
export enum TaxProvider {
  /**
   * The configuration data is invalid.
   * {0:string}
   */
  InvalidConfigurationData = "TaxProvider:InvalidConfigurationData",
  /**
   * The provided Type is invalid.
   */
  InvalidType = "TaxProvider:InvalidType",
  /**
   * Name already exists.
   */
  InvalidName = "TaxProvider:InvalidName",
  /**
   * No tax provider configuration exists for {0:number}.
   */
  NoConfiguration = "TaxProvider:NoConfiguration",
  /**
   * Name, Type and BackendID cannot be null.
   */
  InvalidRequiredFields = "TaxProvider:InvalidRequiredFields",
}
export enum StockMutator {
  /**
   * Cannot ignore serialnumber check when a serialnumber is provided.
   */
  CannotIgnoreSerialNumber = "StockMutator:CannotIgnoreSerialNumber",
}
export enum ShippingMethod {
  /**
   * Either Code or Handler should have a value.
   */
  CodeOrHandlerShouldBeSet = "ShippingMethod:CodeOrHandlerShouldBeSet",
  /**
   * An invalid handler has been provided.
   */
  InvalidShippingMethodHandler = "ShippingMethod:InvalidShippingMethodHandler",
}
export enum ModifyQuantityShipped {
  /**
   * Order {0:number} is not a PurchaseOrder
   */
  NoPurchaseOrder = "ModifyQuantityShipped:NoPurchaseOrder",
  /**
   * Shipment {0:number} is already completed
   */
  ShipmentIsCompleted = "ModifyQuantityShipped:ShipmentIsCompleted",
  /**
   * Shipment {0:number} is already exported
   */
  ShipmentIsExported = "ModifyQuantityShipped:ShipmentIsExported",
  /**
   * ShipmentLine {0:number} is already completed
   */
  ShipmentLineIsCompleted = "ModifyQuantityShipped:ShipmentLineIsCompleted",
  /**
   * ShipmentLine {0:number} is already fully delivered
   */
  ShipmentLineIsFullyDelivered = "ModifyQuantityShipped:ShipmentLineIsFullyDelivered",
  /**
   * OrderLine {OrderLineID:number} of ShipmentLine {ShipmentLineID:number} is already invoiced
   */
  OrderLineIsInvoiced = "ModifyQuantityShipped:OrderLineIsInvoiced",
  /**
   * ShipmentLine {0:number} has already more Delivered
   */
  ShipmentLineHasMoreDeliveredThanRequested = "ModifyQuantityShipped:ShipmentLineHasMoreDeliveredThanRequested",
}
export enum SerialNumbers {
  /**
   * Serial number is required
   */
  SerialNumberRequired = "SerialNumbers:SerialNumberRequired",
  /**
   * Product is required when setting a serial number
   */
  ProductRequired = "SerialNumbers:ProductRequired",
  /**
   * Quantity does not match serial number count
   */
  InvalidQuantity = "SerialNumbers:InvalidQuantity",
  /**
   * Serial number {0:string} is invalid
   */
  SerialNumberInvalid = "SerialNumbers:SerialNumberInvalid",
  /**
   * Duplicate serial number {0:string}
   */
  DuplicateSerialNumber = "SerialNumbers:DuplicateSerialNumber",
  /**
   * Serial number {0:string} product does not match
   */
  InvalidProduct = "SerialNumbers:InvalidProduct",
  /**
   * Serial number {0:string} cannot be received because it was never shipped.
   */
  SerialNumberNotShipped = "SerialNumbers:SerialNumberNotShipped",
  /**
   * Serial Number Configuration cannot be deleted because it is still used by existing Serial Number Products.
   */
  CannotDeleteUsedConfiguration = "SerialNumbers:CannotDeleteUsedConfiguration",
  /**
   * Request contains duplicate serial numbers
   */
  SerialNumberDuplicates = "SerialNumbers:SerialNumberDuplicates",
  /**
   * The provided Serial Number does not match with the existing one.
   */
  SerialNumberDoesNotMatch = "SerialNumbers:SerialNumberDoesNotMatch",
}
export enum Sellability {
  /**
   * Product {0:number} is not sellable.
   */
  ProductNotSellable = "Sellability:ProductNotSellable",
}
export enum SecurityPolicy {
  /**
   * New password is required.
   */
  NewPasswordIsRequired = "SecurityPolicy:NewPasswordIsRequired",
  /**
   * User or password is not valid.
   */
  UserOrPasswordInvalid = "SecurityPolicy:UserOrPasswordInvalid",
  /**
   * Password is too short - it must be at least 8 characters.
   */
  TooShort = "SecurityPolicy:TooShort",
  /**
   * Password authentication is disabled for this user.
   */
  PasswordAuthenticationDisabled = "SecurityPolicy:PasswordAuthenticationDisabled",
  /**
   * Password is too long - 1024 seems like a decent enough length for a strong password, no?
   */
  TooLong = "SecurityPolicy:TooLong",
  /**
   * This password has been found in one or more breaches and should not be used (see https://haveibeenpwned.com/Passwords).
   */
  Pwned = "SecurityPolicy:Pwned",
  /**
   * Password safety check could not be performed.
   */
  PwnedUnavailable = "SecurityPolicy:PwnedUnavailable",
}
export enum Url {
  /**
   * Url is empty.
   */
  UrlEmpty = "Url:UrlEmpty",
  /**
   * Url is not valid.
   */
  UrlInvalid = "Url:UrlInvalid",
  /**
   * Url does not match expectation.
   */
  UrlDoesNotMatch = "Url:UrlDoesNotMatch",
}
export enum Passkey {
  /**
   * Malformed request
   */
  MalformedRequest = "Passkey:MalformedRequest",
  /**
   * Invalid challenge
   */
  InvalidChallenge = "Passkey:InvalidChallenge",
  /**
   * Invalid signature
   */
  InvalidSignature = "Passkey:InvalidSignature",
}
export enum MagicLink {
  /**
   * Magic links are disabled by your administrator.
   */
  Disabled = "MagicLink:Disabled",
  /**
   * The redirect URL was not configured as supported domain.
   */
  NotConfiguredDomain = "MagicLink:NotConfiguredDomain",
  /**
   * The provided combination of Email Address and OrderID yielded no results.
   */
  EmailOrderMismatch = "MagicLink:EmailOrderMismatch",
  /**
   * Manual codes through magic links are disabled by your administrator.
   */
  ManualCodeDisabled = "MagicLink:ManualCodeDisabled",
}
export enum ReceiveShipment {
  /**
   * Shipment {0:number} cannot be found.
   */
  ShipmentNotFound = "ReceiveShipment:ShipmentNotFound",
  /**
   * Shipment {0:number} has already been completed, can no longer receive it.
   */
  ShipmentCompleted = "ReceiveShipment:ShipmentCompleted",
  /**
   * Shipment {0:number} is still pending, delete the shipment instead of cancelling it..
   */
  ShipmentPending = "ReceiveShipment:ShipmentPending",
  /**
   * Shipment {0:number} has already been (partly) received, can no longer cancel it.
   */
  ShipmentReceived = "ReceiveShipment:ShipmentReceived",
  /**
   * Cannot receive product {0:number} without reference because it is already in the shipment
   */
  CannotReceiveProductWithoutReference = "ReceiveShipment:CannotReceiveProductWithoutReference",
}
export enum ProductRequirements {
  /**
   * The given productrequirement doesn't match the product on this orderline.
   */
  InvalidProductRequirement = "ProductRequirements:InvalidProductRequirement",
  /**
   * ProductRequirement '{0:string}' is an array, but the given value is not.
   */
  IsArray = "ProductRequirements:IsArray",
  /**
   * ProductRequirement '{ProductRequirementName:string}' is an {ValueType:string}, but the given value is not.
   */
  InvalidType = "ProductRequirements:InvalidType",
  /**
   * ProductRequirement '{0:string}' is an enum, but the given value is not a valid enum value.
   */
  InvalidArrayValue = "ProductRequirements:InvalidArrayValue",
  /**
   * ProductRequirement '{0:string}' is required.
   */
  IsRequired = "ProductRequirements:IsRequired",
}
export enum ProductBundles {
  /**
   * Cannot create a ProductBundle for Product {0:number} because it's not a BundleProduct
   */
  BundleProductRequired = "ProductBundles:BundleProductRequired",
  /**
   * A ProductBundleLine requires at least one ProductBundleLineOption.
   */
  OptionsRequired = "ProductBundles:OptionsRequired",
  /**
   * Creating a ProductBundleLine when there are multiple Options requires selecting a DefaultProductID.
   */
  DefaultProductRequired = "ProductBundles:DefaultProductRequired",
  /**
   * Product {0:number} cannot be found.
   */
  UnknownProduct = "ProductBundles:UnknownProduct",
  /**
   * Creating a ProductBundleLine where one of the options is a Configurable product requires a DefaultProductID.
   */
  DefaultProductRequiredForConfigurable = "ProductBundles:DefaultProductRequiredForConfigurable",
  /**
   * Product {0:number} is not one of the options nor part of the hierarchy of the options.
   */
  InvalidDefaultProduct = "ProductBundles:InvalidDefaultProduct",
  /**
   * When creating/updating a ProductBundle a ProductBundleID or a BundleProductID is required
   */
  ProductBundleIDOrBundleProductIDRequired = "ProductBundles:ProductBundleIDOrBundleProductIDRequired",
  /**
   * Product {0:number} can not be used as default product for bundle line {1:string} because it can be excluded by an option on bundle line {2:string}.
   */
  DefaultProductCouldBeExcluded = "ProductBundles:DefaultProductCouldBeExcluded",
  /**
   * The ProductBundleLine contains the same product ({0:number}) multiple times.
   */
  DuplicateOption = "ProductBundles:DuplicateOption",
  /**
   * Only ProductBundleLines of type Optional allow selecting multiple options.
   */
  MultiSelectOnlyPossibleOnOptionalLines = "ProductBundles:MultiSelectOnlyPossibleOnOptionalLines",
  /**
   * An option refers to the same product as the root bundle product, causing an infinite recursion!
   */
  InfiniteRecursion = "ProductBundles:InfiniteRecursion",
  /**
   * ProductBundleLine {0:number} cannot be found on this bundle.
   */
  UnknownProductBundleLine = "ProductBundles:UnknownProductBundleLine",
  /**
   * Product {selectedProductID:number} is not a valid option for ProductBundleLineID {productBundleLineID:number}
   */
  InvalidProductSelection = "ProductBundles:InvalidProductSelection",
  /**
   * It's no longer possible to switch products for ProductBundleLineID {productBundleLineID:number} on Order {orderID:number}
   */
  CannotChangeProductID = "ProductBundles:CannotChangeProductID",
  /**
   * The selection contains multiple lines for ProductBundleLineID {0:number}
   */
  DuplicateProductBundleLines = "ProductBundles:DuplicateProductBundleLines",
  /**
   * The selection contains {SelectedCount:number} for ProductBundleLineID {ProductBundleLineID:number}, while only a maximum of {MaximumCount:number} is allowed.
   */
  MoreOptionsSelectedThanAllowed = "ProductBundles:MoreOptionsSelectedThanAllowed",
  /**
   * Selected product {selectedProductID:number} on ProductBundleLine {productBundleLineID:number} is excluded by the selected option on ProductBundleLine {excludingProductBundleLineID:number}
   */
  ProductIsExcludedByOtherProductBundleLine = "ProductBundles:ProductIsExcludedByOtherProductBundleLine",
}
export enum PriceCalculation {
  /**
   * PriceList {0:number} references itself.
   */
  PriceListReferencesItself = "PriceCalculation:PriceListReferencesItself",
  /**
   * PriceList {priceListID:number} depends on deleted pricelist {adjustmentPriceListID:number}.
   */
  PriceListDependsOnDeletedPriceList = "PriceCalculation:PriceListDependsOnDeletedPriceList",
  /**
   * PriceList {priceListID:number} has a component {adjustmentPriceListID:number} that does not have the required data object.
   */
  MissingData = "PriceCalculation:MissingData",
}
export enum PriceLists {
  /**
   * Currency mismatch: OrganizationUnitID {organizationUnitID:number} uses CurrencyID {ouCurrency:string} but PriceList {priceListID:number} uses CurrencyID {priceListCurrency:string}
   */
  CurrencyMismatch = "PriceLists:CurrencyMismatch",
  /**
   * OrganizationUnit {organizationUnit:string} already uses pricelist {priceListName:string} for {usageType:string}, remove it first before attaching a new pricelist.
   */
  PriceListAlreadyAttachedToOrganizationUnit = "PriceLists:PriceListAlreadyAttachedToOrganizationUnit",
  /**
   * You are not allowed to attach a pricelist of type {UsageType:string} to organization unit {OrganizationUnitName:string}.
   */
  NotAllowed = "PriceLists:NotAllowed",
  /**
   * The adjustment or provided data for the adjustment is invalid.
   */
  InvalidAdjustment = "PriceLists:InvalidAdjustment",
  /**
   * Cannot modify or delete second chance product adjustment.
   */
  CannotModifyOrDeleteSecondChanceProductAdjustment = "PriceLists:CannotModifyOrDeleteSecondChanceProductAdjustment",
  /**
   * Historical pricelists are protected and cannot be modified.
   */
  HistoricalPricesAreProtectedAndCannotBeModified = "PriceLists:HistoricalPricesAreProtectedAndCannotBeModified",
}
export enum CreatePayment {
  /**
   * Could not calculate sales tax for this order.
   */
  TaxCalculationFailed = "CreatePayment:TaxCalculationFailed",
  /**
   * MultiCurrency is not available for this payment method.
   */
  MultiCurrencyNotAvailable = "CreatePayment:MultiCurrencyNotAvailable",
  /**
   * MultiCurrency is not available for this payment type.
   */
  MultiCurrencyNotAvailableForPaymentType = "CreatePayment:MultiCurrencyNotAvailableForPaymentType",
  /**
   * MultiCurrency is not available for refunds.
   */
  MultiCurrencyNotAvailableForRefunds = "CreatePayment:MultiCurrencyNotAvailableForRefunds",
  /**
   * There is no PaymentMethod available for the requested PaymentType {paymentTypeOrCode:string}, available methods are: {availableMethods:string}
   */
  NoPaymentMethodAvailable = "CreatePayment:NoPaymentMethodAvailable",
}
export enum Payments {
  /**
   * PaymentTransaction {0:number} can no longer be cancelled.
   */
  CancellationNotAvailable = "Payments:CancellationNotAvailable",
  /**
   * Failed to cancel PaymentTransaction {0:number}.
   */
  CancellationFailed = "Payments:CancellationFailed",
  /**
   * Auto shipment after confirm is only allowed in the context of a store.
   */
  AutoShipOnlyAllowedInShops = "Payments:AutoShipOnlyAllowedInShops",
  /**
   * The current order is not valid for auto shipment after confirm.
   */
  InvalidOrderForAutoShip = "Payments:InvalidOrderForAutoShip",
}
export enum PaymentMethods {
  /**
   * No internet connection available and payment method not usable offline
   */
  NotUsableOffline = "PaymentMethods:NotUsableOffline",
  /**
   * EVA is running on watchtower and this payment method is not supported
   */
  PaymentMethodNotUsableOnWatchtower = "PaymentMethods:PaymentMethodNotUsableOnWatchtower",
  /**
   * EVA is running normally and this payment method is only supported in ops/offline
   */
  PaymentMethodNotUsableInCloud = "PaymentMethods:PaymentMethodNotUsableInCloud",
}
export enum Settlements {
  /**
   * The summed amount of the settlements ({summedAmount:number}) doesn't match the totalamount ({totalAmount:number}).
   */
  AmountMismatch = "Settlements:AmountMismatch",
  /**
   * No financialperiod for settlement.
   */
  NoFinancialPeriod = "Settlements:NoFinancialPeriod",
}
export enum PaymentDispute {
  /**
   * PaymentTransaction {0:number} was already resolved.
   */
  AlreadyResolved = "PaymentDispute:AlreadyResolved",
  /**
   * PaymentTransaction {0:number} has no dipute.
   */
  NoDispute = "PaymentDispute:NoDispute",
  /**
   * PaymentTransaction {0:number} has no order attached.
   */
  NoOrderAttached = "PaymentDispute:NoOrderAttached",
}
export enum CustomPaymentMethod {
  /**
   * This payment needs to be confirmed
   */
  ConfirmationRequired = "CustomPaymentMethod:ConfirmationRequired",
  /**
   * Over payments are blocked on this payment method
   */
  BlockedOverPayments = "CustomPaymentMethod:BlockedOverPayments",
  /**
   * Currency not available for the current organization unit.
   */
  CurrencyNotAvailable = "CustomPaymentMethod:CurrencyNotAvailable",
  /**
   * Exchange rate is required.
   */
  ExchangeRateRequired = "CustomPaymentMethod:ExchangeRateRequired",
}
export enum OrganizationUnitSets {
  /**
   * An OrganizationUnit can only be part of a single set within the scope {scope:string}, but it appears in multiple sets: {setNames:string}
   */
  OrganizationUnitNotUniqueInScope = "OrganizationUnitSets:OrganizationUnitNotUniqueInScope",
  /**
   * OrganizationUnitSet {set:string} has scope {scope:string}, cannot add subset {subSet:string} with scope {subScope:string}
   */
  SubsetCannotHaveDifferentScope = "OrganizationUnitSets:SubsetCannotHaveDifferentScope",
  /**
   * OrganizationUnitSet {set:string} does not have a scope, cannot add subset {subset:string} with scope {subScope:string} to it.
   */
  SetWithoutScopeCannotHaveSubsetWithScope = "OrganizationUnitSets:SetWithoutScopeCannotHaveSubsetWithScope",
  /**
   * OrganizationUnitSet {set:string} has a Scope {scope:string} with OrganizationUnitUniqueInScope = true, which means it cannot contain dynamic sets.
   */
  ScopeCannotContainDynamicSet = "OrganizationUnitSets:ScopeCannotContainDynamicSet",
  /**
   * OrganizationUnitSet {setID:number} is its own subset. Reference chain: {recursionChain:string}
   */
  CircularReferenceInSet = "OrganizationUnitSets:CircularReferenceInSet",
  /**
   * All subsets are required for reordering.
   */
  AllSubsetsAreRequiredForReordering = "OrganizationUnitSets:AllSubsetsAreRequiredForReordering",
  /**
   * Duplicate subsets are not allowed when setting sequences.
   */
  DuplicateSubsetsNotAllowedWhenSequencing = "OrganizationUnitSets:DuplicateSubsetsNotAllowedWhenSequencing",
}
export enum OrganizationUnit {
  /**
   * ParentID is required.
   */
  MissingParentID = "OrganizationUnit:MissingParentID",
  /**
   * Name is required.
   */
  MissingName = "OrganizationUnit:MissingName",
  /**
   * EmailAddress is invalid.
   */
  InvalidEmailAddress = "OrganizationUnit:InvalidEmailAddress",
  /**
   * Subnet is invalid.
   */
  InvalidSubnet = "OrganizationUnit:InvalidSubnet",
  /**
   * IpAddress is invalid.
   */
  InvalidIpAddress = "OrganizationUnit:InvalidIpAddress",
  /**
   * Cannot enable endless stock on non supplier organizationUnit.
   */
  CannotEnableEndlessStockOnNonSupplierOrganizationUnit = "OrganizationUnit:CannotEnableEndlessStockOnNonSupplierOrganizationUnit",
  /**
   * Cannot remove the VisibilityGroup from an organizationUnit.
   */
  CannotRemoveVisibilityGroup = "OrganizationUnit:CannotRemoveVisibilityGroup",
  /**
   * Container cannot also be shop.
   */
  ContainerCannotAlsoBeShop = "OrganizationUnit:ContainerCannotAlsoBeShop",
  /**
   * Cash handler currency must be equal to organizationUnit currency.
   */
  InvalidCashHandler = "OrganizationUnit:InvalidCashHandler",
  /**
   * Cannot delete root organization unit.
   */
  CannotDeleteRootOrganizationUnit = "OrganizationUnit:CannotDeleteRootOrganizationUnit",
  /**
   * Cannot delete organization unit with children. Please delete the children first.
   */
  CannotDeleteOrganizationUnitWithChildren = "OrganizationUnit:CannotDeleteOrganizationUnitWithChildren",
  /**
   * It's not possible to change the CostPriceCurrencyMethod to FIFO for OrganizationUnit {0:string} when it has existing stock. Remove the stock first and re-create it by receiving a Purchase Order
   */
  CannotChangeToFifoWithExistingStock = "OrganizationUnit:CannotChangeToFifoWithExistingStock",
}
export enum LoyaltyAPI {
  /**
   * The configuration for this LoyaltyAPI is incomplete.
   */
  ConfigurationIncomplete = "LoyaltyAPI:ConfigurationIncomplete",
  /**
   * Could not validate the payment. Service returned the following error: {0:string}.
   */
  CouldNotValidatePayment = "LoyaltyAPI:CouldNotValidatePayment",
}
export enum EVALoyalty {
  /**
   * User is not (or no longer) subscribed.
   */
  UserIsNotOrNoLongerSubscribed = "EVALoyalty:UserIsNotOrNoLongerSubscribed",
  /**
   * Withdrawal not allowed.
   */
  WithdrawalNotAllowed = "EVALoyalty:WithdrawalNotAllowed",
  /**
   * Refund not allowed.
   */
  RefundNotAllowed = "EVALoyalty:RefundNotAllowed",
  /**
   * Invalid deposit transaction id.
   */
  InvalidDepositTransactionID = "EVALoyalty:InvalidDepositTransactionID",
  /**
   * Unknown deposit transaction id.
   */
  UnknownDepositTransactionID = "EVALoyalty:UnknownDepositTransactionID",
  /**
   * Cannot revoke more than deposited.
   */
  CannotRevokeMoreThanDeposited = "EVALoyalty:CannotRevokeMoreThanDeposited",
  /**
   * Cannot withdraw more than available.
   */
  CannotWithdrawMoreThanAvailable = "EVALoyalty:CannotWithdrawMoreThanAvailable",
  /**
   * Cannot refund more than there is left to refund.
   */
  CannotRefundMoreThanThereIsLeftToRefund = "EVALoyalty:CannotRefundMoreThanThereIsLeftToRefund",
  /**
   * Original transaction does not exist.
   */
  OriginalTransactionDoesNotExist = "EVALoyalty:OriginalTransactionDoesNotExist",
  /**
   * Can only refund on a withdraw transaction.
   */
  CanOnlyRefundOnAWithdrawTransaction = "EVALoyalty:CanOnlyRefundOnAWithdrawTransaction",
  /**
   * Can only revoke on a deposit transaction.
   */
  CanOnlyRevokeOnADepositTransaction = "EVALoyalty:CanOnlyRevokeOnADepositTransaction",
  /**
   * The LoyaltyPointAdjustmentType provided is not supported for LoyaltyPoint rebalances.
   */
  UnsupportedLoyaltyPointAdjustmentType = "EVALoyalty:UnsupportedLoyaltyPointAdjustmentType",
  /**
   * Too many mutations requested, the limit of this service is 10.000 mutations per request.
   */
  TooManyMutationsRequested = "EVALoyalty:TooManyMutationsRequested",
  /**
   * Only the EVALOYALTY can be used for this service.
   */
  UnsupportedHandlerForService = "EVALoyalty:UnsupportedHandlerForService",
  /**
   * Unknown error while mutating the user's loyalty point balance.
   */
  UnknownLoyaltyPointBalanceError = "EVALoyalty:UnknownLoyaltyPointBalanceError",
}
export enum PointPolicy {
  /**
   * The point policy `{0:string}` is unknown.
   */
  UnknownPointPolicy = "PointPolicy:UnknownPointPolicy",
  /**
   * Could not calculate the data because the data isn't initialized.
   */
  DataNotInitialized = "PointPolicy:DataNotInitialized",
  /**
   * {PolicyName:string} data for the {Type:PointPolicyType} policy in {parentType:string} {parentID:number} is required.
   */
  MissingPolicyData = "PointPolicy:MissingPolicyData",
  /**
   * {PolicyName:string} data for the {Type:PointPolicyType} policy in {parentType:string} {parentID:number} is invalid.
   */
  InvalidPolicyData = "PointPolicy:InvalidPolicyData",
}
export enum CustomPointPolicy {
  /**
   * The custom policy time interval `{0:string}` is not implemented.
   */
  NotImplementedCustomPolicyTimeInterval = "CustomPointPolicy:NotImplementedCustomPolicyTimeInterval",
  /**
   * Interval value must be higher then zero.
   */
  IntervalValueMustBeHigherThanZero = "CustomPointPolicy:IntervalValueMustBeHigherThanZero",
}
export enum ExpireEndOfYearPointPolicy {
  /**
   * LeniencyDays cannot be negative.
   */
  LeniencyDaysCannotBeNegative = "ExpireEndOfYearPointPolicy:LeniencyDaysCannotBeNegative",
}
export enum ExpireEndOfMonthPointPolicy {
  /**
   * LeniencyDays cannot be negative.
   */
  LeniencyDaysCannotBeNegative = "ExpireEndOfMonthPointPolicy:LeniencyDaysCannotBeNegative",
}
export enum ExpireSetDatePointPolicy {
  /**
   * Invalid month value.
   */
  InvalidMonthValue = "ExpireSetDatePointPolicy:InvalidMonthValue",
  /**
   * Invalid day value.
   */
  InvalidDayValue = "ExpireSetDatePointPolicy:InvalidDayValue",
  /**
   * LeniencyDays cannot be negative.
   */
  LeniencyDaysCannotBeNegative = "ExpireSetDatePointPolicy:LeniencyDaysCannotBeNegative",
}
export enum EvaClient {
  /**
   * Failed to call service {serviceName:string}: {error:string}
   */
  CallFailed = "EvaClient:CallFailed",
}
export enum LiveGuard {
  /**
   * No TOTP token generated for this store.
   */
  NoTOTPTokenGenerated = "LiveGuard:NoTOTPTokenGenerated",
}
export enum Invoices {
  /**
   * Missing billing details for invoice {0:string}
   */
  MissingBillingDetailsForInvoice = "Invoices:MissingBillingDetailsForInvoice",
  /**
   * Invoice {0:number} is not open for modification
   */
  CannotBeModified = "Invoices:CannotBeModified",
  /**
   * Could not calculate sales tax for order {0:number}.
   */
  TaxCalculationFailed = "Invoices:TaxCalculationFailed",
  /**
   * Dispute {0:number} is not open for modification
   */
  DisputeCannotBeModified = "Invoices:DisputeCannotBeModified",
  /**
   * Invoice {0:number} is already closed
   */
  AlreadyClosed = "Invoices:AlreadyClosed",
  /**
   * Invoice {invoiceID:number} has an original amount of {originalTotalAmount:number}, but the total amount is {totalAmount:number}.
   */
  TotalAmountMismatch = "Invoices:TotalAmountMismatch",
  /**
   * Invoice {invoiceID:number} has an expected amount of {expectedTotalAmount:number}, but the total amount is {totalAmount:number}.
   */
  ExpectedTotalAmountMismatch = "Invoices:ExpectedTotalAmountMismatch",
  /**
   * InvoiceAdditionalAmount {invoiceAdditionalAmountID:number} has an original amount of {originalTotalAmount:number}, but the total amount is {totalAmount:number}.
   */
  AdditionalAmountMismatch = "Invoices:AdditionalAmountMismatch",
  /**
   * Invoice {0:number} has another CalculationMethod than the OrderLine.
   */
  CalculationMismatch = "Invoices:CalculationMismatch",
  /**
   * An OrderLineID or ShipmentLineID must be supplied to create an InvoiceLine
   */
  OrderLineOrShipmentLineRequired = "Invoices:OrderLineOrShipmentLineRequired",
  /**
   * A Discount OrderLine cannot be invoiced, it will be invoiced based on the other lines
   */
  DiscountOrderLinesCantBeInvoiced = "Invoices:DiscountOrderLinesCantBeInvoiced",
  /**
   * A Dispute cannot be attached to an InvoiceLine and an AdditionalAmount
   */
  DisputeCannotBeAttachedTwice = "Invoices:DisputeCannotBeAttachedTwice",
  /**
   * There already exists a blob for invoice {invoiceID:number} and order {orderID:number}
   */
  InvoiceBlobAlreadyExists = "Invoices:InvoiceBlobAlreadyExists",
  /**
   * There already exists a blob for invoice {invoiceID:number}, attached to order {orderID:number}
   */
  InvoiceBlobAlreadyExistsForDifferentOrder = "Invoices:InvoiceBlobAlreadyExistsForDifferentOrder",
  /**
   * There is no email available to send the invoice.
   */
  EmailNotAvailable = "Invoices:EmailNotAvailable",
  /**
   * Invoice {0:number} has unresolved disputes
   */
  HasUnresolvedDisputes = "Invoices:HasUnresolvedDisputes",
  /**
   * There are no point of sale available for organization: {0:number}.
   */
  PointOfSaleNotAvailable = "Invoices:PointOfSaleNotAvailable",
  /**
   * The payment is not available: {0:string}.
   */
  PaymentNotAvailable = "Invoices:PaymentNotAvailable",
  /**
   * Manual Invoices are not available for this country: {0:string}.
   */
  ManualInvoicesDisabled = "Invoices:ManualInvoicesDisabled",
}
export enum InvoiceExport {
  /**
   * Invoice {0:number} must be closed before it can be exported.
   */
  InvoiceNotClosed = "InvoiceExport:InvoiceNotClosed",
}
export enum ExecutableAction {
  /**
   * Invalid action.
   */
  InvalidAction = "ExecutableAction:InvalidAction",
}
export enum OpeningHours {
  /**
   * Either DayOfWeek or Date is required for an OpeningHour
   */
  DayOfWeekOrDateRequired = "OpeningHours:DayOfWeekOrDateRequired",
  /**
   * Cannot create OpeningHours in the past
   */
  CannotCreateHoursInThePast = "OpeningHours:CannotCreateHoursInThePast",
  /**
   * Time slots start time can not be higher or equal to the end time
   */
  TimeSlotStartTimeCanNotBeHigherOrEqualToTheEndTime = "OpeningHours:TimeSlotStartTimeCanNotBeHigherOrEqualToTheEndTime",
  /**
   * Multiple time slots on the same day cannot overlap each other
   */
  TimeSlotsCannotOverlap = "OpeningHours:TimeSlotsCannotOverlap",
  /**
   * Time slot start time and end time must be between 00:00:00.0 and 23:59:59.0
   */
  InvalidTimeSlotTimeSpan = "OpeningHours:InvalidTimeSlotTimeSpan",
  /**
   * OpeninghoursTemplate can not be removed untill all organization unit sets are disconnected from it.
   */
  CannotRemoveOpeninghoursTemplateWhenOUSetsAreConnected = "OpeningHours:CannotRemoveOpeninghoursTemplateWhenOUSetsAreConnected",
  /**
   * Special openinghours can not be added or updated when the date is in the past.
   */
  CannotAddOrUpdateSpecialOpeningHoursInThePast = "OpeningHours:CannotAddOrUpdateSpecialOpeningHoursInThePast",
  /**
   * Either `DayOfWeek` or `Date` is required to set openinghours exception.
   */
  EitherDayOfWeekOrDateIsRequiredToSetOpeningHoursException = "OpeningHours:EitherDayOfWeekOrDateIsRequiredToSetOpeningHoursException",
  /**
   * Invalid period given.
   */
  InvalidPeriodGiven = "OpeningHours:InvalidPeriodGiven",
  /**
   * OrganizationUnitSet {0:number} is already attached to a template of the same type. When you wish to overwrite this, you must provide the 'OverrideIfOpeningHoursTypeAlreadyAttached' or remove the already attached template of the same type.
   */
  AlreadyAttached = "OpeningHours:AlreadyAttached",
}
export enum PhoneNumber {
  /**
   * The phonenumber is invalid: {0:string}
   */
  InvalidPhoneNumber = "PhoneNumber:InvalidPhoneNumber",
}
export enum GiftWrapping {
  /**
   * The selected product is not a greetingcard.
   */
  NoGreetingCardProduct = "GiftWrapping:NoGreetingCardProduct",
  /**
   * The selected orderline is not available for giftwrapping.
   */
  LineNotAvailableForWrapping = "GiftWrapping:LineNotAvailableForWrapping",
  /**
   * An OrderLine in the giftwrapping is not in the duplicated order, can't duplicate the giftwrapping.
   */
  LineInGiftWrappingNotInDuplicatedOrder = "GiftWrapping:LineInGiftWrappingNotInDuplicatedOrder",
}
export enum GiftCards {
  /**
   * The given amount {amount:number} is not in the allowed values {values:string}.
   */
  ActivateValueNotInValues = "GiftCards:ActivateValueNotInValues",
  /**
   * The given amount {givenAmount:number} is lower than {minAmount:number}.
   */
  ActivateValueTooLow = "GiftCards:ActivateValueTooLow",
  /**
   * The given amount {givenAmount:number} is higher than {maxAmount:number}.
   */
  ActivateValueTooHigh = "GiftCards:ActivateValueTooHigh",
  /**
   * Failed to execute the action {action:string} for orderline {orderLineID:number}
   */
  ActionFailed = "GiftCards:ActionFailed",
  /**
   * Failed to execute the action {0:string}
   */
  UnknownError = "GiftCards:UnknownError",
  /**
   * It's required to supply a PIN when calling this services without being logged in
   */
  PinRequiredForAnonymous = "GiftCards:PinRequiredForAnonymous",
  /**
   * Unable to determine the cardhandler for this card.
   */
  CardHandlerNotFound = "GiftCards:CardHandlerNotFound",
  /**
   * No Giftcard configuration for product {0:number}
   */
  CardHandlerNotFoundForProduct = "GiftCards:CardHandlerNotFoundForProduct",
  /**
   * Unable to fetch cardinfo: {0:string}
   */
  FailedToFetchCardInfo = "GiftCards:FailedToFetchCardInfo",
  /**
   * The delivery of this giftcard is scheduled within an hour, updates are not allowed.
   */
  DeliveryScheduleToClose = "GiftCards:DeliveryScheduleToClose",
  /**
   * Redemption of card {0:string} failed
   */
  RedemptionFailed = "GiftCards:RedemptionFailed",
  /**
   * Card with number {0:string} was not found.
   */
  CardNotFound = "GiftCards:CardNotFound",
  /**
   * Card with number {0:string} is expired.
   */
  CardExpired = "GiftCards:CardExpired",
  /**
   * The card has currency {cardCurrency:string}, but the requested currency is {transactionCurrency:string}.
   */
  CurrencyMismatch = "GiftCards:CurrencyMismatch",
  /**
   * The total balance of all the cards cannot cover the paid amount
   */
  CardsBalanceTooLow = "GiftCards:CardsBalanceTooLow",
  /**
   * The current card handler does not support multiple cards at once.
   */
  MultipleCardsPaymentNotSupported = "GiftCards:MultipleCardsPaymentNotSupported",
  /**
   * It is not allowed to pay for GiftCards with a GiftCard.
   */
  PayingForGiftCardsWithGiftCards = "GiftCards:PayingForGiftCardsWithGiftCards",
  /**
   * A PaymentTransaction for this type of giftcard can only be refunded for the full amount
   */
  RefundsCanOnlyBeDoneForTheFullAmount = "GiftCards:RefundsCanOnlyBeDoneForTheFullAmount",
  /**
   * No CardActionLedger could be found for the PaymentTransaction
   */
  NoCardActionLedgerFound = "GiftCards:NoCardActionLedgerFound",
  /**
   * No refund option available for this giftcard
   */
  RefundNotAvailable = "GiftCards:RefundNotAvailable",
  /**
   * This Giftcard does not allow for refunding this directly.
   */
  RefundWithoutTransactionNotAllowed = "GiftCards:RefundWithoutTransactionNotAllowed",
  /**
   * This Giftcard has a higher value than the allowed amount for refunding directly.
   */
  CardBalanceTooHigh = "GiftCards:CardBalanceTooHigh",
  /**
   * An expired card cannot be used.
   */
  Expired = "GiftCards:Expired",
  /**
   * A card must be activated before use.
   */
  NotActivated = "GiftCards:NotActivated",
  /**
   * Insufficient balance on card.
   */
  InsufficientBalance = "GiftCards:InsufficientBalance",
}
export enum APIGiftCard {
  /**
   * The cards have these currencies {cardCurrency:string}, but the requested ones are {transactionCurrency:string}.
   */
  CurrencyMismatches = "APIGiftCard:CurrencyMismatches",
}
export enum Finance {
  /**
   * There is still an open CashJournal for this Device.
   */
  OpenCashJournal = "Finance:OpenCashJournal",
  /**
   * There is still an open CashJournal for this Station.
   */
  OpenCashJournalForStation = "Finance:OpenCashJournalForStation",
}
export enum FinancialPeriods {
  /**
   * There is no financial period available for this organization.
   */
  NoFinancialPeriod = "FinancialPeriods:NoFinancialPeriod",
}
export enum OpenFinancialPeriod {
  /**
   * The period has already been opened.
   */
  PeriodAlreadyOpened = "OpenFinancialPeriod:PeriodAlreadyOpened",
}
export enum CloseFinancialPeriod {
  /**
   * The period can only be closed when its status is Open, it's currently {0:string}.
   */
  PeriodNotOpen = "CloseFinancialPeriod:PeriodNotOpen",
  /**
   * Financial period management for webshops is temporarily disabled.
   */
  WebshopFinancialPeriodManagementDisabled = "CloseFinancialPeriod:WebshopFinancialPeriodManagementDisabled",
}
export enum FinancialPeriodOpeningImpediments {
  /**
   * No employees linked to the financial period.
   */
  LinkedEmployees = "FinancialPeriodOpeningImpediments:LinkedEmployees",
  /**
   * Loyalty program is not linked to the financial period.
   */
  LoyaltyProgram = "FinancialPeriodOpeningImpediments:LoyaltyProgram",
}
export enum FinancialPeriodClosingImpediments {
  /**
   * There are {0:number} active user tasks remaining that must be completed or deactivated before the period can be closed.
   */
  ActiveUserTasks = "FinancialPeriodClosingImpediments:ActiveUserTasks",
  /**
   * There are {0:number} products with negative stock that must be corrected before the period can be closed.
   */
  NegativeStock = "FinancialPeriodClosingImpediments:NegativeStock",
  /**
   * There are {0:number} open cash journals remaining. These must be closed before the period can be closed.
   */
  OpenCashJournals = "FinancialPeriodClosingImpediments:OpenCashJournals",
  /**
   * The PCI question is not yet answered, this is required to close the period.
   */
  PCIQuestionNotAnswered = "FinancialPeriodClosingImpediments:PCIQuestionNotAnswered",
}
export enum FinancialPeriodExport {
  /**
   * No endpoint configured for period ledger export.
   */
  EndpointNotConfigured = "FinancialPeriodExport:EndpointNotConfigured",
  /**
   * Financial period ledger export failed: {0:string}.
   */
  LedgerExportFailed = "FinancialPeriodExport:LedgerExportFailed",
}
export enum CashServices {
  /**
   * The given TotalAmount does not match with the counted coins and banknotes.
   */
  InvalidTotalAmount = "CashServices:InvalidTotalAmount",
}
export enum Cash {
  /**
   * The amount of {amount:number} is too precise, an amount in currency {currencyID:string} can only have a precision of {maxDigits:number} digits.
   */
  TooMuchPrecision = "Cash:TooMuchPrecision",
  /**
   * Depositing cash requires a number.
   */
  CashDepositNumberRequired = "Cash:CashDepositNumberRequired",
  /**
   * Depositing cash is only allowed from the safe.
   */
  CashDepositIsOnlyAllowedFromSafe = "Cash:CashDepositIsOnlyAllowedFromSafe",
  /**
   * A required (pending) cash deposit is missing.
   */
  CashDepositMissing = "Cash:CashDepositMissing",
  /**
   * Safe can not result in a negative balance
   */
  NegativeSafeBalance = "Cash:NegativeSafeBalance",
  /**
   * This sealbag number was already used on {0:string}
   */
  CashDepositNumberAlreadyInUse = "Cash:CashDepositNumberAlreadyInUse",
}
export enum CashCorrections {
  /**
   * Remark is required when creating a cash correction.
   */
  RemarkIsRequired = "CashCorrections:RemarkIsRequired",
}
export enum CashDepositManager {
  /**
   * Cannot drop-off cash deposit because it is not pending.
   */
  CashDepositNotPending = "CashDepositManager:CashDepositNotPending",
}
export enum DeleteCashDeposit {
  /**
   * Cannot delete cash deposit because the cash journal has already been closed.
   */
  CashJournalClosed = "DeleteCashDeposit:CashJournalClosed",
  /**
   * Cannot delete cash deposit because the financial period has already been closed.
   */
  FinancialPeriodClosed = "DeleteCashDeposit:FinancialPeriodClosed",
}
export enum CashJournal {
  /**
   * There is no open cash journal for device {0:string}
   */
  NoOpenCashJournal = "CashJournal:NoOpenCashJournal",
  /**
   * There is still an open cash journal for device {0:string}
   */
  OpenCashJournal = "CashJournal:OpenCashJournal",
  /**
   * This PaymentMethod requires a Device
   */
  DeviceRequired = "CashJournal:DeviceRequired",
  /**
   * It's not possible to open a cash journal for a device when you're not currently logged into that OrganizationUnit.
   */
  InvalidOrganizationUnit = "CashJournal:InvalidOrganizationUnit",
  /**
   * This device could not be found, is not a cash device, or it's not accessible to you.
   */
  InvalidDevice = "CashJournal:InvalidDevice",
  /**
   * This cashjournal has CashJournalMethod None and can't be opened.
   */
  InvalidCashJournalMethod = "CashJournal:InvalidCashJournalMethod",
  /**
   * This currency is not available for this organizationunit.
   */
  InvalidCurrency = "CashJournal:InvalidCurrency",
}
export enum OpenCashJournal {
  /**
   * There is no open financial period available for device {0:string}, cannot create a cash journal.
   */
  FinancialPeriodNotOpened = "OpenCashJournal:FinancialPeriodNotOpened",
  /**
   * There is already an open cash journal for device {device:string}, paymenttype {paymentType:string} in currency {currencyID:string}.
   */
  AlreadyOpened = "OpenCashJournal:AlreadyOpened",
  /**
   * The previous cash journal closed with a different amount than the new journal's opening, this should be corrected before the new journal can be opened.
   */
  UnexplainedOpeningDeviation = "OpenCashJournal:UnexplainedOpeningDeviation",
}
export enum CloseCashJournal {
  /**
   * The amount of cash in device {0:string} exceeds the allowed limit for this device.
   */
  ExceedsCashLimit = "CloseCashJournal:ExceedsCashLimit",
  /**
   * The cash journal cannot be closed when the difference between the expected and the counted amount has not been corrected.
   */
  UnexplainedClosingDeviation = "CloseCashJournal:UnexplainedClosingDeviation",
  /**
   * The cash journal cannot be closed with a correction being made without providing a reason or description.
   */
  MissingReasonOrDescriptionForCashCorrection = "CloseCashJournal:MissingReasonOrDescriptionForCashCorrection",
}
export enum MoveCash {
  /**
   * Source device ({source:string}) cannot be used for moving cash to destination device ({destination:string}).
   */
  InvalidDevice = "MoveCash:InvalidDevice",
  /**
   * An amount of {currencyID:string} {amount:number} is not valid.
   */
  InvalidAmount = "MoveCash:InvalidAmount",
  /**
   * When moving cash between two journals they must both be in the same financial period, but the source period was {sourceFinancialPeriodID:number} while the destination period was {destinationFinancialPeriodID:number}.
   */
  CashJournalsMustBeInSamePeriod = "MoveCash:CashJournalsMustBeInSamePeriod",
  /**
   * Cannot supply Total and Coins or Notes at the same time. Try supplying either the Total or the Coins and Notes.
   */
  CannotSupplyTotalAndCoinsOrNotesAtTheSameTime = "MoveCash:CannotSupplyTotalAndCoinsOrNotesAtTheSameTime",
}
export enum ExchangeRates {
  /**
   * There is no exchange rate available from {baseCurrencyID:string} to {currencyID:string}
   */
  NoExchangeRateAvailable = "ExchangeRates:NoExchangeRateAvailable",
}
export enum EventExportConfiguration {
  /**
   * The provided endpoint is either invalid or is not secure
   */
  InvalidEndpoint = "EventExportConfiguration:InvalidEndpoint",
  /**
   * Cannot change status to Error
   */
  CannotSetToError = "EventExportConfiguration:CannotSetToError",
  /**
   * The provided config is invalid
   */
  InvalidConfig = "EventExportConfiguration:InvalidConfig",
  /**
   * The key ID is invalid (should be either 1 or 2)
   */
  InvalidKeyID = "EventExportConfiguration:InvalidKeyID",
}
export enum Auditing {
  /**
   * There are multiple orders for invoice {0:number}, this is not allowed.
   */
  MultipleOrdersForInvoice = "Auditing:MultipleOrdersForInvoice",
  /**
   * Not allowed to autocomplete orders in an audited country.
   */
  AutoCompletionNotAllowed = "Auditing:AutoCompletionNotAllowed",
  /**
   * '{0}' with value '{1}' doesn't pass validation.
   */
  ValidationError = "Auditing:ValidationError",
}
export enum Printing {
  /**
   * Unable to find a printer.
   */
  PrinterNotFound = "Printing:PrinterNotFound",
  /**
   * IPP printing requires that a host name is configured.
   */
  MissingHostName = "Printing:MissingHostName",
  /**
   * Device {0:number} has no network name or ip address specified, one of these is required for IPP printing
   */
  MissingNetworkName = "Printing:MissingNetworkName",
  /**
   * Converting PDF to PCL through URL {0:string} failed.
   */
  FailureConvertingPDF2PCL = "Printing:FailureConvertingPDF2PCL",
  /**
   * Failed to print: {0:string}
   */
  PrintFailure = "Printing:PrintFailure",
}
export enum Devices {
  /**
   * Unable to find a device.
   */
  DeviceNotFound = "Devices:DeviceNotFound",
  /**
   * There is no connection available to the requested device.
   */
  NoConnectionAvailable = "Devices:NoConnectionAvailable",
  /**
   * Watchtowers can only be created with the CreateWatchtowerDevice service.
   */
  WatchtowerTypeNotAllowed = "Devices:WatchtowerTypeNotAllowed",
  /**
   * Device {0:number} has no Address configured.
   */
  AddressNotConfigured = "Devices:AddressNotConfigured",
  /**
   * Device {0:number} has no valid Address ({1:string}) configured.
   */
  UnableToParseAddress = "Devices:UnableToParseAddress",
}
export enum Stations {
  /**
   * Station is required.
   */
  StationRequired = "Stations:StationRequired",
  /**
   * Station '{0:number}' is not a point of sale
   */
  StationIsNotPointOfSale = "Stations:StationIsNotPointOfSale",
  /**
   * Station {0:number} cannot be used from your current user context.
   */
  InvalidStation = "Stations:InvalidStation",
}
export enum Global {
  /**
   * The client to communicate with the global eva instance is not configured.
   */
  GlobalClientNotConfigured = "Global:GlobalClientNotConfigured",
  /**
   * This service has been deprecated from version {deprecationVersion:number} and will be removed in version {wopVersion:number}.
   */
  ServiceNoLongerInUse = "Global:ServiceNoLongerInUse",
}
export enum Users {
  /**
   * User does not exist.
   */
  DoesNotExist = "Users:DoesNotExist",
  /**
   * Another user with this BackendID already exists.
   */
  DuplicateBackendID = "Users:DuplicateBackendID",
  /**
   * Can only generate a PIN for an employee.
   */
  CanOnlyGeneratePINForEmployee = "Users:CanOnlyGeneratePINForEmployee",
  /**
   * This PIN is not secure enough.
   */
  InsecurePIN = "Users:InsecurePIN",
  /**
   * Tried to generate a PIN with length {attemptLength:number} but it must be length {requiredLength:number}.
   */
  InvalidLengthForPIN = "Users:InvalidLengthForPIN",
  /**
   * EmailAddress '{0:string}' is already in use.
   */
  EmailAddressInUse = "Users:EmailAddressInUse",
  /**
   * EmailAddress of a Customer can't be changed.
   */
  CantChangeCustomerEmailAddress = "Users:CantChangeCustomerEmailAddress",
  /**
   * It is not allowed to change the EmailAddress of another employee.
   */
  CantChangeEmailAddressOfAnotherEmployee = "Users:CantChangeEmailAddressOfAnotherEmployee",
  /**
   * Nickname '{0:string}' is already in use.
   */
  NicknameInUse = "Users:NicknameInUse",
  /**
   * Fiscal ID '{0:string}' is already in use.
   */
  FiscalIDInUse = "Users:FiscalIDInUse",
  /**
   * When no emailaddress is supplied, a password is required.
   */
  PasswordRequired = "Users:PasswordRequired",
  /**
   * It is not allowed to set a password for employee accounts.
   */
  PasswordNotAllowedForEmployees = "Users:PasswordNotAllowedForEmployees",
  /**
   * EmailAddress '{0:string}' is not valid.
   */
  InvalidEmailAddress = "Users:InvalidEmailAddress",
  /**
   * PhoneNumber '{0:string}' is not valid.
   */
  InvalidPhoneNumber = "Users:InvalidPhoneNumber",
  /**
   * This nickname '{0:string}' is not valid.
   */
  InvalidNickname = "Users:InvalidNickname",
  /**
   * This password reset token is not valid
   */
  PasswordResetTokenInvalid = "Users:PasswordResetTokenInvalid",
  /**
   * This password reset token has already been used to reset a password
   */
  PasswordResetTokenAlreadyUsed = "Users:PasswordResetTokenAlreadyUsed",
  /**
   * This password is not valid: {0:string}
   */
  PasswordPolicyViolation = "Users:PasswordPolicyViolation",
  /**
   * It is not allowed to create an employee that is also customer
   */
  MixedAccountsNotAllowed = "Users:MixedAccountsNotAllowed",
  /**
   * It is not allowed to create a customer standard account in this visibility group
   */
  NoCustomerAccountsAllowedInThisVisibilityGroup = "Users:NoCustomerAccountsAllowedInThisVisibilityGroup",
  /**
   * It is not allowed to recover your own account
   */
  CantRecoverOwnAccount = "Users:CantRecoverOwnAccount",
  /**
   * The selected user is not visible.
   */
  NotVisible = "Users:NotVisible",
  /**
   * Delete users limit of {0:number} exceeded.
   */
  DeleteUsersLimitExceeded = "Users:DeleteUsersLimitExceeded",
  /**
   * Duplicate custom fields are invalid.
   */
  DuplicateCustomFields = "Users:DuplicateCustomFields",
}
export enum Roles {
  /**
   * The Role {roleName:string} is not contained in the RoleSet of OrganizationUnit {ouName:string}.
   */
  RoleNotInRoleSet = "Roles:RoleNotInRoleSet",
  /**
   * You are not allowed to set the role {roleName:string} for organization unit {ouName:string}
   */
  NotAllowedToSetRole = "Roles:NotAllowedToSetRole",
  /**
   * The selected Role `{0:number}` has a non-matching UserType.
   */
  RoleUserTypeMismatch = "Roles:RoleUserTypeMismatch",
  /**
   * This role still has users attached - please remove them first!
   */
  RoleStillHasUsers = "Roles:RoleStillHasUsers",
}
export enum Login {
  /**
   * Username is a required field when authenticating with credentials.
   */
  UsernameRequired = "Login:UsernameRequired",
  /**
   * Failed to execute the custom login.
   */
  CustomAuthenticationFailed = "Login:CustomAuthenticationFailed",
}
export enum Barcodes {
  /**
   * Invalid barcode ({0:string}) provided
   */
  InvalidBarcode = "Barcodes:InvalidBarcode",
  /**
   * Invalid handler type was provided to ExecuteBarcode.
   */
  InvalidExecuteHandlerType = "Barcodes:InvalidExecuteHandlerType",
}
export enum OrderLine {
  /**
   * Quantity cannot be modified: {0:string}
   */
  CannotModifyQuantity = "OrderLine:CannotModifyQuantity",
  /**
   * Quantity to split must be positive and cannot be greather or equal to the quantity of the orderline.
   */
  InvalidSplitQuantity = "OrderLine:InvalidSplitQuantity",
  /**
   * Order line #{0:number} has already been shipped.
   */
  AlreadyShipped = "OrderLine:AlreadyShipped",
  /**
   * Require a product to mutate the order line
   */
  ProductRequiredToMutatePrice = "OrderLine:ProductRequiredToMutatePrice",
  /**
   * Require a product with custom pricing to mutate the order line
   */
  ProductRequiresCustomPricingToMutatePrice = "OrderLine:ProductRequiresCustomPricingToMutatePrice",
  /**
   * A GiftCard with CustomPricing requires a positive UnitPrice
   */
  GiftCardsRequireAPositiveUnitPrice = "OrderLine:GiftCardsRequireAPositiveUnitPrice",
  /**
   * This lineactiontype is not valid for the orderline {OrderLineID:number}: {Error:string}
   */
  LineActionTypeChangeNotValid = "OrderLine:LineActionTypeChangeNotValid",
  /**
   * OrderLine {0:number} has already been completed/shipped/invoiced and can therefor no longer be corrected in price.
   */
  UnitPriceCanNoLongerBeCorrected = "OrderLine:UnitPriceCanNoLongerBeCorrected",
  /**
   * It is required to supply a reason for a UnitPriceCorrection.
   */
  UnitPriceCorrectionReasonRequired = "OrderLine:UnitPriceCorrectionReasonRequired",
  /**
   * UnitPriceCorrections are only allowed on manual returnlines.
   */
  UnitPriceCorrectionNotAManualReturn = "OrderLine:UnitPriceCorrectionNotAManualReturn",
  /**
   * Correction amount is limited to {Limit:number}, but {Requested:number} was requested.
   */
  CorrectionAmountExceeded = "OrderLine:CorrectionAmountExceeded",
}
export enum Shipment {
  /**
   * Cannot receive an unshipped shipment.
   */
  CannotReceiveUnshippedShipment = "Shipment:CannotReceiveUnshippedShipment",
  /**
   * Cannot fully receive shipment {0:number}, because all lines are already completed.
   */
  CannotFullyReceiveCompletedShipment = "Shipment:CannotFullyReceiveCompletedShipment",
  /**
   * Shipment already exists with that BackendSystemID/BackendID combination: {backendSystemID:string}/{backendID:string}
   */
  ShipmentAlreadyExists = "Shipment:ShipmentAlreadyExists",
  /**
   * ShipmentReceipt already exists with that BackendSystemID/BackendID combination: {backendSystemID:string}/{backendID:string}
   */
  ShipmentReceiptAlreadyExists = "Shipment:ShipmentReceiptAlreadyExists",
  /**
   * Can only ship purchase orders.
   */
  CanOnlyShipPurchaseOrders = "Shipment:CanOnlyShipPurchaseOrders",
  /**
   * Orders should be supplied with full details.
   */
  OrderWithFullDetailsRequired = "Shipment:OrderWithFullDetailsRequired",
  /**
   * ShipFromOrganizationUnit does not match for order #{orderID:number} which has #{shipFromOnOrder:number} and the shipment being created which has #{shipFromOnShipment:number}
   */
  ShipFromOrganizationUnitMismatch = "Shipment:ShipFromOrganizationUnitMismatch",
  /**
   * The shipment contains an order of the wrong type for shipmentreceiver {0:string}
   */
  OrderTypeMismatch = "Shipment:OrderTypeMismatch",
  /**
   * Surplus are not allowed.
   */
  SurplusNotAllowed = "Shipment:SurplusNotAllowed",
  /**
   * Overshipment is not allowed; attempted to ship {quantityShipped:number} on order line {orderLine:number} even though it only has an open quantity of {quantityOpen:number}.
   */
  OvershipmentNotAllowed = "Shipment:OvershipmentNotAllowed",
  /**
   * Shipment {0:number} is not Pending and cannot be mutated.
   */
  IsNotPending = "Shipment:IsNotPending",
  /**
   * Pending shipments are not yet supported for non-sales orders.
   */
  PendingShipmentNotSupported = "Shipment:PendingShipmentNotSupported",
  /**
   * Can't return OrderLine {returnOrderLineID:number} on Order {returnOrderID:number} for original OrderLine {originalOrderLineID:number}. The QuantityReturned ({quantityReturned:number}) is greather than the QuantityShipped ({quantityShipped:number})
   */
  CannotReturnMoreThanShipped = "Shipment:CannotReturnMoreThanShipped",
  /**
   * Multiple shipping methods are not supported for one shipment.
   */
  MultipleShippingMethodsNotSupported = "Shipment:MultipleShippingMethodsNotSupported",
  /**
   * There are no lines to receive specified for shipment {0:number}
   */
  NoReceiptLinesSpecified = "Shipment:NoReceiptLinesSpecified",
  /**
   * Can't ship order {orderID:number}. There is still an open amount of {openAmount:number}.
   */
  OpenAmount = "Shipment:OpenAmount",
  /**
   * A shipment can either contain lines or packages with lines, not both.
   */
  ShipmentCanOnlyContainLinesOrPackagesWithLines = "Shipment:ShipmentCanOnlyContainLinesOrPackagesWithLines",
  /**
   * Shipment with multiple packages aren't allowed.
   */
  ShipmentWithMultiplePackagesNotAllowed = "Shipment:ShipmentWithMultiplePackagesNotAllowed",
  /**
   * Preventing creation of empty shipment!
   */
  EmptyShipment = "Shipment:EmptyShipment",
  /**
   * Invalid delivery type for shipping method
   */
  InvalidShippingMethodDeliveryType = "Shipment:InvalidShippingMethodDeliveryType",
  /**
   * Package does not match shipping method
   */
  PackageDoesNotMatchShippingMethod = "Shipment:PackageDoesNotMatchShippingMethod",
}
export enum EntityFieldValidator {
  /**
   * The provided field name is not available or doesn't exist for this entity. To see available fields, execute ListEntityFieldValidatorEntityFieldNames.
   */
  FieldDoesNotExist = "EntityFieldValidator:FieldDoesNotExist",
  /**
   * There is already a validator that covers this usertype.
   */
  UserTypeAlreadyExists = "EntityFieldValidator:UserTypeAlreadyExists",
  /**
   * EntityFieldValidators on {entity:string} -> {property:string} with value {value:string} doesn't comply with rule {rule:string} -> {ruleValue:string}.
   */
  FieldValidationError = "EntityFieldValidator:FieldValidationError",
}
export enum Payment {
  /**
   * An Order with an attached customer is required for this payment.
   */
  CustomerRequired = "Payment:CustomerRequired",
  /**
   * Transaction {0:number} has already been confirmed.
   */
  TransactionAlreadyConfirmed = "Payment:TransactionAlreadyConfirmed",
  /**
   * Transaction {0:number} is already being processed.
   */
  TransactionAlreadyProcessing = "Payment:TransactionAlreadyProcessing",
  /**
   * TransactionCapture {0:number} has already been confirmed.
   */
  CaptureAlreadyConfirmed = "Payment:CaptureAlreadyConfirmed",
  /**
   * Transaction {0:number} is failed, create a new transaction.
   */
  TransactionFailed = "Payment:TransactionFailed",
  /**
   * Refund failed: {0:string}
   */
  RefundFailed = "Payment:RefundFailed",
  /**
   * Refund is not available for this order
   */
  RefundNotAvailable = "Payment:RefundNotAvailable",
  /**
   * Requested to refund {requested:number} on the order, but only {available:number} is available for refund
   */
  RefundAmountNotAvailable = "Payment:RefundAmountNotAvailable",
  /**
   * PaymentMethod {0:string} is not available
   */
  PaymentMethodNotAvailable = "Payment:PaymentMethodNotAvailable",
  /**
   * Can't pay more than is due. Order {orderID:number} has an openamount of {openAmount:number} and a payment of {amountToPay:number} is requested.
   */
  OverPayment = "Payment:OverPayment",
  /**
   * CashJournalMethod on PaymentType must be None/Open/OpenAndClose. {0:PaymentCashJournalMethod} is not allowed.
   */
  InvalidCashJournalMethod = "Payment:InvalidCashJournalMethod",
  /**
   * Only Custom PaymentMethods can be mutated.
   */
  PaymentMethodIsNotCustom = "Payment:PaymentMethodIsNotCustom",
  /**
   * A PaymentType must be of the same PaymentMethod.
   */
  PaymentTypeCannotBeChangedToOtherMethod = "Payment:PaymentTypeCannotBeChangedToOtherMethod",
  /**
   * A PaymentTransaction must be committed before it can be captured.
   */
  UncommittedPaymentsCantBeCaptured = "Payment:UncommittedPaymentsCantBeCaptured",
  /**
   * This paymenttransaction cannot be captured.
   */
  CaptureNotAvailable = "Payment:CaptureNotAvailable",
  /**
   * This paymenttransaction can no langer be captured, the authorization has been expired.
   */
  AuthorizationExpired = "Payment:AuthorizationExpired",
  /**
   * The selected device is already in use for a pin transaction.
   */
  PinDeviceAlreadyInUse = "Payment:PinDeviceAlreadyInUse",
  /**
   * Cannot perform this action when setting `PaymentMethods:PaymentTypeAvailabilityByOuSetType` is enabled!
   */
  PaymentTypeAvailabilityByOuSetTypeEnabled = "Payment:PaymentTypeAvailabilityByOuSetTypeEnabled",
  /**
   * The current payment method does not support multiple cards at once.
   */
  MultipleCardsPaymentNotSupported = "Payment:MultipleCardsPaymentNotSupported",
  /**
   * Payment initialization not supported
   */
  InitializationNotSupported = "Payment:InitializationNotSupported",
  /**
   * The current payment method does not support multi currency.
   */
  PaymentMethodDoesNotSupportMultiCurrency = "Payment:PaymentMethodDoesNotSupportMultiCurrency",
}
export enum Discounts {
  /**
   * Coupon {0:string} is invalid.
   */
  InvalidDiscountCoupon = "Discounts:InvalidDiscountCoupon",
  /**
   * Discount {DiscountID:number} has already been used the maximum number of times.
   */
  MaximumDiscountUsageReached = "Discounts:MaximumDiscountUsageReached",
  /**
   * Discount {0:number} has already been used the maximum number of times by the user.
   */
  MaximumDiscountUserUsageCountReached = "Discounts:MaximumDiscountUserUsageCountReached",
  /**
   * User reached the maximum usage of discount {0:number}.
   */
  MaximumDiscountUserUsageBalanceReached = "Discounts:MaximumDiscountUserUsageBalanceReached",
  /**
   * User {CustomerID:number} has insufficient credit to use Discount {DiscountID:number}. Available: {Balance:number}, Discount: {DiscountAmount:number}, Order: {OrderID:number}
   */
  InsufficientUserCardBalance = "Discounts:InsufficientUserCardBalance",
  /**
   * Coupon resolved on different handler then registerd on the discount.
   */
  InvalidCouponHandlerResolvement = "Discounts:InvalidCouponHandlerResolvement",
  /**
   * Coupon '{0:string}' not usable in current organization unit.
   */
  CouponNotUsableInCurrentOrganizationUnit = "Discounts:CouponNotUsableInCurrentOrganizationUnit",
  /**
   * Coupon '{couponCode:string}' not usable for currency '{currency:string}'.
   */
  CouponNotUsableInCurrentCurrency = "Discounts:CouponNotUsableInCurrentCurrency",
  /**
   * Coupon '{couponCode:string}' from '{handler:string}' not longer usable.
   */
  CouponNoLongerUsable = "Discounts:CouponNoLongerUsable",
  /**
   * Coupon maximum usage reached
   */
  CouponMaximumUsageReached = "Discounts:CouponMaximumUsageReached",
  /**
   * Coupon has expired
   */
  ExpiredCoupon = "Discounts:ExpiredCoupon",
  /**
   * Coupon already added
   */
  CouponAlreadyAdded = "Discounts:CouponAlreadyAdded",
  /**
   * Discount can not be used as a manual discount. Trigger is not set as manual.
   */
  DiscountNotSetAsManualDiscount = "Discounts:DiscountNotSetAsManualDiscount",
  /**
   * Discount can not be used as a loyalty discount. Trigger is not set as manual.
   */
  DiscountNotSetAsLoyaltyDiscount = "Discounts:DiscountNotSetAsLoyaltyDiscount",
  /**
   * Coupon {couponCode:string} with DiscountBackendID `{discountBackendID:string}` did not resolve to a valid discount.
   */
  CouponDidNotResolveToAValidDiscount = "Discounts:CouponDidNotResolveToAValidDiscount",
  /**
   * Invalid coupon
   */
  InvalidCoupon = "Discounts:InvalidCoupon",
  /**
   * Line level discount requires lines te be applied to
   */
  LineLevelDiscountRequiresLinesToApplyTo = "Discounts:LineLevelDiscountRequiresLinesToApplyTo",
  /**
   * Discount reason required
   */
  DiscountReasonRequired = "Discounts:DiscountReasonRequired",
  /**
   * Customer is required in order to apply this discount.
   */
  CustomerRequiredForDiscount = "Discounts:CustomerRequiredForDiscount",
  /**
   * Required custom field `{0:number}` is missing.
   */
  RequiredCustomFieldMissing = "Discounts:RequiredCustomFieldMissing",
  /**
   * Manual discount amount is invalid
   */
  ManualDiscountAmountIsInvalid = "Discounts:ManualDiscountAmountIsInvalid",
  /**
   * Manual discount already applied to the order.
   */
  ManualDiscountAlreadyAppliedToTheOrder = "Discounts:ManualDiscountAlreadyAppliedToTheOrder",
  /**
   * Manual discount given on invalid orderline.
   */
  ManualDiscountGivenOnInvalidOrderLine = "Discounts:ManualDiscountGivenOnInvalidOrderLine",
  /**
   * Manual discount on line can not exceed orderline quantity.
   */
  ManualDiscountOnLineCanNotExceedOrderLineQuantity = "Discounts:ManualDiscountOnLineCanNotExceedOrderLineQuantity",
  /**
   * Discount is already verified
   */
  DiscountAlreadyVerified = "Discounts:DiscountAlreadyVerified",
  /**
   * Discount is not verified
   */
  DiscountIsNotVerified = "Discounts:DiscountIsNotVerified",
  /**
   * Discount is not active
   */
  DiscountIsntActive = "Discounts:DiscountIsntActive",
  /**
   * Discount is archived and can no longer be used
   */
  DiscountArchived = "Discounts:DiscountArchived",
  /**
   * Discount is system managed and can not be displayed.
   */
  DiscountIsSystemManaged = "Discounts:DiscountIsSystemManaged",
  /**
   * Order is not of correct type for the discount
   */
  OrderDoesNotMeetDiscountOrderType = "Discounts:OrderDoesNotMeetDiscountOrderType",
  /**
   * Discount is disabled on order
   */
  DiscountIsDisabledOnOrder = "Discounts:DiscountIsDisabledOnOrder",
  /**
   * Coupon is invalid
   */
  CouponIsInvalid = "Discounts:CouponIsInvalid",
  /**
   * Allow price riles for interbranch order is disabled
   */
  AllowPriceRulesForInterbranchOrdersIsDisabled = "Discounts:AllowPriceRulesForInterbranchOrdersIsDisabled",
  /**
   * Automatic discounts aren't allowed on returntosupplier orders
   */
  AutomaticDiscountsArentAllowedOnReturnToSupplierOrders = "Discounts:AutomaticDiscountsArentAllowedOnReturnToSupplierOrders",
  /**
   * Discount on return order is disabled
   */
  DiscountOnReturnOrderIsDisabled = "Discounts:DiscountOnReturnOrderIsDisabled",
  /**
   * Discount on return reversal order disabled
   */
  DiscountOnReturnReversalIsDisabled = "Discounts:DiscountOnReturnReversalIsDisabled",
  /**
   * Order did not meet all of the discount conditions. Condition(s) not met; {0:string}
   */
  OrderDidNotMeetAllTheDiscountConditions = "Discounts:OrderDidNotMeetAllTheDiscountConditions",
  /**
   * Order did not meet any of the discount conditions. Condition(s) not met; {0:string}
   */
  OrderDidNotMeetAnyOfTheDiscountConditions = "Discounts:OrderDidNotMeetAnyOfTheDiscountConditions",
  /**
   * Roles can only be applied to manual discounts.
   */
  RolesCanOnlyBeAppliedToManualDiscounts = "Discounts:RolesCanOnlyBeAppliedToManualDiscounts",
  /**
   * Discount with trigger {0:string} can not be disabled on order. Cancel or removed them.
   */
  CannotDisableDiscountOfTypeX = "Discounts:CannotDisableDiscountOfTypeX",
  /**
   * {0:string} discount action type is invalid.
   */
  InvalidActionType = "Discounts:InvalidActionType",
  /**
   * {0:string} discount condition type is invalid.
   */
  InvalidConditionType = "Discounts:InvalidConditionType",
  /**
   * Discount is generated from a template and can not be modified
   */
  DiscountGeneratedFromTemplate = "Discounts:DiscountGeneratedFromTemplate",
  /**
   * Discount campaign name is required.
   */
  DiscountCampaignNameIsRequired = "Discounts:DiscountCampaignNameIsRequired",
  /**
   * Discount campaign names must be unique. '{0:string}' is already taken.
   */
  DiscountCampaignNameMustBeUnique = "Discounts:DiscountCampaignNameMustBeUnique",
  /**
   * Discount can only applied with order generated coupons.
   */
  DiscountRequiresOrderGeneratedCoupon = "Discounts:DiscountRequiresOrderGeneratedCoupon",
  /**
   * Coupon originating order does not exist.
   */
  OriginatingOrderDoesNotExist = "Discounts:OriginatingOrderDoesNotExist",
  /**
   * Coupon originating order isn't paid.
   */
  OriginatingOrderIsntPaid = "Discounts:OriginatingOrderIsntPaid",
  /**
   * Coupon originating order isn't shipped.
   */
  OriginatingOrderIsntShipped = "Discounts:OriginatingOrderIsntShipped",
  /**
   * Coupon originating order is fully returned and coupon can no longer be used.
   */
  OriginatingOrderIsFullyReturned = "Discounts:OriginatingOrderIsFullyReturned",
  /**
   * Can not create coupon for discount that hasn't got triggertype 'Coupon'.
   */
  CannotCreateCouponForDiscountWhereTriggerTypeIsntCoupon = "Discounts:CannotCreateCouponForDiscountWhereTriggerTypeIsntCoupon",
  /**
   * Coupons for this discount are generated by another discount.
   */
  CouponsForThisDiscountAreGeneratedByOtherDiscount = "Discounts:CouponsForThisDiscountAreGeneratedByOtherDiscount",
  /**
   * Cannot modify active discount template.
   */
  CannotModifyActiveDiscountTemplate = "Discounts:CannotModifyActiveDiscountTemplate",
  /**
   * Cannot create discount from inactive discount template.
   */
  CannotCreateDiscountFromInActiveDiscountTemplate = "Discounts:CannotCreateDiscountFromInActiveDiscountTemplate",
  /**
   * Service only accepts discount lines to cancel.
   */
  ServiceOnlyAcceptsDiscountLinesToCancel = "Discounts:ServiceOnlyAcceptsDiscountLinesToCancel",
  /**
   * DiscountLines of discount type {0:string} cannot be cancelled
   */
  DiscountLinesOfDiscountTypeXCannotBeCancelled = "Discounts:DiscountLinesOfDiscountTypeXCannotBeCancelled",
  /**
   * CustomUserUsageField {CustomFieldID:number} on Discount {DiscountID:number} is not (or no longer) a decimal data type.
   */
  CustomUserUsageFieldTypeInvalid = "Discounts:CustomUserUsageFieldTypeInvalid",
  /**
   * The value in CustomUserUsageField {CustomFieldID:number} for customer {CustomerID:number} is to low to use Discount {DiscountID:number}. CustomUserUsageField value: {CustomUserUsageFieldValue:number}, Discount amount: {DiscountAmount:number}, Order: {OrderID:number}
   */
  CustomUserUsageFieldValueToLow = "Discounts:CustomUserUsageFieldValueToLow",
  /**
   * Discount {0:number} does not support product selection.
   */
  DiscountDoesNotSupportProductSelection = "Discounts:DiscountDoesNotSupportProductSelection",
  /**
   * Failed to load discount product selection data for orderline {0:number}.
   */
  FailedToLoadSelectionDataForDiscountLine = "Discounts:FailedToLoadSelectionDataForDiscountLine",
  /**
   * Invalid orderline for discount product selection.
   */
  InvalidOrderlineForDiscountProductSelection = "Discounts:InvalidOrderlineForDiscountProductSelection",
  /**
   * Coupon `{0:string}` already exists on discount.
   */
  CouponAlreadyExistsOnDiscount = "Discounts:CouponAlreadyExistsOnDiscount",
  /**
   * Coupon `{0:string}` is bound to a specific user.
   */
  CouponIsUserBound = "Discounts:CouponIsUserBound",
  /**
   * There are duplicate coupon codes in the upload.
   */
  DuplicateCouponCodeInUpload = "Discounts:DuplicateCouponCodeInUpload",
  /**
   * The selected product requirement does not (or does no longer) exist.
   */
  InvalidProductRequirement = "Discounts:InvalidProductRequirement",
  /**
   * The given product requirement value (or one of the values) is empty.
   */
  InvalidProductRequirementValue = "Discounts:InvalidProductRequirementValue",
  /**
   * Cannot apply this discount to an order of the logged in user.
   */
  CannotApplyDiscountToOwnOrder = "Discounts:CannotApplyDiscountToOwnOrder",
  /**
   * Loyalty program no longer valid.
   */
  LoyaltyProgramNoLongerValid = "Discounts:LoyaltyProgramNoLongerValid",
  /**
   * Not subscribed to loyalty program.
   */
  NotSubscribedToLoyaltyProgram = "Discounts:NotSubscribedToLoyaltyProgram",
  /**
   * Loyalty program subscription blocked.
   */
  LoyaltyProgramSubscriptionBlocked = "Discounts:LoyaltyProgramSubscriptionBlocked",
  /**
   * Cannot withdraw from loyalty program.
   */
  CannotWithdrawFromLoyaltyProgram = "Discounts:CannotWithdrawFromLoyaltyProgram",
  /**
   * There are insufficient loyalty points to apply the discount.
   */
  InsufficientLoyaltyPoints = "Discounts:InsufficientLoyaltyPoints",
  /**
   * Discount cannot be applied to lines, only on order.
   */
  DiscountCannotBeAppliedToLines = "Discounts:DiscountCannotBeAppliedToLines",
  /**
   * All discount layers are required for reordering.
   */
  AllDiscountLayersAreRequiredForReordering = "Discounts:AllDiscountLayersAreRequiredForReordering",
  /**
   * The system discount layer cannot be changed
   */
  SystemLayerCannotBeChanged = "Discounts:SystemLayerCannotBeChanged",
  /**
   * Layer has active discounts.
   */
  LayerHasActiveDiscounts = "Discounts:LayerHasActiveDiscounts",
  /**
   * DiscountValue in LookupList cannot be negative
   */
  DiscountValueInLookupListCannotBeNegative = "Discounts:DiscountValueInLookupListCannotBeNegative",
  /**
   * DiscountValue in LookupList must be a valid percentage
   */
  DiscountValueInLookupListMustBeAValidPercentage = "Discounts:DiscountValueInLookupListMustBeAValidPercentage",
  /**
   * CustomField to use as discount value must be of type integer or decimal
   */
  CustomFieldToUseAsDiscountValueMustBeOfTypeIntegerOrDecimal = "Discounts:CustomFieldToUseAsDiscountValueMustBeOfTypeIntegerOrDecimal",
  /**
   * CustomField enum values must be convertible to numeric (integer or decimal) values
   */
  CustomFieldEnumValuesMustBeConvertibleToNumericValues = "Discounts:CustomFieldEnumValuesMustBeConvertibleToNumericValues",
  /**
   * A description is required.
   */
  DescriptionRequired = "Discounts:DescriptionRequired",
  /**
   * A currency is required.
   */
  CurrencyRequired = "Discounts:CurrencyRequired",
  /**
   * An action is required.
   */
  ActionRequired = "Discounts:ActionRequired",
  /**
   * An OrganizationUnitSet is required.
   */
  OrganizationUnitSetRequired = "Discounts:OrganizationUnitSetRequired",
  /**
   * A Layer is required.
   */
  LayerRequired = "Discounts:LayerRequired",
  /**
   * The selected date range is invalid.
   */
  InvalidDateRange = "Discounts:InvalidDateRange",
  /**
   * A (Discount)OrderType is required.
   */
  OrderTypeRequired = "Discounts:OrderTypeRequired",
  /**
   * A customer is required.
   */
  CustomerRequired = "Discounts:CustomerRequired",
}
export enum PurchaseOrders {
  /**
   * Expecting UnitPrice to be greater than 0
   */
  UnitPriceNeedsToBeGreaterThenZero = "PurchaseOrders:UnitPriceNeedsToBeGreaterThenZero",
  /**
   * Pruchase order already has a line with the same ProductID and StockLabel.
   */
  PurchaseOrderAlreadyHasALineWithTheSameProductAndStockLabelID = "PurchaseOrders:PurchaseOrderAlreadyHasALineWithTheSameProductAndStockLabelID",
}
export enum CustomFieldFilterErrors {
  /**
   * No fields given to validate filter
   */
  NoFieldsGivenToValidateFilter = "CustomFieldFilterErrors:NoFieldsGivenToValidateFilter",
  /**
   * Invalid custom field value given
   */
  InvalidCustomFieldValueGiven = "CustomFieldFilterErrors:InvalidCustomFieldValueGiven",
  /**
   * Filter validate failed because there are not custom fields of type {0:string}
   */
  CouldNotValidateFilterWhenNoCustomFieldsAreSetToCustomFieldTypeX = "CustomFieldFilterErrors:CouldNotValidateFilterWhenNoCustomFieldsAreSetToCustomFieldTypeX",
  /**
   * Custom field {0:number} in filter is unknown.
   */
  InvalidCustomFieldInFilter = "CustomFieldFilterErrors:InvalidCustomFieldInFilter",
  /**
   * Customfield {0:number} is an array valued field which only supports the `Contains` and `DoesNotContain` operators
   */
  InvalidArrayValueOperator = "CustomFieldFilterErrors:InvalidArrayValueOperator",
  /**
   * Customfield {CustomFieldId:number} of type {DataType:string} does not support the operator {Operator:string}
   */
  InvalidValueOperator = "CustomFieldFilterErrors:InvalidValueOperator",
}
export enum Cultures {
  /**
   * This country already exists for this OrganizationUnit
   */
  CountryAlreadyExists = "Cultures:CountryAlreadyExists",
}
export enum Stock {
  /**
   * Not enough stock available for product {0:number}.
   */
  InsufficientStock = "Stock:InsufficientStock",
  /**
   * Not enough stock available for product {0:number}.
   */
  InsufficientStockForCommit = "Stock:InsufficientStockForCommit",
}
export enum StockMutationReason {
  /**
   * Duplicate name or backendID.
   */
  DuplicateReason = "StockMutationReason:DuplicateReason",
  /**
   * Cannot delete internal stock mutation reason.
   */
  InternalReason = "StockMutationReason:InternalReason",
}
export enum Products {
  /**
   * Product {duplicateProductID:number} already uses BackendID {backendID:string} and BackendID must be unique per catalog.
   */
  DuplicateBackendID = "Products:DuplicateBackendID",
  /**
   * Cannot copy product of this type.
   */
  CannotCopyProductOfThisType = "Products:CannotCopyProductOfThisType",
  /**
   * Can't identify product. Serial number or Orderline ID is missing or Orderline does not contain serial number.
   */
  CannotIdentifyProduct = "Products:CannotIdentifyProduct",
}
export enum TaxRates {
  /**
   * There is no TaxRate for TaxCode {taxCodeName:string} in country {countryID:string} / {countrySubdivisionID:string}.
   */
  NotFoundForTaxCode = "TaxRates:NotFoundForTaxCode",
  /**
   * There is no TaxRate for Product {productID:number} in country {countryID:string} / {countrySubdivisionID:string}.
   */
  NotFoundForProduct = "TaxRates:NotFoundForProduct",
  /**
   * There is no TaxRate for Rate {taxRate:number} in country {countryID:string} / {countrySubdivisionID:string}.
   */
  NotFoundForTaxRate = "TaxRates:NotFoundForTaxRate",
  /**
   * Cannot create a tax rate for tax code {0:string} without specifying a TaxRateCategory.
   */
  CategoryIsRequired = "TaxRates:CategoryIsRequired",
}
export enum ReturnOrders {
  /**
   * Not allowed to add lines of an extax order to an intax order and vice versa.
   */
  CannotCreateReturnLinesWithDifferentTaxHandling = "ReturnOrders:CannotCreateReturnLinesWithDifferentTaxHandling",
  /**
   * Not allowed to return invalid order line {ID:number} ({reason:string})
   */
  CannotCreateReturnOrderWithInvalidLines = "ReturnOrders:CannotCreateReturnOrderWithInvalidLines",
  /**
   * Can't return orderline {0:number} because the original orderline can not be found.
   */
  CannotReturnLineWithInvalidOriginalLine = "ReturnOrders:CannotReturnLineWithInvalidOriginalLine",
  /**
   * Can't return orderline {returnOrderLineID:number} because the original orderline ({originalOrderLineID:number}) is not returnable.
   */
  CannotReturnNonReturnableLine = "ReturnOrders:CannotReturnNonReturnableLine",
  /**
   * Can't send invoices when the original order has no customer or when no customer is provided for unreferenced returns.
   */
  CannotSendInvoiceWithoutCustomer = "ReturnOrders:CannotSendInvoiceWithoutCustomer",
  /**
   * Unreferenced return reversals are not allowed.
   */
  CannotCreateUnreferencedReturnReversals = "ReturnOrders:CannotCreateUnreferencedReturnReversals",
}
export enum Countries {
  /**
   * Subdivision doesn't match the selected country.
   */
  InvalidSubdivision = "Countries:InvalidSubdivision",
}
export enum Companies {
  /**
   * VAT Number is already in use.
   */
  VatNumberAlreadyInUse = "Companies:VatNumberAlreadyInUse",
  /**
   * Backend ID is already in use.
   */
  BackendIDAlreadyInUse = "Companies:BackendIDAlreadyInUse",
  /**
   * Company is still attached to some organization unit(s). Please detach those first before proceeding.
   */
  CannotDeleteCompanyWithRelatedOrganizationUnits = "Companies:CannotDeleteCompanyWithRelatedOrganizationUnits",
  /**
   * Company {0:number} does not have the required type {1:CompanyType}.
   */
  CompanyHasInvalidType = "Companies:CompanyHasInvalidType",
}
export enum Company {
  /**
   * It is not allowed to modify the registration number with closed transactions
   */
  ModifyRegistrationNumberNotAllowed = "Company:ModifyRegistrationNumberNotAllowed",
  /**
   * It is not allowed to modify the vat number with closed transactions
   */
  ModifyVatNumberNotAllowed = "Company:ModifyVatNumberNotAllowed",
  /**
   * Push companies limit exceeded.
   */
  PushCompaniesLimitExceeded = "Company:PushCompaniesLimitExceeded",
  /**
   * Unknown error while pushing company with BackendID `{0:string}`
   */
  UnknownPushCompanyError = "Company:UnknownPushCompanyError",
}
export enum Offers {
  /**
   * Offers cannot be shipped.
   */
  ShipmentsNotAllowed = "Offers:ShipmentsNotAllowed",
  /**
   * Offers cannot have payments.
   */
  PaymentsNotAllowed = "Offers:PaymentsNotAllowed",
  /**
   * Offers cannot be invoiced, yet.
   */
  InvoicesNotSupportedYet = "Offers:InvoicesNotSupportedYet",
  /**
   * This offer requires a customer based on configured settings.
   */
  OfferRequiresCustomer = "Offers:OfferRequiresCustomer",
  /**
   * Expired Offers cannot be mutated.
   */
  Expired = "Offers:Expired",
  /**
   * Order {0:number} cannot be changed, changing an offer is not allowed.
   */
  Unchangeble = "Offers:Unchangeble",
}
export enum OpeningHoursTemplateExcelUpload {
  /**
   * [row {0:number}, column {1:string}]: Status must be either 'Open' or 'Closed'
   */
  NoValidStatusProvided = "OpeningHoursTemplateExcelUpload:NoValidStatusProvided",
  /**
   * [row {0:number}, column {1:string}]: Date must be after today
   */
  InvalidDateProvided = "OpeningHoursTemplateExcelUpload:InvalidDateProvided",
  /**
   * [row {0:number}, column {1:string}]: From and To must be set if status is 'Open'
   */
  NoOpeningHoursProvided = "OpeningHoursTemplateExcelUpload:NoOpeningHoursProvided",
  /**
   * [row {0:number}, column {1:string}]: From and To must not be set if status is 'Closed'
   */
  NoOpeningHoursAllowedIfStatusIsClosed = "OpeningHoursTemplateExcelUpload:NoOpeningHoursAllowedIfStatusIsClosed",
  /**
   * [row {0:number}, column {1:string}]: The hour of opening must be before the hour of closing
   */
  InvalidOpeningHoursProvided = "OpeningHoursTemplateExcelUpload:InvalidOpeningHoursProvided",
  /**
   * [row {0:number}, column {1:string}]: Multiple time slots on the same day cannot overlap each other
   */
  OverlappingTimeSlots = "OpeningHoursTemplateExcelUpload:OverlappingTimeSlots",
  /**
   * Multiple rows have been provided with Date '{0:string}'. Please only provide one row per date
   */
  MultipleRowsWithSameDate = "OpeningHoursTemplateExcelUpload:MultipleRowsWithSameDate",
}
export enum Assortments {
  /**
   * It's not possible to delete the default Assortment.
   */
  CannotDeleteDefault = "Assortments:CannotDeleteDefault",
}
export enum AssortmentProducts {
  /**
   * When adding products to an assortment, either Products or Query needs to have a value.
   */
  NoProductsSpecified = "AssortmentProducts:NoProductsSpecified",
  /**
   * It's not possible to remove products from the default assortment.
   */
  CannotRemoveProductsFromDefaultAssortment = "AssortmentProducts:CannotRemoveProductsFromDefaultAssortment",
  /**
   * Invalid AssortmentProduct date sequence: {0:string} (value: {1:string}) cannot be after {2:string} (value: {3:string}).
   */
  PreorderDatesOutOfOrder = "AssortmentProducts:PreorderDatesOutOfOrder",
}
export enum ProductRelation {
  /**
   * ProductRelationType of type `Group` cannot have a StockRelationType.
   */
  ProductRelationTypeOfTypeGroupCannotHaveAStockRelationType = "ProductRelation:ProductRelationTypeOfTypeGroupCannotHaveAStockRelationType",
  /**
   * Only ProductRelationType `Group` or types with the DynamicRelationProductID set accept a ProductSearchTemplateID or ProductSearchFilters.
   */
  DynamicRelationTypeOnlyAllowedForGroupOrWhenDynamicRelationProductIDIsSet = "ProductRelation:DynamicRelationTypeOnlyAllowedForGroupOrWhenDynamicRelationProductIDIsSet",
  /**
   * Cannot add static relation to a dynamic relationtype
   */
  CannotCreateStaticRelationOnADynamicRelationType = "ProductRelation:CannotCreateStaticRelationOnADynamicRelationType",
  /**
   * Product cannot have a relation with itself
   */
  ProductCannotHaveARelationWithItself = "ProductRelation:ProductCannotHaveARelationWithItself",
  /**
   * Uni- or bidirectional relations require a primary and related product ID
   */
  UniOrBidirectionalRelationsRequireAPrimaryAndRelatedProductID = "ProductRelation:UniOrBidirectionalRelationsRequireAPrimaryAndRelatedProductID",
  /**
   * A unidirectional relation already exists from product {relatedProductID:number} to product {primaryProductID:number}. Can not register a unidirectional relation from {primaryProductID:number} to {relatedProductID:number} on the same relation type.
   */
  UnidirectionalRelationAlreadyExistsFromRelatedProduct = "ProductRelation:UnidirectionalRelationAlreadyExistsFromRelatedProduct",
  /**
   * Product relation not found with primary product '{primaryProductID:number}' and related product '{relatedProductID:number}'.
   */
  ProductRelationNotFound = "ProductRelation:ProductRelationNotFound",
  /**
   * There are no product relations for type the given product relation type '{0:number}'.
   */
  NoProductRelationsForGivenType = "ProductRelation:NoProductRelationsForGivenType",
  /**
   * Filters are required for a dynamic product relation type with uni or bi directional
   */
  DynamicUniOrBiDirectionalRelationTypesRequireAProductFilter = "ProductRelation:DynamicUniOrBiDirectionalRelationTypesRequireAProductFilter",
  /**
   * DynamicRelationProductID required for a dynamic product relation type with uni or bi directional
   */
  DynamicUniOrBiDirectionalRelationTypesRequireADynamicRelationProductID = "ProductRelation:DynamicUniOrBiDirectionalRelationTypesRequireADynamicRelationProductID",
  /**
   * Cannot convert product relation from static to dynamic
   */
  CannotConvertProductRelationFromStaticToDynamic = "ProductRelation:CannotConvertProductRelationFromStaticToDynamic",
  /**
   * Cannot change second chance relation type.
   */
  CannotChangeSecondChanceRelationType = "ProductRelation:CannotChangeSecondChanceRelationType",
}
export enum Inquiries {
  /**
   * The type for custom field {customFieldId:number} should by User, got {type:string}
   */
  InvalidType = "Inquiries:InvalidType",
  /**
   * Items should point to unique custom fields.
   */
  CustomFieldsShouldBeUnique = "Inquiries:CustomFieldsShouldBeUnique",
}
export enum Inquiry {
  /**
   * Inquire can only be filled in once.
   */
  InquireCanOnlyBeFilledInOnce = "Inquiry:InquireCanOnlyBeFilledInOnce",
  /**
   * You cannot submit duplicate inquire items.
   */
  DuplicateInquireItemSubmitted = "Inquiry:DuplicateInquireItemSubmitted",
  /**
   * Missing the following required inquiry item(s): {0:string}.
   */
  MissingRequiredInquiryItems = "Inquiry:MissingRequiredInquiryItems",
}
export enum CaseStatus {
  /**
   * Name '{0:string}' already exists.
   */
  StatusNameAlreadyExists = "CaseStatus:StatusNameAlreadyExists",
  /**
   * Script is not valid.
   */
  ScriptIsNotAValid = "CaseStatus:ScriptIsNotAValid",
  /**
   * The name of internal case status '{0:string}' cannot be modified.
   */
  NameOfInternalStatusCannotBeModified = "CaseStatus:NameOfInternalStatusCannotBeModified",
  /**
   * Internal case status '{0:string}' cannot be deleted.
   */
  InternalStatusCannotBeDeleted = "CaseStatus:InternalStatusCannotBeDeleted",
}
export enum CaseTopic {
  /**
   * Name '{0:string}' already exists.
   */
  TopicNameAlreadyExists = "CaseTopic:TopicNameAlreadyExists",
  /**
   * Can not filter on both RootOnly and ParentID, please provide only one.
   */
  CanNotFilterOnBothRootOnlyAndParent = "CaseTopic:CanNotFilterOnBothRootOnlyAndParent",
}
export enum CaseInteraction {
  /**
   * Can only update own interactions.
   */
  CanOnlyUpdateOwnInteraction = "CaseInteraction:CanOnlyUpdateOwnInteraction",
  /**
   * Can only delete own interactions.
   */
  CanOnlyDeleteOwnInteraction = "CaseInteraction:CanOnlyDeleteOwnInteraction",
}
export enum Case {
  /**
   * Case already closed.
   */
  CaseAlreadyClosed = "Case:CaseAlreadyClosed",
  /**
   * Can only archive closed cases.
   */
  CanOnlyArchiveClosedCases = "Case:CanOnlyArchiveClosedCases",
  /**
   * Cannot update title and description of customer created case.
   */
  CannotUpdateTitleAndDescriptionOfCustomerCreatedCase = "Case:CannotUpdateTitleAndDescriptionOfCustomerCreatedCase",
  /**
   * Cannot update title or description after assignment.
   */
  CannotUpdateTitleOrDescriptionAfterAssignment = "Case:CannotUpdateTitleOrDescriptionAfterAssignment",
  /**
   * Can only update case title and description of own cases.
   */
  CanOnlyUpdateCaseTitleAndDescriptionOfOwnCases = "Case:CanOnlyUpdateCaseTitleAndDescriptionOfOwnCases",
  /**
   * Assignee is invalid user type to assign to case.
   */
  AssigneeIsInvalidUserTypeToAssignToCase = "Case:AssigneeIsInvalidUserTypeToAssignToCase",
  /**
   * Related orderline does not match related order.
   */
  RelatedOrderLineDoesNotMatchRelatedOrder = "Case:RelatedOrderLineDoesNotMatchRelatedOrder",
  /**
   * Can only set warranty on products.
   */
  CanOnlySetWarrantyOnProducts = "Case:CanOnlySetWarrantyOnProducts",
  /**
   * Cannot revert case status to `new` status.
   */
  CannotRevertCaseStatusToNewStatus = "Case:CannotRevertCaseStatusToNewStatus",
  /**
   * Either an OrderLineID or ProductID is required for case related items.
   */
  EitherOrderLineIDOrProductIDIsRequired = "Case:EitherOrderLineIDOrProductIDIsRequired",
  /**
   * Cannot update originating organization unit after it is set.
   */
  CannotUpdateOriginatingOrganizationUnit = "Case:CannotUpdateOriginatingOrganizationUnit",
  /**
   * The case with BackendID `{0:string}` already exists.
   */
  CaseWithBackendIDAlreadyExists = "Case:CaseWithBackendIDAlreadyExists",
  /**
   * CaseTopics are required for Cases.
   */
  CaseTopicIsRequiredForCase = "Case:CaseTopicIsRequiredForCase",
}
export enum PushCase {
  /**
   * Pushed topic '{0:string}' is unknown.
   */
  UnknownPushedTopic = "PushCase:UnknownPushedTopic",
  /**
   * No CaseTopic was found for the given BackendID, but no Name was provided either. Please provide a Name to be able to find the CaseTopic by the Name and Parent.
   */
  NameIsRequiredIfBackendIDIsNotFound = "PushCase:NameIsRequiredIfBackendIDIsNotFound",
  /**
   * There are more than 1 CaseTopics with name `{0:string}`, please use the new field `CaseTopic` and specify the BackendID.
   */
  MultipleOptionsForCaseTopicName = "PushCase:MultipleOptionsForCaseTopicName",
  /**
   * Pushed status '{0:string}' is unknown.
   */
  UnknownPushedStatus = "PushCase:UnknownPushedStatus",
  /**
   * Invalid product backend ID for case related item.
   */
  InvalidProductBackendID = "PushCase:InvalidProductBackendID",
}
export enum Script {
  /**
   * Script {0:number} is unknown.
   */
  UnknownScript = "Script:UnknownScript",
  /**
   * Script type `{givenType:string}` is invalid. Expected script of type `{expectedType:string}`.
   */
  InvalidScriptType = "Script:InvalidScriptType",
  /**
   * Dialect {0:string} is not a recognized dialect
   */
  UnknownDialect = "Script:UnknownDialect",
}
export enum UserRequirement {
  /**
   * Can only set a script when the requirement is for a single action.
   */
  CanOnlySetScriptForSingleRequiredFor = "UserRequirement:CanOnlySetScriptForSingleRequiredFor",
}
export enum ShippingRestriction {
  /**
   * Invalid ProductPropertyType, only types with a Boolean DataType are allowed.
   */
  InvalidProductPropertyType = "ShippingRestriction:InvalidProductPropertyType",
  /**
   * Invalid script type.
   */
  InvalidScriptType = "ShippingRestriction:InvalidScriptType",
  /**
   * OrganizationUnitID or OrganizationUnitSetID is required unless a leading script is provided.
   */
  OrganizationUnitOrSetRequired = "ShippingRestriction:OrganizationUnitOrSetRequired",
  /**
   * CountryID is required unless a leading script is provided.
   */
  CountryRequired = "ShippingRestriction:CountryRequired",
}
export enum PersonalizedPromotion {
  /**
   * Value of property `{0:string}` must be higher than 0.
   */
  ValueMustBeGreaterThanZero = "PersonalizedPromotion:ValueMustBeGreaterThanZero",
  /**
   * Value of property `{0:string}` must be unique.
   */
  ValueMustBeUnique = "PersonalizedPromotion:ValueMustBeUnique",
  /**
   * No products are provided.
   */
  NoProductsGiven = "PersonalizedPromotion:NoProductsGiven",
  /**
   * No available products.
   */
  NoAvailableProducts = "PersonalizedPromotion:NoAvailableProducts",
  /**
   * Product '{0}' not available.
   */
  ProductNotAvailable = "PersonalizedPromotion:ProductNotAvailable",
  /**
   * Maximum distinct products exceeded.
   */
  MaximumDistinctProductsExceeded = "PersonalizedPromotion:MaximumDistinctProductsExceeded",
  /**
   * Personalized promotions is already applied.
   */
  AlreadyApplied = "PersonalizedPromotion:AlreadyApplied",
}
export enum ProductSet {
  /**
   * Product set {ProductSetName:string} requires at least one of the following values: {PropertyName:string}
   */
  ProductSetRequiresAtLeastOneOfTheFollowingValues = "ProductSet:ProductSetRequiresAtLeastOneOfTheFollowingValues",
  /**
   * The value of product set property {PropertyName:string} in product set {ProductSetName:string} must be higher then 0.
   */
  ProductSetValueCannotBeNegativeOrZero = "ProductSet:ProductSetValueCannotBeNegativeOrZero",
  /**
   * The value of product set property {PropertyName:string} in product set {ProductSetName:string} must be higher then or equals to 0.
   */
  ProductSetValueCannotBeNegative = "ProductSet:ProductSetValueCannotBeNegative",
  /**
   * The value of product set property {PropertyNameX:string} in product set {ProductSetName:string} must be higher then or equals to property {PropertyNameY:string}.
   */
  ProductSetValueOfXMustBeLargerThenOrEqualToValueOfY = "ProductSet:ProductSetValueOfXMustBeLargerThenOrEqualToValueOfY",
  /**
   * Product set {0:string} did not resolve to a valid list of products.
   */
  ProductSetDidNotResolveToAListOfValidProducts = "ProductSet:ProductSetDidNotResolveToAListOfValidProducts",
  /**
   * A productset price filter requires a valid from/to value.
   */
  ProductSetPriceFilterRequiresAValidFromOrToValue = "ProductSet:ProductSetPriceFilterRequiresAValidFromOrToValue",
}
export enum AddressRequirement {
  /**
   * Property does not support predefined values.
   */
  PropertyDoesNotSupportPredefinedValues = "AddressRequirement:PropertyDoesNotSupportPredefinedValues",
}
export enum Address {
  /**
   * Invalid country subdivision.
   */
  InvalidCountrySubdivision = "Address:InvalidCountrySubdivision",
}
export enum CashHandlers {
  /**
   * MaxAmountPerOrder {0:number} needs to be rounded based on RoundingFactor. Try again using {1:number} or another rounded value.
   */
  IncorrectMaxAmountPerOrder = "CashHandlers:IncorrectMaxAmountPerOrder",
}
export enum EntityTranslations {
  /**
   * Cannot copy translations from an entity to itself.
   */
  CannotCopyToItself = "EntityTranslations:CannotCopyToItself",
  /**
   * Entity `{0:string}` does not have any translations.
   */
  EntityDoesNotHaveTranslations = "EntityTranslations:EntityDoesNotHaveTranslations",
}
export enum OrderVerificationReason {
  /**
   * Name '{0:string}' already exists.
   */
  NameAlreadyExists = "OrderVerificationReason:NameAlreadyExists",
  /**
   * Internal order verification reason '{0:string}' cannot be deleted.
   */
  InternalOrderVerificationReasonCannotBeDeleted = "OrderVerificationReason:InternalOrderVerificationReasonCannotBeDeleted",
  /**
   * The name of internal order verification reason '{0:string}' cannot be modified.
   */
  NameOfInternalOrderVerificationReasonCannotBeModified = "OrderVerificationReason:NameOfInternalOrderVerificationReasonCannotBeModified",
}
export enum Surveys {
  /**
   * Another survey with this BackendID already exists.
   */
  DuplicateBackendID = "Surveys:DuplicateBackendID",
  /**
   * The trigger type `{0:string}` is unknown.
   */
  UnknownTriggerType = "Surveys:UnknownTriggerType",
  /**
   * The distribution type `{0:string}` is unknown.
   */
  UnknownDistributionType = "Surveys:UnknownDistributionType",
  /**
   * Survey '{SurveyID:number}' is missing data for trigger '{TriggerType:string}'.
   */
  MissingTriggerData = "Surveys:MissingTriggerData",
  /**
   * Survey '{SurveyID:number}' has invalid data for trigger '{TriggerType:string}'.
   */
  InvalidTriggerData = "Surveys:InvalidTriggerData",
  /**
   * The date of property '{0:string}' is invalid.
   */
  InvalidDate = "Surveys:InvalidDate",
  /**
   * Email address '{0:string}' is invalid.
   */
  InvalidEmailAddress = "Surveys:InvalidEmailAddress",
  /**
   * Value of property '{PropertyNameX:string}' must be larger then property '{PropertyNameY:string}'
   */
  ValueOfXMustBeLargerThenValueOfY = "Surveys:ValueOfXMustBeLargerThenValueOfY",
  /**
   * Cannot publish survey without questions.
   */
  CannotPublishSurveyWithoutQuestions = "Surveys:CannotPublishSurveyWithoutQuestions",
  /**
   * All questions have routings pointing to them. One question needs to have no routings pointing to it to act as the first question.
   */
  AllQuestionsRoutedTo = "Surveys:AllQuestionsRoutedTo",
  /**
   * Multiple questions without routings pointing to them. Only one question needs to have no routings pointing to it to act as the first question.
   */
  MultipleQuestionsNotRoutedTo = "Surveys:MultipleQuestionsNotRoutedTo",
  /**
   * Unknown error while verifying survey with ID `{0:number}`
   */
  UnknownVerifySurveyError = "Surveys:UnknownVerifySurveyError",
  /**
   * Question recursion detected; {0:string}.
   */
  PossibleLoopDetectedForQuestion = "Surveys:PossibleLoopDetectedForQuestion",
  /**
   * Routed to non existing question within survey `{0:number}`.
   */
  RoutedToNonExistingQuestionWithinSurvey = "Surveys:RoutedToNonExistingQuestionWithinSurvey",
  /**
   * Possible loop detected.
   */
  PossibleLoopDetected = "Surveys:PossibleLoopDetected",
  /**
   * Cannot update taken survey.
   */
  CannotUpdateTakenSurvey = "Surveys:CannotUpdateTakenSurvey",
  /**
   * Cannot update {0:string} for published survey.
   */
  CannotUpdatePropertyForPublishedSurvey = "Surveys:CannotUpdatePropertyForPublishedSurvey",
  /**
   * Survey is being published
   */
  SurveyIsBeingPublished = "Surveys:SurveyIsBeingPublished",
  /**
   * Survey doesn't have pending changes
   */
  SurveyDoesntHavePendingChanges = "Surveys:SurveyDoesntHavePendingChanges",
  /**
   * Cannot start survey because it has not been published.
   */
  SurveyIsNotPublished = "Surveys:SurveyIsNotPublished",
  /**
   * Question not part of survey.
   */
  QuestionNotPartOfSurvey = "Surveys:QuestionNotPartOfSurvey",
  /**
   * An answer is required.
   */
  QuestionAnswerIsRequired = "Surveys:QuestionAnswerIsRequired",
  /**
   * Survey is not started.
   */
  SurveyIsNotStarted = "Surveys:SurveyIsNotStarted",
  /**
   * Survey requires context.
   */
  SurveyRequiresContext = "Surveys:SurveyRequiresContext",
  /**
   * Automatic survey cannot be started manually.
   */
  AutomaticSurveyCannotBeStartedManually = "Surveys:AutomaticSurveyCannotBeStartedManually",
  /**
   * Either Token or a SurveyID is required
   */
  EitherTokenOrSurveyIDRequired = "Surveys:EitherTokenOrSurveyIDRequired",
  /**
   * Answer is invalid
   */
  AnswerInvalid = "Surveys:AnswerInvalid",
  /**
   * Survey completed
   */
  SurveyCompleted = "Surveys:SurveyCompleted",
  /**
   * Survey expired
   */
  SurveyExpired = "Surveys:SurveyExpired",
  /**
   * Question not answered yet
   */
  QuestionNotAnsweredYet = "Surveys:QuestionNotAnsweredYet",
}
export enum SurveyQuestions {
  /**
   * Another survey question with this BackendID already exists.
   */
  DuplicateBackendID = "SurveyQuestions:DuplicateBackendID",
  /**
   * Another survey question with this LogicalName already exists.
   */
  DuplicateLogicalName = "SurveyQuestions:DuplicateLogicalName",
  /**
   * Either ContextCustomField or question data is Required.
   */
  EitherContextCustomFieldOrQuestionDataIsRequired = "SurveyQuestions:EitherContextCustomFieldOrQuestionDataIsRequired",
  /**
   * ContextCustomField and question data are mutually exclusive.
   */
  ContextCustomFieldAndQuestionDataAreMutuallyExclusive = "SurveyQuestions:ContextCustomFieldAndQuestionDataAreMutuallyExclusive",
  /**
   * Custom field type is required to be of type 'User'.
   */
  InvalidCustomFieldType = "SurveyQuestions:InvalidCustomFieldType",
  /**
   * Data type 'Enum' requires optional answers.
   */
  DataTypeEnumRequiresOptionalAnswers = "SurveyQuestions:DataTypeEnumRequiresOptionalAnswers",
  /**
   * Optional answers require a non empty key and value.
   */
  EnumValuesRequireAKeyAndValue = "SurveyQuestions:EnumValuesRequireAKeyAndValue",
  /**
   * Optional answer keys must be unique.
   */
  OptionalAnswerKeysMustBeUnique = "SurveyQuestions:OptionalAnswerKeysMustBeUnique",
  /**
   * Optional answers are only allowed for datatype `Enum`.
   */
  OptionalAnswersAreOnlyAllowedForDataTypeEnum = "SurveyQuestions:OptionalAnswersAreOnlyAllowedForDataTypeEnum",
  /**
   * Saving on customer requires a custom field.
   */
  SaveOnCustomerRequiresCustomField = "SurveyQuestions:SaveOnCustomerRequiresCustomField",
  /**
   * The question cannot be added to a set belonging to a different survey.
   */
  QuestionSetBelongsToDifferentSurvey = "SurveyQuestions:QuestionSetBelongsToDifferentSurvey",
  /**
   * Cannot update logical name for published survey.
   */
  CannotUpdateLogicalNameForPublishedSurvey = "SurveyQuestions:CannotUpdateLogicalNameForPublishedSurvey",
  /**
   * Cannot switch question to other custom field after publish.
   */
  CannotSwitchQuestionToOtherCustomFieldAfterPublish = "SurveyQuestions:CannotSwitchQuestionToOtherCustomFieldAfterPublish",
  /**
   * Question cannot be detached from custom field after creation.
   */
  QuestionCannotBeDetachedFromCustomFieldAfterCreation = "SurveyQuestions:QuestionCannotBeDetachedFromCustomFieldAfterCreation",
  /**
   * Question cannot be attached to custom field after creation.
   */
  QuestionCannotBeAttachedToCustomFieldAfterCreation = "SurveyQuestions:QuestionCannotBeAttachedToCustomFieldAfterCreation",
  /**
   * Cannot switch DataType after publish.
   */
  CannotSwitchDataTypeAfterPublish = "SurveyQuestions:CannotSwitchDataTypeAfterPublish",
}
export enum SurveyQuestionRoutings {
  /**
   * All question routings are required for reordering.
   */
  AllQuestionRoutingsAreRequiredForReordering = "SurveyQuestionRoutings:AllQuestionRoutingsAreRequiredForReordering",
}

export enum EnvironmentInitialization {
  /**
   * Could not convert initialization state value in Redis to initialization state Enum.
   */
  InvalidEnvironmentInitializationState = "EnvironmentInitialization:InvalidEnvironmentInitializationState",
  /**
   * Tried to set environment state to created but the environment state is already set.
   */
  EnvironmentStateAlreadySet = "EnvironmentInitialization:EnvironmentStateAlreadySet",
  /**
   * Tried to set an already Initialized environment to Initialized.
   */
  EnvironmentAlreadyInitialized = "EnvironmentInitialization:EnvironmentAlreadyInitialized",
  /**
   * The currency passed is not a valid option.
   */
  InvalidCurrencyPassed = "EnvironmentInitialization:InvalidCurrencyPassed",
  /**
   * The country passed is not a valid option.
   */
  InvalidCountryPassed = "EnvironmentInitialization:InvalidCountryPassed",
  /**
   * The language passed is not a valid option.
   */
  InvalidLanguagePassed = "EnvironmentInitialization:InvalidLanguagePassed",
  /**
   * The e-mail passed is not a valid e-mail address.
   */
  InvalidEmailPassed = "EnvironmentInitialization:InvalidEmailPassed",
}
export enum EndpointConfiguration {
  /**
   * The provided endpoint is either invalid or is not secure
   */
  InvalidEndpoint = "EndpointConfiguration:InvalidEndpoint",
  /**
   * Cannot change status to Error
   */
  CannotSetToError = "EndpointConfiguration:CannotSetToError",
  /**
   * The provided config is invalid
   */
  InvalidConfig = "EndpointConfiguration:InvalidConfig",
  /**
   * The key ID is invalid (should be either 1 or 2)
   */
  InvalidKeyID = "EndpointConfiguration:InvalidKeyID",
  /**
   * The requested BackendID is already in use
   */
  DuplicateBackendID = "EndpointConfiguration:DuplicateBackendID",
}
export enum ConditionHandler {
  /**
   * Condition {ConditionName:string} (id {ConditionID:number}) for {ParentName:string} (id {ParentID:number}) was malformed or its build-up resulted in an exception.
   */
  InvalidCondition = "ConditionHandler:InvalidCondition",
  /**
   * The data for condition {ConditionName:string} (id {ConditionID:number}) on {ParentName:string} (id {ParentID:number}) is not initialized properly.
   */
  ConditionDataNotInitialized = "ConditionHandler:ConditionDataNotInitialized",
}
export enum CouponOriginatingOrderOrganizationUnitCondition {
  /**
   * Coupon can only be used on originating order organization unit.
   */
  CouponCanOnlyBeUsedOnOriginatingOrderOrganizationUnit = "CouponOriginatingOrderOrganizationUnitCondition:CouponCanOnlyBeUsedOnOriginatingOrderOrganizationUnit",
}
export enum CouponValidityCondition {
  /**
   * Invalid condition configuration. At least one of the properties must be set.
   */
  InvalidConditionConfiguration = "CouponValidityCondition:InvalidConditionConfiguration",
  /**
   * Originating order does not this coupon registered.
   */
  OriginatingOrderHasntGotThisCouponRegistered = "CouponValidityCondition:OriginatingOrderHasntGotThisCouponRegistered",
  /**
   * Coupon can only be used on originating order requested date, but the originating order does not have a requested date registered.
   */
  OriginatingOrderDoesntHaveARequestedDate = "CouponValidityCondition:OriginatingOrderDoesntHaveARequestedDate",
  /**
   * Coupon is usable from {0:string}
   */
  CouponIsUsableFrom = "CouponValidityCondition:CouponIsUsableFrom",
  /**
   * Coupon is usable on {0:string}
   */
  CouponIsUsableOn = "CouponValidityCondition:CouponIsUsableOn",
  /**
   * Coupon was usable till {0:string}
   */
  CouponWasUsableTill = "CouponValidityCondition:CouponWasUsableTill",
}
export enum OldUserFieldConditionErrors {
  /**
   * No userfield's defined.
   */
  NoUserFieldDefined = "OldUserFieldConditionErrors:NoUserFieldDefined",
  /**
   * Userfield {0:number} is unknown.
   */
  UnknownUserField = "OldUserFieldConditionErrors:UnknownUserField",
  /**
   * User field {0:number} ({1:string}) has options and expects an integer value
   */
  UserFieldWithOptionsExpectsAnIntegerValue = "OldUserFieldConditionErrors:UserFieldWithOptionsExpectsAnIntegerValue",
  /**
   * Option value {2:number} for user field {0:number} ({1:string}) is invalid
   */
  InvalidUserFieldOptionValue = "OldUserFieldConditionErrors:InvalidUserFieldOptionValue",
}
export enum Factory {
  /**
   * Was unable to determine the correct organization unit for device #{0:number}
   */
  UnknownOrganizationUnit = "Document:Printer:Factory:UnknownOrganizationUnit",
  /**
   * Do not have a printer implementation for type {0:string}
   */
  UnknownPrinterType = "Document:Printer:Factory:UnknownPrinterType",
  /**
   * Address of device #{0:string} has not been configured
   */
  NotConfigured = "Document:Printer:Factory:NotConfigured",
}
export enum ReceiptPrinter {
  /**
   * Not allowed to print this invoice
   */
  NotAllowedToPrint = "ReceiptPrinter:NotAllowedToPrint",
  /**
   * Not allowed to print more than 1 copy
   */
  NoMoreThanOneDuplicateAllowed = "ReceiptPrinter:NoMoreThanOneDuplicateAllowed",
}
export enum ReprintThermalPrintResult {
  /**
   * Blob {0:string} cannot be rendered as it is not a thermal print result.
   */
  InvalidBlob = "ReprintThermalPrintResult:InvalidBlob",
}
export enum ThermalPrinter {
  /**
   * Printing to device failed: {0:string}
   */
  DeviceFailure = "ThermalPrinter:DeviceFailure",
  /**
   * Template {0:string} was not found.
   */
  TemplateNotFound = "ThermalPrinter:TemplateNotFound",
  /**
   * Template {0:string} was not found or was disabled.
   */
  TemplateNotFoundOrDisabled = "ThermalPrinter:TemplateNotFoundOrDisabled",
  /**
   * There is no paper present in device {0:string}.
   */
  NoPaper = "ThermalPrinter:NoPaper",
  /**
   * Device {0:string} can't currently print as the cover is open.
   */
  CoverOpen = "ThermalPrinter:CoverOpen",
  /**
   * Device {0:string} is reporting its status as offline.
   */
  Offline = "ThermalPrinter:Offline",
}
export enum OrderField {
  /**
   * Value {value:string} does not match type {type:CustomFieldDataTypes}
   */
  InvalidDataType = "OrderField:InvalidDataType",
}
export enum CustomFields {
  /**
   * CustomField {0:number} is unknown for the given type.
   */
  UnknownFieldForType = "CustomFields:UnknownFieldForType",
  /**
   * CustomField {0:string} is unknown for the given type.
   */
  UnknownFieldNameForType = "CustomFields:UnknownFieldNameForType",
  /**
   * CustomField {0:number} is required
   */
  Required = "CustomFields:Required",
  /**
   * You are not allowed to edit CustomField {0:number}
   */
  NotAllowed = "CustomFields:NotAllowed",
  /**
   * CustomField {CustomFieldID:number} is {CurrentLength:number} chars long but should not be longer than {MaxLength:number}
   */
  TooLong = "CustomFields:TooLong",
  /**
   * CustomField {CustomFieldID:number} is {CurrentLength:number} chars long but should be minimal {MinLength:number} chars
   */
  TooShort = "CustomFields:TooShort",
  /**
   * CustomField {CustomFieldID:number} is {CurrentValue:number} but should not be larger than {MaxValue:number}
   */
  TooLarge = "CustomFields:TooLarge",
  /**
   * CustomField {CustomFieldID:number} is {CurrentValue:number} but should be at least {MinValue:number}
   */
  TooSmall = "CustomFields:TooSmall",
  /**
   * CustomField {CustomFieldID:number} is {CurrentValue:string} but should not be later than {MaxDate:string}
   */
  TooLate = "CustomFields:TooLate",
  /**
   * CustomField {CustomFieldID:number} is {CurrentValue:string} but should not be earlier than {MinDate:string}
   */
  TooEarly = "CustomFields:TooEarly",
  /**
   * CustomField {CustomFieldID:number} is an enum, but the value {Value:string} doesn't exist
   */
  WrongEnumValue = "CustomFields:WrongEnumValue",
  /**
   * CustomField {0:number} is an integer field and can't have a fraction
   */
  IntegerCantHaveFraction = "CustomFields:IntegerCantHaveFraction",
  /**
   * The requested BackendID is already in use
   */
  DuplicateBackendID = "CustomFields:DuplicateBackendID",
  /**
   * A custom field with BackendID {0:string} does not exist
   */
  BackendIDDoesNotExist = "CustomFields:BackendIDDoesNotExist",
  /**
   * Enum values are only allowed when CustomField is of DataType 'Enum'
   */
  EnumValuesAreOnlyAllowedWhenCustomFieldIsOfDataTypeEnum = "CustomFields:EnumValuesAreOnlyAllowedWhenCustomFieldIsOfDataTypeEnum",
  /**
   * Enum values require a key and a value
   */
  EnumValuesRequireAKeyAndValue = "CustomFields:EnumValuesRequireAKeyAndValue",
  /**
   * Enum values are required when CustomField is of DataType 'Enum'
   */
  DataTypeEnumRequiresSomeEnumValues = "CustomFields:DataTypeEnumRequiresSomeEnumValues",
  /**
   * Date options are only allowed on customfields with type Date or DateTime
   */
  DateOptionsOnNonDateField = "CustomFields:DateOptionsOnNonDateField",
  /**
   * Range options are only allowed on customfields with type Integer or Decimal
   */
  RangeOptionsOnNonNumberField = "CustomFields:RangeOptionsOnNonNumberField",
  /**
   * The requested CustomField has a wrong type. Handling {expected:string}, but got a field with {actual:string}
   */
  InvalidType = "CustomFields:InvalidType",
  /**
   * The provided data type does not allow for array values.
   */
  ArrayNotAllowedForDataType = "CustomFields:ArrayNotAllowedForDataType",
  /**
   * One or more custom fields are invalid.
   */
  InvalidCustomFields = "CustomFields:InvalidCustomFields",
  /**
   * Organization unit and organization unit set are mutually exclusive.
   */
  OrganizationUnitAndSetAreMutuallyExclusive = "CustomFields:OrganizationUnitAndSetAreMutuallyExclusive",
}
export enum DiscountCampaign {
  /**
   * Budget of a discount campaign is required and cannot be negative.
   */
  BudgetCannotBeNegative = "DiscountCampaign:BudgetCannotBeNegative",
  /**
   * Budget currency cannot be changed.
   */
  CurrencyCannotBeChanged = "DiscountCampaign:CurrencyCannotBeChanged",
  /**
   * Currency is required when budget is set.
   */
  CurrencyRequiredWhenBudgetIsSet = "DiscountCampaign:CurrencyRequiredWhenBudgetIsSet",
  /**
   * Start date required when discount need to adhere to the campaing start/end date.
   */
  StartDateRequired = "DiscountCampaign:StartDateRequired",
  /**
   * cannot set EndDate without StartDate.
   */
  CannotSetEndDateWithoutStartDate = "DiscountCampaign:CannotSetEndDateWithoutStartDate",
  /**
   * EndDate cannot be lower then StartDate
   */
  EndDateCannotBeLowerThanStartDate = "DiscountCampaign:EndDateCannotBeLowerThanStartDate",
}
export enum EndpointConfigurations {
  /**
   * The certificate could not be parsed.
   */
  InvalidCertificate = "EndpointConfigurations:InvalidCertificate",
  /**
   * The certificate has no private key, please provide a certificate with a private key.
   */
  CertificateHasNoPrivateKey = "EndpointConfigurations:CertificateHasNoPrivateKey",
  /**
   * The certificate does not have Client Authentication usage.
   */
  CertificateHasNoClientAuthentication = "EndpointConfigurations:CertificateHasNoClientAuthentication",
  /**
   * The certificate does not have Digital Signature or Key Agreement usage.
   */
  CertificateHasNoDigitalSignatureOrKeyAgreement = "EndpointConfigurations:CertificateHasNoDigitalSignatureOrKeyAgreement",
  /**
   * The certificate must not be expired.
   */
  CertificateMustNotBeExpired = "EndpointConfigurations:CertificateMustNotBeExpired",
}
export enum EventExportConfigurations {
  /**
   * The certificate could not be parsed.
   */
  InvalidCertificate = "EventExportConfigurations:InvalidCertificate",
  /**
   * The certificate has no private key, please provide a certificate with a private key.
   */
  CertificateHasNoPrivateKey = "EventExportConfigurations:CertificateHasNoPrivateKey",
  /**
   * The certificate does not have Client Authentication usage.
   */
  CertificateHasNoClientAuthentication = "EventExportConfigurations:CertificateHasNoClientAuthentication",
  /**
   * The certificate does not have Digital Signature or Key Agreement usage.
   */
  CertificateHasNoDigitalSignatureOrKeyAgreement = "EventExportConfigurations:CertificateHasNoDigitalSignatureOrKeyAgreement",
  /**
   * The certificate must not be expired.
   */
  CertificateMustNotBeExpired = "EventExportConfigurations:CertificateMustNotBeExpired",
}
export enum PriceListManualInputAdjustment {
  /**
   * ManualInputAdjustments ExpireDate cannot be before the EffectiveDate.
   */
  ManualInputAdjustmentsExpireDateCannotBeBeforeEffectiveDate = "PriceListManualInputAdjustment:ManualInputAdjustmentsExpireDateCannotBeBeforeEffectiveDate",
}
export enum Settings {
  /**
   * Setting with key '{0:string}' doesn't exist.
   */
  NotFound = "Settings:NotFound",
  /**
   * Conversion failed for setting {key:string} to type {settingType:string} for value {value:string}
   */
  ConversionFailed = "Settings:ConversionFailed",
  /**
   * Prevented submission of masked value '{0:string}'!
   */
  MaskedValue = "Settings:MaskedValue",
  /**
   * This setting can only be set on rootlevel
   */
  RootLevelOnly = "Settings:RootLevelOnly",
}
export enum Communications {
  /**
   * The template for this message cannot be found or has been disabled.
   */
  TemplateNotFoundOrDisabled = "Communications:TemplateNotFoundOrDisabled",
}
export enum CreateFinancialPeriodAudit {
  /**
   * No available Financial Periods were found to audit
   */
  NoFinancialPeriodsFound = "CreateFinancialPeriodAudit:NoFinancialPeriodsFound",
  /**
   * The selected Financial Periods exceed a fiscal year, please limit your selection
   */
  FinancialPeriodsExceedFiscalYear = "CreateFinancialPeriodAudit:FinancialPeriodsExceedFiscalYear",
  /**
   * The supplied organization unit must be a country ou
   */
  CountryOuNeeded = "CreateFinancialPeriodAudit:CountryOuNeeded",
}
export enum CustomOrderStatus {
  /**
   * CustomOrderStatus with Name '{name:string}' already exists: {value:number}
   */
  StatusWithSameNameAlreadyExists = "CustomOrderStatus:StatusWithSameNameAlreadyExists",
  /**
   * CustomOrderStatus with Value '{0:number}'
   */
  StatusWithSameValueAlreadyExists = "CustomOrderStatus:StatusWithSameValueAlreadyExists",
  /**
   * Maximum allowed number of CustomOrderStatus rows has been reached
   */
  MaximumAllowedNumberOfStatusRowsReached = "CustomOrderStatus:MaximumAllowedNumberOfStatusRowsReached",
  /**
   * Modifying objects created by EVA is not allowed
   */
  ModifyingEVAObjectsNotAllowed = "CustomOrderStatus:ModifyingEVAObjectsNotAllowed",
  /**
   * Deleting objects created by EVA is not allowed
   */
  DeletingEVAObjectsNotAllowed = "CustomOrderStatus:DeletingEVAObjectsNotAllowed",
}
export enum AutoComplete {
  /**
   * Failed to create invoice while autocompleting order.
   */
  InvoiceNull = "AutoComplete:InvoiceNull",
  /**
   * Failed to invocie the Order while autocompleting order.
   */
  InvoicingFailed = "AutoComplete:InvoicingFailed",
  /**
   * Failed to cancel some of the lines while autocompleting order.
   */
  CancellationFailed = "AutoComplete:CancellationFailed",
}
export enum OrderGeneration {
  /**
   * Non-employee users cannot use LineActionType ShipLine on an Order with a shop as OrganizationUnit
   */
  ShipLinesNotAllowed = "OrderGeneration:ShipLinesNotAllowed",
}
export enum OrderLineCreator {
  /**
   * Missing pricing information provided for OrderLine and unable to determine this because no Product has been provided either.
   */
  NoPricingAndProduct = "OrderLineCreator:NoPricingAndProduct",
  /**
   * A configurable product cannot be added to an order.
   */
  ConfigurableProduct = "OrderLineCreator:ConfigurableProduct",
  /**
   * Can't add new lines to a signed order.
   */
  OrderIsSigned = "OrderLineCreator:OrderIsSigned",
  /**
   * Can't add new lines to a completed order.
   */
  OrderIsCompleted = "OrderLineCreator:OrderIsCompleted",
  /**
   * Can't add new lines to an order with a pending payment.
   */
  PendingPayment = "OrderLineCreator:PendingPayment",
  /**
   * Can't add a line with another currency to an order.
   */
  InvalidCurrency = "OrderLineCreator:InvalidCurrency",
  /**
   * Can't add a line with a product whose pre-sale date has not been reached.
   */
  OrderBeforePreSaleDate = "OrderLineCreator:OrderBeforePreSaleDate",
  /**
   * OrderLine {lineToCloneID:number} is not part of source order {sourceOrderID:number}
   */
  LineToCloneNotPartOfSourceOrder = "OrderLineCreator:LineToCloneNotPartOfSourceOrder",
  /**
   * LoyaltyProgram does not allow exchange orders
   */
  LoyaltyProgramDoesNotAllowExchangeOrders = "OrderLineCreator:LoyaltyProgramDoesNotAllowExchangeOrders",
  /**
   * Cannot add return from different LoyaltyProgram
   */
  CannotAddReturnFromDifferentLoyaltyProgram = "OrderLineCreator:CannotAddReturnFromDifferentLoyaltyProgram",
}
export enum LineActionType {
  /**
   * Cannot mutate the line action type of line {0:number} because it has a related line.
   */
  CannotMutateRelatedLine = "LineActionType:CannotMutateRelatedLine",
}
export enum LockOrder {
  /**
   * These changes are forbidden on a locked order.
   */
  ForbiddenChangesOnLockedOrder = "LockOrder:ForbiddenChangesOnLockedOrder",
}
export enum OrderCurrency {
  /**
   * It's not possible to change the currency of Order {0:number} anymore, please create a new order
   */
  OrderImmutable = "OrderCurrency:OrderImmutable",
  /**
   * It's not possible to change the currency of Order {orderID:number} from {oldCurrencyID:string} to {newCurrencyID:string}. The SoldFrom {orgID:number} has currency {ouCurrencyID:string}
   */
  Invalid = "OrderCurrency:Invalid",
}
export enum OrderCustomer {
  /**
   * Customer on this order can't be changed anymore because it has exported lines.
   */
  OrderHasExportedLines = "OrderCustomer:OrderHasExportedLines",
  /**
   * The selected user {0:number} is not a customer.
   */
  UserIsNotACustomer = "OrderCustomer:UserIsNotACustomer",
  /**
   * Attaching business customer to order is not allowed
   */
  AttachBusinessCustomerNotAllowed = "OrderCustomer:AttachBusinessCustomerNotAllowed",
  /**
   * Attaching a customer to an offline order is not allowed
   */
  AttachCustomerToOfflineOrderNotAllowed = "OrderCustomer:AttachCustomerToOfflineOrderNotAllowed",
  /**
   * Order is not applicable to attach customers
   */
  OrderNotApplicable = "OrderCustomer:OrderNotApplicable",
  /**
   * Customer cannot be changed on orders that only have return lines.
   */
  CustomerCannotBeChangedOnFullyReturn = "OrderCustomer:CustomerCannotBeChangedOnFullyReturn",
  /**
   * Customer cannot be change when loyalty program payments are done.
   */
  CustomerCannotBeChangeWhenLoyaltyProgramPaymentsAreDone = "OrderCustomer:CustomerCannotBeChangeWhenLoyaltyProgramPaymentsAreDone",
}
export enum ModifyQuantityOrdered {
  /**
   * Error: {0:string}
   */
  CannotModifyQuantityOrdered = "ModifyQuantityOrdered:CannotModifyQuantityOrdered",
  /**
   * All lines should be in the same order
   */
  LinesShouldBeInSameOrder = "ModifyQuantityOrdered:LinesShouldBeInSameOrder",
}
export enum OrderOrganizationUnits {
  /**
   * SoldToOrganizationUnitID cannot be the same as the SoldFromOrganizationUnitID
   */
  InvalidOrganizationUnit = "OrderOrganizationUnits:InvalidOrganizationUnit",
  /**
   * It's not possible to attach Order {orderID:number} to OrganizationUnit with ID {orgID:number} because it has Type {type:OrganizationUnitTypes}
   */
  InvalidOrganizationUnitType = "OrderOrganizationUnits:InvalidOrganizationUnitType",
  /**
   * It's not possible to attach Order {orderID:number} to OrganizationUnit with ID {orgID:number} because the ou has currency {ouCurrencyID:string} and the order has currency {orderCurrencyID:string}
   */
  InvalidCurrency = "OrderOrganizationUnits:InvalidCurrency",
  /**
   * Order {0:number} already has exported lines, can no longer change the OrganizationUnit of it.
   */
  OrderAlreadyExported = "OrderOrganizationUnits:OrderAlreadyExported",
}
export enum StockReservation {
  /**
   * Cannot reserve quantity {quantity:number} on OrderLine {orderLineID:number} because it already has {alreadyReserved:number} reserved and only {ordered:number} ordered
   */
  CannotReserveMoreThanOrdered = "StockReservation:CannotReserveMoreThanOrdered",
}
export enum DeliverShipments {
  /**
   * Shipment {0:number} is not a sales order shipment, cannot deliver it.
   */
  MustBeSalesOrder = "DeliverShipments:MustBeSalesOrder",
  /**
   * Shipment {0:number} has already been completed, no deliveries of it can be made anymore.
   */
  ShipmentAlreadyCompleted = "DeliverShipments:ShipmentAlreadyCompleted",
}
export enum ReturnToSupplierRequests {
  /**
   * The ReturnToSupplierRequest {0:number} is not New and cannot be mutated.
   */
  NotNew = "ReturnToSupplierRequests:NotNew",
  /**
   * The ReturnToSupplierRequest {0:number} is not New or Error and cannot be processed or deleted.
   */
  NotNewOrError = "ReturnToSupplierRequests:NotNewOrError",
  /**
   * A ReturnToSupplierRequest already exists with the Name {0:string}.
   */
  DuplicateName = "ReturnToSupplierRequests:DuplicateName",
}
export enum OrderOrchestration {
  /**
   * Cannot determine a fulfillment proposition for order {0:number}
   */
  CannotFulfill = "OrderOrchestration:CannotFulfill",
}
export enum OrderExportRouter {
  /**
   * Could not determine warehouse for Order {0:number}
   */
  NoWarehouseFound = "OrderExportRouter:NoWarehouseFound",
  /**
   * Could not determine warehouse as there are multiple candidates: {0:string}
   */
  MultipleWarehousesFound = "OrderExportRouter:MultipleWarehousesFound",
}
export enum ReturnToSupplierOrderExporter {
  /**
   * Endpoint did not respond with a 200 OK, body [ACK]
   */
  InvalidResponse = "ReturnToSupplierOrderExporter:InvalidResponse",
}
export enum WarehouseOrderExporter {
  /**
   * Endpoint did not respond with a 200 OK, body [ACK]
   */
  InvalidResponse = "WarehouseOrderExporter:InvalidResponse",
}
export enum OrderDocuments {
  /**
   * Output type not allowed: {0:string}
   */
  NotAllowed = "OrderDocuments:NotAllowed",
}
export enum CheckoutOptions {
  /**
   * Handler is required
   */
  MissingHandler = "CheckoutOptions:MissingHandler",
  /**
   * The given handler doesn't exist
   */
  UnknownHandler = "CheckoutOptions:UnknownHandler",
  /**
   * The data is not in a valid format for the selected type
   */
  InvalidData = "CheckoutOptions:InvalidData",
  /**
   * Updating data of this checkout option type is not supported
   */
  UpdatingNotSupported = "CheckoutOptions:UpdatingNotSupported",
}
export enum AddressBook {
  /**
   * There is already an addressbookitem with this backendid.
   */
  DuplicateBackendID = "AddressBook:DuplicateBackendID",
  /**
   * It's not possible to delete a default address when you have two or more other addresses in your address book.
   */
  CannotDeleteDefaultAddress = "AddressBook:CannotDeleteDefaultAddress",
}
export enum Orders {
  /**
   * Order {0:number} does not have the required type {1:OrderTypes}.
   */
  OrderHasInvalidType = "Orders:OrderHasInvalidType",
  /**
   * Order {0:number} does not have the required property {1:OrderProperties}.
   */
  OrderHasInvalidProperty = "Orders:OrderHasInvalidProperty",
  /**
   * The current PickupOrganizationUnit of Order {OrderID:number} is not a shop ({OrganizationUnitID:number}).
   */
  PickupOrganizationUnitMustBeShop = "Orders:PickupOrganizationUnitMustBeShop",
  /**
   * Cannot change the PickupOrganizationUnit of an Order to an OrganizationUnit that is not of type Shop.
   */
  OrganizationUnitMustBeShop = "Orders:OrganizationUnitMustBeShop",
}
export enum ReturnReasons {
  /**
   * ReturnReason {0:number} does not have the required type {1:ReturnReasonType}.
   */
  ReturnReasonHasInvalidType = "ReturnReasons:ReturnReasonHasInvalidType",
}
export enum CustomerOrderReturns {
  /**
   * Not allowed to create returns on your current organization unit.
   */
  CannotCreateReturnsOnOrganizationUnit = "CustomerOrderReturns:CannotCreateReturnsOnOrganizationUnit",
  /**
   * Not allowed to create returns from order with type other than Sales.
   */
  OriginalOrderMustBeSalesOrder = "CustomerOrderReturns:OriginalOrderMustBeSalesOrder",
  /**
   * No OrderLine found on Order {orderToReturnID:number} to ship {quantity:number} items of product/orderline {productOrderLineID:number}
   */
  NoOrderLineForReturn = "CustomerOrderReturns:NoOrderLineForReturn",
}
export enum Deactivate {
  /**
   * Cannot deactivate {taskType:string} task {taskID:number} because it has already been completed
   */
  UserTaskAlreadyCompleted = "UserTasks:Deactivate:UserTaskAlreadyCompleted",
  /**
   * Cannot deactivate {taskType:string} task {taskID:number} because it has already been deactivated.
   */
  UserTaskAlreadyDeactivated = "UserTasks:Deactivate:UserTaskAlreadyDeactivated",
}
export enum Start {
  /**
   * Cannot start {taskType:string} task {taskID:number} because it has already been completed
   */
  UserTaskAlreadyCompleted = "UserTasks:Start:UserTaskAlreadyCompleted",
  /**
   * Cannot start {taskType:string} task {taskID:number} because it is not active
   */
  UserTaskNotActive = "UserTasks:Start:UserTaskNotActive",
  /**
   * Cannot start {taskType:string} task {taskID:number} because it requires a functionality you don't currently have.
   */
  UserTaskRequiresFunctionality = "UserTasks:Start:UserTaskRequiresFunctionality",
}
export enum Print {
  /**
   * Cannot print task {taskID:number} because its type ({taskTypeID:number}) is not supported
   */
  UserTaskTypeNotSupported = "UserTasks:Print:UserTaskTypeNotSupported",
}
export enum Complete {
  /**
   * Cannot complete {taskType:string} task {taskID:number} because it has already been completed
   */
  UserTaskAlreadyCompleted = "UserTasks:Complete:UserTaskAlreadyCompleted",
  /**
   * Cannot complete {taskType:string} task {taskID:number} because it is not active
   */
  UserTaskNotActive = "UserTasks:Complete:UserTaskNotActive",
  /**
   * Cannot complete {taskType:string} task {taskID:number} because it is not attached to the current user ({currentUserID:number}) but to {attachedUserID:number}
   */
  UserNotAttachedToUserTask = "UserTasks:Complete:UserNotAttachedToUserTask",
  /**
   * Cannot complete {taskType:string} task {taskID:number} because it is not attached to any user
   */
  UserTaskNotAttached = "UserTasks:Complete:UserTaskNotAttached",
}
export enum PrintTask {
  /**
   * Cannot complete print task, it was not yet processed.
   */
  NotYetPrinted = "UserTasks:PrintTask:NotYetPrinted",
}
export enum DiscountsV2 {
  /**
   * Old discount actions and conditions are no longer supported. Please update the discount or archive it and create a new one (recommended).
   */
  OldDiscountActionsAndConditionsAreNoLongerSupported = "DiscountsV2:OldDiscountActionsAndConditionsAreNoLongerSupported",
  /**
   * The discount calculation failed with an unexpected error.
   */
  DiscountCalculationFailedWithUnexpectedError = "DiscountsV2:DiscountCalculationFailedWithUnexpectedError",
  /**
   * DiscountCondition {0:string} is not allowed for discount trigger {1:string}.
   */
  ConditionNotEnabledForTriggerType = "DiscountsV2:ConditionNotEnabledForTriggerType",
  /**
   * CouponCondition {ConditionID:number} for discount {DiscountID:number} was malformed or its build-up resulted in an exception.
   */
  InvalidDiscountCouponCondition = "DiscountsV2:InvalidDiscountCouponCondition",
  /**
   * The data for CouponCondition {ConditionID:number} on discount {DiscountID:number} is not initialized properly.
   */
  DiscountCouponConditionDataNotInitialized = "DiscountsV2:DiscountCouponConditionDataNotInitialized",
  /**
   * The DiscountAction for discount {0:number} was malformed or its build-up resulted in an exception.
   */
  InvalidDiscountAction = "DiscountsV2:InvalidDiscountAction",
  /**
   * The DiscountAction {0:string} is not allowed for discount trigger {1:string}.
   */
  ActionNotEnabledForTriggerType = "DiscountsV2:ActionNotEnabledForTriggerType",
  /**
   * Action data for discount {0:number} is not initialized properly.
   */
  ActionDataNotInitialized = "DiscountsV2:ActionDataNotInitialized",
  /**
   * Discount action type can not be updated when discount is used.
   */
  ActionTypeCanNotBeChangedWhenDiscountIsUsed = "DiscountsV2:ActionTypeCanNotBeChangedWhenDiscountIsUsed",
  /**
   * Discount cannot be applied because order {0:number} is already invoiced.
   */
  OrderIsAlreadyInvoiced = "DiscountsV2:OrderIsAlreadyInvoiced",
  /**
   * Discount cannot be applied because order {0:number} is placed and `FreezeOrderAfterPlacement` is enabled.
   */
  OrderIsPlacedAndFreezeOrderAfterPlacementIsEnabled = "DiscountsV2:OrderIsPlacedAndFreezeOrderAfterPlacementIsEnabled",
  /**
   * Discount cannot be applied because order {0:number} has IgnoreDiscounts enabled.
   */
  IgnoreDiscountsEnabled = "DiscountsV2:IgnoreDiscountsEnabled",
  /**
   * Condition '{0:string}' is deprecated.
   */
  ConditionIsDeprecated = "DiscountsV2:ConditionIsDeprecated",
  /**
   * Action '{0:string}' is deprecated.
   */
  ActionIsDeprecated = "DiscountsV2:ActionIsDeprecated",
  /**
   * Action '{ActionType:string}' requires at least {X:number} occurences of the condition '{ConditionType:string}'.
   */
  ActionRequiresTheConditionAtLeastXTimes = "DiscountsV2:ActionRequiresTheConditionAtLeastXTimes",
  /**
   * Action '{ActionType:string}' allows a maximum of {X:number} occurences of the condition '{ConditionType:string}'.
   */
  ActionAllowsTheConditionForAMaximumOfXTimes = "DiscountsV2:ActionAllowsTheConditionForAMaximumOfXTimes",
  /**
   * Action '{ActionType:string}' can not be combined with condition type '{ConditionType:string}'.
   */
  ActionDoesNotAllowConditions = "DiscountsV2:ActionDoesNotAllowConditions",
  /**
   * Action '{0:string}' can not be applied to a particular line.
   */
  ActionDoesNotAllowLineLevelDiscount = "DiscountsV2:ActionDoesNotAllowLineLevelDiscount",
  /**
   * The value of property {0:string} is missing
   */
  MissingValue = "DiscountsV2:MissingValue",
  /**
   * The value of property {0:string} is cannot be fractional.
   */
  FractionalPercentageNotSupported = "DiscountsV2:FractionalPercentageNotSupported",
  /**
   * At least one of the following values is required: {0:string}
   */
  RequiresAtLeastOneOfTheFollowingValues = "DiscountsV2:RequiresAtLeastOneOfTheFollowingValues",
  /**
   * The value of property {0:string} must be higher then 0.
   */
  ValueCannotBeNegativeOrZero = "DiscountsV2:ValueCannotBeNegativeOrZero",
  /**
   * The value of property {0:string} must be higher then or equals to 0.
   */
  ValueCannotBeNegative = "DiscountsV2:ValueCannotBeNegative",
  /**
   * The value of property {PropertyName:string} must be between {1:number} and {2:number}.
   */
  IntValueMustBeBetweenXAndY = "DiscountsV2:IntValueMustBeBetweenXAndY",
  /**
   * Value of property {PropertyNameX:string} must be equal to or larger then property {PropertyNameY:string}
   */
  ValueOfXMustBeLargerThenOrEqualToValueOfY = "DiscountsV2:ValueOfXMustBeLargerThenOrEqualToValueOfY",
  /**
   * Value of property {PropertyNameX:string} must be larger then property {PropertyNameY:string}
   */
  ValueOfXMustBeLargerThenValueOfY = "DiscountsV2:ValueOfXMustBeLargerThenValueOfY",
  /**
   * The date of property {0:string} is invalid.
   */
  InvalidDate = "DiscountsV2:InvalidDate",
  /**
   * The value of property {PropertyName:string} must be between {1:string} and {2:string}.
   */
  DateValueMustBeBetweenXAndY = "DiscountsV2:DateValueMustBeBetweenXAndY",
  /**
   * At least two tiers is required.
   */
  NoTiersGiven = "DiscountsV2:NoTiersGiven",
  /**
   * Duplicate tier with value '{0:any}' detected.
   */
  DuplicateTiersGiven = "DiscountsV2:DuplicateTiersGiven",
  /**
   * ProductSets in {0:string} can not be mixed. All ProductSets must be either including or exluding.
   */
  ProductSetsIncludingExcludingCanNotBeMixed = "DiscountsV2:ProductSetsIncludingExcludingCanNotBeMixed",
  /**
   * No product sets are provided.
   */
  NoProductSetsGiven = "DiscountsV2:NoProductSetsGiven",
  /**
   * At least one product set must be selected as the discountable product set.
   */
  NoDiscountableProductSetSelected = "DiscountsV2:NoDiscountableProductSetSelected",
  /**
   * The value of property {PropertyName:string} in tier {TierNumber:number} is missing
   */
  TierMissingValue = "DiscountsV2:TierMissingValue",
  /**
   * Tier {TierNumber:number} requires at least one of the following values: {PropertyNames:string}
   */
  TierRequiresAtLeastOneOfTheFollowingValues = "DiscountsV2:TierRequiresAtLeastOneOfTheFollowingValues",
  /**
   * The value of property {PropertyName:string} in tier {TierNumber:number} must be higher then 0.
   */
  TierValueCannotBeNegativeOrZero = "DiscountsV2:TierValueCannotBeNegativeOrZero",
  /**
   * The value of property {PropertyName:string} in tier {TierNumber:number} must be higher then or equals to 0.
   */
  TierValueCannotBeNegative = "DiscountsV2:TierValueCannotBeNegative",
  /**
   * The value of property {PropertyName:string} in tier {TierNumber:number} must be between {2:number} and {3:number}.
   */
  TierValueMustBeBetweenXAndY = "DiscountsV2:TierValueMustBeBetweenXAndY",
  /**
   * Product with ID {ID:number} in property {PropertyName:string} for tier {TierNumber:number} not found.
   */
  TierProductNotFound = "DiscountsV2:TierProductNotFound",
  /**
   * All product sets must have a name.
   */
  AllProductsSetsRequireANameToBePresent = "DiscountsV2:AllProductsSetsRequireANameToBePresent",
  /**
   * Product set {ProductSetName:string} requires at least one of the following values: {PropertyName:string}
   */
  ProductSetRequiresAtLeastOneOfTheFollowingValues = "DiscountsV2:ProductSetRequiresAtLeastOneOfTheFollowingValues",
  /**
   * The value of product set property {PropertyName:string} in product set {ProductSetName:string} must be higher then 0.
   */
  ProductSetValueCannotBeNegativeOrZero = "DiscountsV2:ProductSetValueCannotBeNegativeOrZero",
  /**
   * The value of product set property {PropertyName:string} in product set {ProductSetName:string} must be higher then or equals to 0.
   */
  ProductSetValueCannotBeNegative = "DiscountsV2:ProductSetValueCannotBeNegative",
  /**
   * The value of product set property {PropertyNameX:string} in product set {ProductSetName:string} must be higher then or equals to property {PropertyNameY:string}.
   */
  ProductSetValueOfXMustBeLargerThenOrEqualToValueOfY = "DiscountsV2:ProductSetValueOfXMustBeLargerThenOrEqualToValueOfY",
  /**
   * The product set {ProductSetName:string} requires a connected loyalty program when property {PropertyName:string} is set.
   */
  ProductSetUseLoyaltyProgramProductsRequiresALoyaltyProgram = "DiscountsV2:ProductSetUseLoyaltyProgramProductsRequiresALoyaltyProgram",
  /**
   * Product set {0:string} did not resolve to a valid list of products.
   */
  ProductSetDidNotResolveToAListOfValidProducts = "DiscountsV2:ProductSetDidNotResolveToAListOfValidProducts",
  /**
   * Product set {0:string} product requirement values must be present.
   */
  ProductSetProductRequirementValuesMustBePresent = "DiscountsV2:ProductSetProductRequirementValuesMustBePresent",
  /**
   * Product set properties ProductsIDs, ProductSearchID, Filters and PersonalizedPromotionID cannot be combined in product sets.
   */
  ProductSetPropertiesAreMutuallyExclusive = "DiscountsV2:ProductSetPropertiesAreMutuallyExclusive",
  /**
   * A productset price filter requires a valid from/to value.
   */
  ProductSetPriceFilterRequiresAValidFromOrToValue = "DiscountsV2:ProductSetPriceFilterRequiresAValidFromOrToValue",
  /**
   * The value {ID:number} of property {PropertyName:string} is an invalid {ObjectName:string} ID.
   */
  InvalidID = "DiscountsV2:InvalidID",
  /**
   * Product {0:number} is not found
   */
  ProductNotFound = "DiscountsV2:ProductNotFound",
  /**
   * Product {0:number} has an invalid type for GetAProductAction: {1:ProductTypes}.
   */
  ProductInvalidType = "DiscountsV2:ProductInvalidType",
  /**
   * The provided stocklabel {0:number} could not be found.
   */
  InvalidStockLabel = "DiscountsV2:InvalidStockLabel",
  /**
   * The orderlinetype should be ShippingCosts, ReturnCosts or GiftWrappingCosts.
   */
  InvalidLineType = "DiscountsV2:InvalidLineType",
  /**
   * The coupon handler {0:string} is unknown.
   */
  UnknownCouponHandler = "DiscountsV2:UnknownCouponHandler",
  /**
   * The discount to be applied for the generated coupon is invalid.
   */
  InvalidPostponedDiscount = "DiscountsV2:InvalidPostponedDiscount",
  /**
   * The coupon distribution handler is missing.
   */
  CouponDistributionHandlerMissing = "DiscountsV2:CouponDistributionHandlerMissing",
  /**
   * The coupon distribution handler '{0:string}' is invalid.
   */
  InvalidCouponDistributionHandler = "DiscountsV2:InvalidCouponDistributionHandler",
  /**
   * The given RestitutionOrganizationUnit is invalid. OrganizationUnit needs to exist and typed as DiscountRestitutionOrganizationUnit.
   */
  InvalidRestitutionOrganizationUnit = "DiscountsV2:InvalidRestitutionOrganizationUnit",
  /**
   * Action does not allow excluding product filters on discountable product sets
   */
  ActionDoesNotAllowExcludingProductFiltersOnDiscountableProductSets = "DiscountsV2:ActionDoesNotAllowExcludingProductFiltersOnDiscountableProductSets",
  /**
   * StringFilter {0:string} must have a value to compare.
   */
  StringFilterMustHaveAValue = "DiscountsV2:StringFilterMustHaveAValue",
  /**
   * Invalid user usage reload strategy `{0:string}`.
   */
  InvalidUserUsageReloadStrategy = "DiscountsV2:InvalidUserUsageReloadStrategy",
  /**
   * A discount of trigger type `{0:string}` cannot be combined with (custom)UserUsageLimit.
   */
  TriggertypeCannotBeCombinedWithUserUsageLimit = "DiscountsV2:TriggertypeCannotBeCombinedWithUserUsageLimit",
  /**
   * A discount of trigger type `{0:string}` cannot have other conditions.
   */
  TriggertypeDoesNotAllowForConditions = "DiscountsV2:TriggertypeDoesNotAllowForConditions",
  /**
   * Cannot apply discount to orderline {0:number}.
   */
  InvalidOrderLineForDiscount = "DiscountsV2:InvalidOrderLineForDiscount",
  /**
   * OrderLine {0:number} does not support discount product pick selection.
   */
  InvalidOrderLineForProductPicking = "DiscountsV2:InvalidOrderLineForProductPicking",
  /**
   * Invalid product for product picking.
   */
  InvalidProductForProductPicking = "DiscountsV2:InvalidProductForProductPicking",
  /**
   * Loyalty program is required when discount trigger is 'Loyalty'
   */
  LoyaltyProgramRequired = "DiscountsV2:LoyaltyProgramRequired",
  /**
   * The given loyalty program is unknown
   */
  UnknowLoyaltyProgram = "DiscountsV2:UnknowLoyaltyProgram",
  /**
   * Only EVA loyalty handler can be used
   */
  OnlyEVALoyaltyHandlerCanBeUsed = "DiscountsV2:OnlyEVALoyaltyHandlerCanBeUsed",
  /**
   * Either `Data` or `BlobID` is required for upload.
   */
  EitherDataOrBlobIDIsRequired = "DiscountsV2:EitherDataOrBlobIDIsRequired",
  /**
   * Non async processing only accepts `Data`.
   */
  NonAsyncProcessingOnlyAcceptsData = "DiscountsV2:NonAsyncProcessingOnlyAcceptsData",
  /**
   * Async processing only accepts `BlobID`.
   */
  AsyncProcessingOnlyAcceptsBlobID = "DiscountsV2:AsyncProcessingOnlyAcceptsBlobID",
  /**
   * Invalid custom field type `{0:number}`.
   */
  InvalidCustomFieldType = "DiscountsV2:InvalidCustomFieldType",
  /**
   * Required custom fields are not supported for discount trigger `{0:DiscountTriggers}`.
   */
  RequiredCustomFieldsAreNotSupportedForDiscountTrigger = "DiscountsV2:RequiredCustomFieldsAreNotSupportedForDiscountTrigger",
  /**
   * Custom field type `{0:number}` cannot be required on discount that applies to `{1:DiscountAppliesTo}`.
   */
  DiscountApplyToMismatchOnCustomFieldType = "DiscountsV2:DiscountApplyToMismatchOnCustomFieldType",
}
export enum NamedInstanceFactory {
  /**
   * Handler {0:string} not found.
   */
  HandlerNotFound = "NamedInstanceFactory:HandlerNotFound",
}
export enum TaskScheduler {
  /**
   * Task ID cannot contain '$'
   */
  InvalidCharacters = "TaskScheduler:InvalidCharacters",
  /**
   * Invalid cron expression: {0:string}
   */
  InvalidCronExpression = "TaskScheduler:InvalidCronExpression",
}
export enum Sorting {
  /**
   * Sortingproperty {SortingProperty:string} does not exist in object {ObjectName:string}
   */
  SortingPropertyDoesNotExistInObject = "Sorting:SortingPropertyDoesNotExistInObject",
}
export enum CreateApiKey {
  /**
   * Incorrect user type - must be of type `API`.
   */
  IncorrectUserType = "CreateApiKey:IncorrectUserType",
  /**
   * The ExpirationDate should be in the future.
   */
  InvalidExpirationDate = "CreateApiKey:InvalidExpirationDate",
  /**
   * A PermanentApiKey can't have an ExpirationDate.
   */
  PermanentKeyCantHaveExpirationDate = "CreateApiKey:PermanentKeyCantHaveExpirationDate",
}
export enum PhoneBook {
  /**
   * It's not possible to delete a primary phone number unless it's one of two or the only one.
   */
  CannotDeletePrimaryPhoneNumber = "PhoneBook:CannotDeletePrimaryPhoneNumber",
}
export enum UserAssociation {
  /**
   * Primary and related user IDs should be different.
   */
  SameIDsError = "UserAssociation:SameIDsError",
  /**
   * User association type was deleted, please use another one.
   */
  DeletedAssociationTypeError = "UserAssociation:DeletedAssociationTypeError",
}
export enum Wishlist {
  /**
   * Name must be unique. '{0:string}' already exists.
   */
  NameMustBeUnique = "Wishlist:NameMustBeUnique",
}
export enum ValidateUserEmailAddress {
  /**
   * Email address already validated.
   */
  EmailAddressAlreadyValidated = "ValidateUserEmailAddress:EmailAddressAlreadyValidated",
  /**
   * Validation code incorrect.
   */
  InvalidValidationCode = "ValidateUserEmailAddress:InvalidValidationCode",
}
export enum UserInteractions {
  /**
   * A UserInteraction must be attached to a user. Either provide a UserID in the request or an OrderID that has a CustomerID.
   */
  UserRequired = "UserInteractions:UserRequired",
}
export enum CreateOrganizationUnitSupplier {
  /**
   * When creating a supplier relationship either SupplierOrganizationUnitID, SupplierOrganizationUnitSetID or SupplierOrganizationUnitIDs must be specified.
   */
  SupplierIsRequired = "CreateOrganizationUnitSupplier:SupplierIsRequired",
}
export enum DeleteOrganizationUnitSupplier {
  /**
   * Please delete the supplier stock allocation rules before deleting the supplier.
   */
  HasStockAllocationRules = "DeleteOrganizationUnitSupplier:HasStockAllocationRules",
}
export enum AdjustStockService {
  /**
   * No adjustments defined
   */
  NoAdjustmentsDefined = "AdjustStockService:NoAdjustmentsDefined",
  /**
   * ProductID or ProductBackendID required
   */
  ProductIDOrProductBackendIDRequired = "AdjustStockService:ProductIDOrProductBackendIDRequired",
}
export enum CommitOrderLines {
  /**
   * It's not possible to choose a commitment status other than ManualCommit or ForcedCommit.
   */
  CannotChooseCommitmentStatus = "CommitOrderLines:CannotChooseCommitmentStatus",
}
export enum StockLabelSettings {
  /**
   * Cannot change the default settings for a stocklabel.
   */
  CannotChangeDefaultSettings = "StockLabelSettings:CannotChangeDefaultSettings",
}
export enum MoveStock {
  /**
   * No movements defined.
   */
  NoMovementsDefined = "MoveStock:NoMovementsDefined",
  /**
   * Parameter ProductID or ProductBackendID required.
   */
  ProductRequired = "MoveStock:ProductRequired",
  /**
   * Mutation {0} ({1}) already exists.
   */
  AlreadyExists = "MoveStock:AlreadyExists",
}
export enum ProcessStockMutationFile {
  /**
   * You are not allowed to update the stock of the specified OrganizationUnit.
   */
  UnauthorizedForStockMutation = "ProcessStockMutationFile:UnauthorizedForStockMutation",
  /**
   * StockmutationReason 'FullStockCount' can only be used by the FullStockCount process.
   */
  StockmutationReasonFSCCanOnlyBeUsedByFSCProcress = "ProcessStockMutationFile:StockmutationReasonFSCCanOnlyBeUsedByFSCProcress",
}
export enum CreateShipment {
  /**
   * An Order identifier is required, either `OrderID`, `OrderFulfillmentID` or `OrderBackendID` and `OrderBackendSystemID` must be provided on the top-level request or in the `Lines` or `Packages`
   */
  MissingOrderReference = "CreateShipment:MissingOrderReference",
  /**
   * Cannot have duplicate identifiers
   */
  NoDuplicateIdentifiersAllowed = "CreateShipment:NoDuplicateIdentifiersAllowed",
}
export enum UndoShipment {
  /**
   * Shipment line {ShipmentLineID:number} on shipment {ShipmentID:number} has already been received
   */
  ShipmentLineAlreadyReceived = "UndoShipment:ShipmentLineAlreadyReceived",
  /**
   * Shipment {0:number} has not yet been received and cannot be undone.
   */
  ShipmentNotReceived = "UndoShipment:ShipmentNotReceived",
}
export enum UpdateShipment {
  /**
   * RequireReceiveMethodReason is required
   */
  ReceiveMethodReasonNeedsToBeProvided = "UpdateShipment:ReceiveMethodReasonNeedsToBeProvided",
  /**
   * Cannot have duplicate identifiers
   */
  NoDuplicateIdentifiersAllowed = "UpdateShipment:NoDuplicateIdentifiersAllowed",
}
export enum Scripts {
  /**
   * The Script is still being used, cannot delete.
   */
  ScriptInUse = "Scripts:ScriptInUse",
}
export enum CreatePurchaseOrder {
  /**
   * A SupplierID is required when Unified Orders are enabled.
   */
  SupplierRequiredForUnifiedOrders = "CreatePurchaseOrder:SupplierRequiredForUnifiedOrders",
}
export enum SupplierPurchaseOrder {
  /**
   * Inconsistent use of product identifiers.
   */
  InconsistentProductIdentifiers = "SupplierPurchaseOrder:InconsistentProductIdentifiers",
}
export enum PrintProductPriceLabel {
  /**
   * No barcode found for product with ID {0:number}
   */
  ProductBarcodeNotFound = "PrintProductPriceLabel:ProductBarcodeNotFound",
}
export enum RenderThermalPrintResult {
  /**
   * Cannot use Blob with MimeType {0:string}.
   */
  InvalidMimeType = "RenderThermalPrintResult:InvalidMimeType",
}
export enum ExplainProductPrices {
  /**
   * PriceList is deactivated.
   */
  PriceListIsDeactivated = "ExplainProductPrices:PriceListIsDeactivated",
  /**
   * Must provide between 1 and 100 product ID's.
   */
  RequestedProductIDsInvalid = "ExplainProductPrices:RequestedProductIDsInvalid",
}
export enum PushPriceList {
  /**
   * When creating a new price list, you must specify a currency ID.
   */
  MissingCurrencyID = "PushPriceList:MissingCurrencyID",
  /**
   * Cannot create a markup component that does not have its associated MarkupData
   */
  MissingMarkupData = "PushPriceList:MissingMarkupData",
  /**
   * Cannot create a CopyPricesFromOtherPriceList component that does not have its associated CopyPricesFromOtherPriceListData
   */
  MissingCopyPricesFromOtherPriceListData = "PushPriceList:MissingCopyPricesFromOtherPriceListData",
}
export enum Pricing {
  /**
   * This price list is not valid for simple updates.
   */
  NotValidForSimpleUpdates = "Pricing:NotValidForSimpleUpdates",
  /**
   * Duplicate prices for products are not supported in simple price lists.
   */
  DuplicatePricesForProduct = "Pricing:DuplicatePricesForProduct",
  /**
   * Expiration dates are not supported for simple price lists.
   */
  ExpirationDatesNotSupported = "Pricing:ExpirationDatesNotSupported",
  /**
   * Prices must have either `ProductID`, `CustomID` or `BackendID` and cannot be mixed!
   */
  InvalidProductIdentifiers = "Pricing:InvalidProductIdentifiers",
  /**
   * One or more products could not be found: {0:string}
   */
  ProductsNotFound = "Pricing:ProductsNotFound",
}
export enum PaymentTransactionLedger {
  /**
   * This PaymentTransactionLedgerType is internal and can't be used to create ledgers
   */
  IsInternal = "PaymentTransactionLedger:IsInternal",
}
export enum OrganizationUnitHierarchy {
  /**
   * If a Functionality Scope is passed, a Functionality must also be passed
   */
  NeedFunctionalityWithScope = "OrganizationUnitHierarchy:NeedFunctionalityWithScope",
}
export enum ImportOrganizationUnits {
  /**
   * ParentID {0:string} does not exist.
   */
  ParentNotFound = "ImportOrganizationUnits:ParentNotFound",
  /**
   * ReturnOrganizationUnitID {0:string} does not exist.
   */
  ReturnOrganizationUnitNotFound = "ImportOrganizationUnits:ReturnOrganizationUnitNotFound",
  /**
   * There is an infinite loop reference starting with {0:string} -> {1:string}.
   */
  InfiniteLoop = "ImportOrganizationUnits:InfiniteLoop",
}
export enum AddServiceProductToOrder {
  /**
   * Product {0:string} is not a service product.
   */
  ProductRequiresServiceType = "AddServiceProductToOrder:ProductRequiresServiceType",
}
export enum CreateOrderAppToken {
  /**
   * Order already has a customer attached.
   */
  OrderHasCustomerAttached = "CreateOrderAppToken:OrderHasCustomerAttached",
}
export enum CreateOrderLedger {
  /**
   * OrderLine is either not found or doesn't belong to the Order
   */
  OrderLineNotFound = "CreateOrderLedger:OrderLineNotFound",
}
export enum ModifyLineActionType {
  /**
   * Either OrderID or OrderLineID is required.
   */
  OrderIDOrOrderLineIDRequired = "ModifyLineActionType:OrderIDOrOrderLineIDRequired",
}
export enum OrderCustomStatus {
  /**
   * This name already exists!
   */
  AlreadyExists = "OrderCustomStatus:AlreadyExists",
  /**
   * This Backend ID is already used!
   */
  AlreadyUsedBackendID = "OrderCustomStatus:AlreadyUsedBackendID",
}
export enum OrderCustomType {
  /**
   * This name already exists!
   */
  AlreadyExists = "OrderCustomType:AlreadyExists",
  /**
   * This Backend ID is already used!
   */
  AlreadyUsedBackendID = "OrderCustomType:AlreadyUsedBackendID",
}
export enum OrderLink {
  /**
   * Primary and related order IDs should be different.
   */
  SameIDsError = "OrderLink:SameIDsError",
  /**
   * Order link type was deleted, please use another one.
   */
  DeletedLinkTypeError = "OrderLink:DeletedLinkTypeError",
}
export enum PushSalesOrders {
  /**
   * The open amount on the order {backendID:string} is supposed to be zero, but it was {openAmount:number}
   */
  OpenAmountNotZero = "PushSalesOrders:OpenAmountNotZero",
}
export enum SetOrderBackendID {
  /**
   * Order already has a BackendID/BackendSystemID set.
   */
  OrderAlreadyHasBackendIdentifier = "SetOrderBackendID:OrderAlreadyHasBackendIdentifier",
  /**
   * This BackendID/BackendSystemID already exists.
   */
  BackendIdentifierAlreadyExists = "SetOrderBackendID:BackendIdentifierAlreadyExists",
  /**
   * Cannot use reserved BackendSystemID {0:string}.
   */
  CannotUseBackendSystemID = "SetOrderBackendID:CannotUseBackendSystemID",
}
export enum SoldBy {
  /**
   * The SoldByID {0:number} is not an Employee-type user.
   */
  SoldByMustBeEmployee = "SoldBy:SoldByMustBeEmployee",
  /**
   * The SoldBy can no longer be updated on Order {0:number} as it has already been invoiced.
   */
  CanNoLongerUpdateSoldBy = "SoldBy:CanNoLongerUpdateSoldBy",
  /**
   * The SoldBy cannot be updated on Order {0:number} because it is not a Sales Order.
   */
  OrderMustBeSalesOrder = "SoldBy:OrderMustBeSalesOrder",
}
export enum ShipExternalOrderService {
  /**
   * Cannot perform shipment on {0:string}, no lines could be shipped.
   */
  CannotPerformShipment = "ShipExternalOrderService:CannotPerformShipment",
}
export enum ShipOrder {
  /**
   * Nothing shipped: {0}
   */
  NothingShipped = "ShipOrder:NothingShipped",
}
export enum UpdateOrderCustomFields {
  /**
   * Either CustomFields or CustomFieldsByBackendID needs to be specified
   */
  MissingCustomFields = "UpdateOrderCustomFields:MissingCustomFields",
}
export enum UpdateOrderLineStockLabel {
  /**
   * Either OrderID or OrderLineID is required.
   */
  OrderIDOrOrderLineIDRequired = "UpdateOrderLineStockLabel:OrderIDOrOrderLineIDRequired",
}
export enum CreateReturnToSupplierOrder {
  /**
   * Cannot create a ReturnToSupplier order from an Interbranch order
   */
  CannotCreateRTSFromInterbranch = "CreateReturnToSupplierOrder:CannotCreateRTSFromInterbranch",
}
export enum UpdateReturnToSupplierOrder {
  /**
   * Order is not a return to supplier order
   */
  NotAnRTSOrder = "UpdateReturnToSupplierOrder:NotAnRTSOrder",
  /**
   * Cannot update placed order
   */
  CannotUpdatePlacedOrder = "UpdateReturnToSupplierOrder:CannotUpdatePlacedOrder",
}
export enum AutoTransferInterbranchOrder {
  /**
   * One of the organization units must be the current organization unit.
   */
  OneOfOrganizationUnitsMustBeCurrent = "AutoTransferInterbranchOrder:OneOfOrganizationUnitsMustBeCurrent",
  /**
   * Cannot transfer between these organization units.
   */
  CannotTransferBetweenOrganizationUnits = "AutoTransferInterbranchOrder:CannotTransferBetweenOrganizationUnits",
  /**
   * Order is not an interbranch order.
   */
  OrderNotInterbranch = "AutoTransferInterbranchOrder:OrderNotInterbranch",
  /**
   * Order cannot be placed: {0:string}.
   */
  OrderCannotBePlaced = "AutoTransferInterbranchOrder:OrderCannotBePlaced",
  /**
   * Order must be unified to be shipped.
   */
  OrderMustBeUnified = "AutoTransferInterbranchOrder:OrderMustBeUnified",
}
export enum OrderFulfillment {
  /**
   * It was not possible to split OrderLine {0:number} to complete the partial cancellation of the OrderLine's fulfillment
   */
  SplitOrderLineFailed = "OrderFulfillment:SplitOrderLineFailed",
  /**
   * It's not currently allowed to partially cancel an OrderLine fulfillment, this is controlled by the setting `OrderFulfillment:AllowPartialCancellation`.
   */
  PartialCancellationNotAllowed = "OrderFulfillment:PartialCancellationNotAllowed",
}
export enum SetOrderFulfillmentOptions {
  /**
   * The selected fulfillment option is not valid.
   */
  InvalidOptionSelected = "SetOrderFulfillmentOptions:InvalidOptionSelected",
}
export enum ShipOrderFulfillment {
  /**
   * It's only possible to ship OrderFulfillmentLines of type Physical.
   */
  CannotShipNonPhysicalLines = "ShipOrderFulfillment:CannotShipNonPhysicalLines",
  /**
   * The OrderLine {OrderLineID:number} behind OrderFulfillmentLineID {OrderFulfillmentLineID:number} has already been shipped or otherwise completed.
   */
  AlreadyShipped = "ShipOrderFulfillment:AlreadyShipped",
}
export enum UpdateOrderFulfillmentLine {
  /**
   * It's not possible to increase the quantity of an OrderFulfillmentLine
   */
  CannotIncreaseQuantity = "UpdateOrderFulfillmentLine:CannotIncreaseQuantity",
  /**
   * It's only possible to change the quantity of a physical fulfillment line.
   */
  PhysicalLineRequired = "UpdateOrderFulfillmentLine:PhysicalLineRequired",
}
export enum RequeueErrorMessage {
  /**
   * Can only requeue a message that is in status New or FailureAfterRetry.
   */
  CannotRetrySuccessfulMessage = "RequeueErrorMessage:CannotRetrySuccessfulMessage",
}
export enum CreateInvoice {
  /**
   * When creating an invoice it's required to specify CreditorOrganizationUnitID.
   */
  CreditorRequired = "CreateInvoice:CreditorRequired",
}
export enum InvoiceAdditionalAmountType {
  /**
   * InvoiceAdditionalAmountType with name `{0:string}` already exists
   */
  AlreadyExists = "InvoiceAdditionalAmountType:AlreadyExists",
}
export enum InvoiceDispute {
  /**
   * InvoiceDisputeReason with name `{0:string}` already exists
   */
  AlreadyExists = "InvoiceDispute:AlreadyExists",
  /**
   * InvoiceDisputeReasons with AutoResolve require a LedgerClass
   */
  LedgerClassRequired = "InvoiceDispute:LedgerClassRequired",
}
export enum ListManualInvoices {
  /**
   * Use at least one filter.
   */
  InvalidRequestMessage = "ListManualInvoices:InvalidRequestMessage",
}
export enum ListShipmentsToInvoice {
  /**
   * Either ShipFromOrganizationUnitID or ShipToOrganizationUnitID must be specified.
   */
  OrganizationUnitFilterRequired = "ListShipmentsToInvoice:OrganizationUnitFilterRequired",
}
export enum UpdateInvoice {
  /**
   * When creating an invoice it's required to specify CreditorOrganizationUnitID.
   */
  CreditorRequired = "UpdateInvoice:CreditorRequired",
}
export enum ExternalFullStockCountService {
  /**
   * A product identifier is missing for line {0:number}.
   */
  MissingProductIdentifierForLine = "ExternalFullStockCountService:MissingProductIdentifierForLine",
  /**
   * The product identifier for line {0:number} did not resolve to a valid product.
   */
  InvalidProductIdentifierForLine = "ExternalFullStockCountService:InvalidProductIdentifierForLine",
  /**
   * The StockLabelID for line {0:number} is invalid.
   */
  InvalidStockLabelIDForLine = "ExternalFullStockCountService:InvalidStockLabelIDForLine",
}
export enum CashDeposit {
  /**
   * SignatureBlobID is required
   */
  SignatureRequired = "CashDeposit:SignatureRequired",
}
export enum CashExpense {
  /**
   * SignatureBlobID is required
   */
  SignatureRequired = "CashExpense:SignatureRequired",
}
export enum DeleteCashExpense {
  /**
   * Cannot delete cash expense because the cash journal has already been closed.
   */
  CashJournalClosed = "DeleteCashExpense:CashJournalClosed",
  /**
   * Cannot delete cash expense because the financial period has already been closed.
   */
  FinancialPeriodClosed = "DeleteCashExpense:FinancialPeriodClosed",
}
export enum SetEmployeesForFinancialPeriod {
  /**
   * Cannot set commission for some employees and not for others. Either set commission for all employees or none.
   */
  CannotSetPartialCommission = "SetEmployeesForFinancialPeriod:CannotSetPartialCommission",
  /**
   * The financial period is not pending, so employees cannot be changed.
   */
  FinancialPeriodNotPendingEmployees = "SetEmployeesForFinancialPeriod:FinancialPeriodNotPendingEmployees",
  /**
   * The commission shares must add up to 100%.
   */
  CommissionMustAddUp = "SetEmployeesForFinancialPeriod:CommissionMustAddUp",
}
export enum SetLoyaltyProgramForFinancialPeriod {
  /**
   * The financial period is not pending, so the loyalty program cannot be changed.
   */
  FinancialPeriodNotPendingLoyaltyProgram = "SetLoyaltyProgramForFinancialPeriod:FinancialPeriodNotPendingLoyaltyProgram",
}
export enum CashCorrectionReasons {
  /**
   * A correction reason with this name already exists
   */
  CashCorrectionReasonWithNameAlreadyExists = "CashCorrectionReasons:CashCorrectionReasonWithNameAlreadyExists",
  /**
   * A correction reason with this backendID already exists
   */
  CashCorrectionReasonWithBackendIDAlreadyExists = "CashCorrectionReasons:CashCorrectionReasonWithBackendIDAlreadyExists",
}
export enum ReplayEvents {
  /**
   * This event export configuration does not support replaying events
   */
  CannotReplayEvents = "ReplayEvents:CannotReplayEvents",
  /**
   * This operation is not supported on a replay-configuration
   */
  NotSupportedOnReplay = "ReplayEvents:NotSupportedOnReplay",
}
export enum Currency {
  /**
   * Currencies are not mutable
   */
  Immutable = "Currency:Immutable",
}
export enum ValidateToken {
  /**
   * Cloud is currently not available
   */
  CloudUnavailable = "ValidateToken:CloudUnavailable",
}
export enum AddBoardingPassToOrder {
  /**
   * This boarding pass barcode does not match the expected format.
   */
  FormatInvalid = "AddBoardingPassToOrder:FormatInvalid",
  /**
   * This boarding pass is not valid for this airport.
   */
  InvalidFlight = "AddBoardingPassToOrder:InvalidFlight",
}
export enum ApplyScriptEdits {
  /**
   * Invalid value for field {Field:string}, expected a string, number, boolean or null but got {ActualValue:string}
   */
  InvalidValue = "ApplyScriptEdits:InvalidValue",
}
export enum CustomerReturn {
  /**
   * `OriginalOrderTimeStamp` is not allowed in the future
   */
  FutureTimeStampNotAllowed = "CustomerReturn:FutureTimeStampNotAllowed",
}
export enum CreateInStoreReturn {
  /**
   * This order cannot be returned through this service.
   */
  CannotBeReturned = "CreateInStoreReturn:CannotBeReturned",
  /**
   * This order cannot be refunded.
   */
  CannotBeRefunded = "CreateInStoreReturn:CannotBeRefunded",
}
export enum PrintSecondChanceProductPriceLabelService {
  /**
   * No barcode found for product with ID {0:number}
   */
  ProductBarcodeNotFound = "PrintSecondChanceProductPriceLabelService:ProductBarcodeNotFound",
}
export enum Repairs {
  /**
   * Customer must be attached to case for deferred shipment repair.
   */
  DeferredShippingMustHaveAttachedCustomer = "Repairs:DeferredShippingMustHaveAttachedCustomer",
  /**
   * Customer must have an email address.
   */
  DeferredShippingCustomerMustHaveEmailAddress = "Repairs:DeferredShippingCustomerMustHaveEmailAddress",
  /**
   * Either a ProductID or an OrderLineID for a product is required.
   */
  EitherOrderLineIDOrProductIDIsRequired = "Repairs:EitherOrderLineIDOrProductIDIsRequired",
  /**
   * EVAPay not loaded
   */
  EVAPayNotLoaded = "Repairs:EVAPayNotLoaded",
  /**
   * EVAPay not configured
   */
  EVAPayNotConfigured = "Repairs:EVAPayNotConfigured",
  /**
   * Cannot use EVAPay because order has no customer attached
   */
  EVAPayNoCustomer = "Repairs:EVAPayNoCustomer",
  /**
   * UserTaskID or RepairID must be specified when printing repair thermal receipt
   */
  UserTaskOrRepairIDRequiredWhenPrintingReceipt = "Repairs:UserTaskOrRepairIDRequiredWhenPrintingReceipt",
}
export enum StockMovementFollowUp {
  /**
   * Invalid quantity
   */
  InvalidQuantity = "StockMovementFollowUp:InvalidQuantity",
}
export enum ShipFromStoreUserTaskComposer {
  /**
   * Station is required for this step.
   */
  StationRequired = "ShipFromStoreUserTaskComposer:StationRequired",
  /**
   * Order is not paid and thus cannot be delivered.
   */
  OrderNotPaid = "ShipFromStoreUserTaskComposer:OrderNotPaid",
  /**
   * Picked quantity is too much.
   */
  ExcessPickedQuantity = "ShipFromStoreUserTaskComposer:ExcessPickedQuantity",
  /**
   * Product {replacementProductID:number} is not a replacement product for {originalProductID:number}.
   */
  NotAReplacementProduct = "ShipFromStoreUserTaskComposer:NotAReplacementProduct",
}
export enum ReturnToSupplierTaskErrors {
  /**
   * You can only have one active return to supplier task.
   */
  UserCanOnlyHaveOneActiveReturnToSupplierTask = "ReturnToSupplierTaskErrors:UserCanOnlyHaveOneActiveReturnToSupplierTask",
  /**
   * Can not exclude more products for shipping then there are available on the shipment.
   */
  CanNotExcludeMoreProductThenPresentOnTheShipment = "ReturnToSupplierTaskErrors:CanNotExcludeMoreProductThenPresentOnTheShipment",
  /**
   * Can not ship more products then present on the order.
   */
  CanNotShipMoreProductsThenPresentOnTheOrder = "ReturnToSupplierTaskErrors:CanNotShipMoreProductsThenPresentOnTheOrder",
  /**
   * Task no longer has a linked shipment. You can complete this task.
   */
  NoLinkidShipmentOnTask = "ReturnToSupplierTaskErrors:NoLinkidShipmentOnTask",
  /**
   * Can not finalize return to supplier order when other tasks are active.
   */
  CanNotFinalizeReturnToSupplierOrderWithOpenTasks = "ReturnToSupplierTaskErrors:CanNotFinalizeReturnToSupplierOrderWithOpenTasks",
  /**
   * Selected return to supplier task is already started.
   */
  ReturnToSupplierTaskAlreadyStarted = "ReturnToSupplierTaskErrors:ReturnToSupplierTaskAlreadyStarted",
}
export enum SetLineForReturnToSupplierShipment {
  /**
   * Cannot provide more than one serial number
   */
  CannotProvideMoreThanOneSerialNumber = "SetLineForReturnToSupplierShipment:CannotProvideMoreThanOneSerialNumber",
}
export enum PriceLabelPrinter {
  /**
   * Product not found
   */
  ProductNotFound = "PriceLabelPrinter:ProductNotFound",
}
export enum CreateCustomUserTaskType {
  /**
   * Can't create CustomUserTaskType because its name already exists
   */
  CustomUserTaskTypeAlreadyExists = "CreateCustomUserTaskType:CustomUserTaskTypeAlreadyExists",
}
export enum DeactivateAllUserTasks {
  /**
   * Could not deactivate all user tasks for this environment.
   */
  InvalidEnvironment = "DeactivateAllUserTasks:InvalidEnvironment",
}
export enum UserTaskNotCompletedReasonErrors {
  /**
   * Can't create UserTaskNotCompletedReason because its name already exists
   */
  UserTaskNotCompletedReasonAlreadyExists = "UserTaskNotCompletedReasonErrors:UserTaskNotCompletedReasonAlreadyExists",
  /**
   * Cannot update a system UserTaskNotCompletedReason
   */
  CannotUpdateSystemUserTaskNotCompletedReason = "UserTaskNotCompletedReasonErrors:CannotUpdateSystemUserTaskNotCompletedReason",
}
export enum UserTaskPriorityServices {
  /**
   * This User Task Priority already exists
   */
  UserTaskPriorityAlreadyExists = "UserTaskPriorityServices:UserTaskPriorityAlreadyExists",
  /**
   * Cannot modify the Default User Task Priority
   */
  CannotModifyDefaultPriority = "UserTaskPriorityServices:CannotModifyDefaultPriority",
}
export enum CreateZonedCycleCountService {
  /**
   * Cannot create cycle count for product {productID:number} in organization unit {ouID:number} because {reason:string}
   */
  CannotCreateCount = "CreateZonedCycleCountService:CannotCreateCount",
}
export enum CycleCountZoneGroup {
  /**
   * This name is already in use
   */
  DuplicateName = "CycleCountZoneGroup:DuplicateName",
}
export enum ZonedCycleCountSchedule {
  /**
   * Date needs to be at least one day in the future.
   */
  DateTooSoon = "ZonedCycleCountSchedule:DateTooSoon",
  /**
   * Require at least a product query or filter to be set.
   */
  NoProductFilters = "ZonedCycleCountSchedule:NoProductFilters",
  /**
   * Require at least a Date or CycleCountIntervalInDays set.
   */
  NoDateOrInterval = "ZonedCycleCountSchedule:NoDateOrInterval",
}
export enum SecondChangeProductRegistration {
  /**
   * Product does not match expected product for the given usertask.
   */
  ProductMismatch = "SecondChangeProductRegistration:ProductMismatch",
  /**
   * MimeType {MimeType:string} for BlobID {BlobID:string} is an invalid MimeType for an image
   */
  InvalidMimeTypeForImage = "SecondChangeProductRegistration:InvalidMimeTypeForImage",
}
export enum GiftCardConfigurationUsageTypes {
  None = 0,
  Sale = 1,
  Payment = 2,
}
export enum EnvironmentInitializationState {
  Created = 0,
  Initialized = 1,
}
export enum FunctionalityScope {
  None = 0,
  Create = 1,
  Edit = 2,
  Delete = 4,
  View = 8,
  Manage = 31,
  Settings = 32,
  Scripting = 64,
  All = 127,
}
export enum SortDirection {
  Ascending = 0,
  Descending = 1,
}
export enum AuditingFieldDataType {
  String = 0,
  Bool = 1,
  Integer = 2,
  Decimal = 3,
}
export enum AuditingFiscalIDValidationResult {
  Unknown = 0,
  Success = 1,
  Failure = -1,
}
export enum FinancialEventTypes {
  Sales = 10,
  SalesTax = 11,
  SalesDiscounts = 12,
  Purchase = 20,
  PurchaseTax = 21,
  PurchasePriceVariance = 22,
  PurchaseInvoiceDispute = 23,
  PurchaseInvoiceDisputeResolved = 24,
  PurchaseDiscounts = 25,
  CostOfGoods = 30,
  Payment = 40,
  PaymentEndRounding = 41,
  PaymentSettlement = 42,
  PaymentCapture = 43,
  PaymentRelease = 44,
  CashAdjustment = 50,
  StockMutation = 60,
  StockMutationAutomaticCorrection = 61,
  StockSold = 62,
  StockReceived = 63,
  Shipment = 70,
}
export enum UserTypes {
  None = 0,
  Employee = 1,
  Customer = 2,
  Anonymous = 4,
  Business = 8,
  System = 17,
  Debtor = 64,
  Tester = 512,
  RemovedByRequest = 1024,
  Api = 2048,
  Impersonated = 4096,
}
export enum AuditChainTypes {
  EventLedger = 0,
  InvoiceTotals = 1,
  FinancialPeriodTotals = 2,
  MonthlyFinancialReportingPeriodTotals = 3,
  YearlyFinancialReportingPeriodTotals = 4,
  InvoicePrint = 5,
  Invoice = 6,
  FinancialPeriodAudit = 7,
  InvoiceReprint = 8,
  TerminalTotals = 9,
  FinancialPeriod = 10,
  WorkingDocument = 11,
  TaxFreeForm = 12,
}
export enum TaxRateCategory {
  High = 0,
  Low = 1,
  Zero = 2,
  Exempt = 3,
  Intermediate = 4,
  Dynamic = 5,
}
export enum ReturnReasonType {
  OrganizationUnit = 0,
  Customer = 1,
}
export enum OrderBlobTypes {
  None = 0,
  Invoice = 1,
  InvoiceThermal = 2,
  ReturnLabel = 3,
  ReturnForm = 4,
  ShipmentLabel = 5,
  Identification = 6,
  PackingSlip = 7,
  PickSlip = 8,
  ThermalPrint = 9,
  PinReceipt = 10,
  Export = 11,
  ElectronicReceipt = 12,
  TaxFreeForm = 13,
  Offline = 14,
}
export enum InvoiceLineTypes {
  Default = 0,
  Discount = 1,
  Return = 2,
}
export enum InvoiceStatuses {
  Open = 0,
  Closed = 1,
}
export enum InvoiceTypes {
  Sales = 0,
  Purchase = 1,
  ControlDocument = 2,
  InitializationDocument = 3,
  ClosingDocument = 4,
  MonthlyControlDocument = 5,
  YearlyControlDocument = 6,
  IntegrityCheckDocument = 7,
  Offer = 8,
}
export enum InvoiceDocumentLedgerTypes {
  Physical = 0,
  Digital = 1,
}
export enum PaperFormats {
  A3 = 1,
  A4 = 2,
  A5 = 3,
  Legal = 4,
  Letter = 5,
  Tabloid = 6,
  Auto = 7,
  Ledger = 8,
  A0 = 9,
  A1 = 10,
  A2 = 11,
  A6 = 12,
}
export enum PaperOrientations {
  Portrait = 1,
  Landscape = 2,
}
export enum ThermalPrinterTemplateTypes {
  Xml = 0,
  Html = 1,
}
export enum InvoiceDocumentLedgerFormats {
  Receipt = 0,
  Invoice = 1,
}
export enum InvoiceCalculationMethod {
  InTax = 0,
  ExTax = 1,
  InTaxNew = 2,
  ExTaxNew = 3,
}
export enum TaskState {
  Scheduled = 1,
  Running = 2,
  Completed = 3,
}
export enum OrganizationUnitStatus {
  Default = 0,
  Open = 1,
  Closed = 2,
  OpeningSoon = 4,
  TemporarilyClosed = 8,
  Sale = 16,
  Hidden = 32,
}
export enum BarcodeFormat {
  QR = 0,
  EAN = 1,
  Code39 = 2,
  Code128 = 3,
}
export enum CreateApiUserResults {
  CreatedApiUser = 0,
  NicknameInUse = 1,
}
export enum UserAccountType {
  Standard = 0,
  Basic = 1,
  Incognito = 2,
}
export enum OrganizationUnitTypes {
  None = 0,
  /**
   * A shop represents a physical store where products can be sold.
   */
  Shop = 1,
  /**
   * A WebShop represents an online channel that allows delivery and reservation orders, but no carry out sales.
   */
  WebShop = 2,
  /**
   * A container is an OrganizationUnit purely used to group some other OrganizationUnits, to allow easier configuration.
   */
  Container = 4,
  /**
   * Pickup can be combined with type Shop to allow reservation orders in the store.
   */
  Pickup = 8,
  /**
   * A warehouse represents an OrganizationUnit where delivery orders can be shipped. The stock of these organizationunits can be made available for delivery orders from (web)shops.
   */
  Warehouse = 16,
  /**
   * A Country is a special case of the Container type that represents a Country division in the OrganizationUnits structure.
   */
  Country = 36,
  /**
   * A shop can be flagged as franchiser to allow some special flows.
   */
  Franchise = 64,
  /**
   * The type EVA indicates that the shop is running EVA in the store. This will trigger Tasks etc that will not be generated for Shops that are not (yet) converted to running EVA. P/a non-EVA stores will receive an email for pickupordrs instead of a StockReservationTask.
   */
  EVA = 128,
  /**
   * TestOrganizationUnit can be used to test some things in a production environment. This is not advised :warning:. These stores will be excluded from a lot of processes.
   */
  TestOrganizationUnit = 256,
  /**
   * OrganizationUnits with DisableLogin cannot be selected in the Login process.
   */
  DisableLogin = 512,
  /**
   * An external supplier is an organization that is not part of your internal organization structure but that you would still like to have available in EVA to for example create purchase Orders for to replenish your warehouse or stores.
   */
  ExternalSupplier = 1024,
  /**
   * Some suppliers deliver their stock in consignment.
   */
  Consignment = 3072,
  /**
   * For Business-to-business orders this type can be set. Orders in these organizationunits will be ex-tax.
   */
  B2b = 4096,
  /**
   * A Region is a special case of the Container type that represents a subdivision under Country OrganizationUnits.
   */
  Region = 8196,
  /**
   * An OrganizationUnit that is meant to be used by customers for returning Orders.
   */
  ReturnsPortal = 16384,
  /**
   * An OrganizationUnit that is meant to be invoiced for restitutions.
   */
  Restitution = 32768,
  /**
   * An OrganizationUnit that also does repairs.
   */
  RepairCenter = 65536,
  /**
   * An OrganizationUnit that is mobile. This indicates that it might have a different address for every financial period.
   */
  Mobile = 131072,
  /**
   * An OrganizationUnit representing a Store-In-Store.
   */
  ConcessionStore = 262144,
}
export enum CreateCustomerResults {
  CreatedCustomer = 0,
  InvalidEmail = 1,
  EmailAlreadyInUse = 3,
  NicknameAlreadyInUse = 4,
  PhoneNumberAlreadyInUse = 5,
  AutoLoginFailed = 6,
  FiscalIDAlreadyInUse = 7,
}
export enum IdentificationTypes {
  DiplomaticPassport = 1,
  DriverLicense = 2,
  EuropeanIdentification = 3,
  ForeignPassport = 4,
  INDSticker = 5,
  NatoPassport = 6,
  Passport = 7,
  PermanentResidencyDocument = 8,
  PrivilegedDocument = 9,
  ResidencePermitTypeEuEea = 10,
  ResidencePermitTypeI = 11,
  ResidencePermitTypeII = 12,
  ResidencePermitTypeIII = 13,
  ResidencePermitTypeIV = 14,
  ResidencePermitTypeW = 15,
  VNGCard = 16,
  ResidencePermitTypeV = 17,
}
export enum SubscriptionStatus {
  None = 0,
  Requested = 1,
  Subscribed = 2,
  Unsubscribed = 3,
}
export enum LoyaltyProgramOptions {
  None = 0,
  EnablePaymentMethods = 1,
  EnableLoyaltyPoints = 2,
  EnableDiscounts = 4,
}
export enum LoyaltyPointUsageOptions {
  None = 0,
  AllowAwarding = 1,
  UsableAsPaymentMethod = 2,
  UsableForWithdraw = 4,
}
export enum UserLoyaltyMutationType {
  Deposit = 0,
  Revoke = 1,
  Withdraw = 2,
  Refund = 3,
  Rebalance = 4,
}
export enum ResubscribeHandlingType {
  UpdateUserIdentifier = 0,
  KeepOriginalUserIdentifier = 1,
  Block = 2,
}
export enum AllowUnsubscribeByType {
  None = 0,
  Subscriber = 1,
  Employee = 2,
  All = 3,
}
export enum PhoneNumberTypes {
  Unspecified = 0,
  Home = 1,
  Mobile = 2,
  Work = 3,
  Fax = 4,
  Other = 999,
}
export enum SearchUserSources {
  Internal = 1,
  External = 2,
}
export enum SubscriptionConfirmation {
  None = 0,
  Email = 1,
  Webhook = 2,
}
export enum BorrowedState {
  Open = 0,
  Returned = 1,
  Purchased = 2,
}
export enum UserInteractionTypes {
  /**
   * @deprecated Use InteractionType.PrivateNote instead
   */
  Manual = 0,
  /**
   * @deprecated Use InteractionType.Email instead
   */
  Communication = 1,
}
export enum InteractionType {
  PublicNote = 0,
  PrivateNote = 1,
  Ledger = 2,
  Email = 3,
}
export enum TransputJobStatuses {
  New = 0,
  Running = 1,
  Finished = 2,
  Failed = -1,
}
export enum OrganizationUnitSupplierTargets {
  Default = 0,
  Replenishment = 1,
  SupplierReturns = 2,
  Pickup = 3,
}
export enum OrganizationUnitSupplierTypes {
  Internal = 0,
  External = 1,
}
export enum OrderLineCommitmentStatus {
  Uncommitted = 0,
  SoftCommit = 1,
  HardCommit = 2,
  ManualCommit = 5,
  ForcedCommit = 10,
}
export enum PickupTypes {
  /**
   * Returns the shop that has the earliest availability date for each product.
   */
  FastestShop = 0,
  /**
   * Returns the pickup availability date for your current shop.
   */
  CurrentShop = 1,
}
export enum CarryOutTypes {
  /**
   * Returns the shop that has the most stock available for each product.
   */
  MostStock = 0,
  /**
   * Returns the carry-out availability date for your current shop.
   */
  CurrentShop = 1,
}
export enum PickupOrganizationUnitOpeningHoursType {
  /**
   * Openinghours are not returned.
   */
  None = 0,
  /**
   * Openinghours for the next seven days are returned. Each result will contain the date and day-of-week. This will take into account any special
   * upcoming openinghours.
   */
  NextSevenDays = 1,
  /**
   * Openinghours for the current week are returned. Similar to NextSevenDays with the difference that the first item will always be Monday.
   */
  CurrentWeek = 2,
  /**
   * The regular openinghours for each day of the week will be returned without taking into account any special openinghours or holidays.
   */
  Regular = 3,
}
export enum ProductBundleAvailabilityCalculationMethods {
  AnyOptionHasStock = 0,
  DefaultOptionHasStock = 1,
}
export enum ProductStatus {
  None = 0,
  PreRelease = 1,
  DeliveryOnly = 4,
  DisableDelivery = 8,
  DisablePickup = 16,
  DisableBackorder = 32,
  UseUp = 34,
  NonSellable = 64,
}
export enum ProductBundleLineTypes {
  Required = 0,
  Optional = 1,
  OptionalIncludedInPrice = 2,
}
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
export enum OrderLineAvailabilityStatus {
  Unknown = 0,
  Blocked = 5,
  CannotFulfill = 10,
  CannotFulfillOnTime = 12,
  CanFulfill = 15,
}
export enum OrderExportStatuses {
  NotExported = 0,
  ShouldBeExported = 1,
  Exporting = 2,
  Exported = 3,
  WillNotBeExported = 4,
}
export enum OrderTypes {
  Sales = 0,
  Purchase = 1,
}
export enum AvailabilityTimelineItemTypes {
  CurrentAvailability = 0,
  ExpectedCommitment = 1,
  ExpectedReplenishment = 2,
  ExpectedShipment = 3,
}
export enum ProductPromisedDeliveryStatuses {
  Unknown = 0,
  KnownUnSure = 1,
  KnownQuiteSure = 2,
  KnownSure = 3,
  OnDay = 4,
  Stock = 5,
}
export enum ProductTypes {
  None = 0,
  Stock = 1,
  Insurance = 2,
  Marketing = 4,
  GiftCard = 8,
  Service = 16,
  GreetingCard = 32,
  CustomPricing = 64,
  External = 128,
  OrderCosts = 256,
  SystemGenerated = 512,
  ProductSet = 1024,
  BundleProduct = 2048,
  VirtualProduct = 4096,
  MadeToOrder = 8192,
  Configurable = 16384,
  SupplierProduct = 32768,
  Template = 65536,
  SecondChance = 131072,
  NotReturnable = 262144,
}
export enum StockLabelFeature {
  Sellable = 0,
  Movable = 1,
  Adjustable = 2,
  Returnable = 3,
  CycleCountable = 4,
  FullStockCountable = 5,
  ReturnToSupplierable = 6,
}
export enum OrderLineTypes {
  NormalProduct = 0,
  Discount = 1,
  ExtraCosts = 2,
  ShippingCosts = 5,
  ReturnCosts = 6,
  PriceCorrection = 7,
  Service = 8,
  GiftWrappingCosts = 9,
  DownPayment = 10,
}
export enum LineActionTypes {
  None = 0,
  ReserveLine = 1,
  OrderLine = 2,
  ShipLine = 3,
  Delivery = 4,
}
export enum ShippingMethodDeliveryTypes {
  None = 0,
  Default = 1,
  ShipFromStore = 2,
  Interbranch = 4,
}
export enum ExternalModificationStatuses {
  None = 0,
  CancellationRequested = 1,
  QuantityChangeRequested = 2,
  CancellationRequestDenied = 4,
  CancellationRequestApproved = 8,
  QuantityChangeRequestDenied = 16,
  QuantityChangeRequestApproved = 32,
}
export enum OrderLineFulfillmentStatuses {
  None = 0,
  WaitingForStock = 300,
  Pending = 400,
  InProgress = 500,
  Cancelled = 800,
  Completed = 1000,
}
export enum OrderLineHoldStatus {
  None = 0,
  SerialNumberValidationRequired = 1,
}
export enum UpdateStockIdentifier {
  ID = 0,
  BackendID = 1,
}
export enum ShipmentReceiveMethods {
  Manual = 0,
  Automatic = 1,
  UserDefined = 2,
}
export enum ShipmentIdentifierTypes {
  Crate = 0,
  Seal = 1,
}
export enum ShipmentStatuses {
  Open = 0,
  Completed = 10,
  Cancelled = 11,
  Pending = -1,
}
export enum ShipmentIdentifierRequiredFor {
  None = 0,
  RTS = 1,
  PurchaseOrder = 2,
}
export enum ShipmentLineDeliveryTypes {
  Default = 0,
  Surplus = 1,
  Deficiency = 2,
}
export enum ShipmentStatusType {
  CarrierStatus = 0,
}
export enum SerialNumberRegistrationStatus {
  Pending = 0,
  Verified = 1,
  Rejected = -1,
}
export enum SerialNumberConfigurationRequirement {
  Sales = 0,
  OwnershipChange = 1,
  StockMovements = 2,
  None = -1,
}
export enum ParseTypes {
  None = 0,
  Fast = 1,
  TypeCheck = 3,
  Full = 7,
}
export enum CompletionCategories {
  Keyword = 0,
  Member = 1,
  Snippet = 2,
}
export enum EditableFieldTypes {
  SetVariable = 0,
  DeclareTable = 1,
}
export enum EditableFieldValueTypes {
  String = 0,
  Integer = 1,
  Decimal = 2,
  Boolean = 3,
  Date = 4,
}
export enum EditableItemValueTypes {
  NumberLiteral = 0,
  StringLiteral = 1,
  BooleanLiteral = 2,
  NullLiteral = 3,
  DynamicValue = 4,
}
export enum EditableFieldEntityTypes {
  OrganizationUnitBackendID = 0,
  ProductBackendID = 1,
  CountryID = 2,
}
export enum EditableTableColumnType {
  Unknown = 0,
  String = 1,
  Number = 2,
  Boolean = 3,
}
export enum LastState {
  Unknown = 0,
  Succeeded = 1,
  Failed = 2,
}
export enum QuickBuyType {
  Checkout = 1,
  ShoppingCart = 2,
}
export enum ProductRelationDirection {
  Unidirectional = 0,
  Bidirectional = 1,
  Group = 2,
}
export enum StockRelationType {
  None = 0,
  Replacing = 1,
}
export enum ProductAvailabilityFilterTypes {
  CurrentOrganizationUnit = 0,
  Suppliers = 1,
}
export enum DiscountTriggers {
  Automatic = 0,
  Manual = 1,
  Coupon = 2,
  Bundles = 4,
  Loyalty = 5,
  SystemManaged = -1,
}
export enum DiscountAppliesTo {
  None = 0,
  Order = 1,
  Line = 2,
  Both = 3,
}
export enum CustomFieldFilterType {
  And = 0,
  Or = 1,
}
export enum ProductBundleTypes {
  Discount = 0,
  Configurable = 1,
}
export enum PrintType {
  Paper = 0,
  Zebra = 1,
}
export enum RenderThermalPrintResultOutputFormat {
  Html = 1,
  Pdf = 2,
  Png = 4,
}
export enum ComponentTypes {
  PriceEntries = 0,
  Markup = 1,
  CopyPricesFromOtherPriceList = 2,
}
export enum LowProductCountOptions {
  Auto = 0,
  Optimize = 1,
  DoNotOptimize = 2,
}
export enum PriceListUsageTypes {
  /**
   * The price that is used as the price that a product is sold for in the normal B2C scenarios.
   */
  Sales = 0,
  /**
   * The price that is used to calculat what the cost price is of products that are sold to the party who sells it.
   */
  Cost = 1,
  /**
   * A promotional price reduction of the normal Sales price.
   */
  Promotion = 2,
  /**
   * The price that is used for sales prices between organizations in a B2B scenario.
   */
  Purchase = 3,
  /**
   * RecommendedRetail prices can be used for display purposes, not used in any logic.
   */
  RecommendedRetail = 4,
  /**
   * Can be used to give an indication of effective product prices after applying discounts. This is purely for display purposes.
   */
  PotentialDiscountedPrice = 5,
  /**
   * Economic taxes (recycling fees) can be used for display purposes, not used in any logic.
   */
  EcoTax = 6,
}
export enum FactorType {
  Amount = 0,
  Percentage = 1,
}
export enum ApprovementFailureReasons {
  AlreadyApproved = 1,
  OrderAlreadyPaid = 2,
}
export enum PaymentStatuses {
  New = 0,
  Pending = 1,
  Partial = 2,
  Confirmed = 10,
  Failed = -1,
}
export enum PaymentTypeCaptureMoment {
  None = 0,
  Manual = 1,
  Ship = 2,
  Confirmation = 3,
}
export enum PaymentTransactionProperties {
  None = 0,
  /**
   * The transaction was reversed by the psp
   */
  Reversed = 1,
  /**
   * The transaction was charched back due to a dispute
   */
  Chargedback = 2,
  /**
   * The losses created by a Reversal or a Chargeback are booked
   */
  DisputeResolved = 4,
  /**
   * A request for cancelling the remaining capturable amount has been done
   */
  CaptureCancelRequested = 8,
  /**
   * A request for cancelling the remaining capturable amount has been confirmed
   */
  CaptureCancelConfirmed = 16,
  /**
   * A request for a fraud check has been issued, pending external verification
   */
  FraudCheckRequested = 32,
  /**
   * A request for a fraud check has passed
   */
  FraudCheckPassed = 64,
  /**
   * A request for a fraud check has been denied
   */
  FraudCheckDenied = 128,
  /**
   * A request for cancelling the remaining capturable amount was failed
   */
  CaptureCancelFailed = 256,
}
export enum CostPriceCalculationMethod {
  Manual = 0,
  WeightedAverageCost = 1,
  LastIn = 2,
  Fifo = 3,
}
export enum ExcludedProperties {
  CustomFields = 1,
  Openinghours = 2,
  Address = 4,
}
export enum OrderCancellationOptions {
  CanBeCancelled = 0,
  AlreadyCancelled = 1,
  ShouldBeRequested = 2,
  MustBeRequested = 3,
  CannotBeCancelled = 4,
}
export enum AutoProcess {
  Ship = 0,
  ShipAndRefund = 1,
  None = 2,
}
export enum OrderExportValidationResults {
  Unknown = 0,
  Valid = 1,
  Invalid = 2,
  NothingToBeExported = 3,
}
export enum RequiredFor {
  PlaceOrder = 1,
  Payment = 2,
  Ship = 4,
  Invoice = 8,
  All = 15,
}
export enum OrderStatus {
  Cart = 0,
  Order = 1,
}
export enum OrderProperties {
  None = 0,
  IsPickup = 1,
  CreatedByEmployee = 2,
  IsInterbranch = 4,
  IsB2B = 8,
  Duplicated = 16,
  /**
   * Indicates that the order was auto completed with force by a user.
   */
  Autocompleted = 32,
  /**
   * ReturnToSupplier indicates that the order will be returned from the store back to the supplier (RMA/CMA).
   */
  ReturnToSupplier = 128,
  /**
   * CustomTaxesApplied indicates that the order has custom taxes applied and other processes should not touch the taxes anymore.
   */
  CustomTaxesApplied = 256,
  /**
   * Replenishment indicates that this order is used to replenish a store/warehouse
   */
  Replenishment = 512,
  /**
   * Indicates that the SoldBy, which is normally determined automatically, has been manually overriden on the Order level.
   */
  SoldByOverridden = 1024,
  /**
   * Indicates that this order was created in localmode on liveguard and pushed to the cloud.
   */
  ImportedFromLiveGuard = 2048,
  IsTransferOrder = 4096,
  IsReturnsTransferOrder = 8192,
  IsOffer = 16384,
  IsUnified = 32768,
  /**
   * Indicates that this order has been returned, partially or completely, by another order.
   */
  IsReturned = 65536,
  /**
   * Indicates the order contains lines that have negative quantities to ship, aka, are returned lines.
   */
  HasReturnLines = 131072,
  DownPayment = 262144,
  /**
   * Indicates that the order is a restitution order
   */
  Restitution = 524288,
  /**
   * Indicates that the order is a repair order and contains products used during a repair
   */
  Repairs = 1048576,
  /**
   * Indicates that the order is the result of a return reversal.
   */
  IsReturnReversal = 2097152,
}
export enum OrderPreferredPriceDisplayMode {
  InTax = 0,
  ExTax = 1,
}
export enum DiscountInvalidReasons {
  None = 0,
  /**
   * The coupon does not exist
   */
  NotExist = 1,
  /**
   * The coupon is not valid (yet)
   */
  NotValidYet = 2,
  /**
   * The coupon is expired
   */
  Expired = 3,
  /**
   * The coupon has already been used
   */
  HasAlreadyBeenUsed = 4,
  /**
   * The coupon code has already been added
   */
  CouponCodeAlreadyAdded = 5,
  /**
   * The manual discount does not meet all conditions
   */
  ManualValueIsInvalid = 6,
  /**
   * The discount does not apply to this order type
   */
  InvalidOrderType = 7,
  Invalid = 8,
}
export enum ReturnWithoutProductsResult {
  None = 0,
  ReturnWithoutProducts = 1,
  ReturnWithProducts = 2,
}
export enum ReturnableStockOrganizationUnitReasons {
  CurrentOrganizationUnit = 0,
  ShipFromOrganizationUnit = 1,
  SupplierOrganizationUnit = 2,
  ReturnOrganizationUnit = 3,
  SoldFromOrganizationUnit = 4,
}
export enum OrderImportFulfillmentMethod {
  Delivery = 1,
  Pickup = 2,
  CarryOut = 3,
}
export enum PaymentStatus {
  NotPaid = 0,
  Paid = 1,
  Partial = 2,
}
export enum OrderShippingStatusType {
  Picked = 0,
  Packed = 1,
  CarrierStatus = 2,
}
export enum ReturnToSupplierRequestStatus {
  New = 0,
  Processing = 1,
  Processed = 2,
  Error = 9,
}
export enum PotentialFulfillmentOptionBlockingReasons {
  NoPartialFulfillmentAllowed = 0,
}
export enum OrderFulfillmentLineStatuses {
  New = 0,
  Deactivated = 50,
  Cancelled = 100,
  WaitingForStock = 500,
  Pending = 600,
  InProgress = 900,
  Completed = 1000,
}
export enum MessageQueueErrorStatuses {
  New = 0,
  Republished = 1,
  SuccessAfterRetry = 2,
  FailureAfterRetry = 3,
  Ignored = 4,
}
export enum InvoiceDisputeTypes {
  Original = 0,
  Expected = 1,
}
export enum InvoiceFullExportStatus {
  None = 0,
  Complete = 1,
  NotComplete = 2,
}
export enum InvoiceExportExternalStatus {
  None = 0,
  Pending = 1,
  Completed = 2,
  Failed = -1,
}
export enum InvoiceOutputType {
  None = 0,
  Mail = 1,
  PDF = 2,
  Paper = 4,
  Thermal = 8,
  All = 15,
}
export enum ControlDocumentTypes {
  ControlDocument = 2,
  InitializationDocument = 3,
  ClosingDocument = 4,
  MonthlyControlDocument = 5,
  YearlyControlDocument = 6,
  IntegrityCheckDocument = 7,
  Unknown = 8,
}
export enum ProductGiftCardCommunicationOptions {
  Mail = 1,
  Sms = 2,
}
export enum CashCorrectionTypes {
  CashJournalOpening = 1,
  CashJournalClosing = 2,
}
export enum CashDepositStatus {
  Done = 0,
  Pending = -1,
}
export enum CashJournalTypes {
  Default = 0,
  NonMonetary = 1,
}
export enum CashExpenseAmountTypes {
  Expense = 0,
  Income = 1,
}
export enum PaymentCashJournalMethod {
  None = 0,
  Close = 1,
  OpenAndClose = 2,
  Ignore = 3,
}
export enum CashTransactionLedgerTypes {
  MoveFrom = 0,
  MoveTo = 1,
  Deposit = 2,
  Correction = 3,
  Expense = 4,
  AmountGiven = 5,
  Change = 6,
}
export enum FinancialPeriodStatus {
  Open = 0,
  Closing = 1,
  Closed = 2,
  Processed = 3,
  Pending = -1,
}
export enum DeviceStatus {
  Unknown = 0,
  Open = 1,
  Opening = 2,
  Closed = 3,
  Missing = 4,
}
export enum LegacyCashJournalTypes {
  Register = 0,
  Safe = 1,
}
export enum FinancialEventStatuses {
  Unprocessed = 0,
  Processed = 1,
  Ignored = 2,
  NoProcessingRequired = 3,
  NoMatchingRecipe = 4,
}
export enum FinancialPeriodExportTrigger {
  PeriodOpened = 0,
  PeriodClosed = 1,
  CashJournalOpened = 2,
  CashJournalClosed = 3,
  CashDeposit = 4,
  CashExpense = 5,
  CashMovement = 6,
  Difference = 7,
}
export enum EventExportConfigurationStatus {
  Enabled = 1,
  Disabled = 2,
  Error = 3,
  Done = 4,
}
export enum EventExportConfigurationResponseMode {
  /**
   * Expects a 200 OK response with a body of exactly: '[ACK]' (without the singular quotes)
   */
  Normal = 0,
  /**
   * We don't care what you send back as long as it is a 2XX statuscode
   */
  Loose = 1,
}
export enum EventExportTarget {
  Order = 1,
  Shipment = 2,
  PaymentTransaction = 3,
  Customer = 4,
  Invoice = 5,
  StockMutation = 6,
  OrganizationUnit = 7,
  FinancialPeriod = 8,
  FinancialPeriodAudit = 9,
  OrderFulfillmentLine = 10,
  System = 11,
  UserTasks = 12,
  CashCorrection = 13,
  CashDeposit = 14,
  CashExpense = 15,
  CashMovement = 16,
  RecurringTask = 17,
  OrderFulfillment = 18,
  Coupon = 19,
  ProductAvailability = 20,
  Repairs = 21,
  StockNotification = 22,
  SerialNumber = 23,
  LoyaltyProgram = 24,
  Cases = 25,
  Wishlist = 26,
  Assortment = 27,
  Survey = 28,
  SurveyResponse = 29,
}
export enum ProtocolTypes {
  /**
   * Use the best available HTTP version (default)
   */
  HTTP_ANY = 0,
  /**
   * Explicitly instruct EVA to use HTTP/1.1
   */
  HTTP_1_1 = 1,
}
export enum DiscountValueType {
  Percentage = 0,
  Amount = 1,
}
export enum CasePriority {
  Low = 0,
  Medium = 1,
  High = 2,
  Critical = 9,
}
export enum AuthenticationResults {
  NotAuthenticated = 0,
  NotAuthorized = 1,
  Authenticated = 2,
  NeedsTwoFactorAuthentication = 3,
  NeedsOrganizationUnitID = 4,
  NeedsEmailVerification = 5,
  NeedsPasswordReset = 6,
  NeedsVisibilityGroupID = 7,
}
export enum AuthenticationFailureReasons {
  None = 0,
  InvalidRequest = 1,
  NeedsEmailVerification = 2,
  NotAuthorized = 3,
  InvalidCredentials = 4,
  ThirdPartyFailure = 5,
  InvalidUserType = 6,
  NeedsPasswordReset = 7,
  AuthenticationOnPrimaryRegionFailed = 8,
  SelectedOrganizationUnitNotAvailable = 9,
  UserDoesntExist = 10,
  UserDeactivated = 11,
  InvalidTwoFactorAuthentication = 12,
  CannotUseApiKeyForLogin = 13,
}
export enum TwoFactorAuthenticatorStatus {
  Disabled = 0,
  Pending = 1,
  Confirmed = 2,
  Failed = 3,
}
export enum TwoFactorAuthenticatorType {
  Passive = 0,
  Active = 1,
}
export enum FinancialReportingPeriodTypes {
  Month = 0,
  FiscalYear = 1,
}
export enum TerminalReportType {
  X = 0,
  Z = 1,
}
export enum AddressType {
  Street = 0,
  Postal = 1,
  Billing = 2,
  ShipTo = 3,
  ShipFrom = 4,
}
export enum FinancialPeriodAuditStatus {
  New = 0,
  Processing = 10,
  ProcessingError = 11,
  Processed = 12,
  Exporting = 20,
  ExportingError = 21,
  Exported = 22,
  NothingToBeExported = 999,
}
export enum AuditingVatNumberValidationResult {
  Unknown = 0,
  Success = 1,
  Failure = -1,
}
export enum AsyncServiceRequestStatus {
  InProgress = 0,
  Completed = 1,
  Failed = 2,
}
export enum RuleType {
  Warning = 0,
  Error = 1,
}
export enum PropertyType {
  FirstName = 1,
  LastName = 2,
  Address1 = 3,
  Address2 = 4,
  HouseNumber = 6,
  ZipCode = 7,
  Subdistrict = 8,
  District = 9,
  City = 10,
  State = 11,
  CountryID = 12,
  EmailAddress = 13,
  PhoneNumber = 14,
  CountrySubdivisionID = 15,
  Remark = 16,
}
export enum VisibilityType {
  Visible = 1,
  NotVisible = 2,
  VisibleAndRequired = 3,
}
export enum FraudDataType {
  Address = 1,
  IBAN = 2,
  IdentificationDocument = 3,
}
export enum UserRequirementFor {
  None = 0,
  Create = 1,
  PlaceOrder = 2,
  Payment = 4,
  All = 7,
}
export enum OrganizationUnitSetTypes {
  System = 0,
  Custom = 1,
  AdHoc = 2,
}
export enum SubscriptionType {
  Default = 0,
  Loyalty = 1,
}
export enum SurveyDeactivationReasons {
  None = 0,
  Unpublished = 1,
  DeactivatedByUser = 2,
  BySurveyTimeFrame = 4,
}
export enum SurveyContext {
  None = 0,
  User = 1,
  Order = 2,
}
export enum CustomFieldDataTypes {
  String = 0,
  Bool = 1,
  /**
   * Whole numbers, no fractions
   */
  Integer = 2,
  Decimal = 3,
  Enum = 4,
  /**
   * Multiline string
   */
  Text = 5,
  DateTime = 6,
  Date = 7,
  Blob = 8,
}
export enum CustomFieldValueOperator {
  Equals = 0,
  NotEquals = 1,
  LowerThan = 2,
  LowerThanOrEqualTo = 3,
  HigherThanOrEqualTo = 4,
  HigherThan = 5,
  Contains = 7,
  DoesNotContain = 8,
  StartsWith = 9,
  DoesNotStartWith = 10,
  EndsWith = 11,
  DoesNotEndWith = 12,
  HasValue = 13,
  DoesntHaveValue = 14,
}
export enum SurveyResponseStatus {
  Open = 0,
  Started = 1,
  Completed = 2,
  Cancelled = 3,
  Terminated = 4,
}
export enum StockAllocationRuleValueTypes {
  Quantity = 0,
  Percentage = 1,
}
export enum StockAllocationRuleTypes {
  Reservation = 0,
  Limitation = 1,
}
export enum ShippingRestrictionType {
  SoldFromOrganizationUnit = 0,
  ShipFromOrganizationUnit = 1,
}
export enum ProductBarcodeOrigin {
  Imported = 0,
  UserDefined = 1,
}
export enum ProductRequirementDataTypes {
  String = 0,
  Bool = 1,
  Integer = 2,
  Decimal = 3,
  Enum = 4,
  Text = 5,
}
export enum ProductSearchTemplateTypes {
  Uncached = 0,
  Cached = 1,
  Static = 2,
}
export enum RoundingDirection {
  Closest = 0,
  Up = 1,
  Down = 2,
}
export enum RoundingAmountType {
  Fraction = 0,
  Divisor = 1,
}
export enum PriceListAdjustmentSystemType {
  Default = 0,
  SecondChance = 1,
}
export enum PaymentTransactionSettlementFileStatus {
  New = 0,
  Processing = 1,
  Processed = 2,
  Failed = -10,
}
export enum PaymentTransactionCaptureStatuses {
  /**
   * Payment Transaction Capture is created, but not yet communicated with the PSP.
   */
  New = 0,
  /**
   * The PSP is aware of the payment transaction capture and allows (or is) processing it.
   */
  Pending = 1,
  /**
   * The PSP has confirmed that the payment capture is complete.
   */
  Confirmed = 10,
  /**
   * The PSP has confirmed that the capture has failed.
   */
  Failed = -1,
}
export enum PaymentReturnActions {
  Default = 0,
  Blocked = 1,
  Forced = 2,
}
export enum PaymentTypeCategory {
  Other = 0,
  Debit = 1,
  Credit = 2,
  Cash = 3,
  Voucher = 4,
  Online = 5,
  Rounding = 6,
  Exchange = 7,
}
export enum OrganizationUnitSetOperatorTypes {
  Add = 0,
  Remove = 1,
}
export enum MessageTemplateTypes {
  Template = 1,
  Partial = 2,
  Layout = 3,
}
export enum MessageTemplateDestinations {
  Mail = 1,
  Sms = 2,
  Pdf = 4,
  Thermal = 8,
  Notification = 16,
  Http = 32,
}
export enum MessageTargetContentTypes {
  Html = 0,
  Pdf = 1,
  Plain = 2,
  Png = 3,
}
export enum LoyaltyProgramUsageTypes {
  None = 0,
  AllowAwarding = 1,
  UsableAsPaymentMethod = 2,
  UsableForWithdraw = 4,
}
export enum LoyaltyProgramStatus {
  Inactive = 0,
  Active = 1,
}
export enum LoyaltyProgramDeactivationReasons {
  None = 0,
  DeactivatedByGroup = 1,
  ManuallyDeactivated = 2,
  TimeFrame = 4,
  BudgetReached = 8,
}
export enum LoyaltyProgramGroupDeactivationReasons {
  None = 0,
  ManuallyDeactivated = 1,
  TimeFrame = 2,
  BudgetReached = 4,
}
export enum LoyaltyPaymentTaxHandlingType {
  InTax = 0,
  UpToTax = 1,
}
export enum UserBudgetDeduction {
  None = 0,
  Program = 1,
  PaymentMethod = 2,
  Both = 3,
}
export enum ClientApplicationType {
  None = 0,
  Tasks = 1,
  Sales = 2,
  Customers = 4,
}
export enum EntityFieldValidatorEntityTypes {
  PriceList = 1,
  PriceListAdjustment = 2,
}
export enum EntityFieldValidatorType {
  DefaultValidator = 0,
  StringValidator = 1,
}
export enum EntityFieldValidatorUserTypes {
  Employee = 1,
  Customer = 2,
  Api = 4,
  All = 7,
}
export enum EndpointConfigurationStatus {
  Enabled = 1,
  Disabled = 2,
  Error = 3,
}
export enum DiscountOrderTypes {
  None = 0,
  Sales = 1,
  Purchase = 2,
  All = 3,
}
export enum DaysOfWeek {
  None = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
  All = 127,
}
export enum DiscountConditionTypes {
  OneOf = 0,
  All = 1,
}
export enum FinancialDespersionType {
  DivideInProportionToProductPrice = 0,
  MostExpensiveToCheapest = 1,
  CheapestToMostExpensive = 2,
  HighestToLowestTaxRate = 3,
  LowestToHighestTaxRate = 4,
}
export enum DiscountCompliancyRule {
  None = 0,
  EmployeeDiscountTaxExclusion = 1,
}
export enum DiscountDeactivationReasons {
  None = 0,
  Unverified = 1,
  DisabledByUser = 2,
  ByDiscountUsage = 4,
  ByDiscountBudget = 8,
  ByDiscountTimeFrame = 16,
  ByDiscountCampaignBudget = 32,
  ByDiscountCampaignTimeFrame = 64,
}
export enum SettingRemovalReason {
  Untyped = 0,
  DeletedOrganizationUnit = 1,
  ParentHasSameValue = 2,
  ValueIsSameAsDefault = 3,
}
export enum SettingSensitivityTypes {
  Normal = 0,
  Sensitive = 1,
  Masked = 2,
  Encrypted = 6,
  CloudOnly = 8,
}
export enum SettingValueEntityTypes {
  None = 0,
  OrganizationUnit = 1,
}
export enum CompanyType {
  Unknown = 0,
  Customer = 1,
  OrganizationUnit = 2,
}
export enum RepairStatus {
  WaitingForGoods = 0,
  WaitingForRepair = 1,
  WaitingForPayment = 2,
  WaitingForShipment = 3,
  Finished = 4,
  Cancelled = 5,
  WaitingForPickup = 6,
  Started = 7,
  WaitingForGoods_OnHold = 8,
  WaitingForRepair_OnHold = 9,
  WaitingForShipment_OnHold = 10,
}
export enum CaseStatusAction {
  None = 0,
  CreateRepair = 1,
  CreateOrder = 2,
}
export enum AuditingSettingType {
  String = 0,
  Bool = 1,
  Integer = 2,
  Decimal = 3,
}
export enum BookingFlags {
  None = 0,
  WithTaxInformation = 1,
  WithoutOffsets = 2,
  WithOrderNumber = 4,
  WithReference = 8,
  WithInvoiceNumber = 16,
  WithCurrencyInformation = 32,
}
export enum AccountType {
  GeneralLedger = 1,
  Debtor = 2,
  Creditor = 3,
}
export enum CustomerInteractionTaskPriorities {
  None = 0,
  Low = 1,
  Normal = 2,
  High = 3,
  Immediate = 4,
}
export enum ProductCapacityTimeFrame {
  Daily = 0,
  Weekly = 1,
}
export enum ParsingErrorType {
  DuplicateVariableDeclaration = 1,
  InvalidSheetType = 3,
  InvalidScopeType = 4,
  InvalidExpressionValueType = 5,
  InvalidOption = 6,
}
export enum NodeTypes {
  Root = 0,
  Comparison = 1,
  Number = 2,
  Calculation = 3,
  And = 4,
  ReadVariable = 5,
  Array = 6,
  String = 7,
  DeclareVariable = 8,
  Switch = 9,
  SwitchCase = 10,
  Boolean = 11,
  Or = 12,
  Require = 13,
  Scope = 14,
  BoostScore = 15,
  NullCheck = 16,
  Null = 17,
  Fanout = 18,
  InterpolatedString = 19,
  Group = 20,
  Block = 22,
  DeclareTable = 23,
  ForEach = 24,
  If = 25,
  Lookup = 26,
  Output = 27,
  SetVariable = 28,
  FunctionCall = 30,
  FunctionCallParameter = 31,
  Exit = 32,
  IfBranch = 33,
  TableRow = 34,
  TableCell = 35,
  ArrayAccessor = 36,
  Identifier = 37,
  MemberAccess = 38,
  LambdaFunction = 39,
  Not = 40,
  ExtensionPoint = 1000,
  Recipe = 2000,
  Book = 2001,
  BookLine = 2002,
  Ignore = 2003,
}
export enum RepairReceiveMethod {
  Immediate = 0,
  Deferred = 1,
}
export enum RepairShippingOptions {
  Pickup = 0,
  Delivery = 1,
}
export enum RepairResult {
  Completed = 0,
  Cancelled = 1,
}
export enum RepairState {
  WaitingForGoods = 0,
  WaitingForRepair = 1,
  WaitingForPayment = 2,
  WaitingForShipment = 3,
  Finished = 4,
  Cancelled = 5,
  WaitingForPickup = 6,
  Started = 7,
  OnHold = 8,
}
export enum DeterminationType {
  Oldest = 0,
  Smallest = 1,
  Largest = 2,
}
export enum ProductCountFilter {
  SingleProduct = 1,
  MultiProduct = 2,
  All = 3,
}
export enum PrintTaskType {
  Paper = 0,
  Zebra = 1,
}
export enum CompleteZonedCycleCountCompletionTypes {
  AcceptPreCount = 0,
  Recount = 1,
}
export enum ZonedCycleCountResultTypes {
  Accepted = 0,
  Recount = 1,
  CompleteResources = 2,
}
export enum CycleCountNotCreatedReason {
  AlreadyExists = 0,
  ProductNotStock = 1,
  ProductNotInAssortment = 2,
}
export enum ZonedCycleCountResultStatus {
  None = 0,
  /**
   * The results of the PreCounts matched the expected stock, so no stockmutations were created
   */
  Correct = 1,
  /**
   * The cyclecount results were discarded and a new one is created
   */
  RecountRequested = 2,
  /**
   * The results of the PreCounts are modified during finalizing
   */
  Modified = 4,
  /**
   * The finalizing resulted in at least one stockmutation
   */
  Deviated = 8,
}
export enum ZonedCycleCountSerialNumberStatus {
  /**
   * Serial number was expected and counted.
   */
  ExpectedAndCounted = 0,
  /**
   * Serial number was expected but not counted.
   */
  ExpectedNotCounted = 1,
  /**
   * Serial number was expected but counted on a different stock label.
   */
  CountedOnDifferentStockLabel = 2,
  /**
   * Serial number was expected but counted on a different organization unit.
   */
  CountedOnDifferentOrganizationUnit = 3,
  /**
   * Serial number was expected but counted as a different product.
   */
  CountedAsDifferentProduct = 4,
  /**
   * Serial number was counted but not expected.
   */
  Unexpected = 5,
}
export enum TemplateOutputChannel {
  Print = 1,
  Download = 2,
  Email = 4,
  Receipt = 8,
}
export enum NothingToReturnAction {
  Cancel = 0,
  Complete = 1,
}
export enum ReservationCleanupTaskTypes {
  Default = 0,
  ReturnToSupplier = 1,
}
export enum ReservationCleanupTaskLineTypes {
  ExpiredReservation = 0,
  Cancellation = 1,
}
export enum StockReplenishmentType {
  Default = 0,
  Priority = 1,
  AutoGenerated = 2,
}
export enum InitialCycleCountStatus {
  New = 0,
  DetailCount = 1,
  Processing = 2,
  Finished = 3,
}
export enum InitialCycleCountLabelStatus {
  New = 0,
  PreCounted = 1,
  Counted = 2,
}
export enum ValidatePreCountResults {
  Correct = 1,
  Deviation = 2,
  RedoDetailCount = 3,
}
export enum FullStockCountLabelState {
  Waiting = 0,
  CountInProgress = 1,
  WaitingRecount = 2,
  RecountInProgress = 3,
  Cancelled = 8,
  Completed = 9,
}
export enum RejectionReason {
  None = 0,
  NameToLong = 1,
  UknownStockLabel = 2,
  DuplicateInUpload = 3,
  AlreadyExists = 4,
}
export enum FullStockCountFilterState {
  Open = 0,
  Active = 1,
  Completed = 2,
  Cancelled = 3,
}
export enum FullStockCountType {
  Initial = 1,
  InProgress = 2,
}
export enum UserCardAmountTypes {
  Currency = 0,
  Points = 1,
}
export enum UserCardMutationStatuses {
  Pending = 0,
  Completed = 1,
  Cancelled = 2,
}
export enum OpenCashDrawerResults {
  Failure = 0,
  Opened = 1,
  AlreadyOpen = 2,
  OpenedAndClosed = 3,
}
export enum PointPolicyType {
  None = 0,
  Pending = 1,
  Expiration = 2,
}
export enum UserOrigins {
  /**
   * This UserOrigin already exists
   */
  AlreadyExists = "UserOrigins:AlreadyExists",
}
export enum CreateEmployee {
  /**
   * This user already exists, use the UpdateUser service.
   */
  UserAlreadyExists = "CreateEmployee:UserAlreadyExists",
  /**
   * There is already an existing customer.
   */
  ExistingCustomer = "CreateEmployee:ExistingCustomer",
}
export enum GenerateIdentificationCode {
  /**
   * Cannot generate identification codes for Single Sign-On users.
   */
  CannotGenerateIdentificationCodeForSingleSignOn = "GenerateIdentificationCode:CannotGenerateIdentificationCodeForSingleSignOn",
}
export enum TaxService {
  /**
   * StartDate ({0:string}) cannot be after EndDate: ({1:string})
   */
  StartDateShouldBeBeforeEndDate = "TaxService:StartDateShouldBeBeforeEndDate",
  /**
   * StartDate cannot exist without EndDate and vice-versa
   */
  StartAndEndDateRequired = "TaxService:StartAndEndDateRequired",
  /**
   * Validation of the Excel file failed: {0:string}
   */
  InvalidExcelFile = "TaxService:InvalidExcelFile",
}
export enum StockAllocations {
  /**
   * It's required to specify an OrganizationUnit to create this StockAllocationRule.
   */
  OrganizationUnitRequired = "StockAllocations:OrganizationUnitRequired",
  /**
   * OrganizationUnit {0:number} is not directly supplied by OrganizationUnit {1:number}.
   */
  InvalidOrganizationUnit = "StockAllocations:InvalidOrganizationUnit",
}
export enum StockAllocationRules {
  /**
   * Validation of the Excel file failed: {0:string}
   */
  UploadStockAllocationRuleValidationError = "StockAllocationRules:UploadStockAllocationRuleValidationError",
}
export enum StockLabels {
  /**
   * StockLabel with name '{0:string}' already exists
   */
  AlreadyExists = "StockLabels:AlreadyExists",
}
export enum OrganizationUnitShippingMethod {
  /**
   * OrganizationUnitShippingMethod already exists
   */
  OrganizationUnitShippingMethodAlreadyExists = "OrganizationUnitShippingMethod:OrganizationUnitShippingMethodAlreadyExists",
}
export enum Roles {
  /**
   * The code `{0:string}` is preserved for system roles.
   */
  CodeIsPreservedForSystemRoles = "Roles:CodeIsPreservedForSystemRoles",
  /**
   * Cannot modify or delete New Black system roles.
   */
  CannotModifyOrDeleteNewBlackRole = "Roles:CannotModifyOrDeleteNewBlackRole",
}
export enum RoleSets {
  /**
   * A role set with BackendID '{0:string}' already exists
   */
  RoleSetWithBackendIDAlreadyExists = "RoleSets:RoleSetWithBackendIDAlreadyExists",
  /**
   * Role set '{0:string}' is still attached to organization units
   */
  RoleSetIsStillAttached = "RoleSets:RoleSetIsStillAttached",
}
export enum ProductBarcodes {
  /**
   * Barcode {barcode:string} already exists for ProductID {productID:number}
   */
  BarcodeAlreadyExists = "ProductBarcodes:BarcodeAlreadyExists",
  /**
   * Product not found
   */
  ProductNotFound = "ProductBarcodes:ProductNotFound",
  /**
   * UnitOfMeasure not found
   */
  UnitOfMeasureNotFound = "ProductBarcodes:UnitOfMeasureNotFound",
}
export enum ProductRequirements {
  /**
   * The supplied backendid already exists.
   */
  DuplicateBackendID = "ProductRequirements:DuplicateBackendID",
}
export enum ProductUnitOfMeasures {
  /**
   * Product not found
   */
  ProductNotFound = "ProductUnitOfMeasures:ProductNotFound",
  /**
   * UnitOfMeasure not found
   */
  UnitOfMeasureNotFound = "ProductUnitOfMeasures:UnitOfMeasureNotFound",
  /**
   * The Quantity should be greather than 0
   */
  InvalidQuantity = "ProductUnitOfMeasures:InvalidQuantity",
}
export enum UnitOfMeasures {
  /**
   * This UnitOfMeasure already exists
   */
  AlreadyExists = "UnitOfMeasures:AlreadyExists",
}
export enum Pricing {
  /**
   * A BackendID cannot be defined without a BackendSystemID and vice versa.
   */
  BackendIdentifiersRequired = "Pricing:BackendIdentifiersRequired",
  /**
   * PriceList {0:number} has the same BackendID.
   */
  DuplicateBackendID = "Pricing:DuplicateBackendID",
  /**
   * You do not have access to pricelist {0:number}.
   */
  CannotViewPriceList = "Pricing:CannotViewPriceList",
}
export enum PriceLists {
  /**
   * Cannot create relation to historical price list
   */
  CannotCreateRelationToHistoricalPriceList = "PriceLists:CannotCreateRelationToHistoricalPriceList",
  /**
   * The given pricelist has a SpecialPriceListID, which is already attached to the given OU. SpecialPriceList is deprecated and should be remove from the pricelist.
   */
  SpecialPriceListIsAlreadyAttachedToOrganizationUnit = "PriceLists:SpecialPriceListIsAlreadyAttachedToOrganizationUnit",
}
export enum PaymentTransactionLedgerTypes {
  /**
   * This PaymentTransactionLedgerType already exists
   */
  AlreadyExists = "PaymentTransactionLedgerTypes:AlreadyExists",
  /**
   * This PaymentTransactionLedgerType is internal and can't be modified
   */
  IsInternal = "PaymentTransactionLedgerTypes:IsInternal",
}
export enum OrganizationUnitSets {
  /**
   * The OrganizationUnitSetScope is still being used, cannot delete.
   */
  CannotDeleteScopeInUse = "OrganizationUnitSets:CannotDeleteScopeInUse",
  /**
   * The OrganizationUnitSet is still being used, cannot delete.
   */
  CannotDeleteInUse = "OrganizationUnitSets:CannotDeleteInUse",
  /**
   * Set {0:string} is a set managed by EVA and cannot be modified.
   */
  CannotModifySystemSet = "OrganizationUnitSets:CannotModifySystemSet",
  /**
   * It's not possible to use OrganizationUnitSetTypes.System to create new sets.
   */
  CannotCreateSystemSet = "OrganizationUnitSets:CannotCreateSystemSet",
  /**
   * It's not possible to use an AdHoc set as a subset of another set.
   */
  CannotUseAdHocSetsAsSubset = "OrganizationUnitSets:CannotUseAdHocSetsAsSubset",
  /**
   * Name is required when creating a non-AdHoc set.
   */
  NameIsRequired = "OrganizationUnitSets:NameIsRequired",
}
export enum OpeningHoursTypes {
  /**
   * This OpeningHoursType already exists
   */
  AlreadyExists = "OpeningHoursTypes:AlreadyExists",
  /**
   * The name of an internal OpeningHoursType cannot be modified.
   */
  NameOfInternalOpeningHourTypeCannotBeModified = "OpeningHoursTypes:NameOfInternalOpeningHourTypeCannotBeModified",
  /**
   * This OpeningHoursType is internal and cannot be deleted
   */
  InternalTypesCannotBeDeleted = "OpeningHoursTypes:InternalTypesCannotBeDeleted",
  /**
   * This OpeningHoursType has (exception)templates attached and cannot be deleted
   */
  TypeHasTemplatesAndCannotBeDeleted = "OpeningHoursTypes:TypeHasTemplatesAndCannotBeDeleted",
}
export enum Cultures {
  /**
   * This currency already exists for this OrganizationUnit
   */
  CurrencyAlreadyExists = "Cultures:CurrencyAlreadyExists",
  /**
   * Additional currency must differ from main currency.
   */
  AdditionalCurrencyMustDifferFromMainCurrency = "Cultures:AdditionalCurrencyMustDifferFromMainCurrency",
  /**
   * The selected cashhandler is not available for the given currency
   */
  CashHandlerCurrencyMismatch = "Cultures:CashHandlerCurrencyMismatch",
  /**
   * This language already exists for this organization unit
   */
  LanguageAlreadyExists = "Cultures:LanguageAlreadyExists",
  /**
   * The organization unit must have at least one (inherited) language. Add a new language before removing this language.
   */
  MustHaveAtLeastOneLanguage = "Cultures:MustHaveAtLeastOneLanguage",
}
export enum OpeningHours {
  /**
   * There already exists opening hours for organization {organizationUnitName:string} and day {dayOfWeek:EVA.Core.DayOfWeek}
   */
  DuplicateOpeningHoursDayOfWeek = "OpeningHours:DuplicateOpeningHoursDayOfWeek",
  /**
   * There already exists opening hours for organization {organizationUnitName:string} and date {date:string}
   */
  DuplicateOpeningHoursDate = "OpeningHours:DuplicateOpeningHoursDate",
}
export enum CreateOrderLedgerType {
  /**
   * Can't create OrderLedgerType because its name already exists
   */
  OrderLedgerTypeAlreadyExists = "CreateOrderLedgerType:OrderLedgerTypeAlreadyExists",
}
export enum CustomerReturnReasons {
  /**
   * This CustomerReturnReason already exists
   */
  AlreadyExists = "CustomerReturnReasons:AlreadyExists",
}
export enum DeleteOrderLedgerType {
  /**
   * Can't delete OrderLedgerType because it's not custom
   */
  OrderLedgerTypeNotCustom = "DeleteOrderLedgerType:OrderLedgerTypeNotCustom",
}
export enum OrderFields {
  /**
   * OrderField with name '{0:string}' already exists.
   */
  AlreadyExists = "OrderFields:AlreadyExists",
  /**
   * Unknown data type: {0:string}
   */
  UnknownDataType = "OrderFields:UnknownDataType",
}
export enum RefundCorrectionReasons {
  /**
   * RefundCorrectionReason with BackendID '{0:string}' already exists.
   */
  AlreadyExists = "RefundCorrectionReasons:AlreadyExists",
}
export enum UnitPriceCorrectionReasons {
  /**
   * UnitPriceCorrectionReason with BackendID '{0:string}' already exists.
   */
  AlreadyExists = "UnitPriceCorrectionReasons:AlreadyExists",
}
export enum UpdateOrderLedgerType {
  /**
   * Can't update OrderLedgerType because it's not custom
   */
  OrderLedgerTypeNotCustom = "UpdateOrderLedgerType:OrderLedgerTypeNotCustom",
  /**
   * Can't update OrderLedgerType because its name already exists
   */
  OrderLedgerTypeAlreadyExists = "UpdateOrderLedgerType:OrderLedgerTypeAlreadyExists",
}
export enum ReturnReasons {
  /**
   * This return reason already exists.
   */
  AlreadyExists = "ReturnReasons:AlreadyExists",
}
export enum ClientApplications {
  /**
   * The requested BackendID is already in use
   */
  DuplicateBackendID = "ClientApplications:DuplicateBackendID",
}
export enum GiftCardConfigurations {
  /**
   * Handler is required
   */
  MissingHandler = "GiftCardConfigurations:MissingHandler",
  /**
   * The given handler doesn't exist
   */
  UnknownHandler = "GiftCardConfigurations:UnknownHandler",
  /**
   * The data is not in a valid format for the selected type
   */
  InvalidData = "GiftCardConfigurations:InvalidData",
  /**
   * Usage type needs at least Sale or Payment, never None
   */
  InvalidUsageType = "GiftCardConfigurations:InvalidUsageType",
  /**
   * The selected usage type is not supported for the selected handler
   */
  InvalidUsageTypeForHandler = "GiftCardConfigurations:InvalidUsageTypeForHandler",
}
export enum GiftCards {
  /**
   * This product already has an giftcard product
   */
  ProductAlreadyExists = "GiftCards:ProductAlreadyExists",
  /**
   * Handler is required
   */
  MissingHandler = "GiftCards:MissingHandler",
  /**
   * The given type doesn't exist
   */
  UnknownType = "GiftCards:UnknownType",
  /**
   * The data is not in a valid format for the selected type
   */
  InvalidData = "GiftCards:InvalidData",
  /**
   * The given product isn't a giftcard
   */
  InvalidProductType = "GiftCards:InvalidProductType",
}
export enum CashExpenseTypes {
  /**
   * CashExpenseType {0:string} already exists.
   */
  AlreadyExists = "CashExpenseTypes:AlreadyExists",
}
export enum ExportProductCostPriceLedgerSummary {
  /**
   * You can only export up to 100 products at a time.
   */
  TooManyProductsSelected = "ExportProductCostPriceLedgerSummary:TooManyProductsSelected",
}
export enum DiscountCouponServices {
  /**
   * UserID is required when logged in user is a Employee or API user.
   */
  UserIDRequired = "DiscountCouponServices:UserIDRequired",
}
export enum DiscountService {
  /**
   * Discount adheres to campaign start- and end date/time
   */
  DiscountAdheresToCampaignTime = "DiscountService:DiscountAdheresToCampaignTime",
  /**
   * You don't have permission to set discount budget information.
   */
  NotAllowedToSetDiscountBudgetData = "DiscountService:NotAllowedToSetDiscountBudgetData",
}
export enum Culture {
  /**
   * Culture for country {language:string} and language {country:string} already exists.
   */
  CultureAlreadyExists = "Culture:CultureAlreadyExists",
}
export enum SettingsExcelImporter {
  /**
   * OrganizationUnit with ID {0:string} not found on row {1:number}
   */
  OrganizationUnitByIDNotFound = "SettingsExcelImporter:OrganizationUnitByIDNotFound",
  /**
   * OrganizationUnit with BackendID {0:string} not found on row {1:number}
   */
  OrganizationUnitByBackendIDNotFound = "SettingsExcelImporter:OrganizationUnitByBackendIDNotFound",
  /**
   * No BackendID or OrganizationUnitID provided for row {0:number}
   */
  NoOrganizationUnitIdentifier = "SettingsExcelImporter:NoOrganizationUnitIdentifier",
  /**
   * Value on row {0:number} looks like a masked value
   */
  MaskedValueImport = "SettingsExcelImporter:MaskedValueImport",
}
export enum LoyaltyProgram {
  /**
   * Field '{0:string}' is required for the creation of a new Loyalty Program.
   */
  MissingFieldOnLoyaltyProgramCreation = "LoyaltyProgram:MissingFieldOnLoyaltyProgramCreation",
}
export enum CreateEmployeeResults {
  CreatedNewUser = 0,
  UpgradedExistingUser = 1,
  UpdatedExistingUser = 2,
}
export enum OrganizationSubsetOrdering {
  Ascending = 0,
  Descending = 1,
}
export enum LoyaltyPointAdjustmentType {
  Deposit = 0,
  Withdraw = 1,
  Rebalance = 2,
}
export enum ListSettingsTypedFilters {
  ShowAll = 0,
  ShowTyped = 1,
  ShowUntyped = 2,
}
export enum FinancialPeriodClosingImpediments {
  /**
   * There are {0:number} clocked-in users remaining that must be clocked out before the period can be closed.
   */
  ClockedInUsers = "FinancialPeriodClosingImpediments:ClockedInUsers",
}
export enum ParsingErrorType {
  InvalidSyntax = 0,
  UnknownVariable = 1,
  InvalidComparison = 2,
  InvalidOperator = 3,
  MissingCredit = 4,
  MissingDebit = 5,
  AmountMustBeNumerical = 6,
  InvalidBookOnOrganizationUnit = 7,
  InvalidEventType = 8,
  DuplicateVariable = 9,
  InvalidVariable = 10,
}
export enum CompletionSuggestionType {
  Variable = 0,
  Keyword = 1,
  Enum = 2,
  Account = 3,
}
export enum AccountingRecipeToPreviewType {
  V1 = 0,
  V2 = 1,
}
export enum PostNL {
  /**
   * There were no valid coordinates provided.
   */
  NoValidCoordinates = "PostNL:NoValidCoordinates",
  /**
   * There is not ShippingAddress set on the given Order.
   */
  MissingShippingAddress = "PostNL:MissingShippingAddress",
}
export enum TimeframeOptions {
  /**
   * Daytime delivery
   */
  Daytime = 1,
  /**
   * Evening delivery
   */
  Evening = 2,
  /**
   * Morning delivery before 10:00
   */
  Morning = 4,
  /**
   * Morning delivery before 12:00
   */
  Noon = 8,
  /**
   * Sunday delivery
   */
  Sunday = 16,
  /**
   * Sameday delivery (must be used in combination with Evening)
   */
  Sameday = 32,
  /**
   * Afternoon delivery before 17:00
   */
  Afternoon = 64,
  All = 127,
}
export enum Intersolve {
  /**
   * Failed to issue giftcard on order {0:number}
   */
  IssuingFailed = "Intersolve:IssuingFailed",
  /**
   * A CardNumber is required to activate a giftcard
   */
  MissingCardNumber = "Intersolve:MissingCardNumber",
  /**
   * A PaymentTransaction can only be refunded for the full amount
   */
  RefundsCanOnlyBeDoneForTheFullAmount = "Intersolve:RefundsCanOnlyBeDoneForTheFullAmount",
  /**
   * No CardActionLedger could be found for the PaymentTransaction
   */
  NoCardActionLedgerFound = "Intersolve:NoCardActionLedgerFound",
  /**
   * Card with SerialNumber {0:string} was not found
   */
  CardNotFound = "Intersolve:CardNotFound",
  /**
   * Trying to pay with '{currencyID:string}' but the card is in '{cardCurrencyID:string}'
   */
  CurrencyMismatch = "Intersolve:CurrencyMismatch",
  /**
   * Failed to create a payment: {0:string}
   */
  CardActivationFailed = "Intersolve:CardActivationFailed",
  /**
   * Failed to cancel the transaction: {0:string}
   */
  CardTransactionCancellationFailed = "Intersolve:CardTransactionCancellationFailed",
  /**
   * Code {Code:number}: {Message:string}
   */
  UnknownResultCode = "Intersolve:UnknownResultCode",
  /**
   * Error in the configuration at the Intersolve side. Please contact Intersolve.
   */
  ConfigurationError = "Intersolve:ConfigurationError",
  /**
   * Service failed. This can be a typo in the command.
   */
  ServiceFailed = "Intersolve:ServiceFailed",
  /**
   * Invalid operation.
   */
  Error_1 = "Intersolve:Error_1",
  /**
   * XML Syntax error
   */
  Error_2 = "Intersolve:Error_2",
  /**
   * Authorization failed, not logged in.
   */
  Error_3 = "Intersolve:Error_3",
  /**
   * Login failed (Reason not specified).
   */
  Error_4 = "Intersolve:Error_4",
  /**
   * Invalid parameters (e.g. no CardId specified).
   */
  Error_5 = "Intersolve:Error_5",
  /**
   * Not allowed to import this card holder.
   */
  Error_6 = "Intersolve:Error_6",
  /**
   * Retailer not in Group.
   */
  Error_7 = "Intersolve:Error_7",
  /**
   * Invalid Identifier.
   */
  Error_8 = "Intersolve:Error_8",
  /**
   * Invalid/unknown Retailer.
   */
  Error_9 = "Intersolve:Error_9",
  /**
   * Invalid/unknown Terminal.
   */
  Error_10 = "Intersolve:Error_10",
  /**
   * Invalid Terminal/password does not match. Not authorized.
   */
  Error_11 = "Intersolve:Error_11",
  /**
   * Invalid Terminal originating address.
   */
  Error_12 = "Intersolve:Error_12",
  /**
   * No configuration available.
   */
  Error_13 = "Intersolve:Error_13",
  /**
   * Origination address not authorized.
   */
  Error_14 = "Intersolve:Error_14",
  /**
   * No transaction available to confirm or cancel.
   */
  Error_15 = "Intersolve:Error_15",
  /**
   * RetailerId/TerminalId combination does not exist.
   */
  Error_16 = "Intersolve:Error_16",
  /**
   * Unable to cancel. Pos cannot cancel transaction.
   */
  Error_18 = "Intersolve:Error_18",
  /**
   * Transaction can not be cancelled at this moment, please try again later.
   */
  Error_19 = "Intersolve:Error_19",
  /**
   * Add card failed.
   */
  Error_20 = "Intersolve:Error_20",
  /**
   * Card already exists.
   */
  Error_21 = "Intersolve:Error_21",
  /**
   * Unknown brand type.
   */
  Error_22 = "Intersolve:Error_22",
  /**
   * Login failed: Device blocked due to license agreement violation.
   */
  Error_23 = "Intersolve:Error_23",
  /**
   * Login failed: Device usage exceeds license agreement.
   */
  Error_24 = "Intersolve:Error_24",
  /**
   * Post issue failed.
   */
  Error_100 = "Intersolve:Error_100",
  /**
   * Card or Customer does not exist.
   */
  Error_101 = "Intersolve:Error_101",
  /**
   * Card is expired.
   */
  Error_102 = "Intersolve:Error_102",
  /**
   * Card is blocked.
   */
  Error_103 = "Intersolve:Error_103",
  /**
   * Insufficient balance to perform transaction.
   */
  Error_104 = "Intersolve:Error_104",
  /**
   * Usr/pwd does not match with cardholder.
   */
  Error_105 = "Intersolve:Error_105",
  /**
   * Cardholder not registered.
   */
  Error_106 = "Intersolve:Error_106",
  /**
   * Invalid sequence number.
   */
  Error_107 = "Intersolve:Error_107",
  /**
   * Card not related to a Group.
   */
  Error_108 = "Intersolve:Error_108",
  /**
   * Invalid CardId. Invalid EAN.
   */
  Error_109 = "Intersolve:Error_109",
  /**
   * Invalid Email address.
   */
  Error_110 = "Intersolve:Error_110",
  /**
   * Invalid Country.
   */
  Error_111 = "Intersolve:Error_111",
  /**
   * Invalid ObjectId/ObjectType.
   */
  Error_112 = "Intersolve:Error_112",
  /**
   * Invalid CardCode in CustomerId.
   */
  Error_113 = "Intersolve:Error_113",
  /**
   * Card is already active.
   */
  Error_114 = "Intersolve:Error_114",
  /**
   * Invalid GroupId.
   */
  Error_115 = "Intersolve:Error_115",
  /**
   * Invalid Card Format.
   */
  Error_116 = "Intersolve:Error_116",
  /**
   * Invalid Scheme.
   */
  Error_117 = "Intersolve:Error_117",
  /**
   * Card is transferred.
   */
  Error_118 = "Intersolve:Error_118",
  /**
   * Card is not active / Inactive Brand Type.
   */
  Error_119 = "Intersolve:Error_119",
  /**
   * Incorrect activation or purchase value / Pre dominated rule violated / Minimum balance rule violated.
   */
  Error_120 = "Intersolve:Error_120",
  /**
   * Customer already exists.
   */
  Error_121 = "Intersolve:Error_121",
  /**
   * Card already exists.
   */
  Error_122 = "Intersolve:Error_122",
  /**
   * Customer does not exist.
   */
  Error_123 = "Intersolve:Error_123",
  /**
   * Maximum number of transactions per day reached.
   */
  Error_124 = "Intersolve:Error_124",
  /**
   * Maximum number of transactions per day per retailer reached.
   */
  Error_125 = "Intersolve:Error_125",
  /**
   * Maximum issue points per day is reached.
   */
  Error_126 = "Intersolve:Error_126",
  /**
   * Card not available. / Out of cards stock.
   */
  Error_127 = "Intersolve:Error_127",
  /**
   * Not allowed to redeem on date of first transaction (business rule 107 indicates that you are only allowed to perform a redeem one day after the first issue transaction).
   */
  Error_128 = "Intersolve:Error_128",
  /**
   * Number of points for issue or redeem transaction is too high (MaxPointsPerIssue or MaxPointsPerRedeem).
   */
  Error_129 = "Intersolve:Error_129",
  /**
   * The service is not available on the card.
   */
  Error_130 = "Intersolve:Error_130",
  /**
   * The service cannot be verified.
   */
  Error_131 = "Intersolve:Error_131",
  /**
   * Receipt not found.
   */
  Error_132 = "Intersolve:Error_132",
  /**
   * Receipt already claimed.
   */
  Error_133 = "Intersolve:Error_133",
  /**
   * Invalid block date.
   */
  Error_134 = "Intersolve:Error_134",
  /**
   * SchemeId already exists.
   */
  Error_135 = "Intersolve:Error_135",
  /**
   * Balance not available.
   */
  Error_136 = "Intersolve:Error_136",
  /**
   * Invalid Brand.
   */
  Error_140 = "Intersolve:Error_140",
  /**
   * Invalid BrandType.
   */
  Error_141 = "Intersolve:Error_141",
  /**
   * Maximum card balance is reached.
   */
  Error_161 = "Intersolve:Error_161",
  /**
   * Card is not reloadable. / Too many reload transactions. / Wrong balance on Reload.
   */
  Error_162 = "Intersolve:Error_162",
  /**
   * Card is already assigned to a customer.
   */
  Error_163 = "Intersolve:Error_163",
  /**
   * Wrong currency.
   */
  Error_164 = "Intersolve:Error_164",
  /**
   * Transaction would exceed configured limits.
   */
  Error_165 = "Intersolve:Error_165",
  /**
   * Login failed: Unknown user.
   */
  Error_201 = "Intersolve:Error_201",
  /**
   * Maximum simultaneous logins reached.
   */
  Error_202 = "Intersolve:Error_202",
  /**
   * User is already logged in.
   */
  Error_203 = "Intersolve:Error_203",
  /**
   * Login account is set inactive.
   */
  Error_204 = "Intersolve:Error_204",
  /**
   * Database is locked.
   */
  Error_205 = "Intersolve:Error_205",
  /**
   * Database is expired and now set to locked.
   */
  Error_206 = "Intersolve:Error_206",
  /**
   * Login failed: Invalid password.
   */
  Error_207 = "Intersolve:Error_207",
  /**
   * Invalid Username.
   */
  Error_208 = "Intersolve:Error_208",
  /**
   * Invalid SecretAnswer.
   */
  Error_209 = "Intersolve:Error_209",
  /**
   * Invalid SecretQuestion.
   */
  Error_210 = "Intersolve:Error_210",
  /**
   * Invalid PIN.
   */
  Error_211 = "Intersolve:Error_211",
  /**
   * Invalid EAN code.
   */
  Error_212 = "Intersolve:Error_212",
  /**
   * Invalid E-mail address.
   */
  Error_250 = "Intersolve:Error_250",
  /**
   * E-mail or customer information is missing.
   */
  Error_251 = "Intersolve:Error_251",
  /**
   * E-mail address already in use.
   */
  Error_252 = "Intersolve:Error_252",
  /**
   * Invalid ConfigurationId.
   */
  Error_300 = "Intersolve:Error_300",
  /**
   * Invalid VoucherId.
   */
  Error_301 = "Intersolve:Error_301",
  /**
   * Invalid IssuedVoucherId. The specified issued voucher does not exist or does not have the correct status.
   */
  Error_302 = "Intersolve:Error_302",
  /**
   * Voucher not found.
   */
  Error_303 = "Intersolve:Error_303",
  /**
   * Voucher not available.
   */
  Error_304 = "Intersolve:Error_304",
  /**
   * Invalid Voucher/Owner combination.
   */
  Error_305 = "Intersolve:Error_305",
  /**
   * Invalid MediaId/MediaType.
   */
  Error_306 = "Intersolve:Error_306",
  /**
   * Voucher has been redeemed already.
   */
  Error_307 = "Intersolve:Error_307",
  /**
   * Refund not supported for this voucher.
   */
  Error_308 = "Intersolve:Error_308",
  /**
   * Voucher redeem currently denied for this voucher.
   */
  Error_309 = "Intersolve:Error_309",
  /**
   * Invalid redeem currently denied for this voucher.
   */
  Error_310 = "Intersolve:Error_310",
  /**
   * Maximum number of voucher issues exceeded for this voucher.
   */
  Error_311 = "Intersolve:Error_311",
  /**
   * Invalid Brand Type ID. / Voucher has been purchased already.
   */
  Error_312 = "Intersolve:Error_312",
  /**
   * Voucher Reservation Expired.
   */
  Error_313 = "Intersolve:Error_313",
  /**
   * Invalid Delivery Method.
   */
  Error_314 = "Intersolve:Error_314",
  /**
   * CardId/InitialAccessCode mismatch.
   */
  Error_401 = "Intersolve:Error_401",
  /**
   * Scheme not found.
   */
  Error_402 = "Intersolve:Error_402",
  /**
   * Invalid RegisterCode.
   */
  Error_403 = "Intersolve:Error_403",
  /**
   * Card does not belong to the customer.
   */
  Error_404 = "Intersolve:Error_404",
  /**
   * The source and destination card must be specified.
   */
  Error_405 = "Intersolve:Error_405",
  /**
   * May not transfer to the same card.
   */
  Error_406 = "Intersolve:Error_406",
  /**
   * Transfer customer failed.
   */
  Error_407 = "Intersolve:Error_407",
  /**
   * Customer not found.
   */
  Error_408 = "Intersolve:Error_408",
  /**
   * Invalid CustomerId format.
   */
  Error_409 = "Intersolve:Error_409",
  /**
   * No active draw.
   */
  Error_500 = "Intersolve:Error_500",
  /**
   * Draw is closed. New sales starts tomorrow.
   */
  Error_501 = "Intersolve:Error_501",
  /**
   * No lottery tickets available.
   */
  Error_502 = "Intersolve:Error_502",
  /**
   * No active draw for this product.
   */
  Error_503 = "Intersolve:Error_503",
  /**
   * Sold out. Select a different day.
   */
  Error_504 = "Intersolve:Error_504",
  /**
   * Customer does not exist.
   */
  Error_550 = "Intersolve:Error_550",
  /**
   * Validation field 1 not valid.
   */
  Error_551 = "Intersolve:Error_551",
  /**
   * Validation field 2 not valid.
   */
  Error_552 = "Intersolve:Error_552",
  /**
   * Validation field 3 not valid.
   */
  Error_553 = "Intersolve:Error_553",
  /**
   * Delivery type is not supported.
   */
  Error_554 = "Intersolve:Error_554",
  /**
   * Card already has a PIN.
   */
  Error_555 = "Intersolve:Error_555",
  /**
   * Card does have a PIN.
   */
  Error_556 = "Intersolve:Error_556",
  /**
   * Invalid PIN.
   */
  Error_557 = "Intersolve:Error_557",
  /**
   * Could not encrypt PIN.
   */
  Error_558 = "Intersolve:Error_558",
  /**
   * Payment Ok.
   */
  Error_700 = "Intersolve:Error_700",
  /**
   * Payment failed.
   */
  Error_701 = "Intersolve:Error_701",
  /**
   * Payment declined.
   */
  Error_702 = "Intersolve:Error_702",
  /**
   * Payment pending.
   */
  Error_703 = "Intersolve:Error_703",
  /**
   * Payment revoked.
   */
  Error_704 = "Intersolve:Error_704",
  /**
   * Transaction not found.
   */
  Error_705 = "Intersolve:Error_705",
  /**
   * Payment needs to be cancelled.
   */
  Error_706 = "Intersolve:Error_706",
  /**
   * Special fixed value activation already done.
   */
  Error_914 = "Intersolve:Error_914",
  /**
   * Special fixed value activation.
   */
  Error_919 = "Intersolve:Error_919",
  /**
   * No bueno.
   */
  NoBueno = "Intersolve:NoBueno",
}
export enum Adyen {
  /**
   * Received Adyen error: {0:string}
   */
  Error = "Adyen:Error",
  /**
   * Failed to create payment: {0:string}
   */
  CreatePaymentFailed = "Adyen:CreatePaymentFailed",
  /**
   * Failed to create refund: {0:string}
   */
  RefundFailed = "Adyen:RefundFailed",
  /**
   * Failed to create capture: {errorCode:string} / {errorMessage:string}
   */
  CaptureFailed = "Adyen:CaptureFailed",
}
export enum AdyenStoredValue {
  /**
   * A CardNumber is required to activate a giftcard
   */
  MissingCardNumber = "AdyenStoredValue:MissingCardNumber",
  /**
   * Invalid gift card - card not found
   */
  GiftCardNotFound = "AdyenStoredValue:GiftCardNotFound",
  /**
   * Invalid gift card - product not found
   */
  MissingProduct = "AdyenStoredValue:MissingProduct",
  /**
   * Invalid gift card - missing PIN/SSC
   */
  MissingPinSsc = "AdyenStoredValue:MissingPinSsc",
  /**
   * A CardNumber should have a length of {validLength:number} or {validLength2:number}, current length is {invalidLength:number}
   */
  InvalidCardNumberLength = "AdyenStoredValue:InvalidCardNumberLength",
}
export enum AdyenChannel {
  Web = 1,
  iOS = 2,
  Android = 3,
}
export enum LiveGuard {
  /**
   * You're not allowed to generate this liveguard api key.
   */
  NotAllowedToGenerateApiKey = "LiveGuard:NotAllowedToGenerateApiKey",
  /**
   * You're not allowed to generate this liveguard secret.
   */
  NotAllowedToGenerateSecret = "LiveGuard:NotAllowedToGenerateSecret",
  /**
   * This is a stub order for offline evapay payments, no mutations allowed.
   */
  UnableToMutateEVAPayStub = "LiveGuard:UnableToMutateEVAPayStub",
}
export enum LiveGuardCertificate {
  /**
   * The configuration is invalid.
   */
  InvalidConfiguration = "LiveGuardCertificate:InvalidConfiguration",
  /**
   * No valid Watchtower for this request.
   */
  NoValidWatchtower = "LiveGuardCertificate:NoValidWatchtower",
  /**
   * CSR could not be parsed.
   */
  CsrInvalid = "LiveGuardCertificate:CsrInvalid",
  /**
   * Failed to generate the certificate.
   */
  CertificateGenerationFailed = "LiveGuardCertificate:CertificateGenerationFailed",
  /**
   * Hostname for this Watchtower does not match the hostname in the CSR.
   */
  HostnameMismatch = "LiveGuardCertificate:HostnameMismatch",
}
export enum LiveGuardExecuteTask {
  /**
   * There is no current connection to the selected device.
   */
  NoConnection = "LiveGuardExecuteTask:NoConnection",
}
export enum LiveGuardLockStatus {
  /**
   * This watchtower is not connected.
   */
  NotConnected = "LiveGuardLockStatus:NotConnected",
  /**
   * Failed to lock the device.
   */
  LockFailed = "LiveGuardLockStatus:LockFailed",
  /**
   * Failed to unlock the device.
   */
  UnlockFailed = "LiveGuardLockStatus:UnlockFailed",
}
export enum WatchtowerExecuteCommand {
  /**
   * There is no current connection to the selected device.
   */
  NoConnection = "WatchtowerExecuteCommand:NoConnection",
}
export enum WatchtowerIO {
  /**
   * Failed to connect to the device.
   */
  ConnectionFailed = "WatchtowerIO:ConnectionFailed",
}
export enum MenderClient {
  /**
   * No registration request found for this device. (Please reboot the device and try again)
   */
  NoRegistrationRequest = "MenderClient:NoRegistrationRequest",
}
export enum LocalModeManager {
  /**
   * You can only force local mode on shops.
   */
  CanForceLocalModeOnlyOnShop = "LocalModeManager:CanForceLocalModeOnlyOnShop",
}
export enum FleetManagerErrors {
  /**
   * Device with HardwareID {hardwareID:string} already exists with ID {deviceID:number}.
   */
  DeviceAlreadyExists = "FleetManagerErrors:DeviceAlreadyExists",
  /**
   * OrganizationUnit {organizationUnitID:number} already has a watchtower with ID {deviceID:number}.
   */
  DuplicateOrganizationUnit = "FleetManagerErrors:DuplicateOrganizationUnit",
}
export enum FleetManager {
  /**
   * Could not determine a matching FleetManager.
   */
  UnableToDetermineFleetManager = "FleetManager:UnableToDetermineFleetManager",
  /**
   * The FleetManager {0:string} is not available.
   */
  FleetManagerNotAvailable = "FleetManager:FleetManagerNotAvailable",
  /**
   * Device with HardwareID {hardwareID:string} was not found in FleetManager {fleetManager:string}.
   */
  DeviceNotInFleetManager = "FleetManager:DeviceNotInFleetManager",
  /**
   * Device is not valid for this FleetManager.
   */
  InvalidDevice = "FleetManager:InvalidDevice",
  /**
   * Device was already boarded.
   */
  DeviceAlreadyBoarded = "FleetManager:DeviceAlreadyBoarded",
  /**
   * Error while boarding the device: {0:string}
   */
  BoardingFailed = "FleetManager:BoardingFailed",
}
export enum FleetManagerDeviceStatus {
  Accepted = 1,
  Pending = 2,
  Rejected = 3,
  Preauthorized = 4,
  Noauth = 5,
}
export enum PrivacyRemovalRequest {
  /**
   * Privacy removal already requested.
   */
  AlreadyRequested = "PrivacyRemovalRequest:AlreadyRequested",
  /**
   * Cannot request privacy removal for this user type.
   */
  InvalidUserType = "PrivacyRemovalRequest:InvalidUserType",
  /**
   * Can no longer request cancellation of this privacy removal request.
   */
  CannotBeCancelled = "PrivacyRemovalRequest:CannotBeCancelled",
  /**
   * Only the user themselves can cancel the privacy removal request.
   */
  SelfCancelOnly = "PrivacyRemovalRequest:SelfCancelOnly",
  /**
   * The maximum time the processing of a privacy removal request can be delayed is 24 hours.
   */
  InvalidDelay = "PrivacyRemovalRequest:InvalidDelay",
}
export enum RemovalRequestProcessor {
  /**
   * Some invoices are missing for removal request {0:number}, requesting generation.
   */
  InvoicesMissingForRemovalRequest = "RemovalRequestProcessor:InvoicesMissingForRemovalRequest",
}
export enum DataRequestStatus {
  Requested = 0,
  Processing = 1,
  Ready = 2,
  Expired = 3,
}
export enum RemovalRequestStatus {
  Requested = 0,
  Processing = 1,
  Done = 2,
  Failed = 3,
  Cancelled = 4,
}
export enum Workspaces {
  /**
   * You are not authorized to view this workspace.
   */
  UnauthorizedToViewWorkspace = "Workspaces:UnauthorizedToViewWorkspace",
  /**
   * Workspace is owned by other user.
   */
  WorkspaceOwnedByOtherUser = "Workspaces:WorkspaceOwnedByOtherUser",
  /**
   * The workspace is private.
   */
  WorkspaceIsPrivate = "Workspaces:WorkspaceIsPrivate",
  /**
   * The workspace is shared.
   */
  WorkspaceIsShared = "Workspaces:WorkspaceIsShared",
  /**
   * Cannot set a workspace without key to be the active one.
   */
  CannotSetWorkspaceWithoutKeyToActive = "Workspaces:CannotSetWorkspaceWithoutKeyToActive",
  /**
   * Cannot remove a shared workspace as it still has Active members.
   */
  CannotRemoveSharedWorkspace = "Workspaces:CannotRemoveSharedWorkspace",
  /**
   * Cannot remove an active workspace as it's still active
   */
  CannotRemoveActiveWorkspace = "Workspaces:CannotRemoveActiveWorkspace",
  /**
   * Workspaces can't have duplicate names
   */
  DuplicateNames = "Workspaces:DuplicateNames",
  /**
   * RoleIds: {0:string} are not connected to the provided workspace.
   */
  RoleDoesNotHaveSharedWorkspace = "Workspaces:RoleDoesNotHaveSharedWorkspace",
}
export enum ReplenishmentProposals {
  /**
   * This proposal is already running and cannot be retried at this moment.
   */
  AlreadyRunning = "ReplenishmentProposals:AlreadyRunning",
}
export enum DownloadReplenishmentProductsExcel {
  /**
   * You can't download more than 1000000 records through this service.
   */
  TooManyRecords = "DownloadReplenishmentProductsExcel:TooManyRecords",
  /**
   * Replenishment product with ID {0:string} does not exist.
   */
  UnknownReplenishmentProduct = "DownloadReplenishmentProductsExcel:UnknownReplenishmentProduct",
  /**
   * Missing product identifier for row {0:number}.
   */
  MissingProductIdentifier = "DownloadReplenishmentProductsExcel:MissingProductIdentifier",
}
export enum ReplenishmentProducts {
  /**
   * There was a duplicate record for OrganizationUnit/Product/StockLabel {0:string}/{1:string}/{2:string} within one time frame.
   */
  DuplicateRecord = "ReplenishmentProducts:DuplicateRecord",
  /**
   * The MaximumQuantity {0:string} must be greater than or equal to the MinimumQuantity {1:string}.
   */
  InvalidQuantityRange = "ReplenishmentProducts:InvalidQuantityRange",
}
export enum ReplenishmentOutputTypes {
  None = 0,
  AutoOrder = 1,
  AutoOrderAutoConfirm = 2,
  Debug = 4,
  Info = 8,
  Order = 16,
}
export enum ReplenishmentProposalStatus {
  New = 0,
  Processing = 1,
  Processed = 2,
  Error = 9,
}
export enum Monitors {
  /**
   * You are not allowed to edit this monitor.
   */
  NotAllowedToEditMonitor = "Monitors:NotAllowedToEditMonitor",
  /**
   * This handler does not support conditions.
   */
  HandlerDoesNotSupportConditions = "Monitors:HandlerDoesNotSupportConditions",
  /**
   * The handler with name {0:string} does not exist
   */
  InvalidHandler = "Monitors:InvalidHandler",
  /**
   * An unknown error occurred while executing this monitor
   */
  UnknownError = "Monitors:UnknownError",
}
export enum MonitorLevel {
  Info = 0,
  Warning = 1,
  Error = 2,
  Fatal = 3,
}
export enum CreateMonitorRoleOwnerResult {
  Created = 0,
  Updated = 1,
}
export enum GlobalBlue {
  /**
   * Order {0:number} has no valid EligibilityCheck yet.
   */
  NoValidEligibilityCheckDone = "GlobalBlue:NoValidEligibilityCheckDone",
  /**
   * Order {0:number} is already issued with GlobalBlue.
   */
  OrderAlreadyIssued = "GlobalBlue:OrderAlreadyIssued",
  /**
   * Order {0:number} is not invoiced yet, TaxFreeForm unavailable.
   */
  OrderNotInvoiced = "GlobalBlue:OrderNotInvoiced",
  /**
   * Order {0:number} has no TaxFreeForm yet, request it first before printing.
   */
  NoTaxFreeFormAvailable = "GlobalBlue:NoTaxFreeFormAvailable",
  /**
   * The supplier identifier is not valid, it should start with 308604.
   */
  InvalidGlobalBlueIdentifier = "GlobalBlue:InvalidGlobalBlueIdentifier",
  /**
   * Global Blue is not enabled for the current organizationunit.
   */
  Disabled = "GlobalBlue:Disabled",
}
export enum Waldo {
  /**
   * Not valid for processing because: {0:string}
   */
  InvalidForProcessing = "Waldo:InvalidForProcessing",
}
export enum OrderDefinition {
  /**
   * Invalid script language: {0:string}, expected one of: {1:string}
   */
  InvalidScriptLanguage = "OrderDefinition:InvalidScriptLanguage",
}
export enum LoyaltyPayment {
  /**
   * A Customer is required to do a Loyalty payment
   */
  CustomerRequired = "LoyaltyPayment:CustomerRequired",
  /**
   * Invalid loyalty program selected
   */
  InvalidLoyaltyProgram = "LoyaltyPayment:InvalidLoyaltyProgram",
  /**
   * Failed to execute the LoyaltyPayment in the handler
   */
  PaymentFailed = "LoyaltyPayment:PaymentFailed",
}
export enum LoyaltyProgramPayment {
  /**
   * Cannot use given payment type: {0:string}
   */
  CannotUseGivenPaymentType = "LoyaltyProgramPayment:CannotUseGivenPaymentType",
  /**
   * Can only pay for fixed amount
   */
  CanOnlyPayForFixedAmount = "LoyaltyProgramPayment:CanOnlyPayForFixedAmount",
  /**
   * Amount exceeds maximum allowed amount
   */
  AmountExceedsMaximumAllowedAmount = "LoyaltyProgramPayment:AmountExceedsMaximumAllowedAmount",
  /**
   * Can only refund full amount
   */
  CannotRefundMoreThenOriginalAmount = "LoyaltyProgramPayment:CannotRefundMoreThenOriginalAmount",
  /**
   * Payment requires preceding payment to be executed
   */
  PaymentRequiresPrecedingPaymentToBeExecuted = "LoyaltyProgramPayment:PaymentRequiresPrecedingPaymentToBeExecuted",
}
export enum APIGiftCard {
  /**
   * Redemption of card {0:string} failed
   */
  RedemptionFailed = "APIGiftCard:RedemptionFailed",
  /**
   * A CardNumber is required to activate a giftcard
   */
  MissingCardNumber = "APIGiftCard:MissingCardNumber",
  /**
   * Card with number {0:string} was not found.
   */
  CardNotFound = "APIGiftCard:CardNotFound",
  /**
   * The card has currency {cardCurrency:string}, but the requested currency is {transactionCurrency:string}.
   */
  CurrencyMismatch = "APIGiftCard:CurrencyMismatch",
}
export enum EVAPay {
  /**
   * The given data if for a different Order.
   */
  InvalidOrder = "EVAPay:InvalidOrder",
  /**
   * The given token is not valid.
   */
  InvalidToken = "EVAPay:InvalidToken",
  /**
   * Customer is required.
   */
  CustomerRequired = "EVAPay:CustomerRequired",
  /**
   * Invalid amount.
   */
  InvalidAmount = "EVAPay:InvalidAmount",
  /**
   * EmailAddress is required.
   */
  EmailAddressRequired = "EVAPay:EmailAddressRequired",
  /**
   * BaseUrl is required and should either be configured (Setting) or supplied as parameter.
   */
  BaseUrlRequired = "EVAPay:BaseUrlRequired",
  /**
   * No PIN configured.
   */
  NoPinConfigured = "EVAPay:NoPinConfigured",
  /**
   * Service only supports calls from users authenticated with an EVAPay token.
   */
  InvalidAuthenticationToken = "EVAPay:InvalidAuthenticationToken",
}
export enum CashPayment {
  /**
   * There is no open financial period for the current organizationunit.
   */
  NoOpenFinancePeriod = "CashPayment:NoOpenFinancePeriod",
  /**
   * Invalid amounts provided for a CashPayment.
   */
  InvalidAmount = "CashPayment:InvalidAmount",
  /**
   * The amount given can never be equal to or less than 0.
   */
  InvalidAmountGiven = "CashPayment:InvalidAmountGiven",
  /**
   * The amount to refund can never be equal to or less than 0.
   */
  InvalidRefundAmount = "CashPayment:InvalidRefundAmount",
  /**
   * Amount to be paid can't be greater than the open amount on the order.
   */
  OverPaymentNotAllowed = "CashPayment:OverPaymentNotAllowed",
  /**
   * Cash payment limit has been reached for this currency type.
   */
  PaidAmountAboveCashHandlerLimit = "CashPayment:PaidAmountAboveCashHandlerLimit",
  /**
   * Cash payment limit has been reached.
   */
  PaidAmountAboveCashLimit = "CashPayment:PaidAmountAboveCashLimit",
}
export enum CashDrawer {
  /**
   * Opening cash drawers requires Remark field to be set.
   */
  OpeningCashDrawerRequiresRemark = "CashDrawer:OpeningCashDrawerRequiresRemark",
  /**
   * Opening a cash drawer with a PaymentTransaction requires a recent, confirmed cash payment.
   */
  InvalidPaymentTransactionProvided = "CashDrawer:InvalidPaymentTransactionProvided",
}
export enum BankTransfer {
  /**
   * AccountHolderName is required.
   */
  AccountHolderNameRequired = "BankTransfer:AccountHolderNameRequired",
  /**
   * IBAN is required.
   */
  IBANRequired = "BankTransfer:IBANRequired",
  /**
   * IBAN is not valid: {0:string}
   */
  IBANInvalid = "BankTransfer:IBANInvalid",
}
export enum PinTransactionResultStatus {
  None = 0,
  TimeoutFromPinDevice = 1,
  TransactionStillInProgressForPayment = 2,
  Success = 3,
  Aborted = 4,
  Failure = 5,
  DeviceUnavailable = 6,
  PrintLastTicket = 7,
  TimedOut = 8,
  TransactionAlreadyInProgressForUser = 9,
  UserMustScanStationToAbort = 10,
  PaymentValidationError = 11,
  InProgress = 12,
  ClientFailure = 13,
  SelectPaymentMethod = 14,
  MustRevalidate = 16,
  ConnectToTerminal = 17,
}
export enum ValidationError {
  /**
   * ShopID exceeds maximum length of 4 characters, it was: {0:string}.
   */
  ShopNumberExceedsMaximumLength = "ValidationError:ShopNumberExceedsMaximumLength",
  /**
   * TenantCode exceeds maximum length of 5 characters, it was: {0:string}.
   */
  TenantCodeExceedsMaximumLength = "ValidationError:TenantCodeExceedsMaximumLength",
  /**
   * Register (counter) number exceeds maximum length of 3 characters, it was: {0:string}.
   */
  RegisterNumberExceedsMaximumLength = "ValidationError:RegisterNumberExceedsMaximumLength",
  /**
   * The Infrasec RegisterID / FiscalSystemID of the station cannot be null or whitespace.
   */
  InfrasecRegisterIDNullOrWhitespace = "ValidationError:InfrasecRegisterIDNullOrWhitespace",
  /**
   * The Infrasec Organization Number (taken from the Company RegistrationNumber) must be exactly 10 characters, but it was {0:string}.
   */
  CompanyRegistrationNumberNotWithinRange = "ValidationError:CompanyRegistrationNumberNotWithinRange",
  /**
   * {0:string}
   */
  ExceptionInNTHRelatedFlow = "ValidationError:ExceptionInNTHRelatedFlow",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * An exception occured in a flow involving the RT Server!
   * The RT Server might not be the culprit, see the full error message for more details:
   * {0:string}
   */
  ExceptionInRTServerRelatedFlow = "ValidationError:ExceptionInRTServerRelatedFlow",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * The station (till) fiscal system ID must be exactly 8 characters, but it was: {0:string}
   */
  StationFiscalSystemIdOutOfRangeError = "ValidationError:StationFiscalSystemIdOutOfRangeError",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * RT Server did not return a valid content:
   * {0:string}
   */
  RtServerInvalidResponseContent = "ValidationError:RtServerInvalidResponseContent",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * More than one station (till) with this {0:string} FiscalSystemID found in EVA! The FiscalSystemID of the newly created station will be set to null and the station cannot be used further for operations.
   */
  StationWithThisFiscalSystemIdAlreadyCreatedInEva = "ValidationError:StationWithThisFiscalSystemIdAlreadyCreatedInEva",
}
export enum InfrasecErrors {
  /**
   * The station with ID '{0:number}' is not a point of sale
   */
  StationNotPointOfSale = "InfrasecErrors:StationNotPointOfSale",
  /**
   * The FiscalID/RegisterID for station with ID '{0:number}' is null or whitespace.
   */
  StationFiscalIDNotSet = "InfrasecErrors:StationFiscalIDNotSet",
  /**
   * The FiscalID/RegisterID for station with ID '{0:number}' cannot be modified (Infrasec does not support changing the Register Number of a station.
   */
  StationFiscalIDCanNeverBeChanged = "InfrasecErrors:StationFiscalIDCanNeverBeChanged",
  /**
   * The Register (Station) with this ID: {0:string} was already enrolled. The same combination of properties [Counter Number, StoreID (ShopNumber), Chain Code (TenantCode), POS Authority Code, Authority Code] was supplied before to build this RegisterID. Note that the 'Chain' (ChainName) does not matter, only the combination of the properties above
   */
  RegisterWithThisIDAlreadyEnrolled = "InfrasecErrors:RegisterWithThisIDAlreadyEnrolled",
  /**
   * {0:string}
   */
  EnrollmentFailed = "InfrasecErrors:EnrollmentFailed",
  /**
   * {0:string}
   */
  ReceiptError = "InfrasecErrors:ReceiptError",
  /**
   * Auth Error Code 189. RequestID: {0:string}. Check the Organization Number (Organization RegistrationNumber) and the RegisterID from the request, the station from where the order was paid must belong to thesame OU.
   */
  ReceiptAuthError = "InfrasecErrors:ReceiptAuthError",
  /**
   * The Infrasec API is offline, should proceed with Offline Cash invoice fallback flow{0:string}
   */
  InfrasecCCUApiOffline = "InfrasecErrors:InfrasecCCUApiOffline",
}
export enum FiskalySignEsErrors {
  /**
   * Fiskaly is unresponsive
   */
  FiskalyUnresponsive = "FiskalySignEsErrors:FiskalyUnresponsive",
}
export enum FinancialPeriodAudit {
  /**
   * The 'From' {0:string} is greater than the 'To' {1:string} date
   */
  FromGreaterThanTo = "FinancialPeriodAudit:FromGreaterThanTo",
  /**
   * The maximum period is {0:number} days
   */
  PeriodExceeded = "FinancialPeriodAudit:PeriodExceeded",
  /**
   * Got a 'From' date but missing the 'To' date
   */
  MissingToDate = "FinancialPeriodAudit:MissingToDate",
  /**
   * 'From' is in the future
   */
  DateIsInTheFuture = "FinancialPeriodAudit:DateIsInTheFuture",
}
export enum GenerateFinancialPeriodAuditExportService {
  /**
   * The specified audit file type is not handled.
   */
  AuditTypeCannotBeProcessed = "GenerateFinancialPeriodAuditExportService:AuditTypeCannotBeProcessed",
}
export enum ReturnReport {
  /**
   * The report is empty.
   */
  InvalidReport = "Auditing:ReturnReport:InvalidReport",
}
export enum FiscalPrinter {
  /**
   * Invalid value for cashier code '{0:string}'
   */
  InvalidCashierCode = "Auditing:Romania:FiscalPrinter:InvalidCashierCode",
  /**
   * The device '{0:number}' must have the OrganizationUnitID set
   */
  MissingDeviceOrganizationUnit = "Auditing:Romania:FiscalPrinter:MissingDeviceOrganizationUnit",
  /**
   * Cannot find organization unit with ID {0:number}
   */
  MissingOrganizationUnit = "Auditing:Romania:FiscalPrinter:MissingOrganizationUnit",
  /**
   * Cannot find country ID for organization unit with ID {0:number}
   */
  MissingCountryID = "Auditing:Romania:FiscalPrinter:MissingCountryID",
  /**
   * Sale receipts require a fiscal receipt number
   */
  MissingFiscalReceipt = "Auditing:Romania:FiscalPrinter:MissingFiscalReceipt",
  /**
   * Missing tax information, line '{0:string}'
   */
  MissingTaxInfo = "Auditing:Romania:FiscalPrinter:MissingTaxInfo",
  /**
   * Incorrect VAT rate on the product, please check the configuration of the tax rates. Invalid value for category '{0:string}'
   */
  InvalidTaxCategory = "Auditing:Romania:FiscalPrinter:InvalidTaxCategory",
  /**
   * Command '{0:string}', execution failure, error: {1:string}
   */
  FiscalPrinterExecutionError = "Auditing:Romania:FiscalPrinter:FiscalPrinterExecutionError",
  /**
   * Did not expect to print an invoice {0:number} with a fiscal receipt number set {1:string}
   */
  FiscalReceiptNumberIndicatesReprint = "Auditing:Romania:FiscalPrinter:FiscalReceiptNumberIndicatesReprint",
}
export enum SDI {
  /**
   * Invoice {0:number} does not have a customer
   */
  MissingCustomer = "Auditing:Italy:SDI:MissingCustomer",
  /**
   * Invoice {0:number} does not have a billing address
   */
  MissingBillingAddress = "Auditing:Italy:SDI:MissingBillingAddress",
  /**
   * OrganizationUnit {0:number} for Invoice {1:number} does not have a Company attached to it
   */
  MissingParentCompany = "Auditing:Italy:SDI:MissingParentCompany",
  /**
   * Missing 'codice fiscale' to export invoice {0:number} to the SDI
   */
  MissingFiscalID = "Auditing:Italy:SDI:MissingFiscalID",
  /**
   * OrganizationUnit {0:number} has not SDI url configured
   */
  MissingSDIServiceUrl = "Auditing:Italy:SDI:MissingSDIServiceUrl",
  /**
   * OrganizationUnit {0:number} is missing the SDI transmitter country code
   */
  MissingSDITransmitterCountryCode = "Auditing:Italy:SDI:MissingSDITransmitterCountryCode",
  /**
   * OrganizationUnit {0:number} is missing the SDI transmitter VAT number
   */
  MissingSDITransmitterVatNumber = "Auditing:Italy:SDI:MissingSDITransmitterVatNumber",
}
export enum ReprocessFinancialPeriodAuditService {
  /**
   * The specified audit cannot be reprocessed.
   */
  AuditCannotBeReprocessed = "ReprocessFinancialPeriodAuditService:AuditCannotBeReprocessed",
}
export enum SAFT {
  /**
   * InvalidClientConfiguration
   */
  Series = "SAFT:Series",
  /**
   * Failed to list series (one or more settings are invalid)
   */
  InvalidConfiguration = "SAFT:InvalidConfiguration",
}
export enum InvoiceValidationError {
  /**
   * {0:string}
   */
  KSeFRejectedTheInvoice = "InvoiceValidationError:KSeFRejectedTheInvoice",
  /**
   * {0:string}
   */
  KSeFRejectedInvoice = "InvoiceValidationError:KSeFRejectedInvoice",
  /**
   * Validation of the EVA created KSeF Invoice XML against the XSD Schema failed! At least one validation error was encountered:
   * {0:string}
   */
  InvoiceXsdValidationError = "InvoiceValidationError:InvoiceXsdValidationError",
}
export enum PolandFiscalPosPrinter {
  /**
   * Station required on the fiscal printer device
   */
  StationNotSetOnDevice = "PolandFiscalPosPrinter:StationNotSetOnDevice",
  /**
   * Fiscal system ID required on the station
   */
  NoFiscalSystemID = "PolandFiscalPosPrinter:NoFiscalSystemID",
}
export enum SDiCoopInvoiceMiddlewareErrors {
  /**
   * The request message was empty
   */
  EmptyRequestMessage = "SDiCoopInvoiceMiddlewareErrors:EmptyRequestMessage",
  /**
   * The request message did not contain a body.
   */
  EmptyRequestMessageBody = "SDiCoopInvoiceMiddlewareErrors:EmptyRequestMessageBody",
  /**
   * The message body type '{0:string}' is unknown.
   */
  UnknownRequestMessageBodyType = "SDiCoopInvoiceMiddlewareErrors:UnknownRequestMessageBodyType",
  /**
   * The SDI ID '{0:string}' is unknown.
   */
  UnknownInvoiceIdentificationNumber = "SDiCoopInvoiceMiddlewareErrors:UnknownInvoiceIdentificationNumber",
}
export enum RtServerResponse {
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * RT Server did not return a successful response:
   * {0:string}
   */
  ResponseError = "RtServerResponse:ResponseError",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * Received 'Server Closure To Do' response from RT Server. This should indicate that there is at least one station which is open. All stations have to be closed if you want to remove a station from the RT Server.
   * {0:string}
   */
  ServerClosureToDo = "RtServerResponse:ServerClosureToDo",
}
export enum ReceiptError {
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * No matching RT Server Vat Rate found for this Product. Check the Tax Rates from the Organization Unit that the product belongs to. Details: {0:string}
   */
  RtServerVatRateMismatch = "ReceiptError:RtServerVatRateMismatch",
}
export enum ItalyIntegrationErrors {
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * The station (till) fiscal system ID must be exactly 8 characters, but it was null. Station details: {0:string}
   */
  FiscalSystemIDCannotBeNull = "ItalyIntegrationErrors:FiscalSystemIDCannotBeNull",
}
export enum LotteryNumber {
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * Expected lottery number to be exactly 8 characters, but was: {0:string}
   */
  InvalidLotteryNumberProvided = "LotteryNumber:InvalidLotteryNumberProvided",
}
export enum LotteryErrors {
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * Expected lottery number to be exactly 8 characters, but was: {0:string}
   */
  InvalidLotteryNumberProvided = "LotteryErrors:InvalidLotteryNumberProvided",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * {0:string}
   */
  InvalidInstantLotteryComputedVerificationCode = "LotteryErrors:InvalidInstantLotteryComputedVerificationCode",
  /**
   * EXCEPTION_IN_RT_RELATED_FLOW
   * {0:string}
   */
  RtServerNotInActiveState = "LotteryErrors:RtServerNotInActiveState",
}
export enum GermanyIntegrationErrors {
  /**
   * The station fiscal system ID cannot be null. Station details: {0:string}
   */
  FiscalSystemIDCannotBeNull = "GermanyIntegrationErrors:FiscalSystemIDCannotBeNull",
  /**
   * The station fiscal system ID cannot be changed. Station details: {0:string}
   */
  FiscalSystemIDOfAStationCannotBeChanged = "GermanyIntegrationErrors:FiscalSystemIDOfAStationCannotBeChanged",
}
export enum FiscalPrinting {
  /**
   * Printing {typeOfReport:string} failed on station {stationID:number}
   */
  FailureToPrintPeriodicReport = "FiscalPrinting:FailureToPrintPeriodicReport",
}
export enum AustriaFiskalyErrors {
  /**
   * Fiskaly is unresponsive
   */
  FiskalyUnresponsive = "AustriaFiskalyErrors:FiskalyUnresponsive",
}
export enum DataLakeBackfill {
  /**
   * DataLake export is disabled.
   */
  DataLakeExportDisabled = "DataLakeBackfill:DataLakeExportDisabled",
  /**
   * Provide either an id, a list of ids or a from/to range.
   */
  BatchInformationRequired = "DataLakeBackfill:BatchInformationRequired",
  /**
   * Failed to start DataLake backfill process.
   */
  DataLakeBackfillProcessingFailed = "DataLakeBackfill:DataLakeBackfillProcessingFailed",
  /**
   * You requested {requested:number} documents, but your max was set to {limit:number}.
   */
  RequestedQuantityExceedsLimit = "DataLakeBackfill:RequestedQuantityExceedsLimit",
  /**
   * UserBatches backfill only allows from/to range.
   */
  UserBatchesBackfillOnlyAllowsFromTo = "DataLakeBackfill:UserBatchesBackfillOnlyAllowsFromTo",
  /**
   * Backfill of type '{0:DataLakeBackfillType}' only accepts IDs.
   */
  BackfillTypeOnlyAcceptsIDs = "DataLakeBackfill:BackfillTypeOnlyAcceptsIDs",
}
export enum DataLakeBackfillType {
  FinancialPeriods = 1,
  Orders = 2,
  Invoices = 3,
  Users = 4,
  Discounts = 5,
  UserBatches = 6,
  Cases = 7,
  Shipments = 8,
  ZonedCycleCounts = 9,
  UserTasks = 10,
  StockMutations = 11,
  UserLoyaltyPointMutations = 12,
  LoyaltyProgramGroups = 13,
  LoyaltyPrograms = 14,
}

export enum Assortment {
  /**
   * Assortment is missing content culture.
   */
  MissingContentCulture = "Assortment:MissingContentCulture",
}
export enum CustomContent {
  /**
   * Error converting property {0:string}: {1:string}
   */
  DataTypeError = "CustomContent:DataTypeError",
}
export enum ImportProducts {
  /**
   * Product {productID:string} uses unknown TaxCode {taxCode:string}
   */
  UnknownTaxCode = "ImportProducts:UnknownTaxCode",
  /**
   * The provided request did not pass validation. Failures: {0:string}
   */
  ValidationFailures = "ImportProducts:ValidationFailures",
  /**
   * The assortment '{0:string}' is not known.
   */
  UnknownAssortment = "ImportProducts:UnknownAssortment",
}
export enum ProductCapacityPlans {
  /**
   * Either a ProductSearchTemplateID or ProductSearchFilters are required for a dynamic ProductCapacityPlan.
   */
  FiltersAreRequiredForADynamicCapacityPlan = "ProductCapacityPlans:FiltersAreRequiredForADynamicCapacityPlan",
  /**
   * Cannot add or remove products on a dynamic ProductCapacityPlan.
   */
  CannotAddOrRemoveProductsOnADynamicCapacityPlan = "ProductCapacityPlans:CannotAddOrRemoveProductsOnADynamicCapacityPlan",
  /**
   * Products already linked to a static ProductCapacityPlan.
   */
  ProductsAlreadyHaveAStaticProductCapacityPlan = "ProductCapacityPlans:ProductsAlreadyHaveAStaticProductCapacityPlan",
  /**
   * Cannot convert ProductCapacityPlan from static to dynamic.
   */
  CannotConvertProductCapacityPlanFromStaticToDynamic = "ProductCapacityPlans:CannotConvertProductCapacityPlanFromStaticToDynamic",
  /**
   * Exception requires `DayOfWeek` or `Date` to be given.
   */
  ExceptionRequiresDayOfWeekOrDate = "ProductCapacityPlans:ExceptionRequiresDayOfWeekOrDate",
}
export enum ProductExcelServices {
  /**
   * Cannot find header row
   */
  MissingHeaderRow = "ProductExcelServices:MissingHeaderRow",
  /**
   * Unit Of Measure {0:string} does not exist
   */
  UnitOfMeasureNotFound = "ProductExcelServices:UnitOfMeasureNotFound",
  /**
   * Product with CustomID {0:string} cannot be found
   */
  ProductNotFound = "ProductExcelServices:ProductNotFound",
  /**
   * Invalid quantity {quantity:number} for Unit of Measure {unitOfMeasure:string} for product {productCustomID:string}
   */
  InvalidUnitOfMeasureQuantity = "ProductExcelServices:InvalidUnitOfMeasureQuantity",
  /**
   * Duplicate barcode {0:string} found
   */
  DuplicateBarcode = "ProductExcelServices:DuplicateBarcode",
}
export enum ProductSearchSynonyms {
  /**
   * The line {0:string} is not a valid synonym rule.
   */
  InvalidRule = "ProductSearchSynonyms:InvalidRule",
}
export enum ProductContent {
  /**
   * Property {0:string} has not been defined, a property must be defined before the content for that property can be stored.
   */
  UnknownProductPropertyType = "ProductContent:UnknownProductPropertyType",
}
export enum ProductPropertyType {
  /**
   * The value {0:string} cannot be converted to a data type of {1:ProductPropertyTypeDataTypes}
   */
  CannotConvertValueToDataType = "ProductPropertyType:CannotConvertValueToDataType",
}
export enum ProductCompositionProgressState {
  None = 0,
  Composing = 1,
  PostProcessing = 2,
  Publishing = 3,
  Complete = 4,
}
export enum RevisionStatus {
  Open = 1,
  Applied = 2,
  Inactive = 4,
}
export enum ProductPropertyTypeSearchTypes {
  None = 0,
  Keyword = 1,
  Text = 2,
  IsHidden = 4,
}
export enum ProductPropertyTypeDataTypes {
  None = 0,
  Integer = 1,
  Double = 2,
  Boolean = 3,
  String = 4,
  Blob = 5,
  Date = 6,
  RichText = 7,
}
export enum ProductPropertyTypeIntents {
  RichAttribute = 0,
  SimpleField = 1,
  RootLevelOnly = 2,
}
export enum ProductPropertyDataTypes {
  String = 0,
  Int = 1,
  Double = 2,
  Bool = 3,
  Date = 4,
}
export enum ProductPropertyIndexTypes {
  /**
   * The property is not indexed, meaning it's not searchable.
   */
  NotIndexed = 0,
  /**
   * The property is indexed but not analyzed, which means it will only result in a hit if it's an exact match.
   */
  IndexedNotAnalyzed = 1,
  /**
   * The property is indexed and analyzed, meaning the value is broken down to its individual tokens according to the index analyzer.
   */
  IndexedAnalyzed = 2,
}
export enum ImportType {
  /**
   * A normal import; creates and updates the product structure and stores content, if present.
   */
  Normal = 0,
  /**
   * Only imports content, does not create or update the products themselves. It's required that the products already exist.
   */
  ContentOnly = 1,
  /**
   * A dry-run, does not make any modifications, only returns a representation of the product structure that would be generated in the response.
   */
  PreviewOnly = 2,
}
export enum ProductPropertyTypeSearchTypeMatchingMethod {
  MatchesAll = 0,
  MatchesAtLeastOne = 1,
}
export enum ProductPropertyTypeInheritanceTypes {
  NormalInheritance = 0,
  NoInheritance = 1,
}
export enum ProductPropertyTypeOptions {
  None = 0,
  AlphabeticallySortable = 1,
}
export enum ContentLayerTypes {
  UserLayer = 0,
  SystemLayer = 1,
}
export enum QueryConditionTypes {
  Equals = 0,
  Contains = 1,
  StartsWith = 2,
}
export enum StockFilterTypes {
  CurrentOrganizationUnit = 0,
  Suppliers = 1,
}
export enum SortingStrategyOrder {
  Ascending = 0,
  Descending = 1,
}
export enum SortingScriptType {
  Number = 0,
  String = 1,
}
export enum QueryRequirementType {
  Must = 0,
  Should = 1,
}
export enum BoostMode {
  Multiply = 0,
  Replace = 1,
  Sum = 2,
  Average = 3,
  Max = 4,
  Min = 5,
}
export enum ScoreModes {
  Multiply = 0,
  Sum = 1,
  Average = 2,
  First = 3,
  Max = 4,
  Min = 5,
}
export enum ScoringFunctionTypes {
  FieldValueFactor = 0,
  Script = 1,
}
export enum MatchOperator {
  And = 0,
  Or = 1,
}
export enum MultiMatchType {
  BestFields = 0,
  MostFields = 1,
  CrossFields = 2,
  Phrase = 3,
  PhrasePrefix = 4,
}
export enum ProductSearchStrategyTypes {
  Full = 0,
  Partial = 1,
}
export enum PropertyValueType {
  None = 0,
  Int = 1,
  String = 2,
  Bool = 3,
  DateTime = 4,
  Float = 5,
}
export enum ProductMediaTypes {
  Default = 0,
  Swatch = 1,
}
export enum ChartElementTypes {
  Text = 1,
  Bar = 2,
  Pie = 3,
  Table = 4,
}
