import { IntlShape } from "react-intl";

import { DiscountDeactivationReasons } from "~/types/eva-core";

export const getDiscountDeactivationReasonsTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.DiscountDeactivationReasons, string> = {
    [DiscountDeactivationReasons.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [DiscountDeactivationReasons.Unverified]: intl.formatMessage({
      id: "generic.label.unverified",
      defaultMessage: "Unverified",
    }),
    [DiscountDeactivationReasons.DisabledByUser]: intl.formatMessage({
      id: "generic.label.disabled-by-user",
      defaultMessage: "Disabled by user",
    }),
    [DiscountDeactivationReasons.ByDiscountUsage]: intl.formatMessage({
      id: "generic.label.maximum-usage-reached",
      defaultMessage: "Maximum usage reached",
    }),
    [DiscountDeactivationReasons.ByDiscountBudget]: intl.formatMessage({
      id: "generic.label.budget-reached",
      defaultMessage: "Budget reached",
    }),
    [DiscountDeactivationReasons.ByDiscountTimeFrame]: intl.formatMessage({
      id: "generic.label.out-of-start-end-time",
      defaultMessage: "Out of start / end time",
    }),
    [DiscountDeactivationReasons.ByDiscountCampaignBudget]: intl.formatMessage({
      id: "generic.label.campaign-budget-reached",
      defaultMessage: "Campaign budget reached",
    }),
    [DiscountDeactivationReasons.ByDiscountCampaignTimeFrame]: intl.formatMessage({
      id: "generic.label.campaign-out-of-start-end-time",
      defaultMessage: "Campaign out of start / end time",
    }),
  };

  return (status: EVA.Core.DiscountDeactivationReasons) => {
    return translationMap[status];
  };
};
