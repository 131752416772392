import { ReactNode, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, CardActions, DialogTrigger, Separator, SvgIcon } from "@new-black/lyra";

import { SelectedProduct } from "./selected-product";

import { generateSearchProductsLyraSearchListField } from "~/components/suite-composite/generate-search-products-search-list-field";
import {
  IProductSearchModalListItemRenderElements,
  IProductSearchModalResultBase,
  ProductSearchModal,
} from "~/components/suite-composite/product-search-modal";
import ExpanseCard from "~/components/suite-ui/expanse-card";
import useProductIDProperty from "~/hooks/suite-react-hooks/use-product-id-property";
import useGetAssetsUrl from "~/hooks/use-get-assets-url";
import { useSearchProductsQuery } from "~/models/products";

type ProductSingleFilterCardProps = {
  setProductID: (newValue: number | undefined) => void;
  children?: ReactNode;
  productID?: number;
};

export const ProductSingleFilterCard = ({
  children,
  productID,
  setProductID,
}: ProductSingleFilterCardProps) => {
  const intl = useIntl();

  const { getProductIDPropertyValue } = useProductIDProperty();
  const { data } = useSearchProductsQuery(
    {
      Filters: {
        product_id: {
          Values: [productID],
        },
      },
      IncludedFields: ["product_id", "backend_id", "display_value", "custom_id", "primary_image"],
    },
    { enabled: productID !== undefined },
  );

  const selectedProduct = useMemo(() => {
    if (!data || !data.Products) {
      return undefined;
    }

    if (!productID) {
      return undefined;
    }
    const product: IProductSearchModalResultBase = {
      product_id: data.Products[0].product_id,
      product_name: data.Products[0].display_value,
      backend_id: data.Products[0].backend_id,
      custom_id: data.Products[0].custom_id,
      primary_image: data.Products[0].primary_image,
    };

    return product;
  }, [data, productID]);
  const assetUrl = useGetAssetsUrl();
  const getProductRenderElements = useCallback(
    (item: IProductSearchModalResultBase): IProductSearchModalListItemRenderElements => ({
      title: item.product_name,
      description: getProductIDPropertyValue(item),
      imageBlobId: item.primary_image?.blob,
    }),
    [getProductIDPropertyValue],
  );
  const [expanded, setExpanded] = useState(true);
  return (
    //  using expanse card to try to reproduce the filter cards layout
    //    but also keep the add button in the header of the card
    <ExpanseCard
      title={intl.formatMessage({ id: "generic.label.products", defaultMessage: "Products" })}
      open={expanded}
      setOpen={setExpanded}
      customActions={
        expanded ? (
          <CardActions>
            <DialogTrigger>
              <Button variant="secondary">
                <FormattedMessage
                  id="generic.label.advanced-search"
                  defaultMessage="Advanced search"
                />
              </Button>
              <ProductSearchModal.Single
                leftPaneListItemRenderElements={getProductRenderElements}
                selectedProduct={selectedProduct}
                maxWidth="md"
                onSave={(product) => setProductID(product?.product_id)}
              />
            </DialogTrigger>
            <SingleSearchListField.Controlled
              value={selectedProduct}
              onChange={(newValue) => {
                setProductID(newValue?.product_id);
              }}
              selectRenderElements={(product) => ({
                label: product.display_value,
                description: getProductIDPropertyValue(product)?.toString(),
                image:
                  assetUrl && product.primary_image?.blob
                    ? `${assetUrl}/image/48/48/${product.primary_image?.blob}.png`
                    : undefined,
              })}
            >
              <Button variant="icon" fullWidth>
                <SvgIcon name="plus" />
              </Button>
            </SingleSearchListField.Controlled>
          </CardActions>
        ) : null
      }
    >
      <Separator />
      <SelectedProduct selectedProduct={selectedProduct} setProductID={setProductID} />
      {children ? <div className="pt-4">{children}</div> : null}
    </ExpanseCard>
  );
};
const { SingleSearchListField: SingleSearchListField } = generateSearchProductsLyraSearchListField(
  {},
);
