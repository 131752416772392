import { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { Table } from "@new-black/lyra";
import { useSetRecoilState } from "recoil";

import { UserIdAssignedToTask } from "../state";
import { AvailableUserTasksTableColumns, IAvailableUserTaskDto } from "../table/types";
import TaskAssigneeWarningDialogLyra from "../task-assignee-warning-dialog-lyra";

import useAvailableUserTasksTableColumns from "./use-available-user-tasks-table-columns-lyra";

import { useNavigate } from "~/components/routing";
import ErrorBoundary from "~/components/suite-ui/error-boundary";
import useCurrentUser from "~/hooks/use-current-user";
import routeDefinitions from "~/routes/route-definitions";

export interface IAvailableUserTasksTableLyra {
  tasks: IAvailableUserTaskDto[];
  title: string;
  columns: AvailableUserTasksTableColumns[];
  limit?: number;
  start?: number;
  setStart?: (value: number) => void;
  setLimit?: (value: number) => void;
  onRowClick?: (task: IAvailableUserTaskDto) => void;
  disableRowClick?: boolean;
  enableSelection?: boolean;
  customRowActions?: ({ rowData }: { rowData: IAvailableUserTaskDto }) => ReactNode;
  selectedLines?: EVA.Core.AvailableUserTaskDto[];
  setSelectedLines?: Dispatch<SetStateAction<EVA.Core.AvailableUserTaskDto[]>>;
}

const AvailableUserTasksTableLyra = ({
  columns,
  customRowActions,
  disableRowClick,
  enableSelection = false,
  limit,
  onRowClick,
  selectedLines,
  setLimit,
  setSelectedLines,
  setStart,
  start,
  tasks,
  title,
}: IAvailableUserTasksTableLyra) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useCurrentUser();

  const setUserIdAssignedToTask = useSetRecoilState(UserIdAssignedToTask(location.pathname));

  const [tableStart, setTableStart] = useState(0);
  const [tableLimit, setTableLimit] = useState(10);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [localTaskID, setLocalTaskID] = useState<number | undefined>();
  const [localCompositeTaskID, setLocalCompositeTaskID] = useState<number | undefined>();

  const handleTaskClick = useCallback(
    (taskID: number, assigneeUserId?: number, compositeTaskID?: number) => {
      if (assigneeUserId === currentUser?.ID || assigneeUserId === undefined) {
        navigate(`${compositeTaskID ? `${compositeTaskID}/${taskID}` : taskID}`);
      } else {
        setLocalTaskID(taskID);
        setLocalCompositeTaskID(compositeTaskID);
        setShowWarningDialog(true);
        setUserIdAssignedToTask(assigneeUserId);
      }
    },
    [currentUser?.ID, navigate, setUserIdAssignedToTask],
  );

  const handleWarningDialogClose = useCallback(
    (accept: boolean) => {
      setShowWarningDialog(false);

      if (accept) {
        setUserIdAssignedToTask(currentUser?.ID);
        navigate(
          `${localCompositeTaskID ? `${localCompositeTaskID}/${localTaskID}` : localTaskID}`,
          {
            state: {
              fromOverview:
                location.pathname === routeDefinitions.tasks.reservation.overview.path
                  ? true
                  : undefined,
            },
          },
        );
      }
    },
    [
      setUserIdAssignedToTask,
      currentUser?.ID,
      navigate,
      localCompositeTaskID,
      localTaskID,
      location.pathname,
    ],
  );

  const tableColumns = useAvailableUserTasksTableColumns(columns, customRowActions);

  const taskMap = useMemo(() => {
    const map = new Map<string, EVA.Core.AvailableUserTaskDto>();
    tasks.forEach((task) => {
      map.set(task.ID.toString(), task);
    });
    return map;
  }, [tasks]);

  return (
    <ErrorBoundary>
      <div>
        <Table
          aria-label={title}
          columns={tableColumns}
          data={tasks ?? []}
          limit={limit ?? tableLimit}
          start={start ?? tableStart}
          setLimit={setLimit ?? setTableLimit}
          setStart={setStart ?? setTableStart}
          total={tasks?.length ?? 0}
          options={{ hidePagination: tasks?.length <= 5 }}
          selectionMode={enableSelection ? "multiple" : "none"}
          getRowId={(row) => row.ID.toString()}
          selectedRowIds={selectedLines?.map((task) => task.ID.toString())}
          onRowSelectionChange={
            setSelectedLines
              ? (selectedRows) =>
                  setSelectedLines(
                    selectedRows
                      .map((id) => taskMap.get(id))
                      .filter((task): task is EVA.Core.AvailableUserTaskDto => task !== undefined),
                  )
              : undefined
          }
          onRowPress={
            disableRowClick
              ? undefined
              : (rowData: IAvailableUserTaskDto) =>
                  onRowClick
                    ? onRowClick(rowData)
                    : handleTaskClick(rowData.ID, rowData.User?.ID, rowData?.compositeUserTaskID)
          }
        />
        <ErrorBoundary>
          <TaskAssigneeWarningDialogLyra
            open={showWarningDialog}
            onClose={handleWarningDialogClose}
          />
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
};

export default AvailableUserTasksTableLyra;
