import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SvgIcon, Text } from "@new-black/lyra";
import classNames from "clsx";

import ImageGalleryDialog from "~/components/shared/image-gallery-dialog";
import Image from "~/components/suite-ui/image";
import useGetAssetsUrl from "~/hooks/use-get-assets-url";
import { IProduct } from "~/types/product";

export const ProductImages = ({
  enlarge = true,
  product,
}: {
  product?: IProduct;
  enlarge?: boolean;
}) => {
  const assetsUrl = useGetAssetsUrl();

  const galleryImageBlobs = product?.product_media?.gallery;

  const primaryImageBlob = product?.product_media?.primary_image?.blob;

  const galleryImages = useMemo(() => {
    const allUniqueBlobs = Array.from(
      new Set(
        [...(galleryImageBlobs ?? []), primaryImageBlob].filter((blob): blob is string => !!blob),
      ),
    );

    return allUniqueBlobs.map((blob, idx) => ({
      src: `${assetsUrl}/image/500/500/${blob}.png`,
      alt: `img-${idx}`,
      name: `${blob}.png`,
    }));
  }, [assetsUrl, galleryImageBlobs, primaryImageBlob]);

  const [initialZoomedImage, setInitialZoomedImage] = useState<number>();

  return (
    <div className="flex flex-wrap gap-4">
      {galleryImages?.length ? (
        galleryImages.map((img, index) => (
          <Image
            enlarge={false}
            key={img.src}
            {...img}
            className={classNames(
              "h-[8.75rem] w-[8.75rem] rounded-xs border border-default object-cover ",
              {
                "cursor-zoom-in": enlarge,
              },
            )}
            onClick={() => {
              if (enlarge) {
                setInitialZoomedImage(index);
              }
            }}
          />
        ))
      ) : (
        <div className="flex h-[8.75rem] w-[8.75rem] flex-col items-center justify-center gap-4 rounded-xs bg-overlay-subtle text-secondary">
          <SvgIcon name="images" className="h-8 w-8" />
          <Text variant="body-small">
            <FormattedMessage
              id="generic.label.no-image-available"
              defaultMessage="No image available"
            />
          </Text>
        </div>
      )}

      {initialZoomedImage !== undefined ? (
        <ImageGalleryDialog
          items={galleryImages}
          initialSelectedIndex={initialZoomedImage}
          isOpen
          onOpenChange={(open) => {
            if (!open) {
              setInitialZoomedImage(undefined);
            }
          }}
        />
      ) : null}
    </div>
  );
};
