import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { ListManagementShippingMethodsQuery } from "~/models/organization-units";
import { useGetShippingMethodByIdQuery } from "~/models/shipping-methods";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { emptyStringToUndefined } from "~/util/helper";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const ManagementShippingMethodsSearchListField = SearchListFieldGenerator<
  CoreManagement.ListManagementShippingMethods,
  Pick<
    EVA.Core.Management.ListManagementShippingMethodsResponse.Data,
    "CarrierName" | "ID" | "Name"
  >,
  EVA.Core.Management.ListManagementShippingMethodsResponse.Data["ID"]
>({
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.shipping-method",
    defaultMessage: "Shipping method",
  }),
  getItemFromResponse: (res) => res?.Result?.Page,
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  getDescription: (item) => item.CarrierName,
  useItemByID: (id, listItems) => {
    const itemFromList = useMemo(() => {
      if (!id) {
        return undefined;
      }
      return listItems?.find((item) => item.ID === id);
    }, [id, listItems]);
    const { data, isFetching } = useGetShippingMethodByIdQuery(id ? { ID: id } : undefined, {
      denyEmptyRequest: true,
    });
    const result = useMemo(() => {
      if (!id) {
        return { data: undefined, isLoading: false };
      }
      if (itemFromList && itemFromList.ID === id) {
        return { data: itemFromList, isLoading: false };
      }
      return { data, isLoading: isFetching };
    }, [data, id, isFetching, itemFromList]);
    return result;
  },
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: ListManagementShippingMethodsQuery,
      initialRequest: {
        PageConfig: {
          Start: 0,
          Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          Filter: { Name: undefined },
        },
      },
      getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
      setQueryRequest: (req, newValue) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: {
            ...req?.PageConfig?.Filter,
            Name: emptyStringToUndefined(newValue),
          },
        },
      }),
      configureLoadMoreButton: (resp) => ({
        shouldShowLoadMoreButton:
          (resp?.Result?.Total ?? 0) > (resp?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
        onLoadMore: (req) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit:
              (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
              DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
    }),
});
