import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

const rolesForOUSetServiceState = builders.buildServiceFamilyState({
  service: CoreManagement.GetRolesForOrganizationUnitSet,
  key: "CoreManagement:GetRolesForOrganizationUnitSet",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.OrganizationUnitSetID === parameter) {
      return req;
    }
    return { ...req, OrganizationUnitSetID: parameter };
  },
});

export const rolesForOUSetSelector = selectorFamily({
  key: "rolesForOUSetListState:responseSelector",
  get:
    (param) =>
    ({ get }) => {
      const rolesResponse = get(rolesForOUSetServiceState.response(param));
      return rolesResponse?.Roles;
    },
});
