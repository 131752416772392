import { ControlDocumentTypes } from "~/types/eva-core";

export const controlDocumentsDefaultMessages: Record<ControlDocumentTypes, string> = {
  [ControlDocumentTypes.ClosingDocument]: "Closing document",
  [ControlDocumentTypes.ControlDocument]: "Control document",
  [ControlDocumentTypes.InitializationDocument]: "Initialization document",
  [ControlDocumentTypes.IntegrityCheckDocument]: "Integrity check document",
  [ControlDocumentTypes.MonthlyControlDocument]: "Monthly control document",
  [ControlDocumentTypes.YearlyControlDocument]: "Yearly control document",
  [ControlDocumentTypes.Unknown]: "Unknown",
};
