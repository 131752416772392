import { useCallback, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { isNil } from "lodash";

import { TABS } from "./main-pane/order-lines-table/types";
import {
  orderAdditionalOrderDataSelector,
  orderAdditionalProductRequirementsSelector,
  orderAmountsSelector,
  orderResultSelector,
  orderServiceState,
} from "./store/order";
import { useCurrentOrderLines } from "./use-current-order-lines";

import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";
import useParsedRouteParam from "~/hooks/suite-react-hooks/use-parsed-route-param";
import { useCustomFields } from "~/hooks/use-custom-fields";
import { CustomFieldType } from "~/types/custom-field";
import { OrderProperties } from "~/types/eva-core";
import { getCustomFieldValueFromEVACustomFieldValue } from "~/util/custom-fields";

function transformOrderCustomFields(
  input: { [key: number]: EVA.Core.CustomFieldValueWithOptions },
  allCustomFields: EVA.Core.CustomFieldResponse[],
): EVA.Core.CustomFieldResponse[] | undefined {
  const customFieldIDs = Object.keys(input).map((key) => parseInt(key, 10));
  const customFieldV2FieldReplication = customFieldIDs
    .map((customFieldID) => {
      const customFieldValueWithOptions = input[customFieldID];
      const customFieldData = allCustomFields.find((cf) => cf.CustomFieldID === customFieldID);
      if (!customFieldData) {
        return undefined;
      }
      const customFieldPrimitiveValue = getCustomFieldValueFromEVACustomFieldValue(
        customFieldValueWithOptions.Value,
      );
      return {
        Name: customFieldData.Name,
        CustomFieldID: customFieldID,
        IsArray: customFieldData.IsArray,
        Value: customFieldPrimitiveValue,
        DataType: customFieldData.DataType,
        BackendID: customFieldData.BackendID,
        DataTypeID: customFieldData.DataTypeID,
        EnumValues: customFieldData.EnumValues,
        DisplayName: customFieldData.DisplayName,
        Options: customFieldValueWithOptions.Options,
        IsEditableByUser: customFieldData.IsEditableByUser,
      } as EVA.Core.CustomFieldResponse;
    })
    .filter((cf): cf is EVA.Core.CustomFieldResponse => cf !== undefined);
  return customFieldV2FieldReplication;
}

/**
 * Hook that sets the request of the current order based on an OrderID. When no OrderID is passed it just uses the current state.
 * This means that you can re-use this hook without passing an OrderID if the hook is already been called with the OrderID in it.
 * It clears the currentOrderRequest only for the occurrence where the OrderID has been passed.
 */
export const useCurrentOrder = () => {
  const orderID = useParsedRouteParam();

  const currentOrderRequest = hooks.useGetState(orderServiceState.request(orderID));
  const currentOrderResponse = hooks.useGetState(orderServiceState.response(orderID));
  const currentOrderError = hooks.useGetState(orderServiceState.error(orderID));
  const currentOrderIsLoading = hooks.useIsLoading({
    state: orderServiceState.response(orderID),
  });
  const currentOrderIsLoadingWithoutResponse = useMemo(
    () => !currentOrderResponse && currentOrderIsLoading,
    [currentOrderIsLoading, currentOrderResponse],
  );

  const currentOrderResult = hooks.useGetState(orderResultSelector(orderID));

  const currentOrderAmounts = hooks.useGetState(orderAmountsSelector(orderID));

  const additionalProductRequirements = hooks.useGetState(
    orderAdditionalProductRequirementsSelector(orderID),
  );

  const additionalOrderData = hooks.useGetState(orderAdditionalOrderDataSelector(orderID));

  const refetchCurrentOrder = hooks.useSetStale({
    staleState: orderServiceState.stale(orderID),
  });
  const { refetchCurrentOrderLines } = useCurrentOrderLines({ orderID, ui: TABS.ORDER_LINES_TAB });
  const refetchAll = useCallback(() => {
    refetchCurrentOrder();
    refetchCurrentOrderLines();
  }, [refetchCurrentOrder, refetchCurrentOrderLines]);

  const preferredPriceDisplayModeInTax = useMemo(
    () => currentOrderResult?.PreferredPriceDisplayMode === 0,
    [currentOrderResult?.PreferredPriceDisplayMode],
  );

  const hasReturnLines = useMemo(
    () => orderHasReturnLines(currentOrderResult),
    [currentOrderResult],
  );

  const orderOU = useMemo(
    () => currentOrderResult?.OriginatingOrganizationUnitID,
    [currentOrderResult?.OriginatingOrganizationUnitID],
  );

  const { customFields: allOrderCustomFields } = useCustomFields({
    type: CustomFieldType.Order,
    ouId: orderOU,
  });

  const customFieldValuesWithOptions = useMemo(
    () => currentOrderResult?.CustomFieldValuesWithOptions,
    [currentOrderResult?.CustomFieldValuesWithOptions],
  );

  const customFields = useMemo(
    () =>
      transformOrderCustomFields(
        currentOrderResult?.CustomFieldValuesWithOptions ?? {},
        allOrderCustomFields,
      )?.filter((customField) => !isNil(customField.Value)),
    [allOrderCustomFields, currentOrderResult?.CustomFieldValuesWithOptions],
  );

  const availablePaymentMethods = useMemo(
    () => currentOrderResponse?.AdditionalOrderData?.AvailablePaymentMethods,
    [currentOrderResponse?.AdditionalOrderData?.AvailablePaymentMethods],
  );

  const openAmount = useMemo(
    () => currentOrderAmounts?.Open.InTax,
    [currentOrderAmounts?.Open.InTax],
  );

  return {
    orderOU,
    openAmount,
    hasReturnLines,
    refetchCurrentOrder: refetchAll,
    currentOrderRequest,
    currentOrderResponse,
    currentOrderID: orderID,
    currentOrderError,
    currentOrderIsLoading,
    availablePaymentMethods,
    preferredPriceDisplayModeInTax,
    additionalProductRequirements,
    currentOrderIsLoadingWithoutResponse,
    currentOrderResult,
    currentOrderAmounts,
    customFields,
    additionalOrderData,
    customFieldValuesWithOptions,
  };
};

export const useResetCurrentOrder = () => {
  const orderID = useParsedRouteParam();
  useHandleSWRFamily(orderServiceState, orderID);

  return { orderID };
};

export function orderHasReturnLines(order: EVA.Core.OrderDto | undefined) {
  if (order) {
    return (order.Properties & OrderProperties.HasReturnLines) !== 0;
  }
  return false;
}
