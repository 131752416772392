import { useProductSearchModalContext } from "../product-search-modal-context";
import type {
  IProductSearchModalListItemRenderElements,
  IProductSearchModalResultBase,
} from "../types";

import { ProductSearchModalProductResultsHeader } from "./product-search-modal-product-results-header";
import { ProductSearchModalProductResultsListItem } from "./product-search-modal-product-results-list-item";
import { ProductSearchModalProductResultsShowMoreButton } from "./product-search-modal-product-results-show-more-button";

interface IProductSearchModalProductResultsProps<TProduct extends IProductSearchModalResultBase> {
  listItemRenderElements: (item: TProduct) => IProductSearchModalListItemRenderElements;
}

export default function ProductSearchModalProductResults<
  TProduct extends IProductSearchModalResultBase,
>(props: IProductSearchModalProductResultsProps<TProduct>) {
  const { productResults, type } = useProductSearchModalContext<TProduct>();

  if (type === "single" && productResults?.length === 0) {
    return null;
  }

  return (
    <>
      <ProductSearchModalProductResultsHeader<TProduct> />

      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-1 flex-col">
          <div className="flex max-h-[calc(100dvh-35rem)] flex-1 flex-col gap-4 overflow-y-auto py-4">
            {productResults?.map((product) => (
              <ProductSearchModalProductResultsListItem
                product={product}
                key={product.product_id}
                {...props}
              />
            ))}

            <ProductSearchModalProductResultsShowMoreButton<TProduct> />
          </div>
        </div>
      </div>
    </>
  );
}
