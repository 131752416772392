import { useMemo } from "react";

import { useGetCustomFieldTypeId } from "~/hooks/use-custom-fields-type-id";
import { useGetCustomFieldMetadataQuery, useGetCustomFieldsQuery } from "~/models/custom-fields";
import { CustomFieldType } from "~/types/custom-field";
import { CustomFieldDataTypes } from "~/types/eva-core";
import { useAllCustomFields } from "~/util/hooks/use-all-custom-fields";
import { useEditableCustomFields } from "~/util/hooks/use-editable-custom-fields";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

/**
 * Returns a list of custom fields based on the provided `organizationUnitID` (results are based
 * on `GetCustomFields` service).
 */
export const useCustomFieldSearchListFieldItems = ({
  customFieldType,
  dataTypes,
  enabled = false,
  excludeNonEditable = false,
  externalCustomFieldOptions,
  organizationUnitID,
  organizationUnitSetID,
}: {
  customFieldType: CustomFieldType;
  organizationUnitID?: number;
  organizationUnitSetID?: number;
  externalCustomFieldOptions?: {
    [key: number]: EVA.Core.CustomFieldOptions;
  };
  dataTypes?: CustomFieldDataTypes[];

  // enabled prop if true lets us get the CustomFields for the Orders Filters without having an OUID
  // added this to reproduce the old useCustomFields behaviour
  enabled?: boolean;
  excludeNonEditable?: boolean;
}) => {
  const { customFieldTypeID, isLoading: isGetCustomFieldTypeIDLoading } =
    useGetCustomFieldTypeId(customFieldType);

  const isRequestEnabled = useMemo(
    () => customFieldTypeID && (organizationUnitID || organizationUnitSetID || enabled),
    [customFieldTypeID, enabled, organizationUnitID, organizationUnitSetID],
  );

  const { data: customFields, isFetching: isGetCustomFieldsLoading } = useGetCustomFieldsQuery(
    isRequestEnabled
      ? ({
          TypeID: customFieldTypeID,
          OrganizationUnitID: organizationUnitID,
          OrganizationUnitSetID: organizationUnitSetID,
          DataTypes: dataTypes,
        } as any) // TODO: remove after typings update
      : undefined,
    { loaderKey: customFieldTypeID ? [customFieldTypeID] : undefined },
  );

  const { data: customFieldMetadata, isFetching: isGetCustomFieldMetadataLoading } =
    useGetCustomFieldMetadataQuery(isRequestEnabled ? {} : undefined, {});

  const isLoading = useMemo(
    () =>
      isGetCustomFieldTypeIDLoading || isGetCustomFieldsLoading || isGetCustomFieldMetadataLoading,
    [isGetCustomFieldMetadataLoading, isGetCustomFieldTypeIDLoading, isGetCustomFieldsLoading],
  );

  const customFieldOptions = useMemo(
    () => externalCustomFieldOptions ?? customFields?.CustomFieldOptions,
    [externalCustomFieldOptions, customFields?.CustomFieldOptions],
  );

  const editableCustomFiels = useEditableCustomFields(
    customFieldType,
    customFieldOptions,
    customFieldMetadata,
  );
  const allCustomFields = useAllCustomFields(
    customFieldType,
    customFieldOptions,
    customFieldMetadata,
  );
  const items = excludeNonEditable ? editableCustomFiels : allCustomFields;

  return { items, isLoading };
};

export const useGenerateCustomFieldSearchListField = (
  customFieldType: CustomFieldType,
  organizationUnitID?: number,
  organizationUnitSetID?: number,
  externalCustomFieldOptions?: {
    [key: number]: EVA.Core.CustomFieldOptions;
  },
  frontendFilter?: (item: EVA.Core.CustomFieldResponse) => boolean,
) => {
  const { isLoading, items } = useCustomFieldSearchListFieldItems({
    customFieldType,
    organizationUnitID,
    organizationUnitSetID,
    externalCustomFieldOptions,
  });

  const {
    MultiIDSearchListField: CustomFieldMultiIDSearchListField,
    MultiSearchListField: CustomFieldMultiSearchListField,
    SingleIDSearchListField: CustomFieldSingleIDSearchListField,
    SingleSearchListField: CustomFieldSearchListField,
  } = useMemo(
    () =>
      SearchListFieldGenerator.LocalSearchListFieldGenerator({
        items: items,
        idKey: "CustomFieldID",
        labelKey: "Name",
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.custom-field",
          defaultMessage: "Custom field",
        }),
        useItemByID: (id) => {
          const data = items?.find((customField) => customField.CustomFieldID === id);

          return { data };
        },
        useItemsByID(ids) {
          const data = items?.filter((customField) => ids?.includes(customField.CustomFieldID));

          return { data };
        },
        frontendFilter,
      }),
    [frontendFilter, items],
  );

  return {
    CustomFieldMultiIDSearchListField,
    CustomFieldMultiSearchListField,
    CustomFieldSingleIDSearchListField,
    CustomFieldSearchListField,
    items,
    isLoading,
  };
};
