import { useMemo, useState } from "react";

import { IProductSetFilter, IProductSetProduct } from "../types";

import { useSearchProductPropertyTypes } from "~/components/suite-composite/hooks/use-search-product-property-types";
import { useSearchProducts } from "~/components/suite-composite/hooks/use-search-products";

/**
 * Hook that provides necessary state for using the product set modal component
 * @param defaultFilters these are default filters that don't show up in the UI and can be overridden
 */
const useProductSetModal = (
  defaultFilters?: IProductSetFilter[],
  defaultAvailaibilityFilters?: EVA.Core.GetProductAvailabilityOptions,
  defaultQuantity?: number,
  initialQuerySearchValue?: string,
  initialFilters?: IProductSetFilter[],
) => {
  const [isOpen, setIsOpen] = useState(false);

  const [filters, setFilters] = useState<IProductSetFilter[]>(initialFilters ?? []);
  const [querySearchValue, setQuerySearchValue] = useState<string | undefined>(
    initialQuerySearchValue,
  );

  const [inlcudedProducts, setIncludedProducts] = useState<IProductSetProduct[]>([]);

  const { isLoading: isPropertiesLoading, searchProductPropertyTypes } =
    useSearchProductPropertyTypes();

  const searchProductFilters = useMemo((): IProductSetFilter[] => {
    if (!filters?.some((filter) => filter.Values?.length)) {
      return [];
    }

    return filters;
  }, [filters]);

  const searchProductDefaultFilters = useMemo((): IProductSetFilter[] => {
    if (!defaultFilters?.some((filter) => filter.Values)) {
      return [];
    }

    return defaultFilters;
  }, [defaultFilters]);

  const {
    isLoading: isProductsLoading,
    options,
    products,
  } = useSearchProducts({
    filters: searchProductFilters,
    defaultFilters: searchProductDefaultFilters,
    availabilityFilters: defaultAvailaibilityFilters,
    querySearch: querySearchValue,
  });

  const availableProducts = useMemo(
    (): IProductSetProduct[] =>
      products?.map((x) => ({
        ID: x.product_id,
        Name: x.display_value,
        ImageBlobID: x.primary_image?.blob,
        CustomID: x.custom_id,
        BackendID: x.backend_id,
        Quantity: defaultQuantity,
      })) ?? [],
    [defaultQuantity, products],
  );

  const isLoading = useMemo(
    () => isPropertiesLoading || isProductsLoading,
    [isProductsLoading, isPropertiesLoading],
  );

  return {
    isOpen,
    setIsOpen,
    filters,
    setFilters,
    availableProducts,
    isLoading,
    searchProductPropertyTypes,
    inlcudedProducts,
    setIncludedProducts,
    options,
    querySearchValue,
    setQuerySearchValue,
  };
};

export default useProductSetModal;
