import { IntlShape } from "react-intl";

import { ShipmentIdentifierRequiredFor } from "~/types/eva-core";

export const getShipmentIdentifierRequiredForTranslator = (intl: IntlShape) => {
  const translationMap: Record<ShipmentIdentifierRequiredFor, string> = {
    [ShipmentIdentifierRequiredFor.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [ShipmentIdentifierRequiredFor.RTS]: intl.formatMessage({
      id: "generic.label.rts",
      defaultMessage: "RTS",
    }),
    [ShipmentIdentifierRequiredFor.PurchaseOrder]: intl.formatMessage({
      id: "generic.label.purchase-orders",
      defaultMessage: "Purchase orders",
    }),
  };

  return (status: ShipmentIdentifierRequiredFor) => {
    return translationMap[status];
  };
};
