import { FormattedMessage } from "react-intl";

import { Text } from "@new-black/lyra";

import { IProductSearchModalResultBase } from "~/components/suite-composite/product-search-modal";
import { LyraProductSummary } from "~/components/suite-composite/product-summary";
import { IProduct } from "~/types/product";

type SelectedProductProps = {
  selectedProduct?: IProductSearchModalResultBase | IProduct;
  setProductID: (newValue: number | undefined) => void;
};

export const SelectedProduct = ({ selectedProduct, setProductID }: SelectedProductProps) => {
  if (selectedProduct) {
    return (
      <LyraProductSummary
        productName={selectedProduct.product_name ?? "-"}
        productImageBlobID={selectedProduct.primary_image?.blob}
        productID={selectedProduct.product_id}
        backendID={selectedProduct.backend_id}
        customID={selectedProduct.custom_id}
        expandableProductName
        className="py-4"
        onDeleteProduct={() => {
          setProductID(undefined);
        }}
      />
    );
  }

  return (
    <div className="pt-4">
      <Text>
        <FormattedMessage
          id="generic.label.no-products-selected"
          defaultMessage="No products selected"
        />
      </Text>
    </div>
  );
};
