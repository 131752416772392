import { IntlShape } from "react-intl";

import { OrganizationUnitTypes } from "~/types/eva-core";

export const getOrganizationUnitTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<OrganizationUnitTypes, string> = {
    [OrganizationUnitTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [OrganizationUnitTypes.Shop]: intl.formatMessage({
      id: "generic.label.shop",
      defaultMessage: "Shop",
    }),
    [OrganizationUnitTypes.WebShop]: intl.formatMessage({
      id: "generic.label.web-shop",
      defaultMessage: "Web Shop",
    }),
    [OrganizationUnitTypes.Container]: intl.formatMessage({
      id: "generic.label.container",
      defaultMessage: "Container",
    }),
    [OrganizationUnitTypes.Pickup]: intl.formatMessage({
      id: "generic.label.pickup",
      defaultMessage: "Pickup",
    }),
    [OrganizationUnitTypes.Warehouse]: intl.formatMessage({
      id: "generic.label.warehouse",
      defaultMessage: "Warehouse",
    }),
    [OrganizationUnitTypes.Country]: intl.formatMessage({
      id: "generic.label.country",
      defaultMessage: "Country",
    }),
    [OrganizationUnitTypes.Franchise]: intl.formatMessage({
      id: "generic.label.franchise",
      defaultMessage: "Franchise",
    }),
    [OrganizationUnitTypes.EVA]: intl.formatMessage({
      id: "generic.label.eva",
      defaultMessage: "EVA",
    }),
    [OrganizationUnitTypes.TestOrganizationUnit]: intl.formatMessage({
      id: "generic.label.test-organization-unit",
      defaultMessage: "Test Organization Unit",
    }),
    [OrganizationUnitTypes.DisableLogin]: intl.formatMessage({
      id: "generic.label.disable-login",
      defaultMessage: "Disable Login",
    }),
    [OrganizationUnitTypes.ExternalSupplier]: intl.formatMessage({
      id: "generic.label.external-supplier",
      defaultMessage: "External supplier",
    }),
    [OrganizationUnitTypes.Consignment]: intl.formatMessage({
      id: "generic.label.consignment",
      defaultMessage: "Consignment",
    }),
    [OrganizationUnitTypes.B2b]: intl.formatMessage({
      id: "generic.label.b2b",
      defaultMessage: "B2B",
    }),
    [OrganizationUnitTypes.Region]: intl.formatMessage({
      id: "generic.label.region",
      defaultMessage: "Region",
    }),
    [OrganizationUnitTypes.ReturnsPortal]: intl.formatMessage({
      id: "generic.label.returns-portal",
      defaultMessage: "Returns Portal",
    }),
    [OrganizationUnitTypes.Restitution]: intl.formatMessage({
      id: "generic.label.restitution",
      defaultMessage: "Restitution",
    }),
    [OrganizationUnitTypes.RepairCenter]: intl.formatMessage({
      id: "generic.label.repair-center",
      defaultMessage: "Repair Center",
    }),
    [OrganizationUnitTypes.Mobile]: intl.formatMessage({
      id: "generic.label.mobile",
      defaultMessage: "Mobile",
    }),
    [OrganizationUnitTypes.ConcessionStore]: intl.formatMessage({
      id: "generic.label.concession-store",
      defaultMessage: "Concession Store",
    }),
  };

  return (status: OrganizationUnitTypes) => {
    return translationMap[status];
  };
};
