import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigation } from "react-router";

import { DialogProps, FieldGroup, Separator, TextField } from "@new-black/lyra";

import {
  IProductSearchModalResultBase,
  ProductSearchModal,
} from "~/components/suite-composite/product-search-modal";
import useProductIDProperty from "~/hooks/suite-react-hooks/use-product-id-property";
import { useProductDetailQuery } from "~/models/products";

export type ProductWithSerialNumberAndExternalOrderId = IProductSearchModalResultBase & {
  serialNumber?: string;
  externalOrderIdentifier?: string;
  SerialNumberConfigurationID?: number;
};

type SetCaseProductModalProps = {
  isOpen?: DialogProps["isOpen"];
  onOpenChange: DialogProps["onOpenChange"];
  onSave?: (product: ProductWithSerialNumberAndExternalOrderId | undefined) => void;
  onChange?: (newValue?: ProductWithSerialNumberAndExternalOrderId) => void;
  form?: string;
  initialValue?: ProductWithSerialNumberAndExternalOrderId;
};

export const SetCaseProductModal = ({
  form,
  initialValue,
  isOpen,
  onChange,
  onOpenChange,
  onSave,
}: SetCaseProductModalProps) => {
  const intl = useIntl();
  const { state } = useNavigation();

  const [localSelection, setLocalSelection] = useState<IProductSearchModalResultBase | undefined>(
    initialValue
      ? {
          product_id: initialValue.product_id,
          backend_id: initialValue.backend_id,
          custom_id: initialValue.custom_id,
          primary_image: initialValue?.primary_image,
          product_name: initialValue?.product_name,
        }
      : undefined,
  );
  const [serialNumber, setSerialNumber] = useState<string | undefined>(initialValue?.serialNumber);
  const [externalOrderIdentifier, setExternalOrderIdentifier] = useState<string | undefined>(
    initialValue?.externalOrderIdentifier,
  );

  const { getProductIDPropertyValue } = useProductIDProperty();

  const { data: productDetail, isFetching: isProductDetailLoading } = useProductDetailQuery(
    localSelection?.product_id ? { ID: localSelection?.product_id } : undefined,
    { refetchOnMount: false },
  );

  return (
    <ProductSearchModal.Single
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      form={form}
      isSaveLoading={isProductDetailLoading || state === "submitting"}
      onSave={(product) =>
        onSave?.(
          product
            ? {
                ...product,
                serialNumber,
                externalOrderIdentifier,
              }
            : undefined,
        )
      }
      maxWidth="lg"
      selectedProduct={localSelection}
      onSelectedProductChange={(newProduct) => {
        setLocalSelection(newProduct);
        onChange?.(
          newProduct
            ? {
                ...newProduct,
                serialNumber,
                externalOrderIdentifier,
              }
            : undefined,
        );
      }}
      leftPaneListItemRenderElements={(item) => ({
        title: item.product_name,
        description: getProductIDPropertyValue(item),
        imageBlobId: item.primary_image?.blob,
      })}
      customContent={
        localSelection ? (
          <div>
            <Separator />

            <FieldGroup className="p-4">
              <TextField
                label={intl.formatMessage({
                  id: "generic.label.external-order-identifier",
                  defaultMessage: "External order identifier",
                })}
                value={externalOrderIdentifier ?? ""}
                onChange={(newValue) => {
                  setExternalOrderIdentifier(newValue);
                  onChange?.(
                    localSelection
                      ? {
                          ...localSelection,
                          serialNumber,
                          externalOrderIdentifier: newValue,
                        }
                      : undefined,
                  );
                }}
              />

              {productDetail?.SerialNumberConfigurationID !== undefined ? (
                <TextField
                  label={intl.formatMessage({
                    id: "generic.label.serial-number",
                    defaultMessage: "Serial number",
                  })}
                  value={serialNumber ?? ""}
                  onChange={(newValue) => {
                    setSerialNumber(newValue);
                    onChange?.(
                      localSelection
                        ? {
                            ...localSelection,
                            serialNumber: newValue,
                            externalOrderIdentifier,
                          }
                        : undefined,
                    );
                  }}
                />
              ) : null}
            </FieldGroup>
          </div>
        ) : null
      }
    />
  );
};
