import { FormattedMessage } from "react-intl";

import { Button } from "@new-black/lyra";

import { useProductSearchModalContext } from "../../product-search-modal-context";
import type { IProductSearchModalResultBase } from "../../types";

export function ProductSearchModalProductResultsListItemActions<
  TProduct extends IProductSearchModalResultBase,
>({ product, className }: { product: TProduct, className?: string }) {
  const {
    isProductSelectable,
    selectedProducts,
    setSelectedProductResults,
    setSelectedProducts,
    type,
  } = useProductSearchModalContext<TProduct>();

  if (type === "multi") {
    return (
      <Button
        isDisabled={
          selectedProducts.some(
            (selectedProduct) => selectedProduct.product_id === product.product_id,
          ) || !(isProductSelectable ?? (() => true))(product)
        }
        variant="secondary"
        onPress={() => {
          setSelectedProducts([...selectedProducts, product]);
          setSelectedProductResults((current) =>
            current.filter((x) => x.product_id !== product.product_id),
          );
        }}
        className={className}
      >
        <FormattedMessage id="generic.label.add" defaultMessage="Add" />
      </Button>
    );
  }

  return null;
}
