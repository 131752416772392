import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const useControlRoomChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: settingsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.SETTINGS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: appSettingsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.APP_SETTINGS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: monitorsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.MONITORS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: synchronizationFunctionalityAllowed } = useHasFunctionality(
    Functionalities.SYNCHRONIZATION,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: apiUsersChapterAllowed } = useHasFunctionality(
    Functionalities.API_USERS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: infrastructureChapterAllowed } = useHasFunctionality(
    Functionalities.INFRASTRUCTURE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewMessageTemplatesAllowed } = useHasFunctionality(
    Functionalities.MESSAGE_TEMPLATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewErrorQueueAllowed } = useHasFunctionality(
    Functionalities.ERROR_QUEUE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewSingleSignOnAllowed } = useHasFunctionality(
    Functionalities.OPEN_ID_PROVIDERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewLiveGuardAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.LIVE_GUARD,
  );

  const { functionalityAllowed: viewWatchtowersAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.WATCHTOWERS,
  );

  const { functionalityAllowed: viewEventExportConfigurationsAllowed } =
    useHasFunctionalityWithoutScope(Functionalities.EVENT_EXPORT_CONFIGURATION);

  const { functionalityAllowed: viewRecurringTasksAllowed } = useHasFunctionality(
    Functionalities.RECURRING_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewScriptsAllowed } = useHasFunctionality(
    Functionalities.SCRIPTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewAssortmentContentCulturesAllowed } = useHasFunctionality(
    Functionalities.ASSORTMENT_CONTENT_CULTURES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewContentCultureMappingAllowed } = useHasFunctionality(
    Functionalities.CONTENT_CULTURE_MAPPING,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewEntityFieldValidatorsAllowed } = useHasFunctionality(
    Functionalities.ENTITY_FIELD_VALIDATORS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewCustomFieldsAllowed } = useHasFunctionality(
    Functionalities.CUSTOM_FIELDS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewSerialNumbersAllowed } = useHasFunctionality(
    Functionalities.SERIAL_NUMBERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewEndpointConfigurationsAllowed } = useHasFunctionality(
    Functionalities.ENDPOINT_CONFIGURATIONS,
    FunctionalityScope.View,
  );

  const controlRoomChapters = useMemo((): IChapter[] => {
    const chapters: IChapter[] = [];

    if (settingsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.settings.chapters.settings",
          defaultMessage: "Settings",
        }),
        path: "/settings",
        iconName: "settings",
      });
    }

    if (appSettingsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.app-settings",
          defaultMessage: "App Settings",
        }),
        path: "/app-settings",
        iconName: "app-settings",
      });
    }

    if (monitorsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.monitors.chapters.monitors",
          defaultMessage: "Monitors",
        }),
        path: "/monitors",
        iconName: "monitors",
      });
    }

    if (synchronizationFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "synchronization.chapter.title",
          defaultMessage: "Synchronization",
        }),
        path: "/synchronization",
        iconName: "synchronization",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.create",
              defaultMessage: "Create",
            }),
            path: "/synchronization/create",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.compare",
              defaultMessage: "Compare",
            }),
            path: "/synchronization/compare",
          },
        ],
      });
    }

    if (apiUsersChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.api-users",
          defaultMessage: "API Users",
        }),
        path: "/api-users",
        iconName: "api-user",
      });
    }

    if (infrastructureChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.infrastructure",
          defaultMessage: "Infrastructure",
        }),
        path: "/infrastructure",
        iconName: "infrastructure",
      });
    }

    if (viewMessageTemplatesAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.stencil",
          defaultMessage: "Stencil",
        }),
        path: "/stencil",
        iconName: "stencil",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.template",
              defaultMessage: "Template",
            }),
            path: "/stencil/template",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.partial",
              defaultMessage: "Partial",
            }),
            path: "/stencil/partial",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.layout",
              defaultMessage: "Layout",
            }),
            path: "/stencil/layout",
          },
        ],
      });
    }

    if (viewErrorQueueAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.error-queue",
          defaultMessage: "Error Queue",
        }),
        path: "/error-queue",
        iconName: "error-queue",
      });
    }

    if (viewSingleSignOnAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.single-sign-on",
          defaultMessage: "Single Sign-On",
        }),
        path: "/single-sign-on",
        iconName: "single-sign-on",
      });
    }

    if (viewLiveGuardAllowed || viewWatchtowersAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.watchtower",
          defaultMessage: "Watchtower",
        }),
        path: "/watchtower",
        iconName: "watchtower",
      });
    }

    if (viewEventExportConfigurationsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.event-export-configuration",
          defaultMessage: "Event Export Configuration",
        }),
        path: "/event-export-configurations",
        iconName: "event-export",
      });
    }

    if (viewEndpointConfigurationsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.endpoint-configurations",
          defaultMessage: "Endpoint Configurations",
        }),
        path: "/endpoint-configurations",
        iconName: "endpoint",
      });
    }

    if (viewRecurringTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.scheduled-tasks",
          defaultMessage: "Scheduled Tasks",
        }),
        path: "/scheduled-tasks",
        iconName: "scheduled-tasks",
      });
    }

    if (viewScriptsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.scripting",
          defaultMessage: "Scripting",
        }),
        path: "/scripting",
        iconName: "code-editor",
      });
    }

    //todo: check for functionality when that will be created
    chapters.push({
      title: intl.formatMessage({
        id: "generic.title.async-requests",
        defaultMessage: "Async Requests",
      }),
      path: "/async-requests",
      iconName: "async-requests",
    });

    if (viewAssortmentContentCulturesAllowed || viewContentCultureMappingAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.cultures",
          defaultMessage: "Cultures",
        }),
        path: "/cultures",
        iconName: "cultures",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.assortment-content-cultures",
              defaultMessage: "Assortment content cultures",
            }),
            path: "/cultures/assortment-content-cultures",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.content-culture-mapping",
              defaultMessage: "Content culture mapping",
            }),
            path: "/cultures/content-culture-mapping",
          },
        ],
      });
    }

    if (viewEntityFieldValidatorsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.entity-field-validators",
          defaultMessage: "Entity Field Validators",
        }),
        path: "/entity-field-validators",
        iconName: "autocomplete",
      });
    }

    // open cash drawer reasons requires no functionality for viewing the list
    chapters.push({
      title: intl.formatMessage({
        id: "generic.label.reasons",
        defaultMessage: "Reasons",
      }),
      path: "/reasons",
      iconName: "reasons",
      items: [
        {
          title: intl.formatMessage({
            id: "generic.label.open-cash-drawer",
            defaultMessage: "Create",
          }),
          path: "/reasons/open-cash-drawer",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.tax-exemption",
            defaultMessage: "Tax exemption",
          }),
          path: "/reasons/tax-exemption",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.organization-return",
            defaultMessage: "Organization return",
          }),
          path: "/reasons/organization-return",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.customer-return",
            defaultMessage: "Customer return",
          }),
          path: "/reasons/customer-return",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.cash-corrections",
            defaultMessage: "Cash corrections",
          }),
          path: "/reasons/cash-corrections",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.refund-corrections",
            defaultMessage: "Refund corrections",
          }),
          path: "/reasons/refund-correction",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.unit-price-correction",
            defaultMessage: "Unit price correction",
          }),
          path: "/reasons/unit-price-correction",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.task-decline",
            defaultMessage: "Task decline",
          }),
          path: "/reasons/task-decline",
        },
        {
          title: intl.formatMessage({
            id: "generic.label.order-verification",
            defaultMessage: "Order verification",
          }),
          path: "/reasons/order-verification",
        },
      ],
    });

    if (viewCustomFieldsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.custom-fields",
          defaultMessage: "Custom Fields",
        }),
        path: "/custom-fields",
        iconName: "custom-field",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.order",
              defaultMessage: "Order",
            }),
            path: "/custom-fields/order",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.order-line",
              defaultMessage: "Order line",
            }),
            path: "/custom-fields/order-line",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.organization-unit",
              defaultMessage: "Organization unit",
            }),
            path: "/custom-fields/organization-unit",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.promotion",
              defaultMessage: "Promotion",
            }),
            path: "/custom-fields/promotion",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.user",
              defaultMessage: "User",
            }),
            path: "/custom-fields/user",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.wishlist",
              defaultMessage: "Wishlist",
            }),
            path: "/custom-fields/wishlist",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.shipment",
              defaultMessage: "Shipment",
            }),
            path: "/custom-fields/shipment",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.shipping-method",
              defaultMessage: "Shipping method",
            }),
            path: "/custom-fields/shipping-method",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.task",
              defaultMessage: "Task",
            }),
            path: "/custom-fields/task",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.case",
              defaultMessage: "Case",
            }),
            path: "/custom-fields/case",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.repair",
              defaultMessage: "Repair",
            }),
            path: "/custom-fields/repair",
          },
        ],
      });
    }

    // This chapter does not require functionality checks since the overview is based on `ListCountries`
    chapters.push({
      title: intl.formatMessage({
        id: "generic.title.address-requirements",
        defaultMessage: "Address Requirements",
      }),
      path: "/address-requirements",
      iconName: "stock-locations",
    });

    if (viewSerialNumbersAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "serial-number-configuration.chapter.title",
          defaultMessage: "Serial Number Configuration",
        }),
        path: "/serial-number-configuration",
        iconName: "serial-number",
      });
    }

    return chapters;
  }, [
    settingsFunctionalityAllowed,
    appSettingsFunctionalityAllowed,
    monitorsFunctionalityAllowed,
    synchronizationFunctionalityAllowed,
    apiUsersChapterAllowed,
    infrastructureChapterAllowed,
    viewMessageTemplatesAllowed,
    viewErrorQueueAllowed,
    viewSingleSignOnAllowed,
    viewLiveGuardAllowed,
    viewWatchtowersAllowed,
    viewEventExportConfigurationsAllowed,
    viewEndpointConfigurationsAllowed,
    viewRecurringTasksAllowed,
    viewScriptsAllowed,
    intl,
    viewAssortmentContentCulturesAllowed,
    viewContentCultureMappingAllowed,
    viewEntityFieldValidatorsAllowed,
    viewCustomFieldsAllowed,
    viewSerialNumbersAllowed,
  ]);

  return controlRoomChapters;
};
