import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router";

import { isNil } from "lodash";
import { getParams } from "remix-params-helper";
import { ZodType } from "zod";

import useDebounce from "~/hooks/suite-react-hooks/use-debounce";

export const createSearchParamsContext = <FormSchema extends object>(
  schema: ZodType<FormSchema, any, any>,
) => {
  type ContextType = ReturnType<typeof useSearchParamsForm<FormSchema>>;
  const SearchParamsContext = createContext<ContextType>({} as ContextType);

  const Provider = ({ children }: { children: ReactNode }) => {
    return (
      <SearchParamsContext.Provider value={useSearchParamsForm(schema)}>
        {children}
      </SearchParamsContext.Provider>
    );
  };

  const useSearchParamsContext = () => {
    const context = useContext(SearchParamsContext);
    if (context === undefined) {
      throw new Error("useSearchParamsContext must be used within a SearchParamsProvider");
    }
    return context;
  };

  return { Provider, useSearchParamsContext };
};

const useSearchParamsForm = <FormSchema extends object>(schema: ZodType<any, any, any>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialParams = getParams(searchParams, schema);

  const [formState, setFormState] = useState<FormSchema>(
    initialParams?.success ? initialParams.data : {},
  );

  const debouncedFormState = useDebounce(formState, 500);

  // Ref to track whether an immediate update has occurred.
  // This prevents the debounce logic from overriding bypassed updates.
  const bypassDebounceRef = useRef(false);

  const updateSearchParamsIfChanged = useCallback(
    (updatedFormState: object) => {
      const newSearchParams = new URLSearchParams(searchParams);
      generateSearchParams(newSearchParams, updatedFormState);

      // Only update if the search params have actually changed
      if (newSearchParams.toString() !== searchParams.toString()) {
        setSearchParams(newSearchParams.toString(), { replace: true });
      }
    },
    [searchParams, setSearchParams],
  );

  const get = useCallback(
    <T extends keyof FormSchema>(keyName: T) => {
      return formState?.[keyName];
    },
    [formState],
  );

  const set = useCallback(
    <T extends keyof FormSchema>(
      keyName: T,
      value: FormSchema[T],
      resetKeyNames?: (keyof FormSchema)[],
      bypassDebounce?: boolean,
    ) => {
      let resetted = {};

      resetKeyNames?.forEach((resetKeyName) => {
        resetted = {
          ...resetted,
          [resetKeyName]: undefined,
        };
      });

      // Determine if debounce should be bypassed automatically or explicitly, so we set the search params immediately.
      const shouldBypassDebounce =
        bypassDebounce ??
        ["nextresulttoken", "limit", "start", "skip", "token"].includes(
          keyName.toString().toLowerCase(),
        );

      setFormState((current) => {
        const updatedFormState = {
          ...current,
          ...resetted,
          [keyName]: value,
        };

        if (shouldBypassDebounce) {
          // Mark that debounce should not override this update. otherwise in the useEffect for handling debounced state updates
          // it will be overridden and there will be 2 UI updates.
          bypassDebounceRef.current = true;
          updateSearchParamsIfChanged(updatedFormState);
        }

        return updatedFormState;
      });
    },
    [updateSearchParamsIfChanged],
  );

  const reset = useCallback(<T extends keyof FormSchema>(ignoreProperties?: T[]) => {
    setFormState((current) => {
      // reset all properties except the ones in ignoreProperties
      let resetted: FormSchema = { ...current } as FormSchema;
      Object.keys(current).forEach((key) => {
        if (!ignoreProperties?.includes(key as T)) {
          resetted = {
            ...resetted,
            [key]: undefined,
          };
        }
      });
      return resetted;
    });
  }, []);

  /**
   * Handles the debounced update of search parameters.
   * Skips the update if a bypass occurred.
   */
  useEffect(() => {
    if (bypassDebounceRef.current) {
      bypassDebounceRef.current = false; // Reset the flag to ensure that future updates follow the normal debounced behavior.
      return;
    }

    if (debouncedFormState) {
      updateSearchParamsIfChanged(debouncedFormState);
    }
    // We should only depend on the debouncedFormState to change. Not the other way around.
  }, [debouncedFormState, updateSearchParamsIfChanged]);

  return {
    formState,
    get,
    setFormState,
    set,
    reset,
    initialParams: (initialParams.data ?? {}) as FormSchema,
    debouncedFormState,
  };
};

const handleSearchParamIsArray = (newSearchParams: URLSearchParams, value: any[], key: string) => {
  const allValuesInSearchParams = newSearchParams.getAll(key);

  if (allValuesInSearchParams.length > value.length) {
    newSearchParams.delete(key);
    value.forEach((val: string) => {
      newSearchParams.append(key, val);
    });
  } else if (allValuesInSearchParams.length < value.length) {
    value.forEach((val) => {
      if (!allValuesInSearchParams.includes(value?.toString())) {
        newSearchParams.append(key, val);
      }
    });
  } else {
    newSearchParams.delete(key);
    value.forEach((val: string) => {
      newSearchParams.append(key, val);
    });
  }
};

const generateSearchParams = <T extends object>(newSearchParams: URLSearchParams, state: T) => {
  Object.entries(state).forEach(([key, value]) => {
    if (value === 0 || !isNil(value)) {
      const isArray = Array.isArray(value);
      const isObject = typeof value === "object" && !isArray;

      if (isObject) {
        throw new Error("There is no support to use this form state with object values.");
      } else if (isArray) {
        handleSearchParamIsArray(newSearchParams, value, key);
      } else {
        newSearchParams.set(key, value.toString());
      }
    } else if (newSearchParams.get(key) && (value === undefined || value === null)) {
      newSearchParams.delete(key);
    }
  });
};
