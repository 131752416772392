import { Core } from "@springtree/eva-services-core";
import { QueryClient } from "@tanstack/react-query";

import {
  getCurrentUserQueryKeys,
  getUserQueryKeys,
  listEmployeeDatasQueryKeys,
} from "~/models/users";
import { mutate } from "~/util/mutate";

export const mutateUpdateUser = (queryClient: QueryClient, userID: number) =>
  mutate({
    service: Core.UpdateUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries(getCurrentUserQueryKeys.withKey(["root"]));
      queryClient.invalidateQueries(listEmployeeDatasQueryKeys.withKey([`${userID}`]));
      queryClient.invalidateQueries(getUserQueryKeys.withKey([`${userID}`]));
    },
  });
