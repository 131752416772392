import { PropsWithChildren, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, SvgIcon, Text, TextProps } from "@new-black/lyra";
import clsx from "clsx";
import { isNil } from "lodash";
import { tv } from "tailwind-variants";

import { ProductImage } from "./product-image";

import {
  ExpandableLyraText,
  IExpandableLyraTextProps,
} from "~/components/suite-ui/expandable-text";
import useProductIDProperty from "~/hooks/suite-react-hooks/use-product-id-property";

const styledTextClasses = tv({
  base: "block overflow-hidden whitespace-nowrap text-ellipsis",
  variants: {
    strikeTrough: {
      true: "line-through text-secondary",
    },
    disabled: {
      true: "text-secondary",
    },
  },
});

const StyledText = ({
  className,
  disabled,
  strikeTrough,
  ...props
}: { strikeTrough?: boolean; fontSize?: number; disabled?: boolean } & TextProps) => (
  <Text {...props} className={clsx(styledTextClasses({ strikeTrough, disabled }), className)} />
);

const DEFAULT_EXPANDABLE_TEXT_PROPS = (text: string) =>
  ({
    spaceBeforeLessAction: true,
    spaceBeforeMoreAction: true,
    variant: "body-medium",
    linkButtonVariant: "body-small",
    maxLength: 40,
    text,
  }) as const;

export interface IProductSummaryProps {
  /** Name of the product you want to display  */
  productName: string;
  /** ID of the product you want to display  */
  productID?: number | string;
  /** custom ID of the product you want to display  */
  customID?: number | string;
  /** backend ID of the product you want to display  */
  backendID?: number | string;
  /** Barcode of the product you want to display  */
  barcode?: number | string;
  /** Wether the product holds warranty or not */
  isWarranty?: boolean;
  /** ID of a blob stored in EVA that contains the image of the product */
  productImageBlobID?: string;
  /** Placeholder image to be shown when the product does not have an image. By default a gray box is shown. */
  placeholderImageUrl?: string;
  /** If true, the component has a lower opacity and the text has a line trough. Use this to indicate that the product is disabled / unavailable. */
  strikeTrough?: boolean;
  /** Width and height of the image in pixels. Defaults to 50 */
  imageSize?: number;
  /** Width and height of the enlarged image in pixels. Defaults to 500 */
  enlargedImageSize?: number;
  /* If true, image can be clicked to open an enlarged version */
  enlargeImage?: boolean;
  /** If true, image will be hidden */
  hideImage?: boolean;
  /** If true, product information will be hidden */
  hideProductInfo?: boolean;
  /** Show a label for the Product ID / Custom ID / Backend ID. Defaults to `false` */
  showIDLabel?: boolean;
  /** Show a label for the barcode. Defaults to `false` */
  showBarcodeLabel?: boolean;
  /** Classname of the first html element */
  className?: string;
  /** Makes the product name render as an ExpandableText component */
  expandableProductName?: boolean;
  /** Max characters to show when expandableProductName is true */
  expandableProductNameProps?:
    | IExpandableLyraTextProps
    | ((
        defaultProps: ReturnType<typeof DEFAULT_EXPANDABLE_TEXT_PROPS>,
      ) => IExpandableLyraTextProps);
  /** If true, the component has a lower opacity. Use this to indicate that the product is disabled / unavailable without having the strikethrough available. */
  disabled?: boolean;
  /** By passing onDeleteProduct, a delete button will be rendered after each product summary */
  onDeleteProduct?: (id: number | string) => void;
}

export const ProductSummary = ({
  productName,
  productID,
  customID,
  backendID,
  barcode,
  isWarranty,
  productImageBlobID,
  placeholderImageUrl,
  strikeTrough,
  imageSize = 50,
  enlargeImage,
  enlargedImageSize = 500,
  hideImage = false,
  hideProductInfo = false,
  showIDLabel = false,
  showBarcodeLabel = false,
  className,
  children,
  expandableProductName = false,
  disabled,
  expandableProductNameProps = (defaultProps) => ({ ...defaultProps, text: productName ?? "" }),
  onDeleteProduct,
}: PropsWithChildren<IProductSummaryProps>) => {
  const intl = useIntl();

  const { getProperty: getIdProp, idProperty } = useProductIDProperty();

  const itemID = useMemo(
    () => getIdProp([productID, customID, backendID]),
    [backendID, customID, getIdProp, productID],
  );

  const IDLabel = useMemo(() => {
    if (showIDLabel) {
      switch (idProperty) {
        case "backend_id":
          return (
            <FormattedMessage
              id="generic.label.backend-id-value"
              defaultMessage="Backend ID: {value}"
              values={{ value: itemID }}
            />
          );
        case "custom_id":
          return (
            <FormattedMessage
              id="generic.label.custom-id-value"
              defaultMessage="Custom ID: {value}"
              values={{ value: itemID }}
            />
          );
        case "product_id":
        default:
          return (
            <FormattedMessage
              id="generic.label.product-id-value"
              defaultMessage="Product ID: {value}"
              values={{ value: itemID }}
            />
          );
      }
    }

    return null;
  }, [idProperty, itemID, showIDLabel]);

  const expandableProductNameTextProps = useMemo(
    () =>
      typeof expandableProductNameProps === "function"
        ? expandableProductNameProps(DEFAULT_EXPANDABLE_TEXT_PROPS(productName ?? ""))
        : expandableProductNameProps,
    [expandableProductNameProps, productName],
  );

  return (
    <div className={clsx("flex flex-nowrap items-start", className)}>
      {hideImage ? null : (
        <ProductImage
          disabled={disabled}
          enlargeImage={enlargeImage}
          enlargedImageSize={enlargedImageSize}
          imageSize={imageSize}
          placeholderImageUrl={placeholderImageUrl}
          productImageBlobID={productImageBlobID}
          strikeTrough={strikeTrough}
          classes={{ root: "me-4" }}
        />
      )}
      {hideProductInfo ? null : (
        <div className="min-w-0 flex-grow basis-0">
          <div className="flex-nowwrap flex flex-col justify-center gap-1">
            <div className="min-w-0">
              <div>
                <StyledText strikeTrough={strikeTrough} disabled={disabled}>
                  {expandableProductName ? (
                    <span className="block max-w-[35ch] flex-wrap overflow-visible !whitespace-normal text-base [text-overflow:unset]">
                      <ExpandableLyraText {...expandableProductNameTextProps} />
                    </span>
                  ) : (
                    <span>{productName}</span>
                  )}
                </StyledText>
              </div>
            </div>
            {isNil(itemID) ? null : (
              <div className="min-w-0">
                <StyledText
                  variant="body-small"
                  color="secondary"
                  strikeTrough={strikeTrough}
                  disabled={disabled}
                >
                  {IDLabel ?? itemID}
                </StyledText>
              </div>
            )}
            {isNil(isWarranty) ? null : (
              <div className="min-w-0">
                <div className="flex items-center gap-1">
                  {isWarranty ? (
                    <SvgIcon name="verified-checkmark" className="text-success" />
                  ) : (
                    <SvgIcon name="clear" className="text-error" />
                  )}
                  <FormattedMessage id="generic.label.warranty" defaultMessage="Warranty" />
                </div>
              </div>
            )}
            {isNil(barcode) ? null : (
              <div className="min-w-0">
                <StyledText
                  variant="body-small"
                  color="secondary"
                  strikeTrough={strikeTrough}
                  disabled={disabled}
                >
                  {showBarcodeLabel ? (
                    <FormattedMessage
                      id="generic.label.barcode-:"
                      defaultMessage="Barcode: {value}"
                      values={{ value: barcode }}
                    />
                  ) : (
                    barcode
                  )}
                </StyledText>
              </div>
            )}
          </div>
          {children}
        </div>
      )}
      {onDeleteProduct && productID && (
        <Button
          className="ms-2"
          variant="icon"
          tooltip={intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })}
          onPress={() => {
            if (productID) {
              onDeleteProduct(productID);
            }
          }}
        >
          <SvgIcon name="delete" />
        </Button>
      )}
    </div>
  );
};
