import { ReactNode } from "react";
import { useIntl } from "react-intl";

import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";

import TasksTableCompleteAction from "../task-table-actions/tasks-table-complete-action";
import TasksTableDeclineAction from "../task-table-actions/tasks-table-decline-action";
import usePrintTasks from "../task-table-actions/use-print-tasks";

import { IAvailableUserTaskDto } from "./types";

interface IAvailableUserTasksTableActionsProps {
  selectedLines: IAvailableUserTaskDto[];
  refetchTasks: () => void;
  emptySelectedLines: () => void;
  enablePrinting?: boolean;
  enableDeclining?: boolean;
  enableCompleting?: boolean;
  completeTasksCustomIcon?: ReactNode;
  completeMultipleAtOnce?: boolean;
}

const AvailableUserTasksTableActions = ({
  completeMultipleAtOnce,
  completeTasksCustomIcon,
  emptySelectedLines,
  enableCompleting,
  enableDeclining,
  enablePrinting,
  refetchTasks,
  selectedLines,
}: IAvailableUserTasksTableActionsProps) => {
  const intl = useIntl();
  const printTasksService = usePrintTasks(emptySelectedLines, refetchTasks);

  return (
    <ButtonGroup>
      {enablePrinting ? (
        <Button
          variant="icon"
          tooltip={intl.formatMessage({ id: "generic.label.print", defaultMessage: "Print" })}
          onPress={() => printTasksService(selectedLines.map((item) => item.ID))}
        >
          <SvgIcon name="pdf" />
        </Button>
      ) : null}
      {enableCompleting ? (
        <TasksTableCompleteAction
          selectedLines={selectedLines}
          emptySelectedLines={emptySelectedLines}
          refetchTasks={refetchTasks}
          completeTasksCustomIcon={completeTasksCustomIcon}
          completeMultipleAtOnce={completeMultipleAtOnce}
        />
      ) : null}
      {enableDeclining ? (
        <TasksTableDeclineAction<IAvailableUserTaskDto>
          selectedLines={selectedLines}
          emptySelectedLines={emptySelectedLines}
          refetchTasks={refetchTasks}
        />
      ) : null}
    </ButtonGroup>
  );
};

export default AvailableUserTasksTableActions;
