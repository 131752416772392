import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listManagementShippingMethodsLoaderQuery,
  serviceQueryKeys: listManagementShippingMethodsQueryKeys,
  useServiceQueryHook: useListManagementShippingMethodsQuery,
} = createServiceQuery(CoreManagement.ListManagementShippingMethods);

export const {
  serviceLoaderQuery: listShippingMethodsLoaderQuery,
  serviceQuery: listShippingMethodsQuery,
  serviceQueryKeys: listShippingMethodsQueryKeys,
  useServiceQueryHook: useListShippingMethodsQuery,
} = createServiceQuery(CoreManagement.ListShippingMethods);

export const {
  serviceLoaderQuery: getShippingMethodByIdLoaderQuery,
  serviceQueryKeys: getShippingMethodByIdQueryKeys,
  useServiceQueryHook: useGetShippingMethodByIdQuery,
} = createServiceQuery(CoreManagement.GetShippingMethodByID);

export const { serviceQuery: listOrganizationUnitShippingMethodsQuery } = createServiceQuery(
  CoreManagement.ListOrganizationUnitShippingMethods,
  true,
);

export const { useServiceQueryHook: useGetOrganizationUnitShippingMethodByIDQuery } =
  createServiceQuery(CoreManagement.GetOrganizationUnitShippingMethodByID, true);

export const { serviceQuery: getAvailableShippingMethodHandlers } = createServiceQuery(
  CoreManagement.GetAvailableShippingMethodHandlers,
  true,
);
