import { FormattedMessage } from "react-intl";

import {
  getLyraNumberField,
  getLyraSearchField,
  getNumberInput,
  getStringInput,
} from "./inputs.util";

import { intlAccessor } from "~/util/intl-accessor";

export const IdentifierInput = getStringInput({
  label: <FormattedMessage id="generic.label.identifier" defaultMessage="Identifier" />,
  name: "Identifier",
});

export const ValueInput = getStringInput({
  label: <FormattedMessage id="generic.label.value" defaultMessage="Value" />,
  name: "Value",
});

export const NameInput = {
  ...getStringInput({
    label: <FormattedMessage id="generic.label.name" defaultMessage="Name" />,
    name: "Name",
  }),
  Lyra: getLyraSearchField({
    name: "Name",
    hideHintLabel: true,
    label: intlAccessor.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
  }),
};

export const FirstNameInput = getStringInput({
  label: <FormattedMessage id="generic.label.first-name" defaultMessage="First name" />,
  name: "FirstName",
});

export const LastNameInput = getStringInput({
  label: <FormattedMessage id="generic.label.last-name" defaultMessage="Last name" />,
  name: "LastName",
});

export const NicknameInput = getStringInput({
  label: <FormattedMessage id="generic.label.nickname" defaultMessage="Nickname" />,
  name: "Nickname",
});

export const InitialsInput = getStringInput({
  label: <FormattedMessage id="generic.label.initials" defaultMessage="Initials" />,
  name: "Initials",
});

export const DescriptionInput = getStringInput({
  label: <FormattedMessage id="generic.label.description" defaultMessage="Description" />,
  name: "Description",
});

export const CodeInput = {
  ...getStringInput({
    label: <FormattedMessage id="generic.label.code" defaultMessage="Code" />,
    name: "Code",
  }),
  Lyra: getLyraSearchField({
    name: "Code",
    hideHintLabel: true,
    label: intlAccessor.formatMessage({ id: "generic.label.code", defaultMessage: "Code" }),
  }),
};

export const BackendIDInput = {
  ...getStringInput({
    label: <FormattedMessage id="generic.label.backend-id" defaultMessage="Backend ID" />,
    name: "BackendID",
  }),
  Lyra: getLyraSearchField({
    name: "BackendID",
    hideHintLabel: true,
    label: intlAccessor.formatMessage({
      id: "generic.label.backend-id",
      defaultMessage: "Backend ID",
    }),
  }),
};

export const BackendSystemIDInput = getStringInput({
  label: (
    <FormattedMessage id="generic.label.backend-system-id" defaultMessage="Backend system ID" />
  ),
  name: "BackendSystemID",
});

export const EmailAddressInput = {
  ...getStringInput({
    label: <FormattedMessage id="generic.label.email-address" defaultMessage="Email address" />,
    name: "EmailAddress",
  }),
};

export * from "./email-address-validated-input/email-address-validated-input";

export const PhoneNumberInput = getStringInput({
  label: <FormattedMessage id="generic.label.phone-number" defaultMessage="Phone number" />,
  name: "PhoneNumber",
});

export const BackendRelationIDInput = {
  ...getStringInput({
    label: (
      <FormattedMessage
        id="generic.label.backend-relation-id"
        defaultMessage="Backend relation ID"
      />
    ),
    name: "BackendRelationID",
  }),
  Lyra: getLyraSearchField({
    name: "BackendRelationID",
    hideHintLabel: true,
    label: intlAccessor.formatMessage({
      id: "generic.label.backend-relation-id",
      defaultMessage: "Backend relation ID",
    }),
  }),
};

export const BankAccountInput = getStringInput({
  label: <FormattedMessage id="generic.label.bank-account" defaultMessage="Bank account" />,
  name: "BankAccount",
});

export const PlaceOfBirthInput = getStringInput({
  label: <FormattedMessage id="generic.label.place-of-birth" defaultMessage="Place of birth" />,
  name: "PlaceOfBirth",
});

export const TitleInput = getStringInput({
  label: <FormattedMessage id="generic.label.title" defaultMessage="Title" />,
  name: "Title",
});

export const UserAgentInput = getStringInput({
  label: <FormattedMessage id="generic.label.user-agent" defaultMessage="User agent" />,
  name: "UserAgent",
});

export const SalutationInput = getStringInput({
  label: <FormattedMessage id="generic.label.salutation" defaultMessage="Salutation" />,
  name: "Salutation",
});

export const FiscalIDInput = getStringInput({
  label: <FormattedMessage id="generic.label.fiscal-id" defaultMessage="Fiscal ID" />,
  name: "FiscalID",
});

export const SSNInput = getStringInput({
  label: <FormattedMessage id="generic.label.ssn" defaultMessage="Social security number" />,
  name: "SocialSecurityNumber",
});

export const IDInput = {
  ...getNumberInput({
    label: <FormattedMessage id="generic.label.id" defaultMessage="ID" />,
    name: "ID",
  }),
  Lyra: getLyraNumberField({
    name: "ID",
    hideHintLabel: true,
    label: intlAccessor.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
  }),
  LyraSearchField: getLyraSearchField({
    name: "ID",
    hideHintLabel: true,
    inputMode: "numeric",
    label: intlAccessor.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
  }),
};

export const IntegerInput = getNumberInput({ name: "Number", precision: 0 });

export const OrderIDInput = getNumberInput({
  label: <FormattedMessage id="generic.label.order-id" defaultMessage="Order ID" />,
  name: "OrderID",
});

export const MessageInput = getStringInput({
  label: <FormattedMessage id="generic.label.message" defaultMessage="Message" />,
  name: "Message",
});

export const SearchQueryInput = getStringInput({
  label: <FormattedMessage id="generic.label.search-query" defaultMessage="Search query" />,
  name: "SearchQuery",
});
