import { IConfig } from "~/types/auth";
import { queryClient } from "~/util/query-client";

export const getEvaConfig = async () => {
  const useJson = import.meta.env.VITE_EVA_USE_CONFIG_JSON === "true";

  if (useJson) {
    const evaConfigQuery = {
      queryKey: ["evaConfig"],
      queryFn: async () => {
        const response = await fetch("/eva-config.json");

        let queryEvaConfigResponse: IConfig | null = null;
        try {
          queryEvaConfigResponse = await response.json();
        } catch {
          // ignored
        }
        return queryEvaConfigResponse;
      },
    };

    // store the config in cache on a variable
    const evaConfigFromCache = queryClient.getQueryData(evaConfigQuery.queryKey) as
      | IConfig
      | undefined;

    // function to fetch the eva config. If it fails, it will set the evaConfigNotAvailable to true
    const fetchEVAConfig = async () => {
      try {
        return (await queryClient.fetchQuery({
          ...evaConfigQuery,
          staleTime: 1000 * 60 * 60, // 1 hour
        })) as IConfig | undefined;
      } catch (error) {
        queryClient.setQueryData(["evaConfigNotAvailable"], true);
      }
    };

    // if the config is not in cache, fetch it
    const evaConfig: IConfig | undefined = evaConfigFromCache ?? (await fetchEVAConfig());

    return evaConfig;
  } else {
    //@ts-ignore
    if (TENANT !== null) {
      // use tenant global
      //@ts-ignore
      return TENANT as IConfig;
    } else {
      // check /eva-config.json
      // when present
    }
  }

  // no config available, go to endpoint selection
  return undefined;
};
