import { useMemo } from "react";

import { useListStockMutationReasonsQuery } from "~/models/reasons";

export const useStockMutationReasonById = (id?: number) => {
  const { data, isFetching } = useListStockMutationReasonsQuery(
    id !== undefined
      ? {
          PageConfig: {
            Filter: {
              IDs: [id],
            },
          },
        }
      : undefined,
    {},
  );

  const reason = useMemo(() => {
    if (id !== undefined) {
      const result = data?.Result?.Page?.[0];
      return result
        ? {
            ID: result.ID,
            Name: result.Name,
            Description: result.Description,
          }
        : undefined;
    }
    return undefined;
  }, [data?.Result?.Page, id]);

  return {
    data: reason,
    isLoading: isFetching,
  };
};
