import { memo, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { SvgIcon, TextField } from "@new-black/lyra";

type SerialNumberInputProps = {
  errorMessage?: string;
  value?: string;
  onValidateSerialNumber?: (serialNumber: string) => Promise<boolean | undefined>;
  onChange?: (serialNumber: string, isSerialNumberValid?: boolean) => void;
  hideError?: boolean;
};

export const SerialNumberInput = memo(
  ({
    errorMessage,
    hideError = false,
    onChange,
    onValidateSerialNumber,
    value,
  }: SerialNumberInputProps) => {
    const intl = useIntl();

    const [localValue, setLocalValue] = useState<string>(value ?? "");

    const [isValid, setIsValid] = useState<boolean | undefined>(() => {
      if (value) {
        return !errorMessage;
      }
      return errorMessage ? false : undefined;
    });

    const validate = useCallback(async () => {
      let isValid: boolean | undefined;

      if (localValue && onValidateSerialNumber) {
        isValid = await onValidateSerialNumber(localValue);
      }

      setIsValid(isValid);
      onChange?.(localValue, isValid);
    }, [localValue, onChange, onValidateSerialNumber]);

    const onChangeValue = useCallback((newValue: string) => {
      setIsValid(undefined);
      setLocalValue(newValue);
    }, []);

    const displayedError = useMemo(() => {
      if (isValid) {
        return undefined;
      }

      if (isValid === false && localValue && !errorMessage) {
        return intl.formatMessage({
          id: "generic.label.invalid-serial-number",
          defaultMessage: "Invalid serial number",
        });
      }

      if (errorMessage) {
        return errorMessage;
      }

      return undefined;
    }, [errorMessage, intl, isValid, localValue]);

    return (
      <TextField
        placeholder={intl.formatMessage({
          id: "generic.label.enter-serial-number",
          defaultMessage: "Enter serial number",
        })}
        hideInputHeader
        endSlot={
          isValid && localValue ? <SvgIcon name="verified-checkmark" color="green" /> : undefined
        }
        value={localValue}
        aria-label={intl.formatMessage({
          id: "generic.label.serial-number",
          defaultMessage: "Serial number",
        })}
        onChange={onChangeValue}
        onBlur={validate}
        errorMessage={displayedError && hideError === false ? displayedError : undefined}
      />
    );
  },
);

SerialNumberInput.displayName = "SerialNumberInput";
