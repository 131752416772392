import { CoreManagement } from "@springtree/eva-services-core-management";

import { listDiscountTemplatesQuery, useGetDiscountTemplateByIDQuery } from "~/models/promotions";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateDiscountTemplatesSearchListField = (
  initialRequest?: EVA.Core.Management.ListDiscountTemplates,
) =>
  SearchListFieldGenerator<
    CoreManagement.ListDiscountTemplates,
    { ID: number; Description: string },
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listDiscountTemplatesQuery,
        refetchOnFocus: false,
        initialRequest: {
          ...initialRequest,
          PageConfig: {
            ...initialRequest?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...initialRequest?.PageConfig?.Filter,
              Description: "",
            },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Description,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Description: newValue,
            },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Description ?? "",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((template) => ({
        ID: template.ID,
        Description: template.Description,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.template",
      defaultMessage: "Template",
    }),
    useItemByID: (id) => {
      const { data: templateResponse, isFetching: isLoading } = useGetDiscountTemplateByIDQuery(
        id ? { ID: id } : undefined,
        { denyEmptyRequest: true },
      );

      return { data: id ? templateResponse : undefined, isLoading };
    },
  });
