import { Text } from "@new-black/lyra";

import { Link } from "~/components/routing";
import { cn } from "~/util/classname";

interface IconTileProps {
  className?: string;
  to: string;
  icon: React.ReactNode;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  onEnter?: () => void;
  onLeave?: () => void;
  variant?: "grid";
}

export const ModuleIconTile = ({
  className,
  icon,
  isActive = true,
  label,
  onClick,
  onEnter,
  onLeave,
  to,
  variant,
}: IconTileProps) => {
  const isGrid = variant === "grid";

  return (
    <Link
      to={to}
      className={cn("group no-underline", className)}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onFocus={onEnter}
      onBlur={onLeave}
      onPointerCancel={onLeave}
    >
      <div
        className={cn(
          "flex w-[150px] flex-col justify-center gap-3",
          isGrid && "items-center gap-1",
        )}
      >
        <div
          className={cn(
            "h-[62px] w-[62px] rounded-xl text-center p-1 -m-1",
            "border-2 border-transparent group-focus-visible:border-focus group-focus-visible:shadow-highlight",
            "transition-scale scale-95 duration-200 ease-in-out group-hover:scale-100 group-focus-visible:scale-100",
            isGrid && "h-[71px] w-[71px] rounded-[1.4rem] p-1 -m-0",
          )}
        >
          <svg
            viewBox="0 0 56 56"
            className={cn("transition-opacity duration-200 ease-in-out", !isActive && "opacity-80")}
          >
            {icon}
          </svg>
        </div>
        <Text
          className={cn(
            "transition-opacity duration-200 ease-in-out",
            !isActive && "opacity-80",
            isGrid && "font-semibold",
          )}
          elementType={"h2"}
          variant={isGrid ? "body-medium" : "heading-1"}
        >
          {label}
        </Text>
      </div>
    </Link>
  );
};
