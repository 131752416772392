import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getLedgerClassesQuery } from "~/models/ledger-classes";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateLedgerClassSearchListField = () =>
  SearchListFieldGenerator<CoreManagement.GetLedgerClasses, { ID: string; Name: string }, string>({
    getItemFromResponse: (response) =>
      response?.LedgerClasses?.map((ledger) => ({ ID: ledger, Name: ledger })) ?? [],
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.ledger-class",
      defaultMessage: "Ledger class",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () =>
          ids
            ?.map((id) => itemsFromList?.find((item) => item.ID === id))
            ?.filter((item): item is { ID: string; Name: string } => !!item),
        [ids, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getLedgerClassesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
