import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getEndpointConfigurationsQuery } from "~/models/endpoint-configurations";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateEndpointConfigurationSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetEndpointConfigurations,
    { ID: number; Name: string },
    number
  >({
    getItemFromResponse: (response) => response?.Items ?? [],
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.endpoint",
      defaultMessage: "Endpoint",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () => itemsFromList?.filter((item) => ids?.includes(item.ID)),
        [ids, itemsFromList],
      );

      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getEndpointConfigurationsQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
