import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listDiscountCouponHandlersQuery } from "~/models/coupons";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "~/util/lyra-search-list-field-generator/search-list-field-generator.types";

export type DiscountCouponHandlerSearchListFieldItem = {
  ID: string;
  Name: string;
};

type BaseSearchListFieldGeneratorProps = ISearchListFieldGeneratorProps<
  Core.ListDiscountCouponHandlers,
  DiscountCouponHandlerSearchListFieldItem,
  string
>;

export const generateDiscountCouponHandlerLyraSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: BaseSearchListFieldGeneratorProps["frontendFilter"];
}) =>
  SearchListFieldGenerator<
    Core.ListDiscountCouponHandlers,
    DiscountCouponHandlerSearchListFieldItem,
    string
  >({
    getItemFromResponse: (response) =>
      response?.Handlers?.map((handler) => ({
        ID: handler,
        Name: handler,
      })) ?? [],
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.coupon-handler",
      defaultMessage: "Coupon handler",
    }),
    useItemByID: (name, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.Name === name),
        [name, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (names, itemsFromList) => {
      const items = useMemo(
        () =>
          names
            ?.map((name) => itemsFromList?.find((item) => item.Name === name))
            ?.filter((item): item is DiscountCouponHandlerSearchListFieldItem => !!item),
        [names, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listDiscountCouponHandlersQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const GeneratedDiscountCouponHandlerLyraSearchListField =
  generateDiscountCouponHandlerLyraSearchListField({});
