import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MenuItemProps } from "~/components/suite-ui/select";
import { DiscountOrderTypes } from "~/types/eva-core";

const useDiscountOrderTypes = () => {
  const intl = useIntl();

  const discountOrderTypes = useMemo(
    (): MenuItemProps[] => [
      {
        label: intl.formatMessage({
          id: "discounts.order-types.sales",
          defaultMessage: "Sales",
        }),
        value: DiscountOrderTypes.Sales,
      },
      {
        label: intl.formatMessage({
          id: "discounts.order-types.purchase",
          defaultMessage: "Purchase",
        }),
        value: DiscountOrderTypes.Purchase,
      },
      {
        label: intl.formatMessage({
          id: "discounts.order-types.all",
          defaultMessage: "All",
        }),
        value: DiscountOrderTypes.All,
      },
    ],
    [intl],
  );

  return { discountOrderTypes };
};

export default useDiscountOrderTypes;
