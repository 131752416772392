import { useIntl } from "react-intl";

import { TagInput } from "@new-black/lyra";

type FilterOnOrderIDsProps = {
  orderIDs: number[] | undefined;
  setOrderIDs: (newValue: number[] | undefined) => void;
};

export const FilterOnOrderIDsTagInput = ({ orderIDs, setOrderIDs }: FilterOnOrderIDsProps) => {
  const intl = useIntl();

  const parseStringValuesArray = (newValue: string[], parseFunction: (string: string) => number) =>
    newValue.reduce((accumulator, current) => {
      const parsedValue = parseFunction(current);

      if (isNaN(parsedValue)) return accumulator;

      return [...accumulator, parsedValue];
    }, [] as number[]);

  return (
    <TagInput
      onBeforeInput={(event) => {
        const newCharValue = (event as any).data as string;
        // Prevent input if the last key input is not an integer
        if (parseInt(newCharValue).toString() !== newCharValue) {
          event.preventDefault();
        }
      }}
      value={orderIDs?.map((val) => val.toString())}
      onChange={(newValue) => setOrderIDs(parseStringValuesArray(newValue, parseInt))}
      hideHintLabel
      label={
        !orderIDs?.length
          ? intl.formatMessage({ id: "generic.label.order-ids", defaultMessage: "Order ID's" })
          : undefined
      }
      inputMode="numeric"
      description={intl.formatMessage({
        id: "generic.label.tag-input-description",
        defaultMessage: "Press enter or comma to add a value",
      })}
    />
  );
};
