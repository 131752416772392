import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  KeyValueRow,
  KeyValueRowList,
  SvgIcon,
  Text,
} from "@new-black/lyra";
import { useMutation, useQuery } from "@tanstack/react-query";

import { mutateDownloadUserInteractionCommunicationAttachment } from "./mutations/mutate-download-user-interaction-communication-attachment";
import { InteractionLogRawEmailBox } from "./interaction-log-raw-email-container";
import { ResendInteractionEmailModal } from "./resend-interaction-email-modal";

import ErrorBoundary from "~/components/suite-ui/error-boundary";
import LoadingStateBox from "~/components/suite-ui/loading-state-box";
import {
  previewUserInteractionCommunicationQuery,
  previewUserInteractionCommunicationQueryKeys,
} from "~/models/interaction-logs";
import { saveResource } from "~/util/save-resource";

interface IPreviewInteractionLogEmailModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedInteractionLog: EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined;
  setSelectedInteractionLog: Dispatch<
    SetStateAction<EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined>
  >;
  onSuccess?: () => void;
}

export const PreviewInteractionLogEmailModal = ({
  onSuccess,
  open,
  selectedInteractionLog,
  setOpen,
  setSelectedInteractionLog,
}: IPreviewInteractionLogEmailModalProps) => {
  const intl = useIntl();

  const [resendModalOpen, setResendModalOpen] = useState(false);

  const { data: interactionCommunicationPreview, isLoading: isPreviewLoading } = useQuery({
    ...previewUserInteractionCommunicationQuery(
      selectedInteractionLog?.ID ? { ID: selectedInteractionLog.ID } : undefined,
      previewUserInteractionCommunicationQueryKeys.withKey([selectedInteractionLog?.ID]),
    ),
    enabled: !!selectedInteractionLog?.ID,
  });

  const handleClose = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen) {
        setOpen(false);
        setSelectedInteractionLog(undefined);
      }
    },
    [setOpen, setSelectedInteractionLog],
  );

  const handleOpenResendModal = useCallback(() => {
    setOpen(false);
    setResendModalOpen(true);
  }, [setOpen, setResendModalOpen]);

  const dateString = useMemo(
    () =>
      intl.formatDate(selectedInteractionLog?.CreationTime, {
        dateStyle: "short",
        timeStyle: "short",
      }),
    [intl, selectedInteractionLog?.CreationTime],
  );

  const emailSender = useMemo(
    () =>
      interactionCommunicationPreview?.FromName
        ? `${interactionCommunicationPreview.FromName} <${interactionCommunicationPreview.FromAddress}>`
        : interactionCommunicationPreview?.FromAddress ?? "",
    [interactionCommunicationPreview?.FromAddress, interactionCommunicationPreview?.FromName],
  );

  const emailRecipient = useMemo(
    () =>
      interactionCommunicationPreview?.ToName
        ? `${interactionCommunicationPreview.ToName} <${interactionCommunicationPreview.ToAddress}>`
        : interactionCommunicationPreview?.ToAddress ?? "",
    [interactionCommunicationPreview?.ToAddress, interactionCommunicationPreview?.ToName],
  );

  const { isLoading, mutateAsync } = useMutation(
    mutateDownloadUserInteractionCommunicationAttachment(),
  );

  const onDownloadAttachment = useCallback(
    (attachmentID: string, attachmentName: string) =>
      selectedInteractionLog?.ID
        ? () => {
            if (selectedInteractionLog?.ID) {
              mutateAsync({ ID: selectedInteractionLog?.ID, AttachmentID: attachmentID }).then(
                (result) => {
                  if (result?.success) {
                    saveResource(result.response?.ResourceInfo.Url, attachmentName, true);
                  }
                },
              );
            }
          }
        : undefined,
    [mutateAsync, selectedInteractionLog?.ID],
  );

  const emailAttachments = useMemo(
    () =>
      interactionCommunicationPreview?.Attachments?.map((attachment) => ({
        id: attachment.ID,
        label: attachment.Name,
        onClick: onDownloadAttachment(attachment.ID, attachment.Name),
      })),
    [interactionCommunicationPreview?.Attachments, onDownloadAttachment],
  );

  return (
    <>
      <Dialog maxWidth="xl" onOpenChange={handleClose} isOpen={open && !!selectedInteractionLog}>
        <DialogCard>
          <ErrorBoundary>
            {selectedInteractionLog && interactionCommunicationPreview && !isPreviewLoading ? (
              <>
                <CardHeader
                  title={
                    selectedInteractionLog.Text ??
                    intl.formatMessage({
                      id: "orders.detail.interaction-logs.preview-email-modal.no-subject",
                      defaultMessage: "<no subject>",
                    })
                  }
                  actions={
                    <Text color="secondary" className="mt-2">
                      {dateString}
                    </Text>
                  }
                />

                <CardContent className="space-y-4">
                  <ErrorBoundary>
                    <KeyValueRowList>
                      <KeyValueRow
                        label={intl.formatMessage({
                          id: "generic.label.from",
                          defaultMessage: "From",
                        })}
                      >
                        {emailSender}
                      </KeyValueRow>

                      <KeyValueRow
                        label={intl.formatMessage({
                          id: "generic.label.to",
                          defaultMessage: "To",
                        })}
                      >
                        {emailRecipient}
                      </KeyValueRow>

                      <KeyValueRow
                        type="button"
                        label={intl.formatMessage({
                          id: "generic.label.attachments",
                          defaultMessage: "Attachments",
                        })}
                      >
                        {emailAttachments?.length ? (
                          <ButtonGroup>
                            {emailAttachments.map((attachment) => (
                              <Button
                                key={attachment.id}
                                variant="secondary"
                                isLoading={isLoading}
                                onPress={attachment.onClick}
                                endIcon={<SvgIcon name="open-in-new-tab" />}
                              >
                                {attachment.label}
                              </Button>
                            ))}
                          </ButtonGroup>
                        ) : null}
                      </KeyValueRow>
                    </KeyValueRowList>
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <InteractionLogRawEmailBox rawHtml={interactionCommunicationPreview.Content} />
                  </ErrorBoundary>
                </CardContent>
                <CardFooter>
                  <ButtonGroup>
                    <Button variant="secondary" onPress={handleOpenResendModal}>
                      <FormattedMessage
                        id="generic.label.resend-email"
                        defaultMessage="Resend email"
                      />
                    </Button>

                    <Button onPress={() => handleClose(false)}>
                      <FormattedMessage id="generic.label.ok" defaultMessage="OK" />
                    </Button>
                  </ButtonGroup>
                </CardFooter>
              </>
            ) : (
              <LoadingStateBox limit={16} />
            )}
          </ErrorBoundary>
        </DialogCard>
      </Dialog>

      <ResendInteractionEmailModal
        open={resendModalOpen}
        setOpen={setResendModalOpen}
        selectedInteractionLog={selectedInteractionLog}
        setSelectedInteractionLog={setSelectedInteractionLog}
        onSuccess={onSuccess}
      />
    </>
  );
};
