import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listZonedCycleCountsLoaderQuery,
  serviceQueryKeys: listZonedCycleCountsLoaderQueryKeys,
  useServiceQueryWithRecoilRequest: useListZonedCycleCountsQueryWithRecoilRequest,
} = createServiceQuery(Core.ListZonedCycleCounts);

export const {
  serviceLoaderQuery: getZonedCycleCountLoaderQuery,
  serviceQueryKeys: getZonedCycleCountQueryKeys,
  useServiceQueryHook: useGetZonedCycleCountQuery,
} = createServiceQuery(Core.GetZonedCycleCount);

export const {
  serviceLoaderQuery: getStatusForZonedCycleCountPreCountsLoaderQuery,
  serviceQueryKeys: getStatusForZonedCycleCountPreCountsQueryKeys,
  useServiceQueryHook: useGetStatusForZonedCycleCountPreCountsQuery,
} = createServiceQuery(Core.GetStatusForZonedCycleCountPreCounts);

export const {
  serviceLoaderQuery: getListZonedCycleCountPreCountResultLoaderQuery,
  useServiceQueryHook: useGetListZonedCycleCountPreCountResultQuery,
} = createServiceQuery(Core.ListZonedCycleCountPreCountResult);

export const {
  serviceLoaderQuery: getListZonedCycleCountSerialNumbersStatusLoaderQuery,
  useServiceQueryHook: useGetListZonedCycleCountSerialNumbersStatusQuery,
} = createServiceQuery(Core.ListZonedCycleCountSerialNumbersStatus);

export const { serviceQuery: getCycleCountOriginTypesQuery } = createServiceQuery(
  Core.GetCycleCountOriginTypes,
);
