import { useMemo } from "react";

import { ProductSearchModalListItem } from "../../product-search-modal-list-item";
import type {
  IProductSearchModalListItemRenderElements,
  IProductSearchModalResultBase,
} from "../../types";

import { ProductSearchModalProductResultsListItemActions } from "./product-search-modal-product-results-list-item-actions";
import { ProductSearchModalProductResultsListItemCheckbox } from "./product-search-modal-product-results-list-item-checkbox";

export function ProductSearchModalProductResultsListItem<
  TProduct extends IProductSearchModalResultBase,
>({
  listItemRenderElements,
  product,
}: {
  product: TProduct;
  listItemRenderElements: (item: TProduct) => IProductSearchModalListItemRenderElements;
}) {
  const { customContent, addButtonClassName, ...renderElements } = useMemo(
    () => listItemRenderElements(product),
    [listItemRenderElements, product],
  );

  return (
    <div className="flex items-center gap-4 px-4">
      <ProductSearchModalProductResultsListItemCheckbox product={product} />

      <div className="min-w-0 flex-1">
        <ProductSearchModalListItem {...renderElements} />
      </div>

      {customContent}

      <ProductSearchModalProductResultsListItemActions
        className={addButtonClassName}
        product={product}
      />
    </div>
  );
}
