export const adminSuiteConfig = {
  deliverTasks: false,
  orderDetailsV2: true,
  auditFiles: true,
  waldoV2: true,
  cookbookV2: true,
  cookbookV1: true,
  packTasks: true,
  inForm: false,
};
