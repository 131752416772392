import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Text } from "@new-black/lyra";

import TransputJobDocument from "./transput-job-document";

interface ITransputJobDocumentsProps {
  type: "requests" | "responses";
  documents: EVA.Core.TransputJobDocument[];
}

export default function TransputJobDocuments({ documents, type }: ITransputJobDocumentsProps) {
  const intl = useIntl();

  const label = useMemo(() => {
    if (type === "requests") {
      return documents.length
        ? intl.formatMessage({
            id: "generic.label.requests",
            defaultMessage: "Requests",
          })
        : intl.formatMessage({
            id: "generic.label.requests-none",
            defaultMessage: "Requests (none)",
          });
    }

    return documents.length
      ? intl.formatMessage({
          id: "generic.label.responses",
          defaultMessage: "Responses",
        })
      : intl.formatMessage({
          id: "generic.label.responses-none",
          defaultMessage: "Responses (none)",
        });
  }, [documents.length, intl, type]);

  return (
    <div>
      <div className={type === "responses" && !documents.length ? undefined : "pb-4"}>
        <Text variant="heading-2">{label}</Text>
      </div>
      <div className="space-y-4">
        {documents.map((document) => (
          <TransputJobDocument key={document.CreationTime} document={document} />
        ))}
      </div>
    </div>
  );
}
