import { IntlShape } from "react-intl";

import { DiscountAppliesToTypes } from "~/features/discount-edit/edit-discount.types";

export const getDiscountAppliesToTypesTranslation = (intl: IntlShape) => {
  const translationMap: Record<DiscountAppliesToTypes, string> = {
    [DiscountAppliesToTypes.All]: intl.formatMessage({
      id: "generic.label.all",
      defaultMessage: "All",
    }),
    [DiscountAppliesToTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [DiscountAppliesToTypes.Order]: intl.formatMessage({
      id: "generic.label.order",
      defaultMessage: "Order",
    }),
    [DiscountAppliesToTypes.OrderLine]: intl.formatMessage({
      id: "generic.label.order-line",
      defaultMessage: "Order line",
    }),
  };

  return (item: DiscountAppliesToTypes) => {
    return translationMap[item];
  };
};
