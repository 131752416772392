import { FormattedMessage, useIntl } from "react-intl";
import { useNavigation } from "react-router";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  DialogCard,
  Text,
  TextProps,
} from "@new-black/lyra";

import { IConfirmationModalProps, IConfirmationModalWithSubmitProps } from ".";

export const ConfirmationModalContentLyra = ({
  cancelButtonVariant,
  children,
  confirmButtonVariant,
  form,
  isLoading,
  messages,
  name,
  onCancel,
  onConfirm,
  translations,
  type = "callback",
  value,
}: Omit<
  IConfirmationModalProps | IConfirmationModalWithSubmitProps,
  "isOpen" | "maxWidth" | "fullWidth" | "textDescriptionVariant" | "variant"
> & { textDescriptionVariant?: TextProps["variant"] }) => {
  const intl = useIntl();
  const { state } = useNavigation();

  return (
    <DialogCard>
      <CardHeader
        title={
          messages?.titleMessage ??
          intl.formatMessage({
            id: translations?.titleKey ?? "generic.question.are-you-sure",
            defaultMessage: "Are you sure?",
          })
        }
      />

      <CardContent className="space-y-4">
        <Text>
          {messages?.descriptionMessage ??
            intl.formatMessage({
              id: "generic.confirmation-modal.description",
              defaultMessage: "Are you sure you want to proceed?",
            })}
        </Text>

        {children}
      </CardContent>

      <CardFooter>
        <ButtonGroup>
          <Button
            onPress={onCancel}
            isDisabled={isLoading}
            variant={cancelButtonVariant ?? "secondary"}
          >
            {messages?.cancelButtonMessage ?? (
              <FormattedMessage
                id={translations?.cancelButtonKey ?? "generic.label.cancel"}
                defaultMessage="Cancel"
              />
            )}
          </Button>
          <Button
            onPress={onConfirm}
            type={type === "submit" ? "submit" : "button"}
            name={name}
            value={value}
            form={form}
            isLoading={type === "submit" ? state !== "idle" : isLoading}
            variant={confirmButtonVariant ?? "primary"}
          >
            {messages?.confirmButtonMessage ?? (
              <FormattedMessage
                id={translations?.confirmButtonKey ?? "generic.action.confirm"}
                defaultMessage="Confirm"
              />
            )}
          </Button>
        </ButtonGroup>
      </CardFooter>
    </DialogCard>
  );
};
