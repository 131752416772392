import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { NumericFormatProps } from "react-number-format";

import { InputBaseComponentProps } from "@material-ui/core";

import Input from "../input";

import { AmountInputComponentWrapper, NumberFormatCustomComponent } from "./amount-input-helpers";
import useCurrencySymbol from "./use-currency-symbol";

export interface IAmountInput extends NumericFormatProps<InputBaseComponentProps> {
  name?: string;
  CurrencyID: string | undefined;
  fullWidth?: boolean;
  helperText?: string;
  precision: number;
  inputRef?: any;
  small?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const AmountInput = (props: IAmountInput) => {
  const intl = useIntl();
  const {
    CurrencyID,
    defaultValue,
    disabled,
    error,
    fullWidth = true,
    helperText,
    inputRef,
    label,
    name,
    onChange,
    passive,
    placeholder,
    precision = 2,
    required,
    small,
    startAdornment,
    value,
    ...other
  } = props;

  const [thousandSeparator, setThousandSeparator] = useState<string | undefined>();
  const [decimalSeparator, setDecimalSeparator] = useState<string | undefined>();
  const currencyPrefix = useCurrencySymbol(CurrencyID);

  const currencyPrefixWithSpace = useMemo(() => {
    const currencyDecoratorContainsLetters = /^[A-Za-z\s]*$/.test(currencyPrefix);

    return currencyDecoratorContainsLetters ? currencyPrefix.concat(" ") : currencyPrefix;
  }, [currencyPrefix]);

  useEffect(() => {
    const numberParts = intl.formatNumberToParts(
      1000.0,
      CurrencyID ? { style: "currency", currency: CurrencyID } : undefined,
    );
    const thousandGroup = numberParts.find((part) => part.type === "group");
    setThousandSeparator(thousandGroup?.value);
  }, [intl, CurrencyID, setThousandSeparator]);

  useEffect(() => {
    const numberParts = intl.formatNumberToParts(
      1000.95,
      CurrencyID ? { style: "currency", currency: CurrencyID } : undefined,
    );
    const decimalsGroup = numberParts.find((part) => part.type === "decimal");
    setDecimalSeparator(decimalsGroup?.value);
  }, [intl, CurrencyID]);

  return (
    <Input
      name={name}
      label={label}
      defaultValue={defaultValue}
      value={value}
      small={small}
      disabled={disabled}
      passive={passive}
      autoComplete="off"
      fullWidth={fullWidth}
      required={required}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      startIcon={startAdornment}
      InputProps={{ inputComponent: AmountInputComponentWrapper as any }}
      inputProps={{
        component: NumberFormatCustomComponent as any,
        onChange,
        decimalScale: precision,
        fixedDecimalScale: true,
        thousandSeparator,
        decimalSeparator,
        currencyPrefix: currencyPrefixWithSpace,
        ...other,
      }}
      inputRef={inputRef}
    />
  );
};

export default AmountInput;
