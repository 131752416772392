import { useCallback, useMemo, useState } from "react";

import { ModuleIconTile } from "./module-icon-tile";
import { useChapterFinderData } from "./use-chapter-finder-data";

interface ModuleGridProps {
  onModuleIconClick?: () => void;
}

export const ModuleGrid = ({ onModuleIconClick }: ModuleGridProps) => {
  const modules = useChapterFinderData();

  const filteredModules = useMemo(
    () => modules.filter((module) => module.chapters.length >= 1),
    [modules],
  );

  const [isActive, setIsActive] = useState<string | null>();

  const onSelect = useCallback((id: string | null) => {
    setIsActive(id);
  }, []);

  return (
    <div className="grid grid-cols-3 items-start justify-center justify-items-center gap-x-3 gap-y-6 sm:grid-cols-4">
      {filteredModules.map((module, index) => {
        const id = `${module.title}-${index}`;
        const to = `/${module.path}${module.chapters[0].path}`;

        return (
          <div className="inline-flex flex-shrink justify-center" key={id}>
            <ModuleIconTile
              icon={module.icon}
              label={module.title}
              variant="grid"
              to={to}
              isActive={!isActive ? true : isActive === id}
              onClick={onModuleIconClick}
              onEnter={() => {
                onSelect(id);
              }}
              onLeave={() => {
                onSelect(null);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
