import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listTaxProvidersLoaderQuery,
  serviceQuery: listTaxProvidersQuery,
  serviceQueryKeys: listTaxProvidersQueryKeys,
  useServiceQueryHook: useListTaxProvidersQuery,
  useServiceQueryWithRequest: useListTaxProvidersQueryWithRequest,
} = createServiceQuery(Core.ListTaxProviders, true);

export const {
  serviceLoaderQuery: getTaxProviderLoaderQuery,
  serviceQuery: getTaxProviderQuery,
  serviceQueryKeys: getTaxProviderQueryKeys,
  useServiceQueryHook: useGetTaxProviderQuery,
  useServiceQueryWithRequest: useGetTaxProviderQueryWithRequest,
} = createServiceQuery(Core.GetTaxProvider, true);

export const {
  serviceLoaderQuery: getTaxProviderTypesLoaderQuery,
  serviceQuery: getTaxProviderTypesQuery,
  serviceQueryKeys: getTaxProviderTypesQueryKeys,
  useServiceQueryHook: useGetTaxProviderTypesQuery,
  useServiceQueryWithRequest: useGetTaxProviderTypesQueryWithRequest,
} = createServiceQuery(Core.GetTaxProviderTypes, true);
