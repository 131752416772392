import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listCouponsByQueryLoaderQuery,
  serviceQuery: listCouponsByQueryQuery,
  serviceQueryKeys: listCouponsByQueryQueryKeys,
  useServiceQueryHook: useListCouponsByQueryQuery,
  useServiceQueryWithRequest: useListCouponsByQueryQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCouponsByQuery, true);

export const { useServiceQueryHook: useGetDiscountCouponByID } = createServiceQuery(
  CoreManagement.GetDiscountCouponByID,
  true,
);

export const { serviceQuery: listDiscountCouponHandlersQuery } = createServiceQuery(
  Core.ListDiscountCouponHandlers,
);

export const {
  serviceLoaderQuery: getDiscountCouponByIDLoaderQuery,
  serviceQuery: getDiscountCouponByIDQuery,
  serviceQueryKeys: getDiscountCouponByIDQueryKeys,
  useServiceQueryHook: useGetDiscountCouponByIDQuery,
  useServiceQueryWithRequest: useGetDiscountCouponByIDQueryWithRequest,
} = createServiceQuery(CoreManagement.GetDiscountCouponByID, true);
