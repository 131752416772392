import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: ListShipmentIdentifiersLoaderQuery,
  serviceQuery: ListShipmentIdentifiersService,
  serviceQueryKeys: ListShipmentIdentifiersQueryKeys,
  useServiceQueryHook: useListShipmentIdentifiersQuery,
} = createServiceQuery(Core.ListShipmentIdentifiers);

export const {
  serviceLoaderQuery: getShipmentIdentifierLoaderQuery,
  serviceQuery: getShipmentIdentifierService,
  serviceQueryKeys: getShipmentIdentifierQueryKeys,
  useServiceQueryHook: useGetShipmentIdentifierQuery,
} = createServiceQuery(Core.GetShipmentIdentifier);
