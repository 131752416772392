import { Core } from "@springtree/eva-services-core";

import { getStockLabelsForFeatureQuery } from "~/models/stock-locations";
import { StockLabelFeature } from '~/types/eva-core';
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateStockLabelsForFeatureLyraSearchListField = ({
  feature,
  ouId,
}: {
  feature: StockLabelFeature;
  ouId?: number;
}) =>
  LyraSearchListFieldGenerator<
    Core.GetStockLabelsForFeature,
    {
      ID?: number;
      Name?: string;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name!,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.StockLabels?.map((stockLabel) => ({ ID: stockLabel.ID, Name: stockLabel.DisplayName })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.stock-label",
      defaultMessage: "Stock label",
    }),

    useItemByID: (id, stockLabels) => {
      const stockLabel = stockLabels?.find((stockLabel) => stockLabel.ID === id);

      return {
        data: stockLabel,
      };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getStockLabelsForFeatureQuery,

        initialRequest: {
          Feature: feature as number,
          OrganizationUnitID: ouId,
        },
      }),
  });
