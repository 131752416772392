import {
  Dispatch,
  KeyboardEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Combobox } from "@headlessui/react";
import { Button, SvgIcon, Text } from "@new-black/lyra";
import Fuse from "fuse.js";

import { ModuleGrid } from "./module-grid";
import { useChapterFinderData } from "./use-chapter-finder-data";

import { ButtonLink, useNavigate } from "~/components/routing";
import { QueryableLabel } from "~/components/shared/queryable-label";
import useMutationObserver from "~/hooks/use-mutation-observer";
import routeDefinitions from "~/routes/route-definitions";
import { cn } from "~/util/classname";

type ComboBoxItem = {
  label: string;
  path: string;
  category: string;
  subcategory?: string;
  icon: ReactNode;
};

type ChapterFinderInputProps = {
  isInModal?: boolean;
  onEnter: () => void;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

const ChapterFinderInput = ({ isInModal, onEnter, query, setQuery }: ChapterFinderInputProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && query) {
      event.stopPropagation();
      inputRef.current?.focus();
      setQuery("");
    }

    if (event.key === "Tab") {
      setQuery("");
      // Fix for focus-visible not being triggered on Safari
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }, 10);
    }

    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <div
      className={cn(
        "relative z-10 flex w-full items-center gap-3 px-5 pe-4 bg-surface-primary h-[60px]",
        "border-b border-solid border-default bg-surface-primary",
        !isInModal && query && "rounded-t-lg",
        !isInModal && !query && "rounded-lg",
        !isInModal && "border",
      )}
    >
      <SvgIcon name="search" className="min-w-4 pointer-events-none" />
      <Combobox.Input
        ref={inputRef}
        className={cn("text-lg w-full border-0 bg-transparent placeholder:text-tertiary")}
        autoFocus
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage({
          id: "generic.label.search",
          defaultMessage: "Search",
        })}
        onChange={(event) => setQuery(event.target.value)}
        displayValue={() => query}
        value={query}
      />

      {query && (
        <Button
          excludeFromTabOrder
          variant="icon"
          className="h-6 w-6 min-w-6 duration-160 ease-in-out p-0 text-secondary"
          onPress={() => {
            setQuery("");
            inputRef.current?.focus();
          }}
        >
          <SvgIcon name="escape" />
        </Button>
      )}
    </div>
  );
};

type ChapterFinderOptionProps = {
  item: ComboBoxItem;
  query: string;
  onClick: () => void;
  onSetActiveItem: () => void;
};

const ChapterFinderOption = ({
  item,
  onClick,
  onSetActiveItem,
  query,
}: ChapterFinderOptionProps) => {
  const ref = useRef<HTMLLIElement>(null);

  // Combobox doesn't keep track of the active element, so we need to use a mutation observer to detect when the option is active
  useMutationObserver(ref, () => {
    onSetActiveItem();
  });

  return (
    <Combobox.Option
      value={item}
      className={({ active }) =>
        cn(
          "-mx-2 my-2 select-none rounded-md p-2 cursor-pointer text-base",
          active && "bg-action-default text-inverted",
        )
      }
      onClick={onClick}
      ref={ref}
    >
      {({ active }) => (
        <div className="flex items-center gap-2">
          <svg className="h-4 w-4" viewBox="0 0 56 56">
            {item.icon}
          </svg>
          {item.subcategory && (
            <>
              {item.subcategory}
              <SvgIcon
                name="arrow-right"
                className={cn("text-tertiary", active && "text-inverted")}
              />
            </>
          )}
          <QueryableLabel label={item.label} query={query} />
        </div>
      )}
    </Combobox.Option>
  );
};

const ChapterFinderOptions = ({
  filteredItems,
  isInModal,
  onClick,
  onSetActiveItem,
  query,
}: {
  query: string;
  filteredItems: ComboBoxItem[];
  isInModal?: boolean;
  onClick: (item: ComboBoxItem) => void;
  onSetActiveItem: (item: ComboBoxItem) => void;
}) => {
  const groupItems = filteredItems.reduce(
    (groups, item) => ({ ...groups, [item.category]: [...(groups[item.category] || []), item] }),
    {} as { [key: string]: ComboBoxItem[] },
  );

  const noResultsAvailable = query && filteredItems.length === 0;

  return (
    <div className="relative h-full min-h-[calc(502px-60px)]">
      <div
        className={cn(
          "absolute z-10 my-0 w-full scroll-pb-2 scroll-pt-11 list-none overflow-y-auto bg-surface-primary pl-0 h-full ",
          query && !isInModal && "rounded-b-xl border border-t-0 border-solid border-default",
        )}
      >
        {noResultsAvailable ? (
          <div
            className={cn(
              "flex h-full w-full flex-col items-center justify-center bg-surface-primary p-12 pb-24",
            )}
          >
            <SvgIcon name="search" className="mb-5 h-[40px] w-[40px] text-tertiary" />

            <Text className="mb-2 text-center text-primary font-semibold" variant="body-large">
              <FormattedMessage
                id="generic.label.no-results-found"
                defaultMessage="No Results Found"
              />
            </Text>
            <Text className="text-center text-xs text-secondary">
              <FormattedMessage
                id="chapter-finder.no-results-description"
                defaultMessage="Please try a less specific search term."
              />
            </Text>
          </div>
        ) : (
          <Combobox.Options static>
            {Object.entries(groupItems).map(
              ([category, groupedItems]: [string, ComboBoxItem[]]) => (
                <li className="m-4" key={category}>
                  <Text
                    className="text-secondary select-none"
                    elementType="h2"
                    variant="body-small"
                  >
                    {category}
                  </Text>
                  <ul className="mt-2 list-none">
                    {groupedItems.map((item, index) => {
                      return (
                        <ChapterFinderOption
                          key={`${item.path}_${index}`}
                          item={item}
                          query={query}
                          onClick={() => {
                            onClick(item);
                          }}
                          onSetActiveItem={() => {
                            onSetActiveItem(item);
                          }}
                        />
                      );
                    })}
                  </ul>
                </li>
              ),
            )}
          </Combobox.Options>
        )}
      </div>
    </div>
  );
};

export const ChapterFinderViewAllChapters = ({ onClick }: { onClick?: () => void }) => (
  <ButtonLink
    className="ring-primary-3 rounded-md p-2 no-underline focus-visible:ring-2 mb-4"
    to={routeDefinitions.dashboard.overview.path}
    onClick={onClick}
    variant="secondary"
  >
    <FormattedMessage id="chapter-finder.view-all-chapters" defaultMessage="View all chapters" />
  </ButtonLink>
);

export const ChapterFinderComboBox = ({
  isInModal,
  onClose,
}: {
  onClose?: () => void;
  isInModal?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const modules = useChapterFinderData();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [activeItem, setActiveItem] = useState<ComboBoxItem | null>();
  const items = useMemo(() => {
    const comboBoxItems: ComboBoxItem[] = [];

    modules.forEach((module) => {
      module.chapters.forEach((chapter) => {
        comboBoxItems.push({
          label: chapter.title,
          path: `/${module.path}${chapter.path}`,
          category: module.title,
          icon: module.icon,
        });

        if (chapter.items) {
          chapter.items.forEach((item) => {
            comboBoxItems.push({
              label: item.title,
              path: `/${module.path}${item.path}`,
              category: module.title,
              subcategory: chapter.title,
              icon: module.icon,
            });
          });
        }
      });
    });
    return comboBoxItems;
  }, [modules]);

  const fuse = useMemo(
    () =>
      new Fuse(items, {
        keys: [
          { name: "label", weight: 2 },
          { name: "category", weight: 2 },
          { name: "subcategory", weight: 0.5 },
        ],
        threshold: 0.2,
      }),
    [items],
  );
  const filteredItems = fuse.search(query).map((result) => result.item);

  const onEnter = () => {
    if (!activeItem) return;
    navigate(activeItem?.path);
    onClose?.();
  };

  useEffect(() => {
    if (isInModal) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isInModal]);

  return (
    <Combobox
      ref={wrapperRef}
      as={"div"}
      className={cn(
        "relative grid grid-rows-[60px_1fr] md:h-[502px] rounded-md overflow-hidden",
        !isInModal && "max-w-[600px] w-full",
        query && " h-[80dvh]",
      )}
    >
      <ChapterFinderInput
        setQuery={setQuery}
        query={query}
        isInModal={isInModal}
        onEnter={onEnter}
      />

      {query && (
        <ChapterFinderOptions
          filteredItems={filteredItems}
          query={query}
          isInModal={isInModal}
          onClick={(item) => {
            if (item) {
              navigate(item.path);
              onClose?.();
            }
          }}
          onSetActiveItem={(item) => {
            if (item === activeItem) {
              setActiveItem(null);
            } else {
              setActiveItem(item);
            }
          }}
        />
      )}
      {((isInModal && !query) || !isInModal) && (
        <>
          <div
            className={cn(
              "flex flex-col place-items-center",
              isInModal && "h-full",
              query && "absolute",
            )}
          >
            <div
              className={cn(
                "flex h-full items-center justify-center flex-1",
                isInModal ? "py-5" : "py-14",
              )}
            >
              <ModuleGrid onModuleIconClick={onClose} />
            </div>
            {isInModal && <ChapterFinderViewAllChapters onClick={onClose} />}
          </div>
        </>
      )}
    </Combobox>
  );
};
