import { ISubscriptionLoyaltyProgramAutocompleteItem } from "~/components/suite-composite/generate-subscription-loyalty-program-autocomplete";
import { IOrganizationUnitSetSearchListFieldItem } from "~/components/suite-composite/organization-unit-set-search-list-field";
import { CustomFieldType } from "~/types/custom-field";
import {
  CustomFieldValueOperator,
  DiscountTriggers,
  FinancialDespersionType,
} from "~/types/eva-core";

export const DEFAULT_COUPON_HANDLER = "DEFAULT";

export interface IHandleNavigationClickWrapper {
  handleNavigationClick: (nextView: string) => void;
}

export interface IEditDiscountContext {
  discountOrDiscountTemplateResponse?: IDiscountOrDiscountTemplate;
  handleNavigationClickWrapper?: IHandleNavigationClickWrapper;
  setHandleNavigationClickWrapper: React.Dispatch<
    React.SetStateAction<IHandleNavigationClickWrapper | undefined>
  >;
}

/**
 * Context data on General Info Tab
 *
 * @export
 * @interface IGeneralInfo
 */
export interface IGeneralInfo {
  id?: number;
  name?: string;
  marketingDescription?: string;
  promotionLabel?: string;
  backendId?: string;
  campaignId?: number;
  orderType?: number;
  layer?: number;
  CompliancyRule?: number;
  startDate?: Date | null | string;
  startTime?: string;
  endDate?: Date | null | string;
  endTime?: string;
  organizationUnit?: IOrganizationUnitSetSearchListFieldItem;
  verification?: IGeneralInfoVerified;
  triggerId?: DiscountTriggers;
  needsReason?: boolean;
  ApplyTo?: DiscountAppliesToTypes;
  IsActive?: boolean;
  RoleIds?: number[];
  couponsGeneratedByOtherDiscount?: boolean;
  imageBlobId?: string;
  imageBlobUrl?: string;
  loyaltyProgram?: Partial<ISubscriptionLoyaltyProgramAutocompleteItem>;
  LoyaltyPointWithdrawal?: number;
  DaysApplicable?: EVA.Core.DaysOfWeek;
  CustomFields?: ((EVA.Core.CustomFieldKeyValue & { error?: string }) | undefined)[];
  externalCustomFieldOptions?: { [key: number]: EVA.Core.CustomFieldOptions };
  CannotApplyToOwnOrder?: boolean;
  RequiredCustomFields?: number[];
} // TODO: check usage and see if needsReason, ApplyTo, RoleIds can be moved directly to the IDiscount interface

export interface IGeneralInfoVerified {
  createdByFullName?: string;
  lastModifiedByFullName?: string;
  verifiedByFullName?: string;
}

export enum DiscountAppliesToTypes {
  None = 0,
  Order = 1,
  OrderLine = 2,
  All = 3,
}

/**
 * Context data on Financial Info Tab
 *
 * @export
 * @interface IFinancialInfo
 */
export interface IFinancialInfo {
  currency?: string;
  ledger?: string;
  maxAmountPerOrder?: number | string;
  maxAmountInTotal?: number | string;
  userUsageLimitCustomFieldId?: number;
  userUsageLimit?: number;
  userUsageReloadStrategy?: string;
  maxUsePerUser?: number;
  restitutionOrganizationUnitId?: number;
  restitutionOrganizationUnitName?: string;
}

export enum IConditionsType {
  "DEFAULT" = "DEFAULT",
  "COUPONVALIDITY" = "V2:COUPONVALIDITY",
  "ORDERAMOUNT" = "V2:ORDERAMOUNT",
  "PRODUCT" = "V2:PRODUCT",
  "STOCKLABEL" = "V2:STOCKLABEL",
  "USER" = "V2:USER",
  "USERROLE" = "V2:USERROLE",
  "CUSTOMER" = "V2:CUSTOMER",
  "CUSTOMERAGE" = "V2:CUSTOMERAGE",
  "USERTYPE" = "V2:USERTYPE",
  "FRIENDSFAMILY" = "V2:FRIENDSFAMILY",
  "RITUALSLOYALTY" = "V2:RITUALSLOYALTY",
  "RITUALSWELCOMEGIFT" = "V2:RITUALSWELCOMEGIFT",
  "RITUALSEMPLOYEE" = "V2:RITUALSEMPLOYEE",
  "ORGANIZATIONUNITTYPE" = "V2:OBS:ORGANIZATIONUNITTYPE",
  "USERCUSTOMFIELDS" = "V2:USERCUSTOMFIELD",
  "ORDERCUSTOMFIELD" = "V2:ORDERCUSTOMFIELD",
  "ORDERLINECUSTOMFIELD" = "V2:ORDERLINECUSTOMFIELD",
  "ORIGINALOU" = "V2:COUPONORIGINATINGORDERORGANIZATIONUNIT",
  "ORDERTPYE" = "V2:ORDERTYPE",
  "PRODUCTREQUIREMENT" = "V2:PRODUCTREQUIREMENT",
  "LOYALTYPOINTS" = "V2:LOYALTYPOINTS",
  "ORDERPROPERTIES" = "V2:ORDERPROPERTIES",
}

export enum IActionType {
  // TODO: this is not supported at all; investigate if this can be removed after the promotion engine migration
  "DEFAULT" = "DEFAULT",
  // TODO: this is not supported at all; investigate if this can be removed after the promotion engine migration
  "BUNDLE" = "V2:BUNDLE",
  "DISCOUNTAMOUNT" = "V2:DISCOUNTAMOUNT",
  "DISCOUNTPERCENTAGE" = "V2:DISCOUNTPERCENTAGE",
  "GETAPRODUCT" = "V2:GETAPRODUCT",
  "ORDERTIEREDDISCOUNTAMOUNT" = "V2:ORDERTIEREDDISCOUNTAMOUNT",
  "ORDERTIEREDDISCOUNTPERCENTAGE" = "V2:ORDERTIEREDDISCOUNTPERCENTAGE",
  "OTHERCOSTS" = "V2:OTHERCOSTS",
  "PRODUCTSETDISCOUNTAMOUNT" = "V2:PRODUCTSETDISCOUNTAMOUNT",
  // TODO: this is only used internally because UnitPrice and DiscountAmount are both set on the V2:PRODUCTSETDISCOUNTAMOUNT type in the backend; investigate if this can be removed after the promotion engine migration
  "PRODUCTSETDISCOUNTAMOUNTSETPRICE" = "V2:PRODUCTSETDISCOUNTAMOUNT-SETPRICE",
  "PRODUCTSETDISCOUNTPERCENTAGE" = "V2:PRODUCTSETDISCOUNTPERCENTAGE",
  "PRODUCTSETFIXEDPRICE" = "V2:PRODUCTSETFIXEDPRICE",
  "PRODUCTSETFREEPRODUCT" = "V2:PRODUCTSETFREEPRODUCT",
  "PRODUCTTIEREDDISCOUNTAMOUNT" = "V2:PRODUCTQUANTITYTIEREDDISCOUNTAMOUNT",
  "PRODUCTTIEREDDISCOUNTPERCENTAGE" = "V2:PRODUCTQUANTITYTIEREDDISCOUNTPERCENTAGE",
  "TIEREDPICKAPRODUCT" = "V2:TIEREDPICKAPRODUCT",
  "RITUALSEMPLOYEE" = "V2:RITUALSEMPLOYEE",
  "CUSTOMERAGE" = "V2:CUSTOMERAGE",
  "TIEREDCUSTOMERAGE" = "V2:TIEREDCUSTOMERAGE",
  "GENERATECOUPON" = "V2:GENERATEDISCOUNTCOUPON",
  "ORIGINATINGORDERDISCOUNT" = "V2:ORIGINATINGORDERDISCOUNT",
  "CUSTOMFIELDDISCOUNT" = "V2:CUSTOMFIELDVALUEDETERMINEDDISCOUNT",
  "LOYALTYTIEREDDISCOUNTAMOUNT" = "V2:LOYALTYTIEREDDISCOUNTAMOUNT",
  "LOYALTYTIEREDDISCOUNTPERCENTAGE" = "V2:LOYALTYTIEREDDISCOUNTPERCENTAGE",
}

export type TTranslatedActionTypes = Exclude<IActionType, IActionType.BUNDLE | IActionType.DEFAULT>;

export interface IActionInformation {
  activeActionType?: IActionType;
}

export interface IActionAmount extends IFinancialDispersionActionData {
  Amount?: number;
  MaximumAmount?: number;
  RequiresReason?: boolean;
  ApplyToLine?: boolean;
  RequiredCustomFields?: number[];
}

export interface IActionPercentage extends IFinancialDispersionActionData {
  Percentage?: number;
  MaximumPercentage?: number;
  RequiresReason?: boolean;
  ApplyToLine?: boolean;
  MaximumDiscountAmount?: number;
  RequiredCustomFields?: number[];
}

export type TActionSlidingDiscountAmountTier = {
  DiscountAmount: number;
  OrderAmount?: number;
  Quantity?: number;
};

export type TActionSlidingDiscountPercentageTier = {
  DiscountPercentage: number;
  OrderAmount?: number;
  Quantity?: number;
};

export interface IActionSlidingDiscount extends IFinancialDispersionActionData {
  discountAmountTiers?: TActionSlidingDiscountAmountTier[];
  discountPercentageTiers?: TActionSlidingDiscountPercentageTier[];
  ProductSets?: IEvaDiscountProductSet[];
  MaximumDiscountAmount?: number;
}

export interface IActionSlidingPointsTier {
  LoyaltyPoints: number;
}

export interface IActionSlidingPointsAmountTier extends IActionSlidingPointsTier {
  DiscountAmount: number;
}

export interface IActionSlidingPointsPercentageTier extends IActionSlidingPointsTier {
  DiscountPercentage: number;
}

export interface IActionSlidingPoints extends IFinancialDispersionActionData {
  LoyaltyProgramID: number;
  discountAmountTiers?: IActionSlidingPointsAmountTier[];
  discountPercentageTiers?: IActionSlidingPointsPercentageTier[];
  MaximumDiscountAmount?: number;
}

export interface IActionOtherCosts {
  OrderLineType: number;
  DiscountPercentage?: number;
  DiscountAmount?: number;
  MaximumDiscountAmount?: number;
}

export interface IActionPickProductTier {
  ID?: string;
  uuid: string;
  isValid: boolean;
  Description?: string;
  Prompt?: boolean;
  OrderAmount?: number;
  DefaultProduct?: number;
  PickableProducts?: number[];
}

export interface IActionPickProduct {
  tiers?: IActionPickProductTier[];
}

export interface IActionRitualsEmployees extends IFinancialDispersionActionData {
  DiscountPercentage?: number;
}

export interface IActionProductSets {
  amount?: IEvaActionProductSetDiscountAmount;
  percentage?: IEvaActionProductSetDiscountPercentage;
  fixed?: IEvaActionProductSetFixedPrice;
  freeProduct?: IEvaActionProductSetFreeProduct;
}

export interface IActionGetAProduct {
  ProductId?: number;
  Quantity?: number;
  UnitPrice?: number;
}

export interface IActionCustomFieldDiscount {
  CustomFieldID?: number;
  CustomFieldValueUsage?: number;
  LookupList?: ICustomFieldDiscountLookupListItem[];
  DiscountValueUsage?: number;
  MaxDiscountValue?: number;
  MaximumDiscountAmount?: number;
}

export interface IActionGenerateCoupon {
  CouponDistributionHandler?: "None" | "Email";
  TargetDiscountID?: number;
  BindCouponToOrderUser?: boolean;
  RequiredProducts?: IEvaDiscountProductSet[];
}

export interface ICustomFieldDiscountLookupListItem {
  CustomFieldValue?: Partial<EVA.Core.CustomFieldValue>;
  DiscountValue?: number;
}

export interface IActionOriginatingOrderDiscount extends IFinancialDispersionActionData {
  OriginatingOrderProductsToUseForValueDetermination?: IEvaDiscountProductSet[];
  PercentageToUseForValueDetermination?: number;
  MaximumAmount?: number;
}

export interface IActionCustomerAgeState {
  isTiered: boolean;
  CustomerAge?: IActionCustomerAge;
  TieredCustomerAge?: IActionTieredCustomerAge;
}

export enum ICustomerAgeValuesAsOptions {
  Amount = 0,
  Percentage = 1,
}

export interface IActionCustomerAge extends IFinancialDispersionActionData {
  UseAgeAs?: ICustomerAgeValuesAsOptions;
  MaximumAge?: number;
  MaximumDiscountAmount?: number;
}

export interface CutomerAgeActionTier {
  Age: number;
  Value: number;
}

export interface IActionTieredCustomerAge extends IFinancialDispersionActionData {
  UseTierValueAs?: ICustomerAgeValuesAsOptions;
  Tiers?: CutomerAgeActionTier[];
  MaximumDiscountAmount?: number;
}

export interface IActionProductSetsBase extends IFinancialDispersionActionData {
  ProductActionFinancialDispersion: ProductActionFinancialDispersionType;
  IdenticalProductsOnly: boolean;
  ProductSets?: IEvaDiscountProductSet[];
  UseCheapestProductsToDetermineDiscountAmount: boolean;
}
// PRODUCTSETDISCOUNTAMOUNT-DISCOUNT & PRODUCTSETDISCOUNTAMOUNT-SETPRICE
export interface IEvaActionProductSetDiscountAmount extends IActionProductSetsBase {
  DiscountAmount?: number;
  UnitPrice?: number;
}

// PRODUCTSETDISCOUNTPERCENTAGE
export interface IEvaActionProductSetDiscountPercentage extends IActionProductSetsBase {
  DiscountPercentage?: number;
  MaximumDiscountAmount?: number;
}

// PRODUCTSETDISCOUNTAMOUNT
export interface IEvaActionProductSetFixedPrice extends IActionProductSetsBase {
  ProductSetPrice?: number;
}

// PRODUCTSETDISCOUNTFREEPRODUCT
export interface IEvaActionProductSetFreeProduct
  extends Omit<IActionProductSetsBase, "UseCheapestProductsToDetermineDiscountAmount"> {
  AllowProductPicking?: boolean;
}

/**
 * Context data on Conditions tab
 *
 * @export
 * @interface IConditions
 */

export interface IConditions {
  orderAmount?: IConditionOrderAmount;
  products?: IConditionProduct;
  organizationUnitSets?: IConditionOrganizationUnitSets;
  ouSets?: IConditionsOUSets;
  dates?: IConditionsDates;
  stockLabel?: IConditionStockLabel;
  userRole?: IConditionUserRole;
  userType?: IConditionUserType;
  myRitualsMember?: IConditionMyRitualsMember;
  ritualsWelcomeGift?: IConditionRitualsWelcomeGift;
  warningModalState?: boolean;
  organizationUnitTypes?: IConditionOrganizationUnitTypes;
  customer?: IConditionCustomer;
  customerAge?: IConditionCustomerAge;
  userCustomFields?: IConditionCustomFields;
  orderCustomFields?: IConditionCustomFields;
  orderLineCustomFields?: IConditionCustomFields;
  couponValidity?: IConditionCouponValidity;
  originalOu?: IConditionOriginalOu;
  orderType?: ICounditionOrderType;
  productRequirement?: IConditionProductRequirement[];
  loyaltyPoints?: IConditionLoyaltyPoints[];
  orderProperties?: IConditionOrderProperties;
}

export interface IConditionProduct {
  data?: IConditionProductData;
  isSet?: boolean;
}

export interface IConditionProductData {
  Products?: IEvaDiscountProductSet[];
  IdenticalProductsOnly?: boolean;
  IgnoreProductsWithOriginalPrice?: boolean;
  UsageType?: 0 | 1;
}

export interface IConditionsDates {
  startDate?: Date | null | string;
  endDate?: Date | null | string;
  isSet?: boolean;
}

export interface IConditionStockLabel {
  StockLabelIDs?: number[];
  IsExcludingCondition?: boolean;
  isSet?: boolean;
}

export interface IConditionCustomerAge {
  isSet?: boolean;
  isValid?: boolean;
  ExactAgeOnly?: boolean;
  MinimumAge?: number | null;
  MaximumAge?: number | null;
}

export interface IConditionCustomer extends IConditionCustomerFields {
  UserIDs?: number[];
  EmailAddresses?: string[];
  IsExcluding?: boolean;
  isSet?: boolean;
  SubscriptionID?: number;
}

/** Filters that can be applied on the condition
 * All three filters can be combine. So you can create a filters like
 * EmailAddress should end with '@newblack.io'
 * FirstName should start with an ‘A' and LastName should end with an 'X’
 * FirstName should be ‘James' and EmailAddress should contain 'cutthecrap’
 */
export interface IConditionCustomerFields {
  FirstNameFilter?: IConditionCustomerFieldFilter;
  LastNameFilter?: IConditionCustomerFieldFilter;
  EmailAddressFilter?: IConditionCustomerFieldFilter;
}

export interface IConditionCustomerFieldFilter {
  Operator?: number;
  Value?: string;
}

export interface IConditionUserRole {
  UserRoleIDs?: number[];
  ValidateOnCustomer?: boolean;
  IsExcludingCondition?: boolean;
  isSet?: boolean;
}

export interface IConditionUserType {
  UserTypes?: number;
  IsExcluding?: boolean;
  isSet?: boolean;
}

export const enum EDiscountConditionTypes {
  OneOf = 0,
  All = 1,
}

export enum ConditionCustomFieldsFilterType {
  And = 0,
  Or = 1,
}

export interface IConditionCustomFieldsCondition {
  CustomFieldType?: CustomFieldType;
  CustomFieldId: number;
  CustomFieldValue: Partial<EVA.Core.CustomFieldValue>;
  Operator: CustomFieldValueOperator;
}
export interface IConditionCustomFields {
  CustomFieldConditions?: IConditionCustomFieldsCondition[];
  FilterType?: ConditionCustomFieldsFilterType;
  isSet?: boolean;
}

export interface IConditionMyRitualsMember {
  ExlcudeLoyaltyMembers?: boolean;
  isSet?: boolean;
}

export interface IConditionRitualsWelcomeGift {
  Value?: boolean;
  isSet?: boolean;
}

export interface IConditionOriginalOu {
  Value?: boolean;
  isSet?: boolean;
}
export interface IConditionCouponValidity {
  UseRequestedDateOnOriginatingOrder?: boolean;
  OriginatingOrderProductsToDetermineRequestedDate?: IEvaDiscountProductSet;
  UseFurthestDate?: boolean;
  UsableAfterXDays?: number;
  DaysValid?: number;
  UsableFrom?: Date | null | string;
  UsableTill?: Date | null | string;
  isSet?: boolean;
  UseCouponFirstUsageDateForDaysValid?: boolean;
  TestRandomType?: string;
}

export interface IConditionOrganizationUnitTypes {
  Value?: number;
}

/**
 * Condition Order mount tab
 *
 * @export
 * @interface IConditionOrderAmount
 */
export interface IConditionOrderAmount {
  MinimumAmount?: number;
  MaximumAmount?: number;
  IgnoreProductsWithOriginalPrice?: boolean;
  ExcludedProducts?: IEvaDiscountProductSet[];
  isSet?: boolean;
}

export interface IEvaDiscountProductFilter {
  PropertyName: string;
  Values?: any[];
  ExactMatch: boolean;
  IncludeMissing?: boolean;
  Exclude?: boolean;
  From?: string;
  To?: string;
}

export enum EEvaDiscountProductSetType {
  ProductSet = "ProductSet",
  PersonalizedPromotion = "PersonalizedPromotion",
  ProductIDs = "ProductIDs",
  ProductSearch = "ProductSearch",
  ProductRequirement = "ProductRequirement",
}

export interface IEvaDiscountProductSetProductRequirement {
  ProductID?: number;
  ProductRequirementID?: number;
  Values?: string[] | number[] | boolean[];
  uuid: string;
}

export interface IEvaDiscountProductSet {
  Name: string;
  RequiredQuantityOfProducts?: number;
  RequiredProductAmount?: number;
  IsDiscountableProductSet?: boolean | null;
  DiscountableQuantityOfProducts?: number | null;
  ProductSearchID?: number;
  ProductIDs?: number[];
  DefaultProductToAdd?: number | null;
  LanguageID: string;
  CountryID: string;
  Filters: IEvaDiscountProductFilter[];
  PersonalizedPromotionID?: number;
  ProductRequirements?: IEvaDiscountProductSetProductRequirement[];
}
/**
 * Condition OU Sets tab
 *
 * @export
 * @interface IOrganizationUnitSets
 */
export interface IConditionOrganizationUnitSets {
  ouSetCustom?: EVA.Core.Management.OrganizationUnitSetDetails[];
  ouSetSystem?: EVA.Core.Management.OrganizationUnitSetDetails[];
  getOrganizationUnitSetDetailResponse?: EVA.Core.Management.GetOrganizationUnitSetDetailsResponse;
  listOrganizationUnitSetResponse?: EVA.Core.Management.ListOrganizationUnitSetsResponse;
  listOrganizationUnitSetCustom?: EVA.Core.ListOrganizationUnitSetsItem[] | null;
  listOrganizationUnitSetSystem?: EVA.Core.ListOrganizationUnitSetsItem[] | null;
}

export type TSelectedOUSet = Pick<EVA.Core.ListOrganizationUnitSetsItem, "Name" | "ID" | "Type">;
export interface IConditionsOUSets {
  selectedOUSets?: number[];
  isExcluding: boolean;
  isSet?: boolean;
}

export enum EOrderType {
  CarryOut = 0,
  Delivery = 1,
  Reservation = 2,
}

export interface ICounditionOrderType {
  OrderType?: EOrderType;
  isSet?: boolean;
}

export interface IConditionProductRequirement {
  ProductRequirementID?: number;
  Values?: any[]; // based on ProductRequirementDataTypes
  isSet?: boolean;
  uuid: string;
  ID?: number;
}

export interface IConditionLoyaltyPoints {
  ID?: number;
  LoyaltyProgramID?: number;
  RequiredPoints?: number;
  isSet?: boolean;
  uuid: string;
}

export interface IConditionOrderProperties {
  ID?: number;
  OrderProperties?: number;
  IsExcludingCondition?: boolean;
  RequireAll?: boolean;
  isSet?: boolean;
}

/**
 * Percentage Tab which stores info for in actions.
 *
 * @export
 * @interface IPercentageAction
 */
export interface IPercentageAction {
  percentage?: number | string;
  applyTo?: "order" | "products";
}

/**
 * ProductFilter which stores all filters used in combination with Percentage Tab
 *
 * @export
 * @interface IProductFilter
 */
export interface IProductFilter {
  productFilterKey?: { title: string; value: string };
  advancedSettings?: TAdvancedSettings[];
  productsFilterType?: "value" | "range";
  ValueFilter?: IProductsFilterTypeValue;
  RangeFilter?: IProductsFilterTypeRange;
}

/**
 * Type for adding advanced settings to product filters
 */
export type TAdvancedSettings = "ExcludeSelection" | "IncludeMissing" | "ExactMatch";

/**
 * Product filter on Value Tab
 *
 * @export
 * @interface IProductsFilterTypeValue
 */
export interface IProductsFilterTypeValue {
  productFilterValue?: string[];
}

/**
 * Product filter on Range Tab
 *
 * @export
 * @interface IProductsFilterTypeRange
 */
export interface IProductsFilterTypeRange {
  fromValue?: number;
  toValue?: number;
}

export interface IFinancialDispersionActionData {
  FinancialDispersion?: FinancialDespersionType;
  FinancialDispersionScriptID?: number;
  UseFinancialDispersionScript?: boolean;
}

// Copied ProductActionFinancialDispersionType from EVA typings because we can not import it
//

export enum ProductActionFinancialDispersionType {
  AllProductsInAction = 0,
  AllDiscountableProducts = 1,
  AllNonDiscountableProducts = 2,
  AllDiscountableOrderLines = 3,
}

export enum DiscountAmountCalculationMethod {
  Cheapest = "cheapest",
  MostExpensive = "most-expensive",
}

export const enum UserUsageReloadStrategy {
  Default = "DEFAULT",
  StartOfYear = "STARTOFYEAR",
  Yearly = "YEARLY",
  StartOfQuarter = "STARTOFQUARTER",
  Quarterly = "QUARTERLY",
  StartOfMonth = "STARTOFMONTHL",
  Monthly = "MONTHLY",
}

export interface IDiscountOrDiscountTemplate {
  ActionData: { [key: string]: any };
  ActionType: string;
  ApplyTo?: DiscountAppliesToTypes;
  BackendID?: string;
  CampaignID?: number;
  ConditionType: EDiscountConditionTypes;
  Conditions: IDiscountOrDiscountTemplateCondition[];
  CouponHandler: string;
  CouponsGeneratedByOtherDiscount?: boolean;
  CreatedByFullName?: string;
  CreatedByID?: number;
  CreationTime?: string;
  CurrencyID?: string;
  DaysApplicable?: EVA.Core.DaysOfWeek;
  Description?: string;
  DiscountOrderType: EVA.Core.DiscountOrderTypes;
  EndDate?: string;
  FinancialDispersion?: FinancialDespersionType;
  GeneratedFromTemplate?: boolean;
  ID?: number;
  ImageBlobID?: string;
  DeactivationReasons?: number;
  IsActive?: boolean;
  IsActiveInEVA?: boolean;
  IsExclusive?: boolean;
  IsEditAllowed?: boolean;
  LastModificationTime?: string;
  LastModifiedByFullName?: string;
  LayerID?: number;
  CompliancyRule?: number;
  LedgerClassID?: string;
  MarketingDescription?: string;
  MaximumUsage?: number;
  MaximumUsagePerOrder?: number;
  MaximumUsagePerUser?: number;
  ModifiedByID?: number;
  NeedsReason?: boolean;
  OrganizationUnitSetID?: number;
  OrganizationUnitSetName?: string;
  OrganizationUnitSetType?: EVA.Core.OrganizationUnitSetTypes;
  OrganizationUnitSetBackendID?: string;
  OrderType?: EOrderType;
  PromotionLabel?: string;
  RestitutionOrganizationUnitID?: number;
  RestitutionOrganizationUnitName?: string;
  RoleIDs?: number[];
  StartDate?: string;
  Trigger: EVA.Core.DiscountTriggers;
  UserUsageLimit?: number;
  CustomUserUsageLimitFieldID?: number;
  UserUsageReloadStrategy: string;
  VerificationTime?: string;
  VerifiedByFullName?: string;
  LoyaltyProgramID?: number;
  LoyaltyPointWithdrawal?: number;
  CustomFieldValuesWithOptions?: { [key: number]: EVA.Core.CustomFieldValueWithOptions }; // This is what is returned from the get service
  CustomFields?: EVA.Core.CustomFieldKeyValue[]; // This is what we set on create / update service
  CannotApplyToOwnOrder?: boolean;
  RequiredCustomFields?: number[];
}

export interface IDiscountOrDiscountTemplateCondition {
  ID: number;
  Type: string;
  Data: { [key: string]: any };
  UseAsActionCondition: boolean;
  uuid?: string;
}

export interface IValidateDiscountOrDiscountTemplateResult {
  GeneralMessages: string[];
  ActionMessages: string[];
  ConditionMessages: string[];
  CustomFieldMessages?: EVA.Core.Management.ValidateDiscountResponse.CustomFieldMessage;
}

export interface IActions {
  otherCosts?: IActionOtherCosts;
  slidingDiscount?: IActionSlidingDiscount;
  slidingPoints?: IActionSlidingPoints;
  pickProduct?: IActionPickProduct;
  getAProduct?: IActionGetAProduct;
  ritualsEmployees?: IActionRitualsEmployees;
  amount?: IActionAmount;
  percentage?: IActionPercentage;
  ageDiscount?: IActionCustomerAgeState;
  customFieldDiscount?: IActionCustomFieldDiscount;
  productSets?: IActionProductSets;
  originatingOrderDiscount?: IActionOriginatingOrderDiscount;
  generateCoupon?: IActionGenerateCoupon;
  actionType?: IActionType;
}

export interface IDiscount {
  actions?: IActions;
  conditions?: IConditions;
  ConditionType?: EDiscountConditionTypes;
  generalInfo?: IGeneralInfo;
  financialInfo?: IFinancialInfo;
  isActiveInEVA?: boolean;
  isEditAllowed?: boolean;
  generatedFromTemplate?: boolean;
}

export interface IDiscountSetting {
  name: string;
  available: boolean;
  sortIndex: number;
  triggerTypes?: number[];
}
