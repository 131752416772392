import { useIntl } from "react-intl";

import { Card, Dialog } from "@new-black/lyra";

import { ChapterFinderComboBox } from "~/features/chapter-finder/chapter-finder-combo-box";

export const ChapterFinderDialog = ({
  handleClose,
  isOpen,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const intl = useIntl();

  return (
    <Dialog
      aria-label={intl.formatMessage({
        id: "generic.label.search",
        defaultMessage: "Search",
      })}
      maxWidth="2xl"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          handleClose();
        }
      }}
    >
      <Card>
        <ChapterFinderComboBox isInModal onClose={handleClose} />
      </Card>
    </Dialog>
  );
};
