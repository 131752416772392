import { useCallback, useMemo } from "react";

import { ServiceQueryWithRequest } from "~/types/query.types";

interface IMinimalRequestPageConfig {
  PageConfig?: { Start?: number; Limit?: number };
}

interface IMinimalPagedResponse<T> {
  Result?: {
    Page?: T[];
    Total?: number;
  };
}

export type CountableServiceQuery<Item> = ServiceQueryWithRequest<
  IMinimalRequestPageConfig | undefined,
  IMinimalPagedResponse<Item> | undefined
>;

export interface ICountableTablePaginationProps<Item> {
  data: Item[];
  skip: number;
  limit: number;
  total: number;
  isLoading?: boolean;
  setSkip: (newValue?: number) => void;
  setLimit: (newValue?: number) => void;
}

export interface ILyraCountableTablePaginationProps<Item> {
  data: Item[];
  start: number;
  limit: number;
  total: number;
  isLoading?: boolean;
  setStart: (newValue?: number) => void;
  setLimit: (newValue?: number) => void;
}

/**
 * This hook can only be used when a serviceQueryWithRequest is available. You often find yourself in that situation
 * when using react-query in combination with react-router loaders.
 * @param serviceQueryWithRequest
 * @param includeLoading If you want to include the loading state, you can choose between "isLoading" and "isLoadingWithoutPreviousResponse" or if you don't want to include it, you can leave it `undefined`. (`undefined` by default for backwards compatibility)
 * @returns
 */
export const useCountablePaginationHelpers = <Item>(
  serviceQueryWithRequest?: CountableServiceQuery<Item> | undefined,
  includeLoading?: "isLoading" | "isLoadingWithoutPreviousResponse",
): ICountableTablePaginationProps<Item> => {
  const data = useMemo(
    () => serviceQueryWithRequest?.queryResult.data?.Result?.Page ?? [],
    [serviceQueryWithRequest?.queryResult.data?.Result?.Page],
  );
  const setRequest = useMemo(
    () => serviceQueryWithRequest?.setRequest,
    [serviceQueryWithRequest?.setRequest],
  );

  const isLoading = useMemo(() => {
    if (!includeLoading) {
      return undefined;
    }
    return includeLoading === "isLoading"
      ? serviceQueryWithRequest?.queryResult.isFetching
      : serviceQueryWithRequest?.queryResult.isLoading;
  }, [
    includeLoading,
    serviceQueryWithRequest?.queryResult.isFetching,
    serviceQueryWithRequest?.queryResult.isLoading,
  ]);

  const total = useMemo(
    () => serviceQueryWithRequest?.queryResult.data?.Result?.Total ?? 0,
    [serviceQueryWithRequest?.queryResult.data?.Result?.Total],
  );

  const skip = useMemo(
    () => serviceQueryWithRequest?.request?.PageConfig?.Start ?? 0,
    [serviceQueryWithRequest?.request?.PageConfig?.Start],
  );

  const setSkip = useCallback(
    (newValue?: number) => {
      setRequest?.((request) => ({
        ...request,
        PageConfig: {
          ...request?.PageConfig,
          Start: newValue,
        },
      }));
    },
    [setRequest],
  );

  const limit = useMemo(
    () => serviceQueryWithRequest?.request?.PageConfig?.Limit ?? 5,
    [serviceQueryWithRequest?.request?.PageConfig?.Limit],
  );

  const setLimit = useCallback(
    (newValue?: number) => {
      setRequest?.((request) => ({
        ...request,
        PageConfig: {
          ...request?.PageConfig,
          Limit: newValue,
        },
      }));
    },
    [setRequest],
  );

  return {
    data,
    total,
    skip,
    setSkip,
    limit,
    setLimit,
    isLoading,
  };
};

useCountablePaginationHelpers.mapToLyraTableProps = <Item>({
  setSkip,
  skip,
  ...props
}: ICountableTablePaginationProps<Item>): ILyraCountableTablePaginationProps<Item> => ({
  ...props,
  start: skip,
  setStart: setSkip,
});
