import { forwardRef, useMemo } from "react";
import { NavLink as RouterNavLink, NavLinkProps, useSearchParams } from "react-router";

import { usePrompt } from "./provider";

interface Props extends NavLinkProps {
  keepSearchParams?: boolean;
}

export const NavLink = forwardRef<HTMLAnchorElement, Props>(
  ({ keepSearchParams, onClick, prefetch = "intent", to, ...props }, ref) => {
    const { setConfirmRoute, when } = usePrompt();
    const [searchParams] = useSearchParams();

    const newRoute = useMemo(() => {
      let route = to;
      if (keepSearchParams) {
        route = route + "?" + searchParams.toString();
      }
      return route;
    }, [keepSearchParams, searchParams, to]);

    return (
      <RouterNavLink
        {...props}
        ref={ref}
        to={newRoute}
        prefetch={prefetch}
        onClick={(e) => {
          if (when) {
            e?.preventDefault();
            setConfirmRoute(newRoute);
          } else {
            onClick?.(e);
          }
        }}
      />
    );
  },
);

NavLink.displayName = "NavLink";
