import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listOrdersQuery, useSearchOrdersQuery } from "~/models/orders";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateSearchOrdersSearchListField = ({
  baseRequest,
}: {
  baseRequest?: EVA.Core.SearchOrders;
}) =>
  SearchListFieldGenerator<Core.SearchOrders, { OrderId: number; CreationDate?: string }, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listOrdersQuery,
        refetchOnFocus: false,
        initialRequest: {
          ...baseRequest,
          PageConfig: {
            ...baseRequest?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Start: 0,
            Filter: {
              ...baseRequest?.PageConfig?.Filter,
            },
          },
        } as EVA.Core.SearchOrders,
        getQueryRequest: (req) => req?.OrderIDs?.[0].toString(),
        setQueryRequest: (req, newValue) =>
          ({
            ...req,
            OrderIDs: newValue ? [parseInt(newValue, 10)] : undefined,
          }) as EVA.Core.SearchOrders,
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) <=
            (response?.Result?.Total ?? 0),
          onLoadMore: (request) =>
            ({
              ...request,
              PageConfig: {
                ...request?.PageConfig,
                Limit:
                  (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                  DEFAULT_SEARCH_LIST_FIELD_LIMIT,
              },
            }) as EVA.Core.SearchOrders,
        }),
      }),
    getItemId: (item) => item?.OrderId,
    getLabel: (item) => item.OrderId.toString() ?? "",
    selectRenderElements: (item) => ({
      label: item.OrderId.toString(),
      description: intlAccessor.formatDate(item.CreationDate),
    }),
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((order) => ({
        OrderId: order.ID,
        CreationDate: order.CreationTime,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.order-id",
      defaultMessage: "Order ID",
    }),
    useItemByID: (id) => {
      const response = useSearchOrdersQuery(
        id ? ({ OrderIDs: [id] } as EVA.Core.SearchOrders) : undefined,
        {},
      );

      const data = useMemo(() => {
        const item = response.data?.Result?.Page?.[0];
        return item && id
          ? {
              OrderId: item.ID,
              CreationDate: item.CreationTime,
            }
          : undefined;
      }, [id, response.data?.Result?.Page]);

      return {
        data,
        isLoading: false,
      };
    },
  });
