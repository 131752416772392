import { useCallback, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { useListPIMProperties } from "./store/list-pim-properties";
import {
  allOrderLinesForOrderServiceState,
  orderLinesRequestLimitSelector,
  orderLinesRequestShowCancelledLinesFilterSelector,
  orderLinesRequestStartSelector,
  orderLinesResultSelector,
  orderLinesResultTotalSelector,
  orderLinesServiceState,
} from "./store/order";

import useSetRequest from "~/hooks/suite-react-hooks/use-set-request";
import { useBooleanAppSetting } from "~/util/application-configuration";

export const useCurrentOrderLines = ({ orderID, ui }: { orderID?: number; ui: string }) => {
  const { data: allPimProperties } = useListPIMProperties();
  const [limit, setLimit] = useRecoilState(
    orderLinesRequestLimitSelector({ OrderID: orderID, UI: ui }),
  );
  const [start, setStart] = useRecoilState(
    orderLinesRequestStartSelector({ OrderID: orderID, UI: ui }),
  );
  const hideCancelledLines = useRecoilValue(
    orderLinesRequestShowCancelledLinesFilterSelector({ OrderID: orderID, UI: ui }),
  );
  const showOnlyShippableLinesAppSetting = useBooleanAppSetting(
    "App:Order:ShowOnlyShippableLines",
    true,
  );

  const newRequest = useMemo<EVA.Core.ListOrderLines | undefined>(() => {
    if (orderID && allPimProperties) {
      return {
        OrderID: orderID,
        PageConfig: {
          Limit: limit,
          Start: start,
          Filter: { OnlyShippableLines: hideCancelledLines ?? showOnlyShippableLinesAppSetting },
        },
        UseHierarchy: true,
        ProductProperties: [
          "primary_image",
          ...(allPimProperties ?? []).map((pimProperty) => pimProperty.ID),
        ],
      };
    }
    return undefined;
  }, [
    allPimProperties,
    hideCancelledLines,
    limit,
    orderID,
    showOnlyShippableLinesAppSetting,
    start,
  ]);

  useSetRequest(orderLinesServiceState.request({ OrderID: orderID, UI: ui }), newRequest);

  const currentOrderLinesTotal = hooks.useGetState(
    orderLinesResultTotalSelector({ OrderID: orderID, UI: ui }),
  );
  const currentOrderLines = hooks.useGetState(
    orderLinesResultSelector({ OrderID: orderID, UI: ui }),
  );
  const currentOrderLinesLoading = hooks.useIsLoading({
    state: orderLinesResultSelector({ OrderID: orderID, UI: ui }),
  });

  const currentOrderLinesLoadingWithoutResponse = useMemo(() => {
    return currentOrderLinesLoading && !currentOrderLines;
  }, [currentOrderLines, currentOrderLinesLoading]);

  const refetchPaginatedOrderLines = hooks.useSetStale({
    staleState: orderLinesServiceState.stale({ OrderID: orderID, UI: ui }),
  });

  const refetchAllOrderLines = hooks.useSetStale({
    staleState: allOrderLinesForOrderServiceState.stale(orderID),
  });

  const refetchCurrentOrderLines = useCallback(() => {
    refetchPaginatedOrderLines();
    refetchAllOrderLines();
  }, [refetchAllOrderLines, refetchPaginatedOrderLines]);

  return {
    currentOrderLines,
    currentOrderLinesTotal,
    currentOrderLinesLoading,
    currentOrderLinesLoadingWithoutResponse,
    refetchCurrentOrderLines,
    limit,
    setLimit,
    start,
    setStart,
  };
};
