import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listShippingMethodsQuery } from "~/models/shipping-methods";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateShippingMethodsSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.ListShippingMethods,
    Pick<
      EVA.Core.Management.ListShippingMethodsResponse.ShippingMethodDto,
      "CarrierName" | "ID" | "Name"
    >,
    EVA.Core.Management.ListShippingMethodsResponse.ShippingMethodDto["ID"]
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listShippingMethodsQuery,
      }),

    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getDescription: (item) => item.CarrierName,

    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((sm) => ({ ID: sm.ID, Name: sm.Name, CarrierName: sm.CarrierName })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.shipping-method",
      defaultMessage: "Shipping method",
    }),
    useItemByID: (id, items) => {
      const item = useMemo(() => items?.find((item) => item.ID === id), [id, items]);
      return {
        data: id ? item : undefined,
      };
    },
  });
