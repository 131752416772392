import { CoreManagement } from "@springtree/eva-services-core-management";

import { useRefundReasonByID } from "./use-refund-reason-by-id";

import { listRefundCorrectionReasonsQuery } from "~/models/reasons";
import { DEFAULT_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateRefundReasonsSearchListField = (
  initialFilters?: EVA.Core.ListRefundCorrectionReasonsFilter,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListRefundCorrectionReasons,
    {
      ID?: number;
      Name?: string;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name!,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) => resp?.Results?.map((item) => ({ ID: item.ID, Name: item.Name })),

    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.refund-reason",
      defaultMessage: "Refund reason",
    }),
    useItemByID: useRefundReasonByID,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listRefundCorrectionReasonsQuery,
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: {
          InitialPageConfig: {
            Limit: DEFAULT_LIMIT,
            Filter: initialFilters,
          },
        },
      }),
  });
