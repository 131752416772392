import { forwardRef, memo, ReactNode } from "react";
import { useIntl } from "react-intl";

import { Button, Card, SvgIcon, Text } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "clsx";

import type IDraggable from "../drag-and-drop.types";

const backgroundImage =
  "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgba(0,0,0,0.38)' stroke-width='1' stroke-dasharray='6%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")";

const draggableCardStyle = cva(["rounded", "!mb-5", "hover:cursor-grab"], {
  variants: {
    isDisabled: {
      false: [],
      true: ["border-none", "!bg-transparent"],
    },
  },
  defaultVariants: {
    isDisabled: false,
  },
});

export interface IDraggableCardProps extends IDraggable {
  label?: string;
  isDisabled?: boolean;
  toggleVisible?: () => void;
  removeCardTooltip?: string;
  addCardTooltip?: string;
  children?: ReactNode;
}

const DraggableCard = memo(
  forwardRef<HTMLDivElement, IDraggableCardProps>(
    (
      {
        addCardTooltip,
        isDisabled,
        isDragging,
        label,
        listeners,
        removeCardTooltip,
        toggleVisible,
      },
      ref,
    ) => {
      const intl = useIntl();

      return (
        <Card
          ref={ref}
          variant={isDisabled ? "secondary" : "primary"}
          className={draggableCardStyle({ isDisabled })}
          style={{ backgroundImage: isDisabled ? backgroundImage : undefined }}
        >
          <div className="flex h-12 flex-nowrap items-center justify-between">
            <div className="flex h-full flex-grow items-center pl-4" {...listeners}>
              <Text
                variant="heading-3"
                className={classNames(
                  "text-[color:var(--legacy-eva-color-transparent-900)]",
                  isDisabled && "opacity-[.47]",
                )}
              >
                {label}
              </Text>
            </div>

            <div className="flex items-center gap-2 pr-[10px]">
              <div {...listeners}>
                <SvgIcon
                  name="drag-handle"
                  className={classNames(
                    "h-4 w-4",
                    isDragging && "text-[var(--legacy-eva-color-primary)]",
                  )}
                />
              </div>

              {isDisabled ? (
                <Button
                  variant="icon"
                  onPress={toggleVisible}
                  tooltip={
                    addCardTooltip ??
                    intl.formatMessage({
                      id: "generic.label.show-card",
                      defaultMessage: "Show card",
                    })
                  }
                >
                  <SvgIcon name="password-visible" />
                </Button>
              ) : (
                <Button
                  variant="icon"
                  onPress={toggleVisible}
                  tooltip={
                    removeCardTooltip ??
                    intl.formatMessage({
                      id: "generic.label.hide-card",
                      defaultMessage: "Hide card",
                    })
                  }
                >
                  <SvgIcon name="password-hidden" />
                </Button>
              )}
            </div>
          </div>
        </Card>
      );
    },
  ),
);

DraggableCard.displayName = "DraggableCard";

export default DraggableCard;
