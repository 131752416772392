export const ALL_ROWS_LIMIT = 9999;

export const DEFAULT_LIMIT = 25;
export const DEFAULT_SEARCH_LIST_FIELD_LIMIT = 10;
export const DEFAULT_START = 0;

export const ONE_SECOND = 1000;
export const THIRTY_SECONDS = 30 * 1000;
export const ONE_MINUTE = 1000 * 60;
export const FIVE_MINUTES = ONE_MINUTE * 5;
export const ONE_HOUR = ONE_MINUTE * 60;

export const DEFAULT_LOCALE = "en-GB";

export const SUPPORTED_EXTRA_LANGUAGES = [
  "ar",
  "cs",
  "da",
  "de",
  "es",
  "fi",
  "fr",
  "hu",
  "it",
  "ja",
  "nl",
  "no",
  "pl",
  "pt",
  "sk",
  "sv",
  "ro",
  "zh-HK",
  "zh-CN",
];

export const LOCALE_LANGUAGES_FALLBACKS: Record<string, string> = { zh: "zh-CN" };

export const INT32MAX = 2147483647;

// Mime types supported by `<img>` element
export const SUPPORTED_IMG_MIME_TYPES = [
  "image/apng",
  "image/avif",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
];

export const SUPPORTED_IMG_MIME_TYPES_EXTENSIONS = {
  "image/apng": ".apng",
  "image/avif": ".avif",
  "image/gif": ".gif",
  "image/jpeg": ".jpeg",
  "image/png": ".png",
  "image/svg+xml": ".svg",
  "image/webp": ".webp",
};

// Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPE_EXTENSION_MAP = {
  "application/epub+zip": ".epub",
  "application/gzip": ".gz",
  "application/java-archive": ".jar",
  "application/json": ".json",
  "application/ld+json": ".jsonld",
  "application/msword": ".doc",
  "application/octet-stream": ".bin",
  "application/ogg": ".ogx",
  "application/pdf": ".pdf",
  "application/rtf": ".rtf",
  "application/vnd.amazon.ebook": ".azw",
  "application/vnd.apple.installer+xml": ".mpkg",
  "application/vnd.mozilla.xul+xml": ".xul",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.ms-fontobject": ".eot",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.oasis.opendocument.presentation": ".odp",
  "application/vnd.oasis.opendocument.spreadsheet": ".ods",
  "application/vnd.oasis.opendocument.text": ".odt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
  "application/vnd.rar": ".rar",
  "application/vnd.visio": ".vsd",
  "application/x-7z-compressed": ".7z",
  "application/x-abiword": ".abw",
  "application/x-bzip": ".bz",
  "application/x-bzip2": ".bz2",
  "application/x-cdf": ".cda",
  "application/x-csh": ".csh",
  "application/x-freearc": ".arc",
  "application/x-httpd-php": ".php",
  "application/x-sh": ".sh",
  "application/x-tar": ".tar",
  "application/xhtml+xml": ".xhtml",
  "application/xml": ".xml",
  "application/zip": ".zip",
  "audio/aac": ".aac",
  "audio/midi": ".midi",
  "audio/mpeg": ".mp3",
  "audio/ogg": ".oga",
  "audio/opus": ".opus",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "font/otf": ".otf",
  "font/ttf": ".ttf",
  "font/woff": ".woff",
  "font/woff2": ".woff2",
  "image/apng": ".apng",
  "image/avif": ".avif",
  "image/bmp": ".bmp",
  "image/gif": ".gif",
  "image/jpeg": ".jpeg",
  "image/png": ".png",
  "image/svg+xml": ".svg",
  "image/tiff": ".tiff",
  "image/vnd.microsoft.icon": ".ico",
  "image/webp": ".webp",
  "text/calendar": ".ics",
  "text/css": ".css",
  "text/csv": ".csv",
  "text/html": ".html",
  "text/javascript": ".js",
  "text/plain": ".txt",
  "video/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "video/mp2t": ".ts",
  "video/mp4": ".mp4",
  "video/mpeg": ".mpeg",
  "video/ogg": ".ogv",
  "video/webm": ".webm",
  "video/x-msvideo": ".avi",
} as const;

export const EXTENSION_MIME_TYPE_MAP = Object.fromEntries(
  Object.entries(MIME_TYPE_EXTENSION_MAP).map(([mimeType, extension]) => [extension, mimeType]),
) as {
  [key in (typeof MIME_TYPE_EXTENSION_MAP)[keyof typeof MIME_TYPE_EXTENSION_MAP]]: keyof typeof MIME_TYPE_EXTENSION_MAP;
};
