import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listSerialNumbersLoaderQuery,
  serviceQueryKeys: listSerialNumbersQueryKeys,
  useServiceQueryHook: useListSerialNumbersQuery,
  useServiceQueryWithRequest: useListSerialNumbersQueryWithRequest,
} = createServiceQuery(Core.ListSerialNumbers, true);
