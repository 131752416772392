import { z } from "zod";
import { zfd } from "zod-form-data";

const productSetFiltersSchema = z.object({
  PropertyName: z.string(),
  Values: z.array(z.any()).optional(),
  ExactMatch: z.boolean().optional(),
  IncludeMissing: z.boolean().optional(),
  Exclude: z.boolean().optional(),
  From: z.string().optional(),
  To: z.string().optional(),
});

export const productSetSchema = z.object({
  CountryID: z.string().optional(),
  LanguageID: z.string().optional(),
  Filters: z.array(productSetFiltersSchema),
  Name: z.string().optional(),
  DiscountableQuantityOfProducts: zfd.numeric(z.number().optional()),
  IsDiscountableProductSet: z.boolean().optional(),
  ProductSearchID: zfd.numeric(z.number().optional()),
  RequiredQuantityOfProducts: zfd.numeric(z.number().optional()),
  RequiredProductAmount: zfd.numeric(z.number().optional()),
});

export type TProductSet = z.infer<typeof productSetSchema>;
