import { IntlShape } from "react-intl";

import { RepairStatus } from "../eva-core";

export const getRepairStatusTranslator = (
  intl: IntlShape,
): ((status: number) => string | undefined) => {
  const translationMap: Record<EVA.Core.RepairStatus | number, string> = {
    [RepairStatus.Cancelled]: intl.formatMessage({
      id: "generic.label.cancelled",
      defaultMessage: "Cancelled",
    }),
    [RepairStatus.Finished]: intl.formatMessage({
      id: "generic.label.finished",
      defaultMessage: "Finished",
    }),
    [RepairStatus.Started]: intl.formatMessage({
      id: "generic.label.started",
      defaultMessage: "Started",
    }),
    [RepairStatus.WaitingForGoods]: intl.formatMessage({
      id: "generic.label.waiting-for-goods",
      defaultMessage: "Waiting for goods",
    }),
    [RepairStatus.WaitingForGoods_OnHold]: intl.formatMessage({
      id: "generic.label.on-hold",
      defaultMessage: "On hold",
    }),

    [RepairStatus.WaitingForPayment]: intl.formatMessage({
      id: "generic.label.waiting-for-payment",
      defaultMessage: "Waiting for waiting for payment",
    }),
    [RepairStatus.WaitingForRepair]: intl.formatMessage({
      id: "generic.label.waiting-for-repair",
      defaultMessage: "Waiting for repair",
    }),
    [RepairStatus.WaitingForRepair_OnHold]: intl.formatMessage({
      id: "generic.label.on-hold",
      defaultMessage: "On hold",
    }),
    [RepairStatus.WaitingForShipment]: intl.formatMessage({
      id: "generic.label.waiting-for-shipment",
      defaultMessage: "Waiting for shipment",
    }),
    [RepairStatus.WaitingForShipment_OnHold]: intl.formatMessage({
      id: "generic.label.on-hold",
      defaultMessage: "On hold",
    }),
    [RepairStatus.WaitingForPickup]: intl.formatMessage({
      id: "generic.label.waiting-for-pickup",
      defaultMessage: "Waiting for pickup",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
