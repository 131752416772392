import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { VisibilityType } from "~/types/eva-core";
import { getAddressFieldVisibilityTypeTranslator } from "~/types/translations-enums/address-field-visibility-type";
import enumToArray from "~/util/enum-to-array";

export interface IAddressFieldVisibilityTypeSelect {
  name?: string;
  errorMessage?: string;
  value?: number;
  onChange: (val?: number) => void;
  isRequired?: boolean;
  hideHintLabel?: boolean;
  disableClearLogic?: boolean;
}

export const AddressFieldVisibilityTypeSelect = ({
  disableClearLogic,
  errorMessage,
  hideHintLabel,
  isRequired,
  name,
  onChange,
  value,
}: IAddressFieldVisibilityTypeSelect) => {
  const intl = useIntl();

  const translateAddressFieldVisibilityType = useMemo(
    () => getAddressFieldVisibilityTypeTranslator(intl),
    [intl],
  );

  const items = useMemo(
    () =>
      enumToArray(VisibilityType)?.map((enumValue) => ({
        ...enumValue,
        key: translateAddressFieldVisibilityType(enumValue.value),
      })) ?? [],
    [translateAddressFieldVisibilityType],
  );

  const selectedValue = useMemo(() => items.find((item) => item.value === value), [items, value]);

  return (
    <Select
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      name={name ?? ""}
      value={selectedValue}
      onChange={(newValue) => onChange(newValue?.value)}
      items={items}
      label={intl.formatMessage({ id: "generic.label.visibility", defaultMessage: "Visibility" })}
      errorMessage={errorMessage}
      selectRenderElements={(item) => ({ label: item.key })}
      getItemId={(item) => item.value}
      getLabel={(item) => item.key}
      disableClearLogic={disableClearLogic}
    />
  );
};
