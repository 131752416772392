import { Radio, RadioGroup, RadioGroupProps } from "@new-black/lyra";
import { useField } from "formik";

import type { IRadioButtonList } from "~/components/suite-ui/radio-button-list";
import RadioButtonList from "~/components/suite-ui/radio-button-list";
import type { IRadioButtonLabel } from "~/components/suite-ui/radio-button-list/radio-button-label";
import RadioButtonLabel from "~/components/suite-ui/radio-button-list/radio-button-label";
import Text from "~/components/suite-ui/text";

interface IFormikRadioListOptionsProp {
  options: Omit<IRadioButtonLabel, "activeValue">[];
}

interface IFormikRadioButtonList
  extends Omit<
    IRadioButtonList & IFormikRadioListOptionsProp,
    "children" | "selectedValue" | "handleSelectedValueChange"
  > {
  name: string;
}

interface IFormikRadioButtonListExportProps {
  ({ name, options, ...props }: IFormikRadioButtonList): JSX.Element;
  /** @deprecated Use the Lyra RadioGroup wrapped in a Formik Field component directly instead */
  Lyra: ({ name, options, ...props }: LyraFormikRadioGroupProps) => JSX.Element;
}

export const FormikRadioButtonList: IFormikRadioButtonListExportProps = ({
  name,
  options,
  ...props
}: IFormikRadioButtonList) => {
  const [field, meta] = useField<string | undefined>(name);

  return (
    <>
      <RadioButtonList
        {...props}
        {...field}
        selectedValue={field.value ?? ""}
        handleSelectedValueChange={field.onChange}
      >
        {options.map((item) => (
          <RadioButtonLabel {...item} key={item.value} activeValue={field.value ?? ""} />
        ))}
      </RadioButtonList>

      {!!meta.error && meta.touched ? (
        <Text variant="caption" color="error">
          {meta.error}
        </Text>
      ) : null}
    </>
  );
};

export interface LyraFormikRadioGroupProps
  extends Omit<
    RadioGroupProps & IFormikRadioListOptionsProp,
    "value" | "onChange" | "defaultValue" | "name"
  > {
  name: string;
}

/** @deprecated Use the Lyra RadioGroup wrapped in a Formik Field component directly instead */
export function LyraFormikRadioButtonList({ name, options, ...props }: LyraFormikRadioGroupProps) {
  const [field, meta, { setValue }] = useField<string | undefined>(name);

  return (
    <RadioGroup
      {...props}
      {...field}
      value={field.value ?? ""}
      onChange={(newValue) => setValue(newValue)}
      errorMessage={meta.error && meta.touched ? meta.error : undefined}
    >
      {options.map((item) => (
        <Radio key={item.value} value={item.value ?? ""}>
          {item.label}
        </Radio>
      ))}
    </RadioGroup>
  );
}

FormikRadioButtonList.Lyra = LyraFormikRadioButtonList;
