import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  PasswordTextField,
} from "@new-black/lyra";

import {
  IdentificationCodeQRImage,
  IdentificationMethodRadioButtons,
  IdentificationMethodSaveButton,
} from "./helper-components";
import { IdentificationCodeDialogProps, IdentificationMethod } from "./types";
import { useCreatePINCode } from "./use-create-pin-code";
import { useGenerateQRCode } from "./use-generate-qr-code";

import { generateOrganizationUnitLyraSearchListField } from "~/components/suite-composite/organization-unit-lyra-search-list-field";
import Text from "~/components/suite-ui/text";
import { OrganizationUnitTypes } from "~/types/eva-core";

const { SingleIDSearchListField: OrganizationUnitIDSearchListField } =
  generateOrganizationUnitLyraSearchListField({
    PageConfig: { Filter: { TypeID: OrganizationUnitTypes.Shop } },
  });

export const IdentificationCodeDialog = ({
  open,
  setIsFocused,
  setMouseEntered,
  setOpen,
  user,
}: IdentificationCodeDialogProps) => {
  const intl = useIntl();

  const [method, setMethod] = useState<IdentificationMethod>("qr");
  const [identificationCode, setIdentificationCode] = useState<string>();
  const [organizationUnitID, setOrganizationUnitID] = useState(user?.CurrentOrganizationID);
  const [pinCode, setPinCode] = useState<number>();

  const resetModalState = useCallback(() => {
    setIdentificationCode(undefined);
    setOrganizationUnitID(user?.CurrentOrganizationID);
    setPinCode(undefined);
  }, [user?.CurrentOrganizationID]);

  const onClose = useCallback(() => {
    setOpen(false);
    setMouseEntered?.(false);
    setIsFocused?.(false);

    resetModalState();
  }, [resetModalState, setIsFocused, setMouseEntered, setOpen]);

  const { callback: onGenerateQRCode, isLoading: isGenerateQRCodeLoading } =
    useGenerateQRCode(setIdentificationCode);

  const { callback: onCreatePINCode, isLoading: isCreatePINCodeLoading } = useCreatePINCode(
    pinCode,
    organizationUnitID,
    onClose,
  );

  const isLoading = useMemo(
    () => isGenerateQRCodeLoading || isCreatePINCodeLoading,
    [isCreatePINCodeLoading, isGenerateQRCodeLoading],
  );

  const isDisabled = useMemo(() => {
    if (method === "qr") return isLoading;

    return isLoading || pinCode === undefined || organizationUnitID === undefined;
  }, [isLoading, method, organizationUnitID, pinCode]);

  return (
    <Dialog maxWidth="sm" isOpen={open} onOpenChange={(open) => (!open ? onClose() : undefined)}>
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.identification-code",
            defaultMessage: "Identification code",
          })}
        />

        <CardContent className="flex flex-col gap-4">
          <Text>
            <FormattedMessage
              id="profile.identification-code.dialog.description"
              defaultMessage="Generate a new code when lost or leaked, this will render the old code invalid."
            />
          </Text>

          <IdentificationMethodRadioButtons
            value={method}
            onChange={(newValue) => {
              setMethod(newValue);
              resetModalState();
            }}
          />

          {method === "qr" && identificationCode ? (
            <IdentificationCodeQRImage
              identificationCode={identificationCode}
              className="my-4 self-center"
            />
          ) : null}

          {method === "pin" ? (
            <>
              <OrganizationUnitIDSearchListField.Controlled
                isRequired
                value={organizationUnitID}
                onChange={setOrganizationUnitID}
              />

              <PasswordTextField
                inputMode="numeric"
                isRequired
                value={pinCode?.toString() ?? ""}
                onChange={(newValue) => {
                  const valueToSet = parseInt(newValue);
                  setPinCode(isNaN(valueToSet) ? undefined : valueToSet);
                }}
                label={intl.formatMessage({
                  id: "generic.label.pin-code",
                  defaultMessage: "PIN code",
                })}
                description={intl.formatMessage({
                  id: "generic.label.please-enter-5-digits",
                  defaultMessage: "Please enter 5 digits",
                })}
                maxLength={5}
              />
            </>
          ) : null}
        </CardContent>

        <CardFooter>
          <ButtonGroup>
            <Button variant="secondary" onPress={onClose} isDisabled={isLoading}>
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>

            <IdentificationMethodSaveButton
              method={method}
              onGenerateQRCode={onGenerateQRCode}
              onCreatePINCode={onCreatePINCode}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
          </ButtonGroup>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};
