import { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import { useRevalidator } from "react-router";

import { CardContent, CardHeader, Dialog, DialogCard, SvgIcon } from "@new-black/lyra";
import { defer } from "lodash";

import { ActionSubmitFooter } from "../action-submit-footer";

import { useOUSelector } from "./ou-selector/use-ou-selector";
import { MenuOUSelectorProps } from "./menu.types";
import { MenuButton } from "./menu-button";

import {
  generateOrganizationUnitLyraSearchListField,
  OrganizationUnitSearchListFieldItem,
} from "~/components/suite-composite/organization-unit-lyra-search-list-field";
import { queryClient } from "~/util/query-client";

const OrganizationUnitSearchListField = generateOrganizationUnitLyraSearchListField();

export const MenuOUSelector = ({
  menuState,
  setIsFocused,
  setMouseEntered,
}: MenuOUSelectorProps) => {
  const intl = useIntl();
  const { selectedOrganizationUnit } = useOUSelector();

  const [ouSelectorOpen, setOUSelectorOpen] = useState(false);

  if (selectedOrganizationUnit) {
    return (
      <>
        <MenuButton
          icon={<SvgIcon name="organization-unit" />}
          labelKey={intl.formatMessage({
            id: "generic.label.organization-unit",
            defaultMessage: "Organization unit",
          })}
          labelValue={selectedOrganizationUnit.Name ?? "-"}
          menuState={menuState}
          setMouseEntered={setMouseEntered}
          setIsFocused={setIsFocused}
          onClick={() => setOUSelectorOpen(true)}
        />
        {ouSelectorOpen && <OUSelectorDialog open={ouSelectorOpen} setOpen={setOUSelectorOpen} />}
      </>
    );
  }

  return null;
};

const OUSelectorDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const intl = useIntl();

  const { handleOUSelection, selectedOrganizationUnit: initialSelectedOU } = useOUSelector();

  const revalidator = useRevalidator();

  const [selectedOrganizationUnit, setSelectedOrganizationUnit] = useState<
    OrganizationUnitSearchListFieldItem | undefined
  >(initialSelectedOU);

  const confirmOUHandler = async (OU: OrganizationUnitSearchListFieldItem) => {
    handleOUSelection(OU);
    setOpen(false);

    queryClient.invalidateQueries();
    queryClient.resetQueries();
    queryClient.removeQueries();
    defer(() => revalidator.revalidate());
  };

  return (
    <Dialog isOpen={open} onOpenChange={(isOpen) => !isOpen && setOpen(false)} maxWidth="lg">
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.select-an-organization-unit",
            defaultMessage: "Select an organization unit",
          })}
        />
        <CardContent>
          <OrganizationUnitSearchListField.SingleSearchListField.Controlled
            hideHintLabel
            value={selectedOrganizationUnit}
            disableClearLogic
            onChange={(OU) => OU && setSelectedOrganizationUnit(OU)}
          />
        </CardContent>
        <ActionSubmitFooter
          messages={{
            saveButtonMessage: intl.formatMessage({
              id: "generic.label.confirm",
              defaultMessage: "Confirm",
            }),
          }}
          hideButtons={{
            delete: true,
          }}
          onCancel={() => setOpen(false)}
          onSavePress={() => selectedOrganizationUnit && confirmOUHandler(selectedOrganizationUnit)}
        />
      </DialogCard>
    </Dialog>
  );
};
