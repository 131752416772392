import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { uniq, uniqBy } from "lodash";

import { RolesMultiAutocomplete } from "./roles-multi-autocomplete";
import { RolesSingleAutocomplete } from "./roles-single-autocomplete";
import { IIRolesSingleAutocompleteBaseProps, IRolesMultiAutocompleteProps } from "./types";

import {
  getRolesForOrganizationUnitSetQuery,
  listRolesQuery,
  useListRolesQuery,
} from "~/models/roles";
import { rolesForOUSetSelector } from "~/store/get-roles-for-organization-unit-set";
import { rolesListSelector } from "~/store/roles";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const RolesInput = ({
  disabled,
  error,
  getOptionDisabled,
  helperText,
  initialRoles,
  label,
  onBlur,
  OUSetID,
  passive,
  required,
  rolesLoading: externalRolesLoading,
  selectedRoleID,
  selectedRoleIDs,
  setSelectedRole,
  setSelectedRoleID,
  setSelectedRoleIDs,
  setSelectedRoles,
}: IRolesMultiAutocompleteProps | IIRolesSingleAutocompleteBaseProps) => {
  const rolesSelector = useMemo(() => {
    return OUSetID ? rolesForOUSetSelector(OUSetID) : rolesListSelector;
  }, [OUSetID]);

  const rolesFromService = hooks.useGetState(rolesSelector);

  const rolesStateLoading = hooks.useIsLoading({
    state: rolesSelector,
  });

  const rolesLoading = useMemo(
    () => rolesStateLoading || externalRolesLoading,
    [externalRolesLoading, rolesStateLoading],
  );

  const roles = useMemo(() => {
    return uniqBy([...(initialRoles ?? []), ...(rolesFromService ?? [])], "ID");
  }, [initialRoles, rolesFromService]);

  if (setSelectedRoleID || setSelectedRole) {
    return (
      <RolesSingleAutocomplete
        label={label}
        passive={passive}
        selectedRoleID={selectedRoleID}
        setSelectedRole={setSelectedRole}
        setSelectedRoleID={setSelectedRoleID}
        roles={roles}
        rolesLoading={rolesLoading}
        disabled={disabled}
        onBlur={onBlur}
        getOptionDisabled={getOptionDisabled}
        error={error}
        helperText={helperText}
        required={required}
      />
    );
  }

  if (setSelectedRoleIDs || setSelectedRoles) {
    return (
      <RolesMultiAutocomplete
        label={label}
        passive={passive}
        selectedRoleIDs={selectedRoleIDs}
        setSelectedRoles={setSelectedRoles}
        setSelectedRoleIDs={setSelectedRoleIDs}
        roles={roles}
        rolesLoading={rolesLoading}
        disabled={disabled}
        onBlur={onBlur}
        getOptionDisabled={getOptionDisabled}
        error={error}
        helperText={helperText}
        required={required}
      />
    );
  }

  return null;
};

export const generateRoleAutocomplete = (ouSetID?: number) =>
  ouSetID
    ? AutocompleteGenerator<
        CoreManagement.GetRolesForOrganizationUnitSet,
        { ID: number; Name: string },
        "ID"
      >({
        idKey: "ID",
        labelKey: "Name",
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.role",
          defaultMessage: "Role",
        }),
        getItemFromResponse: (resp) =>
          resp?.Roles?.map((result) => ({ ID: result.ID, Name: result.Name })),
        useServiceQuery: () =>
          AutocompleteGenerator.useAutocompleteService({
            refetchOnFocus: false,
            query: getRolesForOrganizationUnitSetQuery,
            initialRequest: { OrganizationUnitSetID: ouSetID },
          }),
      })
    : AutocompleteGenerator<CoreManagement.ListRoles, { ID: number; Name: string }, "ID">({
        idKey: "ID",
        labelKey: "Name",
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.role",
          defaultMessage: "Role",
        }),
        getItemFromResponse: (resp) =>
          resp?.Result?.Page?.map((result) => ({ ID: result.ID, Name: result.Name })),
        useServiceQuery: () =>
          AutocompleteGenerator.useAutocompleteService({
            refetchOnFocus: false,
            query: listRolesQuery,
            initialRequest: { PageConfig: { Limit: 5, Start: 0, Filter: {} } },
            getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
            setQueryRequest: (prev, newValue) => ({
              ...prev,
              PageConfig: {
                ...prev?.PageConfig,
                Filter: {
                  ...prev?.PageConfig?.Filter,
                  Name: newValue === "" ? undefined : newValue,
                },
              },
            }),
          }),
      });

export function useRolesByID(roleIDs: (number | undefined)[] | undefined) {
  const searchIDs = useMemo(() => {
    const relevantIDs = roleIDs?.filter((id): id is number => id !== undefined);
    return relevantIDs ? uniq(relevantIDs) : undefined;
  }, [roleIDs]);
  const { data, isFetching } = useListRolesQuery(
    searchIDs?.length
      ? {
          PageConfig: {
            Start: 0,
            Limit: searchIDs.length,
            Filter: { IDs: searchIDs },
          },
        }
      : undefined,
    { denyEmptyRequest: true },
  );
  const resp = useMemo(() => data?.Result?.Page, [data]);
  return { data: roleIDs?.length ? resp : undefined, isLoading: isFetching };
}

function useRoleByID(roleID: number | undefined) {
  const resp = useRolesByID([roleID]);
  const data = useMemo(() => (!roleID ? undefined : resp.data?.[0]), [roleID, resp.data]);
  return { data, isLoading: resp.isLoading };
}

export const generateRoleLyraSearchListField = (ouSetID?: number) =>
  ouSetID
    ? LyraSearchListFieldGenerator<
        CoreManagement.GetRolesForOrganizationUnitSet,
        { ID: number; Name: string },
        number
      >({
        useItemByID: useRoleByID,
        useItemsByID: useRolesByID,
        getItemId: (item) => item.ID,
        getLabel: (item) => item.Name,
        getItemFromResponse: (resp) => resp?.Roles,
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.role",
          defaultMessage: "Role",
        }),
        useServiceQuery: () =>
          LyraSearchListFieldGenerator.useSearchListFieldService({
            refetchOnFocus: false,
            query: getRolesForOrganizationUnitSetQuery,
            initialRequest: { OrganizationUnitSetID: ouSetID },
          }),
      })
    : LyraSearchListFieldGenerator<CoreManagement.ListRoles, { ID: number; Name: string }, number>({
        useItemByID: useRoleByID,
        useItemsByID: useRolesByID,
        getItemId: (item) => item.ID,
        getLabel: (item) => item.Name,
        getItemFromResponse: (resp) => resp?.Result?.Page,
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.role",
          defaultMessage: "Role",
        }),
        useServiceQuery: () =>
          LyraSearchListFieldGenerator.useSearchListFieldService({
            refetchOnFocus: false,
            query: listRolesQuery,
            initialRequest: { PageConfig: { Limit: 10, Start: 0, Filter: {} } },
            getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
            setQueryRequest: (prev, newValue) => ({
              ...prev,
              PageConfig: {
                ...prev?.PageConfig,
                Filter: {
                  ...prev?.PageConfig?.Filter,
                  Name: newValue === "" ? undefined : newValue,
                },
              },
            }),
          }),
      });
