import { IntlShape } from "react-intl";

import { ZonedCycleCountSerialNumberStatus } from "../eva-core";

export const getZonedCycleCountSerialNumberStatusTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.ZonedCycleCountSerialNumberStatus, string> = {
    [ZonedCycleCountSerialNumberStatus.ExpectedAndCounted]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.expected-and-counted",
      defaultMessage: "Expected & counted",
    }),
    [ZonedCycleCountSerialNumberStatus.ExpectedNotCounted]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.expected-but-not-counted",
      defaultMessage: "Expected but not counted",
    }),
    [ZonedCycleCountSerialNumberStatus.CountedOnDifferentStockLabel]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.unexpected-stock-label",
      defaultMessage: "Unexpected stocklabel",
    }),
    [ZonedCycleCountSerialNumberStatus.CountedOnDifferentOrganizationUnit]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.unexpected-ou",
      defaultMessage: "Unexpected OU",
    }),
    [ZonedCycleCountSerialNumberStatus.CountedAsDifferentProduct]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.unexpected-product",
      defaultMessage: "Unexpected product",
    }),
    [ZonedCycleCountSerialNumberStatus.Unexpected]: intl.formatMessage({
      id: "zoned-cycle-count.serial-number-status.unexpected",
      defaultMessage: "Unexpected",
    }),
  };

  return (status: EVA.Core.ZonedCycleCountSerialNumberStatus) => {
    return translationMap[status];
  };
};
