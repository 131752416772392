import { ReactNode } from "react";

import { Skeleton } from "@material-ui/lab";
import classNames from "clsx";

export interface ILoadingStateBox {
  limit: number;
  headerAvailable?: boolean;
  footerAvailable?: boolean;
  componentClassNames?: {
    container?: string;
    skeletonRow?: string;
  };
}

const SkeletonRow = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={`px-5 py-[6px] ${className}`}>{children}</div>
);

const LoadingStateBox = ({
  componentClassNames,
  footerAvailable,
  headerAvailable,
  limit,
}: ILoadingStateBox) => {
  const rows: number[] = Array.from(Array(limit).keys());

  return (
    <div className={classNames("py-5", componentClassNames?.container)}>
      {headerAvailable ? (
        <SkeletonRow
          className={classNames("flex justify-between", componentClassNames?.skeletonRow)}
        >
          <Skeleton width="40%" height="36px" variant="rect" />
          <Skeleton width="36px" height="36px" variant="circle" />
        </SkeletonRow>
      ) : null}
      {rows.map((row) => (
        <SkeletonRow key={row} className={componentClassNames?.skeletonRow}>
          <Skeleton width="100%" height="36px" variant="rect" />
        </SkeletonRow>
      ))}
      {footerAvailable ? (
        <SkeletonRow className={classNames("flex-end flex", componentClassNames?.skeletonRow)}>
          <Skeleton width="50%" height="36px" variant="rect" />
        </SkeletonRow>
      ) : null}
    </div>
  );
};

export default LoadingStateBox;
