import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { To, useNavigate } from "react-router";

import { Dialog } from "@material-ui/core";
import { Button, Card, CardContent, CardFooter, CardHeader, Text } from "@new-black/lyra";

import { usePrompt } from "./provider";

export const BlockedRoutingDialog = () => {
  const intl = useIntl();
  const { confirmRoute, setConfirmRoute } = usePrompt();
  const onCancel = () => setConfirmRoute(undefined);
  const navigate = useNavigate();
  const onConfirm = useCallback(() => {
    if (confirmRoute !== undefined) {
      setConfirmRoute(undefined);
      navigate(confirmRoute as To);
    }
  }, [confirmRoute, navigate, setConfirmRoute]);

  return (
    <Dialog open={!!confirmRoute} onClose={onCancel} maxWidth="xs">
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.are-you-sure",
            defaultMessage: "Are you sure?",
          })}
        />
        <CardContent>
          <Text variant="body-medium">
            <FormattedMessage
              id="generic.label.blocked-route-message"
              defaultMessage="You are about to leave this content page without saving the adjustments made. Are you sure?"
            />
          </Text>
        </CardContent>
        <CardFooter>
          <Button onPress={onCancel} variant="secondary">
            <FormattedMessage id="generic.label.no" defaultMessage="No" />
          </Button>
          <Button onPress={onConfirm}>
            <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
          </Button>
        </CardFooter>
      </Card>
    </Dialog>
  );
};
