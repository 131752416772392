import { ReactNode, useCallback } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import useCompleteTasks from "./use-complete-tasks";
import useWarningDialog from "./use-warning-dialog";

import { ConfirmationModal } from "~/components/suite-composite/confirmation-modal";

interface ITasksTableCompleteAction<T> {
  selectedLines: T[];
  refetchTasks: () => void;
  emptySelectedLines: () => void;
  completeTasksCustomIcon?: ReactNode;
  completeMultipleAtOnce?: boolean;
}

const TasksTableCompleteAction = <T extends EVA.Core.AvailableUserTaskDto>({
  completeMultipleAtOnce,
  completeTasksCustomIcon,
  emptySelectedLines,
  refetchTasks,
  selectedLines,
}: ITasksTableCompleteAction<T>) => {
  const intl = useIntl();
  const completeTasksService = useCompleteTasks(
    emptySelectedLines,
    refetchTasks,
    completeMultipleAtOnce,
  );

  const handleCompleteTask = useCallback(() => {
    completeTasksService(selectedLines.map((item) => item.ID));
  }, [completeTasksService, selectedLines]);

  const { onCloseWarningDialog, setShowWarningDialog, showWarningDialog } =
    useWarningDialog(handleCompleteTask);

  return (
    <>
      <Button
        variant="icon"
        tooltip={intl.formatMessage({
          id: "complete-tasks.label",
          defaultMessage: "Complete task(s)",
        })}
        isDisabled={!selectedLines.length}
        onPress={() => setShowWarningDialog(true)}
      >
        {completeTasksCustomIcon ?? <SvgIcon name="shipping" />}
      </Button>
      <ConfirmationModal
        variant="lyra"
        isOpen={showWarningDialog}
        onCancel={() => onCloseWarningDialog(false)}
        onConfirm={() => onCloseWarningDialog(true)}
        messages={{
          titleMessage: intl.formatMessage({
            id: "complete-tasks.label",
            defaultMessage: "Complete task(s)",
          }),
          descriptionMessage: intl.formatMessage(
            {
              id: "complete-tasks.warning-dialog.description",
              defaultMessage: "Are you sure you want to complete {numberOfTasks} task(s)?",
            },
            { numberOfTasks: selectedLines?.length ?? 0 },
          ),
        }}
      />
    </>
  );
};

export default TasksTableCompleteAction;
