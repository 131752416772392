import { IntlShape } from "react-intl";

import { DiscountTriggers } from "../eva-core";

export const triggerTypeTranslator = (
  intl: IntlShape,
): ((triggerType: Partial<DiscountTriggers>) => string | undefined) => {
  const translationMap: Record<Partial<DiscountTriggers>, string> = {
    [DiscountTriggers.Automatic]: intl.formatMessage({
      id: "discounts.trigger-types.price-rule",
      defaultMessage: "Price rule",
    }),
    [DiscountTriggers.Manual]: intl.formatMessage({
      id: "discounts.trigger-types.manual",
      defaultMessage: "Manual",
    }),
    [DiscountTriggers.Coupon]: intl.formatMessage({
      id: "discounts.trigger-types.coupon",
      defaultMessage: "Coupon",
    }),
    [DiscountTriggers.Loyalty]: intl.formatMessage({
      id: "generic.label.loyalty",
      defaultMessage: "Loyalty",
    }),
    [DiscountTriggers.Bundles]: intl.formatMessage({
      id: "generic.label.bundles",
      defaultMessage: "Bundles",
    }),
    [DiscountTriggers.SystemManaged]: intl.formatMessage({
      id: "generic.label.system-managed",
      defaultMessage: "System managed",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
