import {
  ComponentProps,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createIntl, IntlProvider, RawIntlProvider } from "react-intl";

import "moment/dist/locale/nl";
import "moment/dist/locale/fr";
import "moment/dist/locale/de";
import "moment/dist/locale/it";
import "moment/dist/locale/es";
import "moment/dist/locale/pt";
import "moment/dist/locale/ar";
import "moment/dist/locale/zh-hk";
import "moment/dist/locale/zh-cn";
import "moment/dist/locale/cs";
import "moment/dist/locale/fi";
import "moment/dist/locale/hu";
import "moment/dist/locale/ja";
import "moment/dist/locale/nn";
import "moment/dist/locale/pl";
import "moment/dist/locale/sk";
import "moment/dist/locale/sv";
import "moment/dist/locale/ro";

import EN from "~/assets/i18n/en.json";
import { setMomentLocale } from "~/routes/root/data/set-moment-locale";
import { DEFAULT_LOCALE } from "~/util/base-values";
import { contextGenerator } from "~/util/context-generator";
import { intlAccessor, intlCache } from "~/util/intl-accessor";

type OnErrorFn = Required<ComponentProps<typeof IntlProvider>>["onError"];

/** Context */
export const LocaleContext = createContext({} as { locale: string });

type TranslationProviderProps = {
  children: ReactNode;
};

const { Provider: TranslationContextProvider, useContext: useTranslationProviderContext } =
  contextGenerator<{
    setLocale: (locale: string) => void;
    setMessages: (messages: Record<TranslationKey, string>) => void;
  }>();

export { useTranslationProviderContext };

export default function TranslationProvider({ children }: TranslationProviderProps) {
  const onError = useCallback<OnErrorFn>((err) => {
    if (err.code === "MISSING_TRANSLATION") {
      console.warn("Missing translation", err.message);
      return;
    }
    if (err.code === "MISSING_DATA") {
      console.warn("Missing data", err.message);
      return;
    }
    console.error(err);
  }, []);

  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState<Record<TranslationKey, string>>({ ...EN });

  const intlInstance = useMemo(() => {
    const instance = createIntl(
      { messages, locale, defaultLocale: DEFAULT_LOCALE, onError },
      intlCache,
    );
    intlAccessor.setInstance(instance);

    return instance;
  }, [locale, messages, onError]);

  const value = useMemo(
    () => ({
      locale,
    }),
    [locale],
  );

  useEffect(() => {
    setMomentLocale(locale);
  }, [locale]);

  const contextValue = useMemo(() => ({ setLocale, setMessages }), [setLocale, setMessages]);

  return (
    <TranslationContextProvider value={contextValue}>
      <LocaleContext.Provider value={value}>
        <RawIntlProvider value={intlInstance}>{children}</RawIntlProvider>
      </LocaleContext.Provider>
    </TranslationContextProvider>
  );
}
