import { IntlShape } from "react-intl";

import { TaskState } from "~/types/eva-core";

export const getTaskStateTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.TaskState, string> = {
    [TaskState.Completed]: intl.formatMessage({
      id: "generic.label.completed",
      defaultMessage: "Completed",
    }),
    [TaskState.Running]: intl.formatMessage({
      id: "generic.label.running",
      defaultMessage: "Running",
    }),
    [TaskState.Scheduled]: intl.formatMessage({
      id: "generic.label.scheduled",
      defaultMessage: "Scheduled",
    }),
  };

  return (status: EVA.Core.TaskState) => {
    return translationMap[status];
  };
};
