import { CoreManagement } from "@springtree/eva-services-core-management";

import { listMessageTemplatesQuery } from "~/models/stencil";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateListMessageTemplateHandlersSearchListField = ({
  baseRequest,
}: {
  baseRequest?: EVA.Core.Management.ListMessageTemplates;
}) =>
  SearchListFieldGenerator<CoreManagement.ListMessageTemplates, { Name: string }, string>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listMessageTemplatesQuery,
        refetchOnFocus: false,
        initialRequest: {
          ...baseRequest,
          PageConfig: {
            ...baseRequest?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Start: 0,
            Filter: {
              ...baseRequest?.PageConfig?.Filter,
            },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue,
            },
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) <=
            (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item?.Name,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((messageTemplate) => ({ Name: messageTemplate.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.name",
      defaultMessage: "Name",
    }),
    useItemByID: (id, items) => {
      const item = items?.find((item) => item.Name === id);
      return { data: item, isLoading: false };
    },
  });
