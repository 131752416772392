import { useCallback, useReducer } from "react";
import { useFetcher } from "react-router";

import { loginService, useShouldUseJWT } from "./helpers";
import { loginStateReducer } from "./login.state";
import { LoginActionData } from "./login.types";
import { MainView } from "./main-view";
import { SSOView } from "./sso-view";
import { TwoFactorChooseMethodView } from "./two-factor-choose-method-view";
import { ConfirmTwoFactorView } from "./two-factor-confirm-view";
import { useHandleLoginResponse } from "./use-handle-login-response";

import routeDefinitions from "~/routes/route-definitions";

type LoginProps = {
  applicationConfiguration: { [key: string]: any } | undefined;
  openIDProviders: EVA.Authentication.OpenID.AvailableOpenIDConfiguration[] | undefined;
  onLogin: () => void;
};

export const Login = ({ applicationConfiguration, onLogin, openIDProviders }: LoginProps) => {
  const [loginState, dispatch] = useReducer(loginStateReducer, {
    view: "main",
    status: "idle",
  });

  const fetcher = useFetcher<LoginActionData | undefined>({ key: "LOGIN_TEST" });

  const shouldUseJWT = useShouldUseJWT();

  useHandleLoginResponse(
    dispatch,
    fetcher?.data?.loginResponse,
    fetcher?.data?.chosenAuthenticatorName,
  );

  const onSendToken = useCallback(async () => {
    // Create a promise to resolve
    return new Promise<void>((resolve) => {
      // Check if twoFactorMethod is set
      if (loginState.twoFactorMethod) {
        // Find the authenticator in the fetcher data
        const authenticator = fetcher.data?.loginResponse?.TwoFactorAuthenticators?.find(
          (authenticator) => authenticator.Name === loginState.twoFactorMethod,
        );

        // Check if the authenticator exists and is of type 1 (EMAIL/SMS)
        if (authenticator && authenticator.Type === 1) {
          // Call the login service
          loginService(
            {
              TwoFactorAuthenticator: loginState.twoFactorMethod,
              AsEmployee: true,
              UseJwtTokens: shouldUseJWT,
              SelectFirstOrganizationUnit: true,
              Username: loginState.loginData?.Nickname || loginState.loginData?.EmailAddress,
              AuthenticationToken: loginState.loginData?.AuthenticationToken,
            },
            { authenticationToken: undefined },
          ).then(() => {
            // Resolve the promise
            resolve();
          });
        } else {
          // Resolve the promise
          resolve();
        }
      } else {
        // Resolve the promise
        resolve();
      }
    });
  }, [
    fetcher.data?.loginResponse?.TwoFactorAuthenticators,
    loginState.loginData?.AuthenticationToken,
    loginState.loginData?.EmailAddress,
    loginState.loginData?.Nickname,
    loginState.twoFactorMethod,
    shouldUseJWT,
  ]);

  return (
    <>
      {loginState.view === "main" && (
        <fetcher.Form method="POST" action={routeDefinitions.auth.login.path}>
          <MainView
            loginState={loginState}
            onLogin={onLogin}
            providers={openIDProviders}
            dispatchLoginState={dispatch}
            applicationConfiguration={applicationConfiguration}
            loginActionData={fetcher?.data ?? fetcher.data}
          />
        </fetcher.Form>
      )}
      {loginState.view === "credentialsLogin" && (
        <fetcher.Form method="POST" action={routeDefinitions.auth.login.path}>
          <MainView
            showBackButton
            loginState={loginState}
            onLogin={onLogin}
            ignorePreferredLoginMethod
            dispatchLoginState={dispatch}
            applicationConfiguration={applicationConfiguration}
            loginActionData={fetcher?.data ?? fetcher.data}
          />
        </fetcher.Form>
      )}
      {loginState.view === "ssoLogin" && (
        <SSOView
          dispatchLoginState={dispatch}
          loginState={loginState}
          actionData={fetcher?.data}
          providers={openIDProviders}
          onLogin={onLogin}
        />
      )}
      {loginState.view === "2faChooseMethod" && (
        <TwoFactorChooseMethodView
          dispatchLoginState={dispatch}
          actionData={fetcher?.data}
          twoFactorAuthenticators={loginState.twoFactorMethodsAvailable}
        />
      )}
      {loginState.view === "2faConfirmation" && loginState?.twoFactorMethod && (
        <fetcher.Form method="POST" action={routeDefinitions.auth.login.path}>
          <ConfirmTwoFactorView
            dispatchLoginState={dispatch}
            chosenTwoFactorMethodName={loginState.twoFactorMethod}
            actionData={fetcher?.data}
            twoFactorAuthenticators={loginState.twoFactorMethodsAvailable}
            loginState={loginState}
            onSendToken={onSendToken}
          />
        </fetcher.Form>
      )}
    </>
  );
};
