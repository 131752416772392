import { ComponentProps, useMemo } from "react";
import { useIntl } from "react-intl";

import { Provider } from "@new-black/lyra";

export const useLyraDictionary = () => {
  const intl = useIntl();
  const dictionary = useMemo(() => {
    const translations: ComponentProps<typeof Provider>["dictionary"] = {
      add: intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" }),
      browseFiles: intl.formatMessage({
        id: "generic.label.browse-files",
        defaultMessage: "Browse Files",
      }),
      cancel: intl.formatMessage({ id: "generic.label.cancel", defaultMessage: "Cancel" }),
      clear: intl.formatMessage({ id: "generic.label.clear", defaultMessage: "Clear" }),
      close: intl.formatMessage({ id: "generic.label.close", defaultMessage: "Close" }),
      decrement: intl.formatMessage({
        id: "generic.label.decrement",
        defaultMessage: "Decrement",
      }),
      dragAndDropInputMessage: intl.formatMessage({
        id: "generic.label.drag-and-drop-input-message",
        defaultMessage: "Drag & Drop or browse files to upload",
      }),
      emptyValue: intl.formatMessage({
        id: "generic.label.empty-value",
        defaultMessage: "Empty value",
      }),
      firstPage: intl.formatMessage({
        id: "generic.label.first-page",
        defaultMessage: "First Page",
      }),
      hidePassword: intl.formatMessage({
        id: "generic.label.password.hide",
        defaultMessage: "Hide Password",
      }),
      increment: intl.formatMessage({
        id: "generic.label.increment",
        defaultMessage: "Increment",
      }),
      lastPage: intl.formatMessage({ id: "generic.label.last-page", defaultMessage: "Last page" }),
      loadMore: intl.formatMessage({
        id: "generic.label.load-more",
        defaultMessage: "Load more",
      }),
      maximumFileSize: intl.formatMessage({
        id: "generic.label.maximum-file-size",
        defaultMessage: "Maximum file size",
      }),
      nextPage: intl.formatMessage({ id: "generic.label.next-page", defaultMessage: "Next page" }),
      noResultsFound: intl.formatMessage({
        id: "generic.label.no-results-found",
        defaultMessage: "No results found",
      }),
      open: intl.formatMessage({ id: "generic.label.open", defaultMessage: "Open" }),
      optional: intl.formatMessage({ id: "generic.label.optional", defaultMessage: "Optional" }),
      previousPage: intl.formatMessage({
        id: "generic.label.previous-page",
        defaultMessage: "Previous page",
      }),
      remove: intl.formatMessage({ id: "generic.label.remove", defaultMessage: "Remove" }),
      rowsPerPage: intl.formatMessage({
        id: "generic.label.rows-per-page",
        defaultMessage: "Rows per page",
      }),
      save: intl.formatMessage({ id: "generic.label.save", defaultMessage: "Save" }),
      search: intl.formatMessage({ id: "generic.label.search", defaultMessage: "Search" }),
      selectAllRows: intl.formatMessage({
        id: "generic.label.select-all-rows",
        defaultMessage: "Select all rows",
      }),
      selectedValue: intl.formatMessage({
        id: "generic.label.selected-value",
        defaultMessage: "Selected value",
      }),
      selectValue: (value: string) =>
        intl.formatMessage(
          { id: "generic.label.select-value", defaultMessage: "Select value: {value}" },
          { value },
        ),
      showPassword: intl.formatMessage({
        id: "generic.label.password.show",
        defaultMessage: "Show Password",
      }),
      supportedFormats: intl.formatMessage({
        id: "generic.label.supported-formats",
        defaultMessage: "Supported formats",
      }),
      tagInputDescription: intl.formatMessage({
        id: "generic.label.tag-input-description",
        defaultMessage: "Press enter or comma to add a value",
      }),
      uploadLimitMessage: intl.formatMessage({
        id: "generic.label.upload-limit-message",
        defaultMessage: "You can only upload {value} files at a time",
      }),
      values: intl.formatMessage({ id: "generic.label.values", defaultMessage: "Values" }),
    };

    return translations;
  }, [intl]);
  return dictionary;
};
