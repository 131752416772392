export default {
  module: {
    path: "/promotions",
  },
  promotions: {
    overview: {
      path: "/promotions/promotions",

      searchCoupons: { path: "/promotions/promotions/search-coupons" },
    },

    details: {
      path: "/promotions/promotions/:id",
      generalInformation: {
        path: "/promotions/promotions/:id/general-information",

        edit: {
          path: "/promotions/promotions/:id/general-information/edit",
        },
        editActivePeriod: {
          path: "/promotions/promotions/:id/general-information/edit-active-period",
        },
      },

      displayOptions: {
        path: "/promotions/promotions/:id/display-options",
        edit: {
          path: "/promotions/promotions/:id/display-options/edit",
        },
        uploadImage: {
          path: "/promotions/promotions/:id/display-options/upload-image",
        },
        customFields: {
          edit: {
            path: "/promotions/promotions/:id/display-options/custom-fields/:customFieldId/edit",
          },
          delete: {
            path: "/promotions/promotions/:id/display-options/custom-fields/:customFieldId/delete",
          },
        },
      },

      financialInfo: {
        path: "/promotions/promotions/:id/financial-info",
        edit: {
          path: "/promotions/promotions/:id/financial-info/edit",
        },
        editRecurringBenefits: {
          path: "/promotions/promotions/:id/financial-info/edit-recurring-benefits",
        },
      },

      action: {
        path: "/promotions/promotions/:id/action",

        edit: {
          path: "/promotions/promotions/:id/action/edit",
        },

        editFinancialImplications: {
          path: "/promotions/promotions/:id/action/edit-financial-implications",
        },

        productSets: {
          new: {
            path: "/promotions/promotions/:id/action/product-sets/:productSetType/new",
          },

          editContent: {
            path: "/promotions/promotions/:id/action/product-sets/:productSetIndex/edit-content",
          },

          editGeneralDetails: {
            path: "/promotions/promotions/:id/action/product-sets/:productSetIndex/edit-general-details",
          },

          editFinancialImplications: {
            path: "/promotions/promotions/:id/action/product-sets/edit-financial-implications",
          },
        },

        getAProduct: {
          editProduct: {
            path: "/promotions/promotions/:id/action/get-a-product/edit-product/:productId",
          },
        },

        slidingDiscount: {
          edit: {
            path: "/promotions/promotions/:id/action/sliding-discount/edit",
          },
          tiers: {
            add: {
              path: "/promotions/promotions/:id/action/sliding-discount/tiers/add",
            },
            edit: {
              path: "/promotions/promotions/:id/action/sliding-discount/tiers/:tierIndex/edit",
            },
            delete: {
              path: "/promotions/promotions/:id/action/sliding-discount/tiers/:tierIndex/delete",
            },
          },
        },

        slidingPoints: {
          tiers: {
            add: {
              path: "/promotions/promotions/:id/action/sliding-points/tiers/add",
            },
            edit: {
              path: "/promotions/promotions/:id/action/sliding-points/tiers/:tierIndex/edit",
            },
            delete: {
              path: "/promotions/promotions/:id/action/sliding-points/tiers/:tierIndex/delete",
            },
          },
        },

        pickAProduct: {
          tiers: {
            add: {
              path: "/promotions/promotions/:id/action/pick-a-product/tiers/add",
            },

            edit: {
              path: "/promotions/promotions/:id/action/pick-a-product/tiers/:tierIndex/edit",
            },

            delete: {
              path: "/promotions/promotions/:id/action/pick-a-product/tiers/:tierIndex/delete",
            },

            products: {
              add: {
                path: "/promotions/promotions/:id/action/pick-a-product/tiers/:tierIndex/products/add",
              },

              edit: {
                path: "/promotions/promotions/:id/action/pick-a-product/tiers/:tierIndex/products/:productID/edit",
              },

              delete: {
                path: "/promotions/promotions/:id/action/pick-a-product/tiers/:tierIndex/products/:productID/delete",
              },
            },
          },
        },

        ageDiscount: {
          tiers: {
            add: {
              path: "/promotions/promotions/:id/action/age-discount/tiers/add",
            },

            edit: {
              path: "/promotions/promotions/:id/action/age-discount/tiers/:tierIndex/edit",
            },

            delete: {
              path: "/promotions/promotions/:id/action/age-discount/tiers/:tierIndex/delete",
            },
          },
        },

        customFields: {
          discounts: {
            add: {
              path: "/promotions/promotions/:id/action/custom-fields/discounts/add",
            },

            edit: {
              path: "/promotions/promotions/:id/action/custom-fields/discounts/:discountIndex/edit",
            },

            delete: {
              path: "/promotions/promotions/:id/action/custom-fields/discounts/:discountIndex/delete",
            },
          },
        },
      },

      conditions: {
        path: "/promotions/promotions/:id/conditions",

        addCondition: {
          path: "/promotions/promotions/:id/conditions/new/:conditionType",
        },

        editConditionType: {
          path: "/promotions/promotions/:id/conditions/edit-condition-type",
        },

        orderAmount: {
          edit: {
            path: "/promotions/promotions/:id/conditions/order-amount/edit",
          },
        },

        stockLabel: {
          edit: {
            path: "/promotions/promotions/:id/conditions/stock-label/edit",
          },
        },

        orderProperties: {
          edit: {
            path: "/promotions/promotions/:id/conditions/order-properties/edit",
          },
        },

        customerAge: {
          edit: {
            path: "/promotions/promotions/:id/conditions/customer-age/edit",
          },
        },

        loyaltyPoints: {
          edit: {
            path: "/promotions/promotions/:id/conditions/loyalty-points/edit",
          },
        },

        orderType: {
          edit: {
            path: "/promotions/promotions/:id/conditions/order-type/edit",
          },
        },

        products: {
          edit: {
            path: "/promotions/promotions/:id/conditions/products/edit",
          },
        },

        customer: {
          edit: {
            path: "/promotions/promotions/:id/conditions/customer/edit",
          },

          setFilter: {
            path: "/promotions/promotions/:id/conditions/customer/filter/:filterType",
          },
        },

        ritualsMember: {
          edit: {
            path: "/promotions/promotions/:id/conditions/rituals-member/edit",
          },
        },

        ritualsWelcomeGift: {
          edit: {
            path: "/promotions/promotions/:id/conditions/rituals-welcome-gift/edit",
          },
        },

        userRole: {
          edit: {
            path: "/promotions/promotions/:id/conditions/userRole/edit",
          },
        },

        userType: {
          edit: {
            path: "/promotions/promotions/:id/conditions/userType/edit",
          },
        },

        originalOu: {
          edit: {
            path: "/promotions/promotions/:id/conditions/original-ou/edit",
          },
        },

        couponValidity: {
          edit: {
            path: "/promotions/promotions/:id/conditions/coupon-validity/edit",
          },
        },

        productRequirement: {
          addRequirement: {
            path: "/promotions/promotions/:id/conditions/product-requirement/requirements/new",
          },
          editRequirement: {
            path: "/promotions/promotions/:id/conditions/product-requirement/requirements/:requirementIndex/edit",
          },
        },

        customFields: {
          editDetails: {
            path: "/promotions/promotions/:id/conditions/custom-fields/:customFieldType/edit",
          },
          addCondition: {
            path: "/promotions/promotions/:id/conditions/custom-fields/:customFieldType/add-condition",
          },
          editCondition: {
            path: "/promotions/promotions/:id/conditions/custom-fields/:customFieldType/edit-condition/:conditionIndex",
          },
        },

        productSets: {
          new: {
            path: "/promotions/promotions/:id/conditions/:conditionType/product-sets/:productSetType/new",
          },

          editContent: {
            path: "/promotions/promotions/:id/conditions/:conditionType/product-sets/:productSetIndex/edit-content",
          },

          editGeneralDetails: {
            path: "/promotions/promotions/:id/conditions/:conditionType/product-sets/:productSetIndex/edit-general-details",
          },

          editFinancialImplications: {
            path: "/promotions/promotions/:id/conditions/:conditionType/product-sets/edit-financial-implications",
          },
        },
      },

      summary: {
        path: "/promotions/promotions/:id/summary",

        verify: {
          path: "/promotions/promotions/:id/summary/verify",
        },
      },

      coupons: {
        path: "/promotions/promotions/:id/coupons",

        editHandler: {
          path: "/promotions/promotions/:id/coupons/handler",
        },

        create: {
          custom: {
            path: "/promotions/promotions/:id/coupons/new/custom",
          },
          multiple: {
            path: "/promotions/promotions/:id/coupons/new/multiple",
          },
        },

        edit: {
          path: "/promotions/promotions/:id/coupons/:couponId/edit",
        },

        details: {
          path: "/promotions/promotions/:id/coupons/:couponId",
        },
      },
    },

    create: {
      path: "/promotions/promotions/new",

      generalInformation: {
        path: "/promotions/promotions/new/general-information",

        createOuSet: {
          path: "/promotions/promotions/new/general-information/create-ou-set",
        },
      },

      displayOptions: {
        path: "/promotions/promotions/new/display-options",

        uploadImage: {
          path: "/promotions/promotions/new/display-options/upload-image",
        },
      },

      financialInfo: {
        path: "/promotions/promotions/new/financial-info",
      },

      action: {
        path: "/promotions/promotions/new/action",
      },

      conditions: {
        path: "/promotions/promotions/new/conditions",
      },

      verification: {
        path: "/promotions/promotions/new/verification",
      },
    },
  },
  personalizedPromotions: {
    overview: {
      path: "/promotions/personalized-promotions",

      create: {
        path: "/promotions/personalized-promotions/new",
      },

      edit: {
        path: "/promotions/personalized-promotions/:id/edit",
      },

      delete: {
        path: "/promotions/personalized-promotions/:id/delete",
      },
    },
  },
  campaigns: {
    wrapper: {
      path: "/promotions/campaigns",
    },
    overview: {
      path: "/promotions/campaigns",
    },
    create: {
      path: "/promotions/campaigns/new",
    },
    details: {
      path: "/promotions/campaigns/:id",
      editInformation: {
        path: "/promotions/campaigns/:id/edit-information",
      },
      editBudget: {
        path: "/promotions/campaigns/:id/edit-budget",
      },
      delete: {
        path: "/promotions/campaigns/:id/delete",
      },
    },
  },

  templates: {
    overview: {
      path: "/promotions/discount-templates",
    },
    details: {
      path: "/promotions/discount-templates/:id",
    },
  },

  loyaltyPrograms: {
    path: "/promotions/loyalty-programs",

    loyaltyProgramsOverview: {
      path: "/promotions/loyalty-programs/loyalty-programs-overview",

      create: {
        path: "/promotions/loyalty-programs/loyalty-programs-overview/new",
      },

      details: {
        path: "/promotions/loyalty-programs/loyalty-programs-overview/:id",

        generalInformation: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information",

          editGeneralInformation: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/edit-general-information",
          },

          customFields: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/custom-fields/new",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/custom-fields/:customFieldID/delete",
            },
          },

          priceLists: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/price-lists/new",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/price-lists/:priceListID/delete",
            },
          },

          ouSets: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/new",
            },

            edit: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/:ouSetID/edit",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/:ouSetID/delete",
            },
          },
        },

        pointSavingRules: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/delete",
          },

          conditions: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/delete/:conditionID",
            },

            orderAmount: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/order-amount/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/order-amount/:conditionID/edit",
              },
            },

            productSet: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/product-set/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/product-set/:conditionID/edit",
              },
            },

            consumer: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/consumer/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/consumer/:conditionID/edit",
              },
            },
          },
        },

        paymentTypes: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/delete",
          },

          productLimitationData: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/delete-products",
            },

            productSearchTemplate: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-search-template",
            },

            productIDs: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-ids",

              deleteProduct: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-ids/:productID/delete",
              },
            },

            productFilters: {
              createOrEdit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/:propertyName?",
              },

              createBulk: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/new-bulk",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/:propertyName/delete",
              },
            },
          },
        },

        conditions: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions",

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/delete/:conditionID",
          },

          customer: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/edit-general-information",
              },
            },

            filters: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/:filter",

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/:filter/delete",
              },
            },
          },

          orderType: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-type/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-type/:conditionID/edit-general-information",
              },
            },
          },

          orderProperties: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-properties/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-properties/:conditionID/edit-general-information",
              },
            },
          },

          userCustomFields: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/edit-general-information",
              },
            },

            customFields: {
              create: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/custom-fields/new",
              },

              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/custom-fields/:customFieldID/:index/edit",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/:customFieldID/:index/delete",
              },
            },
          },

          orderCustomFields: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/edit-general-information",
              },
            },

            customFields: {
              create: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/custom-fields/new",
              },

              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/custom-fields/:customFieldID/:index/edit",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/:customFieldID/:index/delete",
              },
            },
          },
        },

        productLimitations: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/delete",
          },

          productLimitationData: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/delete-products",
            },

            productSearchTemplate: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-search-template",
            },

            productIDs: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-ids",

              deleteProduct: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-ids/:productID/delete",
              },
            },

            productFilters: {
              createOrEdit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/:propertyName?",
              },

              createBulk: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/new-bulk",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/:propertyName/delete",
              },
            },
          },
        },
      },
    },

    loyaltyProgramGroups: {
      path: "/promotions/loyalty-programs/loyalty-program-groups",

      create: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/new",
      },

      edit: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/:id/edit",
      },

      delete: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/:id/delete",
      },
    },
  },
} as const;
