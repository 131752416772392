import { useIntl } from "react-intl";

import {
  CardContent,
  CardHeader,
  Dialog,
  DialogCard,
  KeyValueRowList,
  Separator,
} from "@new-black/lyra";

import {
  ProductBasicInformation,
  ProductDetails,
} from "~/components/suite-composite/product-information";
import { ProductImages } from "~/features/products/details/product-images";
import { IProduct } from "~/types/product";

type ProductInformationModalProps = {
  product?: IProduct;
  productNameProperty?: "display_value" | "primitive_name";
  isOpen: boolean;
  onClose: () => void;
};

const LyraProductInformationModal = ({
  isOpen,
  onClose,
  product,
  productNameProperty,
}: ProductInformationModalProps) => {
  const intl = useIntl();

  return (
    <Dialog isOpen={isOpen} maxWidth="2xl" onOpenChange={onClose}>
      <DialogCard>
        <DialogCard>
          <CardHeader
            title={intl.formatMessage({
              id: "generic.label.product-information",
              defaultMessage: "Product information",
            })}
          />
          <CardContent>
            <KeyValueRowList>
              <ProductBasicInformation
                variant="lyra"
                product={product}
                productNameProperty={productNameProperty}
              />
            </KeyValueRowList>
          </CardContent>
        </DialogCard>
        <Separator />
        <DialogCard>
          <CardHeader
            title={intl.formatMessage({
              id: "generic.label.content",
              defaultMessage: "Content",
            })}
          />
          <CardContent>
            <KeyValueRowList>
              <ProductDetails variant="lyra" product={product} />
            </KeyValueRowList>
          </CardContent>
        </DialogCard>
        <Separator />
        <DialogCard>
          <CardHeader
            title={intl.formatMessage({
              id: "generic.label.images",
              defaultMessage: "Images",
            })}
          />
          <CardContent>
            <ProductImages product={product} />
          </CardContent>
        </DialogCard>
      </DialogCard>
    </Dialog>
  );
};

export default LyraProductInformationModal;
