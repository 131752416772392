import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { isNil } from "lodash";
import { selectorFamily } from "recoil";

const FAMILY_KEY_PREFIX = "Composite:UseCustomFieldListOfTypes";

const { createGetterSetterSelectorFamily } = helpers.createSelectors;

const customFieldsListDefaultRequest = {
  PageConfig: {
    Start: 0,
    Limit: 1000,
    Filter: { Name: undefined },
  },
};

export const customFieldTypesServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  allowEmptyRequest: true,
  service: CoreManagement.GetCustomFieldTypes,
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.CustomFieldTypes`,
});

const customFieldsListServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  service: CoreManagement.ListCustomFields,
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields`,
  beforeRequest: ({ parameter, req }) => {
    if (isNil(parameter)) {
      return undefined;
    }
    if (parameter && typeof parameter === "string") {
      return {
        ...customFieldsListDefaultRequest,
        ...req,
        PageConfig: {
          ...customFieldsListDefaultRequest.PageConfig,
          ...req?.PageConfig,
          Filter: {
            ...customFieldsListDefaultRequest.PageConfig.Filter,
            ...req?.PageConfig?.Filter,
            TypeID: undefined,
          },
        },
      };
    }

    if (parameter && typeof parameter === "number") {
      return {
        ...customFieldsListDefaultRequest,
        ...req,
        PageConfig: {
          ...customFieldsListDefaultRequest.PageConfig,
          ...req?.PageConfig,
          Filter: {
            ...customFieldsListDefaultRequest.PageConfig.Filter,
            ...req?.PageConfig?.Filter,
            TypeID: parameter,
          },
        },
      };
    }

    return req;
  },
});

const customFieldsListTotalSelector = selectorFamily({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:Total`,
  get:
    (param) =>
    ({ get }) =>
      get(customFieldsListServiceState.response(param))?.Result?.Total,
});

const customFieldsListResponseSelector = selectorFamily({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:Response`,
  get:
    (param) =>
    ({ get }) =>
      get(customFieldsListServiceState.response(param))?.Result?.Page,
});

const customFieldsListPageConfigSelector = createGetterSetterSelectorFamily<
  EVA.Core.Management.ListCustomFields,
  EVA.Core.PageConfig<EVA.Core.ListCustomFieldsFilter> | undefined
>({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:PageConfig`,
  defaultValue: customFieldsListDefaultRequest.PageConfig,
  state: customFieldsListServiceState.request,
  getData: (req) => req?.PageConfig,
  setData: (req, data) => ({
    ...req,
    PageConfig: data,
  }),
});

const customFieldsListStartSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListCustomFieldsFilter>,
  number
>({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:PageConfig.Start`,
  defaultValue: customFieldsListDefaultRequest.PageConfig.Start,
  state: customFieldsListPageConfigSelector,
  getData: (req) => req?.Start ?? customFieldsListDefaultRequest.PageConfig.Start,
  setData: (req, data) => ({
    ...req,
    Start: data,
  }),
});

const customFieldsListLimitSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListCustomFieldsFilter>,
  number
>({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:PageConfig.Limit`,
  defaultValue: customFieldsListDefaultRequest.PageConfig.Limit,
  state: customFieldsListPageConfigSelector,
  getData: (req) => req?.Limit ?? customFieldsListDefaultRequest.PageConfig.Limit,
  setData: (req, data) => ({
    ...req,
    Limit: data,
  }),
});

const customFieldsListFilterSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListCustomFieldsFilter>,
  EVA.Core.ListCustomFieldsFilter | undefined
>({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:PageConfig.Filter`,
  defaultValue: customFieldsListDefaultRequest.PageConfig.Filter,
  state: customFieldsListPageConfigSelector,
  getData: (req) => req?.Filter,
  setData: (req, data) => ({
    ...req,
    Filter: data,
  }),
});

const customFieldsListNameFilterSelector = createGetterSetterSelectorFamily<
  EVA.Core.ListCustomFieldsFilter,
  string | undefined
>({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.ListCustomFields:PageConfig.Filter.Name`,
  defaultValue: customFieldsListDefaultRequest.PageConfig.Filter.Name,
  state: customFieldsListFilterSelector,
  getData: (req) => req?.Name,
  setData: (req, data) => ({
    ...req,
    Name: data,
  }),
});

export const customFieldsListService = {
  serviceState: customFieldsListServiceState,
  total: customFieldsListTotalSelector,
  response: customFieldsListResponseSelector,
  pageConfig: customFieldsListPageConfigSelector,
  start: customFieldsListStartSelector,
  limit: customFieldsListLimitSelector,
  filter: customFieldsListFilterSelector,
  nameFilter: customFieldsListNameFilterSelector,
};
