export default {
  wrapper: {
    path: "/dashboard",
  },
  search: {
    path: "/dashboard/search",
    info: {
      path: "/dashboard/search/info",
    },
    oldAdminSurvey: {
      path: "/dashboard/search/old-admin-survey",
    },
  },
  overview: {
    path: "/dashboard/overview",
  },
} as const;
