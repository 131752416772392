import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogCard,
  Separator,
  SvgIcon,
  SvgIconProps,
  Text,
} from "@new-black/lyra";
import classNames from "clsx";

import { IdentificationCodeDialog } from "../identification-code-dialog/identification-code-dialog";
import { MenuState } from "../menu/menu.types";

import { ButtonLink } from "~/components/routing";
import { useGetCurrentUserQuery } from "~/models/users";
import { useRootRouteData } from "~/routes/root";
import routeDefinitions from "~/routes/route-definitions";

type ProfileButtonProps = {
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
  setMouseEntered?: Dispatch<SetStateAction<boolean>>;
  menuState?: MenuState;
};

export const ProfileButton = ({ setIsFocused, setMouseEntered }: ProfileButtonProps) => {
  const rootData = useRootRouteData();
  const { data } = useGetCurrentUserQuery(
    rootData.currentUser?.request,
    rootData.currentUser?.queryProps ?? {},
  );

  const user = useMemo(() => data?.User, [data?.User]);

  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [identificationCodeDialogOpen, setIdentificationCodeDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="icon"
        className="w-fit"
        onPress={() => {
          setProfileDialogOpen(true);
          setMouseEntered?.(false);
          setIsFocused?.(false);
        }}
        onHoverStart={() => {
          setMouseEntered?.(true);
        }}
        onHoverEnd={() => {
          setMouseEntered?.(false);
        }}
        onFocus={() => {
          setIsFocused?.(true);
        }}
        onBlur={() => {
          setIsFocused?.(false);
        }}
      >
        <SvgIcon name="profile" />
      </Button>
      <ProfileDialog
        open={profileDialogOpen}
        setOpen={setProfileDialogOpen}
        user={user}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
        onOpenIdentificationCodeDialog={() => {
          setProfileDialogOpen(false);
          setIdentificationCodeDialogOpen(true);
        }}
      />
      <IdentificationCodeDialog
        open={identificationCodeDialogOpen}
        setOpen={setIdentificationCodeDialogOpen}
        user={user}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
      />
    </>
  );
};

type ProfileDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user?: EVA.Core.LoggedInUserDto | undefined;
  onOpenIdentificationCodeDialog: () => void;
  setMouseEntered?: Dispatch<SetStateAction<boolean>>;
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
};

const ProfileDialog = ({
  onOpenIdentificationCodeDialog,
  open,
  setOpen,
  user,
}: ProfileDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      maxWidth="sm"
      isOpen={open}
      onOpenChange={(open) => (!open ? setOpen(false) : undefined)}
    >
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({ id: "generic.label.profile", defaultMessage: "Profile" })}
        />

        <CardContent className="mt-4 flex flex-col items-center justify-center gap-4">
          <Avatar name={user?.FullName ?? ""} subtitle={user?.EmailAddress} />
          <Separator className="mt-6" />
          <div className="w-full grid gap-1">
            <Link
              iconName="profile"
              label={intl.formatMessage({
                id: "generic.label.manage-profile",
                defaultMessage: "Manage profile",
              })}
              to={routeDefinitions.profile.path}
            />
            <Button
              variant="secondary"
              onPress={onOpenIdentificationCodeDialog}
              fullWidth
              className={classNames(
                "flex justify-start border-transparent hover:border-transparent gap-2 p-2",
              )}
            >
              <SvgIcon name="pos" />
              <Text>
                {intl.formatMessage({
                  id: "generic.label.identification-code",
                  defaultMessage: "Identififcation code",
                })}
              </Text>
            </Button>
            <Link
              iconName="sign-out"
              label={intl.formatMessage({
                id: "generic.label.sign-out",
                defaultMessage: "Sign out",
              })}
              to={routeDefinitions.auth.logout.path}
            />
          </div>
        </CardContent>
      </DialogCard>
    </Dialog>
  );
};

const Link = ({
  iconName,
  label,
  to,
}: {
  iconName: SvgIconProps["name"];
  to: string;
  label: string;
}) => {
  return (
    <ButtonLink
      to={to}
      variant="secondary"
      fullWidth
      className={classNames(
        "flex justify-start border-transparent hover:border-transparent gap-2 p-2",
      )}
    >
      <SvgIcon name={iconName} />
      <Text>{label}</Text>
    </ButtonLink>
  );
};
