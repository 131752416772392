import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Column } from "react-table";

import { Dialog } from "@material-ui/core";
import { Button, Separator } from "@new-black/lyra";

import { IProductSetProduct } from "~/components/suite-composite/product-set-modal/types";
import { ProductSummary } from "~/components/suite-composite/product-summary";
import { ProductsOverviewTable } from "~/components/suite-composite/products-overview";
import CustomQuery from "~/components/suite-composite/products-overview/custom-query";
import useFuseInstance from "~/hooks/use-fuse-instance";

export const EvaProductSetProductIDsPreviewModal = ({
  onClose,
  open,
  products,
}: {
  open: boolean;
  onClose: () => void;
  products: IProductSetProduct[];
}) => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const [query, setQuery] = useState<string>();

  const updateQuery = useCallback((newValue?: string) => {
    setQuery(newValue);
    setLimit(0);
  }, []);

  const fuseInstance = useFuseInstance(
    ["ID", "BackendID", "CustomID", "Name", "Barcode"],
    products,
  );

  const data: IProductSetProduct[] = useMemo(() => {
    if (fuseInstance && query) {
      return fuseInstance.search(query).map((match) => match.item);
    }

    return products;
  }, [query, fuseInstance, products]);

  const total = useMemo(() => data.length, [data.length]);

  const columns = useMemo(
    (): Column<IProductSetProduct>[] => [
      {
        Header: (
          <FormattedMessage
            id="composite.products-overview.list.header.product"
            defaultMessage="Product"
          />
        ),
        accessor: "ImageBlobID",
        width: "8%",
        Cell: ({ row, value }) => (
          <ProductSummary
            productID={row.original?.ID}
            customID={row.original?.CustomID}
            backendID={row.original?.BackendID}
            productName={row.original?.Name}
            productImageBlobID={value}
            enlargeImage
            imageSize={37}
          />
        ),
      },
    ],
    [],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <ProductsOverviewTable
        data={data}
        skip={skip}
        setSkip={setSkip}
        limit={limit}
        setLimit={setLimit}
        total={total}
        columns={columns}
        customActions={<CustomQuery query={query} updateQuery={updateQuery} />}
      />

      {total > 5 ? <Separator /> : null}
      <div className="flex items-center justify-end p-4">
        <Button variant="primary" onPress={onClose}>
          <FormattedMessage id="generic.label.close" defaultMessage="Close" />
        </Button>
      </div>
    </Dialog>
  );
};
