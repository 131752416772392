import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { DefaultValue, selectorFamily } from "recoil";

export const orderLedgerListServiceState = builders.buildServiceFamilyState({
  key: "Core.ListOrderLedgerForOrder",
  service: Core.ListOrderLedgerForOrder,
  defaultRequest: undefined,
  allowEmptyRequest: false,
  requireLoggedIn: true,
});

export const orderLedgerListTotal = selectorFamily({
  key: "orderLedgerListServiceState:selector:total",
  get:
    (param) =>
    ({ get }) =>
      get(orderLedgerListServiceState.response(param))?.Result?.Total ?? 0,
});

export const orderLedgerListStart = selectorFamily<number, number>({
  key: "orderLedgerListServiceState:selector:start",
  get:
    (param) =>
    ({ get }) =>
      get(orderLedgerListServiceState.request(param))?.PageConfig?.Start ?? 0,
  set:
    (param) =>
    ({ get, set }, newValue) => {
      const currentStart = get(orderLedgerListServiceState.request(param))?.PageConfig?.Start ?? 0;
      if ((newValue instanceof DefaultValue && currentStart !== 0) || newValue !== currentStart) {
        set(orderLedgerListServiceState.request(param), (state) => ({
          ...state,
          OrderID: state?.OrderID ?? param,
          PageConfig: {
            ...state?.PageConfig,
            Start: newValue instanceof DefaultValue ? 0 : newValue,
          },
        }));
      }
    },
});

export const orderLedgerListLimit = selectorFamily<number, number>({
  key: "orderLedgerListServiceState:selector:limit",
  get:
    (param) =>
    ({ get }) =>
      get(orderLedgerListServiceState.request(param))?.PageConfig?.Limit ?? 5,
  set:
    (param) =>
    ({ get, set }, newValue) => {
      const currentLimit = get(orderLedgerListServiceState.request(param))?.PageConfig?.Limit ?? 5;
      if ((newValue instanceof DefaultValue && currentLimit !== 5) || newValue !== currentLimit) {
        set(orderLedgerListServiceState.request(param), (state) => ({
          ...state,
          OrderID: state?.OrderID ?? param,
          PageConfig: {
            ...state?.PageConfig,
            Limit: newValue instanceof DefaultValue ? 5 : newValue,
          },
        }));
      }
    },
});
