import { useEffect, useMemo } from "react";

import { Select } from "@new-black/lyra";

import useGiftcardHandlers from "../use-giftcard-handlers";

interface IGiftCardHandlersSelectProps {
  value: string | undefined;
  setValue: (newValue: string | undefined) => void;
  name?: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  hideHintLabel?: boolean;
  disableClearLogic?: boolean;
}

export const GiftCardHandlersSelect = ({
  label,
  name,
  setValue,
  value,
  ...props
}: IGiftCardHandlersSelectProps) => {
  const { giftcardHandlers } = useGiftcardHandlers();
  const selectItems = useMemo(
    () =>
      (giftcardHandlers ?? []).map((handler) => ({
        label: handler,
        value: handler,
      })),
    [giftcardHandlers],
  );

  useEffect(() => {
    if (value && giftcardHandlers?.every((handler) => handler !== value)) {
      setValue(undefined);
    }
  }, [giftcardHandlers, setValue, value]);

  return giftcardHandlers ? (
    <Select
      {...props}
      label={label}
      items={selectItems}
      value={selectItems.find((item) => item.value === value)}
      onChange={(newValue) => {
        setValue(newValue?.value);
      }}
      name={name ?? "GiftCardHandlersSelect"}
      getItemId={(item) => item.value}
      getLabel={(item) => item.label}
      selectRenderElements={(item) => ({ label: item.label })}
    />
  ) : null;
};
