import { z } from "zod";
import { zfd } from "zod-form-data";

export const languageTimeZoneFormSchema = z.object({
  TimeZone: z.string().optional(),
  Language: z.string().optional(),
  ID: zfd.numeric(z.number().int()),
});

export type LanguageTimeZone = z.infer<typeof languageTimeZoneFormSchema>;

export const languageTimeZoneFormKeys = languageTimeZoneFormSchema.keyof().Values;

export const languageTimeZoneFormErrorsSchema = z
  .record(languageTimeZoneFormSchema.keyof(), z.string().optional())
  .optional();

export type LanguageTimeZoneFormErrors = z.infer<typeof languageTimeZoneFormErrorsSchema>;

export type LanguageTimeZoneFormProps = {
  formID: string;
  autoFocusFirstField?: boolean;
  currentUserResponse: EVA.Core.GetCurrentUserResponse | undefined;
  errors?: LanguageTimeZoneFormErrors;
  formActionURL?: string;
  blockRoutingOnChange?: boolean;
};

export type LanguageTimeZoneFormFieldsProps = {
  currentUserResponse: EVA.Core.GetCurrentUserResponse | undefined;
  errors?: LanguageTimeZoneFormErrors;
  autoFocusFirstField?: boolean;
};
