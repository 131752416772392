import ky from "ky";
import _, { clone } from "lodash";
import moment from "moment";

import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "./base-values";
import { intlAccessor } from "./intl-accessor";

export const removeUndefinedValues = (obj: any): any => {
  const result = _(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeUndefinedValues) // call only for object values
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .omitBy(_.isUndefined) // remove undefined props
    .value();

  return result;
};

export const removeEmptyObjects = (obj: any): any =>
  _(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeEmptyObjects) // call only for object values
    .omitBy(_.isEmpty) // remove empty objects
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .value();

export const removeUndefinedArrayValues = <T>(arr: (T | undefined)[]): T[] =>
  arr.filter((v): v is T => v !== undefined);

export const enumValueToEnumValuesResponse = (
  value: number,
  enums: { Name: string; Value: number }[],
) => {
  const output: { Name: string; Value: number }[] = [];
  const enumValues: { Name: string; Value: number }[] = clone(enums);
  const sortedEnumValues: { Name: string; Value: number }[] = enumValues.sort(
    (a, b) => b.Value - a.Value,
  );

  while (value > 0) {
    sortedEnumValues.forEach((v) => {
      if (v.Value <= value) {
        value -= v.Value;
        output.unshift(v);
      }
    });
  }
  return output;
};

export const handleKyError = async (e: Error) => {
  if (e.name === "HTTPError") {
    const httpError = e as ky.HTTPError;
    const error = await httpError.response.clone().json();
    console.error(`[Error: ${e.name}]:`, error);
    return error.Error.Message as string;
  } else {
    console.error(`[Error: ${e.name}]:`, e);
    return intlAccessor.formatMessage({
      id: "generic.message.something-went-wrong",
      defaultMessage: "Something went wrong.",
    });
  }
};

export const readFileAsDataUrl = (file: File) =>
  new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const buf = reader.result;
      if (buf) {
        const decoder = new TextDecoder("utf-8");
        const fileTextContent = typeof buf === "string" ? buf : decoder.decode(buf);
        const encodedText = fileTextContent.replace("data:", "").replace(/^.+,/, "");
        resolve(encodedText);
      }
      resolve(undefined);
    };
    reader.onerror = (error) => reject(error);
  });

/** Logic taken from the Old Admin code: https://github.com/new-black/eva-admin/blob/4c3df6bac4dd59eabf1ffc3ce404971a1a9a371f/src/core/components/file-upload.component.ts#L64-L88 */
export const readFileAsBase64String = (file: File) =>
  new Promise<string | undefined>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result as string;
      const idx = result.indexOf("base64,");
      resolve(result.substring(idx + "base64,".length));
    };
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsDataURL(file);
  });

export const base64EncodeFileData = (fileData: string | ArrayBuffer | null | undefined) =>
  /base64,(.+)/.exec(fileData as any)?.[1];

export const noop = () => {};

export function checkEmailValid(value: string) {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
}

export const numberOrNullToUndefined = (value: number | null) =>
  value === null || isNaN(value) ? undefined : value;

export const emptyStringToUndefined = <T extends string>(str?: T) => (str === "" ? undefined : str);

export const isEmptyStringOrUndefined = (value: string | undefined) => {
  if (value === "" || value === undefined) {
    return true;
  }
  return false;
};

export const removeZeroWidthCharacters = (value?: string) =>
  value?.replace(/[\u200B-\u200D\uFEFF]/g, "");

export const getUrlWithSearchParams = (url: string, searchParams: { [key: string]: string }) => {
  const urlSearchParams = new URLSearchParams();

  Object.entries(searchParams).forEach(([key, value]) => urlSearchParams.set(key, value));

  return url.concat(`?${urlSearchParams.toString()}`);
};

export const emptyArrayToUndefined = <T>(arr?: T[]) => (arr?.length === 0 ? undefined : arr);

/**
 * Returns the mapped sum of the values of all items in an array that satisfy a given condition.
 * @template T The type of items in the array.
 * @param {T[] | undefined} arr The array to search.
 * @param {(item: T) => boolean} filter The condition that an item must satisfy to be included in the sum.
 * @param {(item: T) => number} itemValue A function that returns the value of an item to be included in the sum.
 * @param {number} [start=0] The initial value of the sum.
 * @returns {number | undefined} The sum of the values of all items in the array that satisfy the condition.
 */
export const sumIf = <T>(
  arr: T[] | undefined,
  filter: (item: T) => boolean,
  itemValue: (item: T) => number,
  start = 0,
): number | undefined =>
  arr?.reduce(
    (accumulator, item) => (filter(item) ? accumulator + itemValue(item) : accumulator),
    start,
  );

/** Replace regular line breaks with escaped line breaks so they can be easier to explicitly identify after serialization */
export const getFormattedContentWithLineBreaks = (value?: string) =>
  value?.replaceAll(/(?:\r\n|\r|\n)/g, "\\n");

/** Type that describes a string where the first character is uppercased */
export type CapitalString<T extends string> = T extends `${infer Head}${infer Tail}`
  ? `${Uppercase<Head>}${Tail}`
  : T;

/** Remove trailing slashes & spaces from the given text
 *
 * The recursive function starts from the end of the string
 * and removes the last character if it is a slash or a space.
 * The recursion stops when the string is empty
 * or the last character is neither a slash, nor a space.
 *
 * @param text - The text to remove the trailing slashes and spaces from
 * @returns The text without trailing slashes or spaces
 */
export function removeTrailingSlashAndTrim(text: string): string {
  if (text === "") {
    return text;
  }
  if (text.endsWith(" ")) {
    return removeTrailingSlashAndTrim(text.trim());
  }
  if (text.endsWith("/")) {
    return removeTrailingSlashAndTrim(text.slice(0, -1));
  }
  return text;
}

/**
 * Selects the next item in a round-robin fashion from an array of items.
 *
 * @template T - The type of the items in the array.
 * @param {T[]} items - The array of items.
 * @param {number} currentIndex - The index of the current item.
 * @returns {T} - The next item in the round-robin sequence.
 */
export function roundRobinSelectNext<T>(items: T[], currentIndex: number): T {
  const nextIndex = currentIndex + 1;
  return items[nextIndex % items.length];
}

/**
 *
 * @param {string} date date to compute the duration to
 * @returns a string representing the duration to the given date formatted as a human-readable string
 */
export function getDurationToPastDate(date: string) {
  // make sure we don't show future values in case of system time mismatch
  const safeValue = moment.min(moment(), moment(date));

  return moment().to(safeValue);
}

/**
 * Pauses the execution for the specified number of milliseconds.
 * @param ms - The number of milliseconds to sleep.
 * @returns A Promise that resolves after the specified number of milliseconds.
 */
export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Increases the given limit by a specified step (DEFAULT_SEARCH_LIST_FIELD_LIMIT by default).
 * Can be used when defining the behaviour of a "Load More" button in (generated) search list fields.
 *
 * @param {number} [oldLimit] - The current limit. If not provided, the step value will be used as the initial limit.
 * @param {number} [step=DEFAULT_SEARCH_LIST_FIELD_LIMIT] - The value to increase the limit by. Defaults to `DEFAULT_SEARCH_LIST_FIELD_LIMIT`.
 * @returns {number} The new limit after increasing by the step value.
 */
export function increaseLimit(oldLimit?: number, step = DEFAULT_SEARCH_LIST_FIELD_LIMIT) {
  const previousLimit = oldLimit ?? step;
  return previousLimit + step;
}

/**
 * Determines whether the "Load More" button should be shown based on the total number of items and the limit.
 * Can be used when defining the behaviour of a "Load More" button in (generated) search list fields.
 *
 * @param {number} [total=0] - The total number of items.
 * @param {number} [limit=DEFAULT_SEARCH_LIST_FIELD_LIMIT] - The limit of items to display before showing the "Load More" button.
 * @returns {boolean} - Returns `true` if the total number of items exceeds the limit, otherwise `false`.
 */
export function getShouldShowLoadMoreButton(total = 0, limit = DEFAULT_SEARCH_LIST_FIELD_LIMIT) {
  return total > limit;
}
