import { ReactNode, useCallback, useMemo } from "react";

import { sortBy } from "lodash";
import { atom } from "recoil";

import useGenerateOrderlineImage from "./use-generate-orderline-image";

import Chip from "~/components/suite-ui/chip";
import Grid from "~/components/suite-ui/grid";
import Text from "~/components/suite-ui/text";
import useProductIDProperty from "~/hooks/suite-react-hooks/use-product-id-property";

type OrderLineImageDescriptionProps = {
  blob?: string;
  disableImageClick?: boolean;
  type: number;
  description?: string;
  children?: ReactNode;
  productID?: number | string;
  backendID?: number | string;
  customID?: number | string;
  additionalProductRequirements?: EVA.Core.ProductRequirementModel;
};

const OrderLineImageIsZoomedAtom = atom({
  key: "orderLineImageIsZoomed",
  default: false,
});

const OrderLineImageDescription = ({
  additionalProductRequirements,
  backendID,
  children,
  customID,
  description,
  productID,
  type,
}: OrderLineImageDescriptionProps) => {
  const generateImage = useGenerateOrderlineImage();
  const { getProperty: getIdProp } = useProductIDProperty();

  const itemID = useMemo(
    () => getIdProp([productID, backendID, customID]),
    [backendID, customID, getIdProp, productID],
  );

  const productRequirements = useMemo(() => {
    return additionalProductRequirements?.RequirementModels.filter(
      (requirement) => requirement?.Value,
    );
  }, [additionalProductRequirements?.RequirementModels]);

  const getChipText = useCallback(
    ({ ID, Name, Value }: EVA.Core.ProductRequirementLineModel, id?: boolean) =>
      id ? `${ID}-${Name}-${Value}` : `${Name}: ${Value}`,
    [],
  );

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>{generateImage(type)}</Grid>
      <Grid item>
        <div className="ml-5">
          <Text variant="body1">{description}</Text>
          {itemID ? <Text variant="body2">{itemID}</Text> : null}
          {productRequirements?.length ? (
            <div className="py-[10px]">
              <Grid container direction="row" spacing={1}>
                {sortBy(productRequirements, (productRequirement) =>
                  getChipText(productRequirement),
                ).map((productRequirement) => (
                  <Grid item key={getChipText(productRequirement, true)}>
                    <Chip color="orange">{getChipText(productRequirement)}</Chip>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : null}
          {children}
        </div>
      </Grid>
    </Grid>
  );
};

export { OrderLineImageDescription, OrderLineImageIsZoomedAtom };
