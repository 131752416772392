import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

const stockLabelsServiceState = builders.buildServiceState({
  service: Core.GetStockLabels,
  key: "Core:GetStockLabels",
  allowEmptyRequest: true,
  requireEmployee: true,
});

export const stockLabelsResultSelector = selector({
  key: "Core:GetStockLabels:Result",
  get: ({ get }) => get(stockLabelsServiceState.response)?.StockLabels,
});

export const sellableStockLabelSelector = selector({
  key: "StockLabels:Sellable",
  get: ({ get }) =>
    get(stockLabelsResultSelector)?.find((stockLabel) => stockLabel.Name === "Sellable"),
});
