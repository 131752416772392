// TODO: remove this and use IConditionsType everywhere instead
enum DiscountConditions {
  CUSTOMER = "customer",
  CUSTOMERAGE = "customer-age",
  ORDERAMOUNT = "order-amount",
  PRODUCTS = "products",
  RITUALSMEMBER = "rituals-member",
  RITUALSWELCOMEGIFT = "rituals-welcome-gift",
  STOCKLABEL = "stock-label",
  USERROLE = "user-role",
  USERTYPE = "user-type",
  USERCUSTOMFIELDS = "user-custom-fields",
  ORDERCUSTOMFIELDS = "order-custom-fields",
  ORDERLINECUSTOMFIELDS = "orderline-custom-fields",
  ORIGINALOU = "original-ou",
  ORDERTYPE = "order-type",
  PRODUCTREQUIREMENT = "product-requirement",
  LOYALTYPOINTS = "loyalty-points",
  ORDERPROPERTIES = "order-properties",
  COUPONVALIDITY = "coupon-validity",
}

export default DiscountConditions;
