import { Dispatch, SetStateAction } from "react";

import { Separator } from "@new-black/lyra";

import { OrderLineItem } from "./orderline-item";
import { ShowMoreButton } from "./show-more-button";

type OrderLinesListProps = {
  selectedOrderLineId?: number;
  setSelectedOrderLineId: (newValue?: number) => void;
  orderLinesResponse?: EVA.Core.ListOrderLinesResponse;
  onChange?: (newSelection?: number) => void;
  orderLinesLimit: number;
  setOrderLinesLimit: Dispatch<SetStateAction<number>>;
  isOrderLinesResponseLoading?: boolean;
};

export const OrderLinesList = ({
  isOrderLinesResponseLoading,
  onChange,
  orderLinesLimit,
  orderLinesResponse,
  selectedOrderLineId,
  setOrderLinesLimit,
  setSelectedOrderLineId,
}: OrderLinesListProps) => {
  return (
    <div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-1 flex-col">
          <div className="flex max-h-[calc(100dvh-35rem)] flex-1 flex-col overflow-y-auto">
            {orderLinesResponse?.Result?.Page?.map((orderLine, idx) => (
              <div key={orderLine.ID}>
                <OrderLineItem
                  orderLine={orderLine}
                  isSelected={selectedOrderLineId === orderLine.ID}
                  onChange={(isSelected) => {
                    if (isSelected) {
                      setSelectedOrderLineId(orderLine.ID);
                      onChange?.(orderLine.ID);
                    } else {
                      setSelectedOrderLineId(undefined);
                      onChange?.(undefined);
                    }
                  }}
                />

                {idx !== (orderLinesResponse?.Result?.Page?.length ?? 0) - 1 ? <Separator /> : null}
              </div>
            ))}
            <ShowMoreButton
              limit={orderLinesLimit}
              setLimit={setOrderLinesLimit}
              total={orderLinesResponse?.Result?.Total}
              isLoading={isOrderLinesResponseLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
