import { ComponentPropsWithoutRef, useMemo } from "react";
import { Key } from "react-aria-components";

import { Select as LyraSelect } from "@new-black/lyra";
import { useField } from "formik";

import Select, { SelectProps } from "~/components/suite-ui/select";

type FormikInputProps = Omit<SelectProps, "value" | "onChange">;

interface IFormikSelectExportProps {
  ({ name, ...props }: FormikInputProps): JSX.Element;
  /** @deprecated Use the Lyra Select wrapped in a Formik Field component directly instead */
  Lyra: <T extends object>({ hideError, name, ...props }: LyraFormikInputProps<T>) => JSX.Element;
}

export const FormikSelect: IFormikSelectExportProps = ({ name, ...props }: FormikInputProps) => {
  const { helpertext, items } = props;
  const [field, meta] = useField<string | number | undefined>(name);

  return (
    <Select
      {...props}
      {...field}
      error={!!meta.error && meta.touched}
      helpertext={meta.error && meta.touched ? meta.error : helpertext}
      value={items.length && field.value !== undefined ? field.value : ""}
    />
  );
};

type LyraSelectProps<T extends object> = ComponentPropsWithoutRef<typeof LyraSelect<T>>;
interface LyraFormikInputProps<T extends object>
  extends Omit<LyraSelectProps<T>, "value" | "onChange" | "name"> {
  name: string;
  hideError?: boolean;
}

/** @deprecated Use the Lyra Select wrapped in a Formik Field component directly instead */
function LyraFormikSelect<T extends object>({
  hideError,
  name,
  ...props
}: LyraFormikInputProps<T>) {
  const { errorMessage, items } = props;
  const [field, meta, { setValue }] = useField<Key | undefined>(name);

  const selectedValue = useMemo(
    () => items.find((item) => props.getItemId(item) === field.value),
    [field.value, items, props],
  );

  return (
    <LyraSelect
      {...props}
      {...field}
      value={selectedValue}
      errorMessage={!hideError && meta.error && meta.touched ? meta.error : errorMessage}
      onChange={(newValue) => {
        setValue(newValue ? props.getItemId(newValue) : undefined);
      }}
    />
  );
}

FormikSelect.Lyra = LyraFormikSelect;
