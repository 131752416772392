import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getDiscountLayerByIDLoaderQuery,
  serviceQuery: getDiscountLayerByIDQuery,
  serviceQueryKeys: GetDiscountLayerByIDQueryKeys,
  useServiceQueryHook: useGetDiscountLayerByIDQuery,
} = createServiceQuery(CoreManagement.GetDiscountLayerByID, true);

export const {
  serviceLoaderQuery: listDiscountLayersLoaderQuery,
  serviceQuery: listDiscountLayersQuery,
  useServiceQueryHook: useListDiscountLayersQuery,
} = createServiceQuery(CoreManagement.ListDiscountLayers);
