export interface IOrderLineDtoWithProductRequirement extends EVA.Core.OrderLineDto {
  ProductRequirement?: EVA.Core.ProductRequirementModel;
  Children?: IOrderLineDtoWithProductRequirement[];
}

export enum TABS {
  ORDER_LINES_TAB = "order-lines-tab",
  RELATED_ORDERS_TAB = "related-orders-tab",
}

export interface IOrderOptions {
  IgnoreDiscounts: boolean;
  IgnoreShippingCosts: boolean;
  IgnoreGiftWrappingCosts: boolean;
  AllowPartialFulfillment: boolean;
}

export const ORDER_OPTIONS = [
  "IgnoreDiscounts",
  "IgnoreShippingCosts",
  "IgnoreGiftWrappingCosts",
  "AllowPartialFulfillment",
] as const;

export type TOrderOption = typeof ORDER_OPTIONS[number];
