import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

/**
 * Builds service state for the GetOrganizationUnitSetScopes service.
 * Is being used in the filters in the Organization Sets chapter overview.
 */
const organizationUnitSetsScopesState = builders.buildServiceState({
  service: CoreManagement.GetOrganizationUnitSetScopes,
  key: "organizationUnitSetsScopes",
  requireEmployee: true,
  allowEmptyRequest: true,
});

/**
 * Convenience helper selector that returns the scopes from the organizationUnitSetsScopesState
 */
export const ouSetsScopes = selector<
  EVA.Core.Management.GetOrganizationUnitSetScopesResponse.OrganizationUnitSetScope[]
>({
  key: "ouSetsScopes:list",
  get: ({ get }) => {
    const response = get(organizationUnitSetsScopesState.response);
    return response?.Scopes ?? [];
  },
});
