import { IntlShape } from "react-intl";

import { DiscountCompliancyRule } from "../eva-core";

export const discountCompliancyRuleTranslator = (
  intl: IntlShape,
): ((triggerType: Partial<DiscountCompliancyRule>) => string | undefined) => {
  const translationMap: Record<Partial<DiscountCompliancyRule>, string> = {
    [DiscountCompliancyRule.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [DiscountCompliancyRule.EmployeeDiscountTaxExclusion]: intl.formatMessage({
      id: "generic.label.employee-discount-tax-exclusion",
      defaultMessage: "Employee discount tax exclusion",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
