import { ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Avatar, Badge, TableColumnDef } from "@new-black/lyra";

import AvailableUserTasksTablePrintStatusCell from "../table/available-user-tasks-table-print-status-cell";
import { AvailableUserTasksTableColumns, IAvailableUserTaskDto } from "../table/types";

import DeadlineTableCell from "~/components/shared/deadline-table-cell";
import { LyraYesNoString } from "~/components/ui/boolean-string";

const useAvailableUserTasksTableColumns = (
  columns: AvailableUserTasksTableColumns[],
  customRowActions?: ({ rowData }: { rowData: IAvailableUserTaskDto }) => ReactNode,
) => {
  const intl = useIntl();

  const getColumnDef = useCallback(
    (column: AvailableUserTasksTableColumns): TableColumnDef<IAvailableUserTaskDto> | undefined => {
      switch (column) {
        case AvailableUserTasksTableColumns.OrderID:
          return {
            header: intl.formatMessage({
              id: "generic.label.order-id",
              defaultMessage: "Order ID",
            }),
            accessorKey: "Data",
            id: "orderID",
            cell: ({ getValue }) => getValue()?.OrderID,
          };
        case AvailableUserTasksTableColumns.RelatedOrderID:
          return {
            header: intl.formatMessage({
              id: "generic.label.related-order-id",
              defaultMessage: "Related order ID",
            }),
            id: "relatedOrderID",
            accessorKey: "Data",
            cell: ({ getValue }) => getValue()?.RelatedOrderID,
          };
        case AvailableUserTasksTableColumns.TaskID:
          return {
            header: intl.formatMessage({
              id: "generic.label.task-id",
              defaultMessage: "Task ID",
            }),
            accessorKey: "ID",
            id: "taskID",
          };

        case AvailableUserTasksTableColumns.Deadline:
          return {
            header: intl.formatMessage({
              id: "generic.label.deadline",
              defaultMessage: "Deadline",
            }),
            accessorKey: "Deadline",
            id: "deadline",
            cell: ({ getValue }) => {
              const value = getValue();
              return value ? <DeadlineTableCell value={value} /> : <div />;
            },
          };

        case AvailableUserTasksTableColumns.Urgency:
          return {
            header: intl.formatMessage({
              id: "generic.label.urgency",
              defaultMessage: "Urgency",
            }),
            accessorKey: "HasUrgency",
            id: "urgency",
            cellType: "badge",
            cell: ({ getValue }) => <LyraYesNoString value={getValue()} />,
          };

        case AvailableUserTasksTableColumns.Status:
          return {
            header: intl.formatMessage({
              id: "generic.label.status",
              defaultMessage: "Status",
            }),
            id: "status",
            accessorKey: "User",
            cellType: "badge",
            cell: ({ getValue }) => {
              const value = getValue()?.FullName;
              return (
                <>
                  {value ? (
                    <Badge variant="yellow">
                      <FormattedMessage
                        id="generic.label.in-progress"
                        defaultMessage="In progress"
                      />
                    </Badge>
                  ) : (
                    <Badge variant="gray">
                      <FormattedMessage id="generic.label.to-do" defaultMessage="To do" />
                    </Badge>
                  )}
                </>
              );
            },
          };

        case AvailableUserTasksTableColumns.Description:
          return {
            header: intl.formatMessage({
              id: "generic.label.description",
              defaultMessage: "Description",
            }),
            accessorKey: "Description",
            id: "description",
            cell: ({ getValue }) => <span>{getValue() || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.SpecialProducts:
          return {
            header: intl.formatMessage({
              id: "generic.label.special-products",
              defaultMessage: "Special products",
            }),
            accessorKey: "Data",
            id: "specialProducts",
            cell: ({ getValue }) => <span>{getValue()?.ProductMarkerValue || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.AssigneeAvatar:
          return {
            header: intl.formatMessage({
              id: "generic.label.assignee",
              defaultMessage: "Assignee",
            }),
            accessorKey: "User",
            id: "assigneeWithAvatar",
            cellType: "badge",
            cell: ({ getValue }) =>
              getValue() ? <Avatar size="medium" name={getValue()?.FullName} /> : null,
          };

        case AvailableUserTasksTableColumns.Assignee:
          return {
            header: intl.formatMessage({
              id: "generic.label.assignee",
              defaultMessage: "Assignee",
            }),
            accessorKey: "User",
            id: "assignee",
            cell: ({ getValue }) => <span>{getValue()?.FullName || "-"}</span>,
          };

        case AvailableUserTasksTableColumns.Label:
          return {
            header: intl.formatMessage({
              id: "generic.label.label",
              defaultMessage: "Label",
            }),
            id: "labels",
            accessorKey: "Labels",
            cell: ({ getValue }) => <span>{getValue()?.join(", ") || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.NumberOfProducts:
          return {
            header: intl.formatMessage({
              id: "generic.label.number-of-products",
              defaultMessage: "Number of products",
            }),
            id: "quantity",
            accessorKey: "Data",
            cell: ({ getValue }) => <span>{getValue().Quantity ?? "-"} </span>,
          };

        // TODO: revisit this when BE changes are ready as the required data is not available yet
        // case AvailableUserTasksTableColumns.ShipmentID:
        //   return {
        //     header: intl.formatMessage({
        //       id: "generic.label.shipment-id",
        //       defaultMessage: "Shipment ID",
        //     }),
        //     id: "shipmentId",
        //     accessor: (row) => row.Data?.ShipmentID,
        //     Cell: ({ value }: { value?: number }) => <span>{value ?? "-"} </span>,
        //   };

        // case AvailableUserTasksTableColumns.ShippingMethod:
        //   return {
        //     header: intl.formatMessage({
        //       id: "generic.label.shipping-method",
        //       defaultMessage: "Shipping method",
        //     }),
        //     id: "shippingMethod",
        //     accessor: (row) => row.Data?.ShippingMethodName,
        //     Cell: ({ value }: { value?: string }) => <span>{value ?? "-"} </span>,
        //   };

        case AvailableUserTasksTableColumns.PrintStatus:
          return {
            header: intl.formatMessage({
              id: "generic.label.status",
              defaultMessage: "Status",
            }),
            id: "print status",
            cell: ({ row }) =>
              row ? <AvailableUserTasksTablePrintStatusCell task={row.original} /> : null,
          };

        case AvailableUserTasksTableColumns.StartTime:
          return {
            header: intl.formatMessage({
              id: "generic.label.start-time",
              defaultMessage: "Start time",
            }),
            accessorKey: "StartTime",
            id: "startTime",
            cell: ({ getValue }) => (
              <span>
                {getValue() ? `${intl.formatDate(getValue())} ${intl.formatTime(getValue())}` : "-"}
              </span>
            ),
          };

        case AvailableUserTasksTableColumns.HasOpenAmount:
          return {
            header: intl.formatMessage({
              id: "generic.label.open-amount",
              defaultMessage: "Open amount",
            }),
            id: "hasOpenAmount",
            accessorKey: "hasOpenAmount",
            cell: ({ getValue }) => (
              <span>
                {getValue() !== undefined ? (
                  <Badge variant={getValue() ? "red" : "green"}>
                    {getValue() ? (
                      <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="generic.label.no" defaultMessage="No" />
                    )}
                  </Badge>
                ) : null}
              </span>
            ),
          };

        default:
          return undefined;
      }
    },
    [intl],
  );

  const tableColumns = useMemo(() => {
    const columnDefs = columns
      .map((column) => getColumnDef(column))
      .filter((def): def is TableColumnDef<IAvailableUserTaskDto> => !!def);

    if (customRowActions) {
      columnDefs.push({
        header: "",
        id: "actions",
        cellType: "button",
        align: "end",
        cell: ({ row }) => customRowActions({ rowData: row.original }),
      });
    }

    return columnDefs;
  }, [columns, getColumnDef, customRowActions]);

  return tableColumns;
};

export default useAvailableUserTasksTableColumns;
