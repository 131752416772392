import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { searchDiscountsByQueryQuery, useGetDiscountByIDQuery } from "~/models/discounts";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface IDiscountSearchListFieldItem {
  ID: number;
  Name: string;
  CouponsGeneratedByOtherDiscount: boolean;
}

export const generateDiscountSearchListField = ({
  filters,
  frontendFilter,
}: {
  filters: Partial<EVA.Core.SearchDiscountsByQueryFilter>;
  frontendFilter?: (value: IDiscountSearchListFieldItem) => boolean;
}) =>
  LyraSearchListFieldGenerator<
    CoreManagement.SearchDiscountsByQuery,
    IDiscountSearchListFieldItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((discount) => ({
        ID: discount.ID,
        Name: discount.Description!,
        CouponsGeneratedByOtherDiscount: !!discount.CouponsGeneratedByOtherDiscount,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.discount",
      defaultMessage: "Discount",
    }),
    useItemByID: (id) => {
      const { data, isFetching: isLoading } = useGetDiscountByIDQuery(id ? { ID: id } : undefined, {
        refetchOnWindowFocus: false,
      });

      const item = useMemo(
        () =>
          data
            ? {
                ID: data?.ID,
                Name: data?.Description!,
                CouponsGeneratedByOtherDiscount: !!data?.CouponsGeneratedByOtherDiscount,
              }
            : undefined,
        [data],
      );

      return {
        data: item,
        isLoading,
      };
    },
    frontendFilter,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: searchDiscountsByQueryQuery,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Description,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Description: newValue === "" ? undefined : newValue,
              Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          },
        }),
        initialRequest: {
          PageConfig: {
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...filters,
            },
          },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: (response?.Result?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit: (req?.PageConfig?.Limit ?? 0) + DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
