import { ComponentProps, ReactNode } from "react";

import { Dialog } from "@material-ui/core";
import { Button, Dialog as LyraDialog, DialogProps as LyraDialogProps } from "@new-black/lyra";

import { ConfirmationModalContent } from "./confirmation-modal-content";
import { ConfirmationModalContentLyra } from "./confirmation-modal-content-lyra";

import { TextProps } from "~/components/suite-ui/text";

export interface IConfirmationModalTranslations {
  cancelButtonKey?: TranslationKey;
  confirmButtonKey?: TranslationKey;
  titleKey?: TranslationKey;
  descriptionKey?: TranslationKey;
}

export interface IConfirmationModalMessages {
  titleMessage?: ReactNode;
  descriptionMessage?: ReactNode;
  cancelButtonMessage?: ReactNode;
  confirmButtonMessage?: ReactNode;
}

type ConfirmationModalPropVariants =
  | {
      variant?: "material";
      textDescriptionVariant?: TextProps["variant"];
      disablePortal?: boolean;
      fullWidth?: boolean;
      maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    }
  | {
      variant: "lyra";
      maxWidth?: LyraDialogProps["maxWidth"];
      "aria-label"?: string;
    };

export type IConfirmationModalBaseProps = ConfirmationModalPropVariants & {
  isOpen?: boolean;
  onCancel: () => void;
  isLoading?: boolean;
  translations?: IConfirmationModalTranslations;
  messages?: IConfirmationModalMessages;
  cancelButtonVariant?: ComponentProps<typeof Button>["variant"];
  confirmButtonVariant?: ComponentProps<typeof Button>["variant"];
  children?: ReactNode;
};

export type IConfirmationModalProps = IConfirmationModalBaseProps & {
  type?: "callback";
  onConfirm?: () => void;
  name?: never;
  value?: never;
  form?: never;
};

export type IConfirmationModalWithSubmitProps = IConfirmationModalBaseProps & {
  type?: "submit";
  onConfirm?: never;
  name?: string;
  value?: string;
  form?: string;
  deleteForm?: string;
};

/**
 * Component used to display a confirmation popup.
 */
export const ConfirmationModal = ({
  cancelButtonVariant,
  confirmButtonVariant,
  form,
  isLoading,
  isOpen,
  messages,
  name,
  onCancel,
  onConfirm,
  translations,
  value,
  ...props
}: IConfirmationModalProps | IConfirmationModalWithSubmitProps) => {
  if (props.variant === "lyra") {
    return (
      <LyraDialog
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            onCancel();
          }
        }}
        maxWidth={props.maxWidth ?? "lg"}
      >
        <ConfirmationModalContentLyra
          onCancel={onCancel}
          onConfirm={onConfirm}
          translations={translations}
          form={form}
          cancelButtonVariant={cancelButtonVariant}
          confirmButtonVariant={confirmButtonVariant}
          isLoading={isLoading}
          messages={messages}
          name={name}
          type={props.type}
          value={value}
          {...props}
        />
      </LyraDialog>
    );
  }

  return (
    <Dialog
      open={isOpen ?? false}
      onClose={onCancel}
      maxWidth={props.maxWidth ?? "sm"}
      fullWidth={props.fullWidth}
      disablePortal={props.disablePortal}
    >
      <ConfirmationModalContent
        onCancel={onCancel}
        onConfirm={onConfirm}
        translations={translations}
        form={form}
        cancelButtonVariant={cancelButtonVariant}
        confirmButtonVariant={confirmButtonVariant}
        isLoading={isLoading}
        messages={messages}
        name={name}
        type={props.type}
        value={value}
        textDescriptionVariant={props.textDescriptionVariant}
      />
    </Dialog>
  );
};
