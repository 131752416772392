import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { SearchListField } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import { Autocomplete } from "../../suite-ui/autocomplete/material-autocomplete";

import SearchActionButton from "~/components/shared/action-buttons/search-action-button";
import useDebounce from "~/hooks/suite-react-hooks/use-debounce";
import {
  addressAutocompleteQuery,
  getAutocompleteAddressByReferenceQuery,
} from "~/models/addresses";

export interface IAddressAutocomplete {
  autoFocus?: boolean;
  country?: string;
  fieldOnly?: boolean;
  small?: boolean;
  passive?: boolean;
  onChange: (fullAddress: EVA.Core.GetAutocompleteAddressByReferenceResponse) => void;
  variant?: "material" | "lyra";
}

const AddressAutocomplete = ({
  autoFocus = false,
  country,
  fieldOnly,
  onChange,
  passive,
  small = false,
  variant = "material",
}: IAddressAutocomplete) => {
  const intl = useIntl();

  const [searchVisible, setSearchVisible] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [address, setAddress] = useState<EVA.Core.AddressSuggestion | undefined>(undefined);

  const onInputChange = useCallback(
    (newValue?: string) => {
      setQuery(newValue ?? "");
    },
    [setQuery],
  );

  const { data, isFetching: isLoading } = useQuery({
    ...addressAutocompleteQuery(
      { Query: debouncedQuery, Countries: country ? [country] : undefined },
      [],
    ),
    enabled: debouncedQuery.length > 3,
  });

  useQuery({
    ...getAutocompleteAddressByReferenceQuery(
      {
        Suggestion: {
          Reference: address?.Reference ?? "",
          ID: address?.Reference ?? "",
          Description: address?.Description ?? "",
        },
      },
      [],
    ),
    enabled: !!address?.Reference,
    onSuccess: onChange,
  });

  return searchVisible || fieldOnly ? (
    <div className="w-full min-w-60 grow">
      {variant === "material" ? (
        <Autocomplete
          small={small}
          autoFocus={autoFocus}
          IDKey="ID"
          LabelKey="Description"
          label={intl.formatMessage({
            id: "generic.label.search-address",
            defaultMessage: "Search address",
          })}
          controlledInputValue={debouncedQuery}
          onInputChange={onInputChange}
          items={data?.Result ?? []}
          passive={passive}
          setValue={(selectedItem) => {
            setAddress(selectedItem ?? undefined);
          }}
          value={address}
          onBlur={() => {
            if (!debouncedQuery?.length) {
              setSearchVisible(false);
            }
          }}
          isLoading={isLoading}
        />
      ) : (
        <SearchListField
          items={data?.Result ?? []}
          isLoading={isLoading}
          getItemId={(item) => item.Reference}
          getLabel={(item) => item.Description}
          selectRenderElements={(item) => ({ label: item.Description })}
          isDisabled={passive}
          label={intl.formatMessage({
            id: "generic.label.search-address",
            defaultMessage: "Search address",
          })}
          hideInputHeader={!fieldOnly}
          hideHintLabel
          placeholder={
            fieldOnly
              ? undefined
              : intl.formatMessage({
                  id: "generic.label.search-address",
                  defaultMessage: "Search address",
                })
          }
          value={address}
          onQueryChange={onInputChange}
          onChange={(selectedItem) => {
            setAddress(selectedItem ?? undefined);
            if (!selectedItem) {
              setSearchVisible(false);
            }
          }}
        />
      )}
    </div>
  ) : (
    <SearchActionButton onPress={() => setSearchVisible(true)} />
  );
};

export default AddressAutocomplete;
