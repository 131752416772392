import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getEndpointConfigurationsLoaderQuery,
  serviceQuery: getEndpointConfigurationsQuery,
  useServiceQueryHook: useGetEndpointConfigurationsQuery,
} = createServiceQuery(CoreManagement.GetEndpointConfigurations);

export const {
  serviceLoaderQuery: getEndpointConfigurationLoaderQuery,
  serviceQueryKeys: getEndpointConfigurationQueryKeys,
  useServiceQueryHook: useGetEndpointConfigurationQuery,
} = createServiceQuery(CoreManagement.GetEndpointConfiguration, true);
