import { useCallback, useMemo } from "react";
import { useNavigation } from "react-router";

import { NavigateOptionsWithForce, useNavigate } from "~/components/routing";

const useNavigateBack = () => {
  const navigate = useNavigate();
  const { state } = useNavigation();

  const canNavigate = useMemo(() => {
    return state === "idle";
  }, [state]);

  const navigateBack = useCallback(
    (options?: NavigateOptionsWithForce | undefined) => {
      if (canNavigate) {
        navigate("../", options);
      }
    },
    [canNavigate, navigate],
  );

  return navigateBack;
};

export default useNavigateBack;
