import { FormattedMessage } from "react-intl";

import { Badge } from "@new-black/lyra";

import { IAvailableUserTaskDto } from "./types";

export default function AvailableUserTasksTablePrintStatusCell({
  task,
}: {
  task: IAvailableUserTaskDto;
}) {
  if (task.Data?.IsError) {
    return (
      <Badge variant="red">
        <FormattedMessage id="generic.label.error" defaultMessage="Error" />
      </Badge>
    );
  }

  return task.User ? (
    <Badge variant="yellow">
      <FormattedMessage id="generic.label.in-progress" defaultMessage="In progress" />
    </Badge>
  ) : (
    <Badge variant="blue">
      <FormattedMessage id="generic.label.to-do" defaultMessage="To do" />
    </Badge>
  );
}
