import { ComponentProps, useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";

import { SingleIDSearchListField } from "./search-list-field-generator.single.types";
import {
  ItemKey,
  SingleSearchListField,
  SpyCallback,
  SpyFunction,
} from "./search-list-field-generator.types";

import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";

export type Controlled<T> = Omit<T, "defaultValue">;
export type Uncontrolled<T> = Omit<T, "value" | "onChange">;
export type UncontrolledWithChangeCallback<T> = Omit<T, "value">;

export interface ISingleSearchListFieldWrapperReturnValues<Item> {
  Controlled: (inputProps: Controlled<SingleSearchListField<Item>["Props"]>) => JSX.Element;
  Uncontrolled: (
    inputProps: Uncontrolled<SingleSearchListField<Item>["Uncontrolled"]>,
  ) => JSX.Element;
  Formik: (inputProps: Controlled<SingleSearchListField<Item>["Formik"]>) => JSX.Element;
  Recoil: (inputProps: Controlled<SingleSearchListField<Item>["Recoil"]>) => JSX.Element;
  RecoilIDOnlyWithQuery: (
    inputProps: Controlled<SingleSearchListField<Item>["RecoilIDOnlyWithQuery"]>,
  ) => JSX.Element;
}

/** Wrapper component that based on a basic SingleSearchListField
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const SingleSearchListFieldWrapper = <Item,>(
  SearchListField: (props: SingleSearchListField<Item>["Props"]) => JSX.Element,
): ISingleSearchListFieldWrapperReturnValues<Item> => {
  const Controlled = (
    inputProps: Controlled<
      ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Controlled"]>
    >,
  ) => (
    <SearchListField
      {...inputProps}
      onClear={() => {
        if (inputProps.onClear) {
          inputProps.onClear();
          return;
        }
        inputProps?.onChange?.(undefined);
      }}
    />
  );

  const Uncontrolled = (
    inputProps: Uncontrolled<
      ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Uncontrolled"]>
    >,
  ) => <SearchListField {...inputProps} />;

  const Formik = ({
    name,
    ...inputProps
  }: Controlled<ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Formik"]>>) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item | undefined>(name);
    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        setFieldValue(name, newValue);
        inputProps?.onChange?.(newValue);
      },
      [inputProps, name, setFieldValue],
    );
    return (
      <SearchListField
        {...inputProps}
        value={field.value}
        onChange={handleChange}
        errorMessage={meta.touched ? meta.error : undefined}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: Controlled<ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Recoil"]>>) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue);
      },
      [customOnChange, setRecoilValue],
    );
    return (
      <Controlled
        value={recoilValue}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
        {...inputProps}
      />
    );
  };

  /** Same as Recoil, but it is based only on the ID and it also allows to pass a hook that retrievs an item by ID */
  const RecoilIDOnlyWithQuery = ({
    customOnChange,
    getItemID,
    recoilState,
    useItem,
    ...inputProps
  }: Controlled<SingleSearchListField<Item>["RecoilIDOnlyWithQuery"]>) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);

    const { isLoading, item } = useItem(recoilValue);

    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        if (customOnChange) {
          customOnChange(setRecoilValue, newValue);
          return;
        }
        setRecoilValue(newValue ? getItemID(newValue) : undefined);
      },
      [customOnChange, getItemID, setRecoilValue],
    );

    return (
      <Controlled
        value={item}
        onClear={() => handleChange(undefined)}
        onChange={(newValue) => handleChange(newValue)}
        isLoading={recoilValue ? isLoading : false || inputProps.isLoading}
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil, RecoilIDOnlyWithQuery };
};

export interface ISingleSearchListFieldWrapperWithIDReturnValues<
  Item extends object,
  IDType extends ItemKey,
> {
  Controlled: (
    inputProps: Controlled<SingleIDSearchListField<Item, IDType>["Props"]>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: UncontrolledWithChangeCallback<
      SingleIDSearchListField<Item, IDType>["Uncontrolled"]
    >,
  ) => JSX.Element;
  Formik: (inputProps: Controlled<SingleIDSearchListField<Item, IDType>["Formik"]>) => JSX.Element;
  Recoil: (inputProps: Controlled<SingleIDSearchListField<Item, IDType>["Recoil"]>) => JSX.Element;
}

/** Wrapper component that is based on a basic, ID-based SingleSearchListField
 * that allows to pass a hook that retrievs an item by ID */
export const SingleSearchListFieldIDWrapper = <Item extends object, IDType extends ItemKey>(
  SearchListField: (props: SingleSearchListField<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  getItemId: (item: Item) => IDType,
  useItemByID: (
    id: IDType | undefined,
    listItems: Item[] | undefined,
  ) => { data: Item | undefined; isLoading?: boolean },
): ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDType> => {
  const Controlled = (
    inputProps: Controlled<
      ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Controlled"]>
    >,
  ) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: currentItem, isLoading } = useItemByID(inputProps.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        inputProps.onChange?.(newValue ? getItemId(newValue) : undefined);
      },
      [inputProps],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        defaultValue={undefined}
        isLoading={isLoading || inputProps.isLoading}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: UncontrolledWithChangeCallback<
    ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Uncontrolled"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemId, setCurrentItemId] = useState(defaultValue);

    useEffect(() => setCurrentItemId(defaultValue), [defaultValue]);

    const { data: currentItem, isLoading } = useItemByID(currentItemId, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );

    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        const newSelection = newValue ? getItemId(newValue) : undefined;
        setCurrentItemId(newSelection);
        inputProps.onChange?.(newSelection);
      },
      [inputProps],
    );

    return (
      <>
        <SearchListField
          {...inputProps}
          name={undefined}
          value={currentItemId !== undefined ? currentItem : undefined}
          spyCallback={invokeSpy}
          isLoading={isLoading || inputProps.isLoading}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          onClear={() => {
            if (inputProps.onClear) {
              inputProps.onClear();
              return;
            }
            handleChange(undefined);
          }}
        />
        {currentItemId !== undefined ? (
          <input readOnly type="hidden" name={inputProps.name} value={currentItemId} />
        ) : null}
      </>
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: Controlled<
    ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Formik"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<IDType | undefined>(name);
    const { data: currentItem, isLoading } = useItemByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        const newSelection = newValue ? getItemId(newValue) : undefined;
        setFieldValue(name, newSelection);
        inputProps?.onChange?.(newSelection);
      },
      [inputProps, name, setFieldValue],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        onChange={handleChange}
        defaultValue={undefined}
        isLoading={isLoading || inputProps.isLoading}
        errorMessage={meta.touched ? meta.error : undefined}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: Controlled<
    ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Recoil"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: currentItem, isLoading } = useItemByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        const newSelection = newValue ? getItemId(newValue) : undefined;
        customOnChange
          ? customOnChange(setRecoilValue, newSelection)
          : setRecoilValue(newSelection);
      },
      [customOnChange, setRecoilValue],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        defaultValue={undefined}
        onChange={(newValue) => handleChange(newValue)}
        isLoading={isLoading || inputProps.isLoading}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
