import { useMemo } from "react";

import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { PIM } from "@springtree/eva-services-pim";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "~/util/base-values";

export const listProductPropertyTypesServiceState = builders.buildServiceState({
  service: PIM.ListProductPropertyTypes,
  key: "Orders:Detail:PIM:ListProductPropertyTypes",
  requireLoggedIn: true,
  requireEmployee: true,
  defaultRequest: {
    PageConfig: {
      Start: 0,
      Limit: ALL_ROWS_LIMIT,
    },
  },
});

export const listProductPropertyTypesResultSelector = selector({
  key: "Orders:Detail:PIM:ListProductPropertyTypes:Result",
  get: ({ get }) => get(listProductPropertyTypesServiceState.response)?.Result?.Page,
});

export const useListPIMProperties = () => {
  const data = hooks.useGetState(listProductPropertyTypesResultSelector);
  const isLoading = hooks.useIsLoading({ state: listProductPropertyTypesServiceState.response });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  return { data, isLoading, isLoadingWithoutResponse };
};
