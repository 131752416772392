import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import { useHasFunctionality } from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const usePimChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: productBundlesChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_BUNDLES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: productRelationsChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_RELATION_TYPES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: productSetsChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_SEARCHES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: supplierProductsAllowed } = useHasFunctionality(
    Functionalities.SUPPLIER_PRODUCTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: unitsOfMeasureAllowed } = useHasFunctionality(
    Functionalities.UNIT_OF_MEASURES,
    FunctionalityScope.View,
  );

  const pimChapters = useMemo((): IChapter[] => {
    let chapters: IChapter[] = [];

    if (productBundlesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.product-bundles",
            defaultMessage: "Product Bundles",
          }),
          path: "/product-bundles",
          iconName: "product-bundles",
        },
      ];
    }
    if (productRelationsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.product-relations",
            defaultMessage: "Product Relations",
          }),
          path: "/product-relations",
          iconName: "product-relations",
        },
      ];
    }
    if (productSetsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.product-sets",
            defaultMessage: "Product Sets",
          }),
          path: "/product-sets",
          iconName: "product-sets",
        },
      ];
    }

    if (unitsOfMeasureAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.units-of-measure",
            defaultMessage: "Units of Measure",
          }),
          path: "/units-of-measure",
          iconName: "units-of-measure",
        },
      ];
    }

    chapters = [
      {
        title: intl.formatMessage({
          id: "generic.label.products-and-assortments",
          defaultMessage: "Products and Assortments",
        }),
        path: "/products",
        iconName: "products",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.products-overview",
              defaultMessage: "Products overview",
            }),
            path: "/products/products-overview",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.product-properties",
              defaultMessage: "Product properties",
            }),
            path: "/products/product-properties",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.assortments",
              defaultMessage: "Assortments",
            }),
            path: "/products/assortments",
          },
        ],
      },

      ...chapters,

      {
        title: intl.formatMessage({
          id: "generic.title.product-requirements",
          defaultMessage: "Product Requirements",
        }),
        path: "/product-requirements",
        iconName: "custom-field",
      },
    ];

    if (supplierProductsAllowed) {
      chapters = [
        ...chapters,

        // Plugin (Admin 1.0 redirect)
        {
          title: intl.formatMessage({
            id: "generic.title.supplier-product-upload",
            defaultMessage: "Supplier Product Upload",
          }),
          path: "/plugin",
          iconName: "plugin",
        },
      ];
    }

    return chapters;
  }, [
    intl,
    productBundlesChapterAllowed,
    productRelationsChapterAllowed,
    productSetsChapterAllowed,
    supplierProductsAllowed,
    unitsOfMeasureAllowed,
  ]);

  return pimChapters;
};
