import { IntlShape } from "react-intl";

import { InvoiceExportExternalStatus } from "~/types/eva-core";

export const getInvoiceExportExternalStatusTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.InvoiceExportExternalStatus, string> = {
    [InvoiceExportExternalStatus.Failed]: intl.formatMessage({
      id: "generic.label.failed",
      defaultMessage: "Failed",
    }),
    [InvoiceExportExternalStatus.Pending]: intl.formatMessage({
      id: "generic.label.pending",
      defaultMessage: "Pending",
    }),
    [InvoiceExportExternalStatus.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [InvoiceExportExternalStatus.Completed]: intl.formatMessage({
      id: "generic.label.completed",
      defaultMessage: "Completed",
    }),
  };

  return (status: EVA.Core.InvoiceExportExternalStatus) => {
    return translationMap[status];
  };
};
