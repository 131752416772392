import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Card, CardActions, CardContent, CardHeader, Text } from "@new-black/lyra";

import EditActionButton from "../action-buttons/edit-action-button";

import CaseInformationConsumerAddressActions from "./case-information-consumer-address-actions";

import { OrderAddressField } from "~/features/consumer-and-addresses-card/edit-customer-modal/customer-edit-modal.types";
import { useAddressDisplay } from "~/features/consumer-and-addresses-card/edit-customer-modal/hooks/use-address-display";

interface IConsumerAddressesProps {
  shippingAddress?: EVA.Core.AddressBookDto;
  billingAddress?: EVA.Core.AddressBookDto;
  pickupAddress?: EVA.Core.AddressBookDto;
  consumerFullName?: string;
  onAddAddress?: (addressType: OrderAddressField) => void;
  onEditAddress?: (addressID: number, addressType: OrderAddressField) => void;
  onSelectAddress?: (type: OrderAddressField) => void;
}

export const ConsumerAddresses = ({
  billingAddress,
  consumerFullName,
  onAddAddress,
  onEditAddress,
  onSelectAddress,
  pickupAddress,
  shippingAddress,
}: IConsumerAddressesProps) => {
  const intl = useIntl();

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
      <ConsumerAddress
        address={shippingAddress?.Address}
        consumerFullName={consumerFullName}
        title={intl.formatMessage({
          id: "generic.label.shipping-address",
          defaultMessage: "Shipping address",
        })}
        onAddAddress={onAddAddress ? () => onAddAddress?.("ShippingAddress") : undefined}
        onEditAddress={
          shippingAddress?.ID
            ? () => onEditAddress?.(shippingAddress?.ID!, "ShippingAddress")
            : undefined
        }
        onSelectAddress={onSelectAddress ? () => onSelectAddress?.("ShippingAddress") : undefined}
      />

      <ConsumerAddress
        address={billingAddress?.Address}
        consumerFullName={consumerFullName}
        title={intl.formatMessage({
          id: "generic.label.billing-address",
          defaultMessage: "Billing address",
        })}
        onAddAddress={onAddAddress ? () => onAddAddress?.("BillingAddress") : undefined}
        onEditAddress={
          billingAddress?.ID
            ? () => onEditAddress?.(billingAddress?.ID!, "BillingAddress")
            : undefined
        }
        onSelectAddress={onSelectAddress ? () => onSelectAddress?.("BillingAddress") : undefined}
      />

      <ConsumerAddress
        address={pickupAddress?.Address}
        consumerFullName={consumerFullName}
        title={intl.formatMessage({
          id: "generic.label.pick-up-address",
          defaultMessage: "Pickup address",
        })}
        onAddAddress={onAddAddress ? () => onAddAddress?.("PickupAddress") : undefined}
        onEditAddress={
          pickupAddress?.ID ? () => onEditAddress?.(pickupAddress?.ID!, "PickupAddress") : undefined
        }
        onSelectAddress={onSelectAddress ? () => onSelectAddress?.("PickupAddress") : undefined}
      />
    </div>
  );
};

const ConsumerAddress = ({
  address,
  consumerFullName,
  onAddAddress,
  onEditAddress,
  onSelectAddress,
  title,
}: {
  address?: EVA.Core.AddressDto;
  consumerFullName?: string;
  title: string;
  onAddAddress?: () => void;
  onEditAddress?: (addressID: number) => void;
  onSelectAddress?: () => void;
}) => {
  const addressDisplay = useAddressDisplay(address);

  const addressName = useMemo(() => {
    if (address?.FirstName || address?.LastName) {
      return [address.FirstName ?? "", address.LastName ?? ""].join(" ");
    }
    return consumerFullName ?? "";
  }, [address?.FirstName, address?.LastName, consumerFullName]);

  const displayEditAction = useMemo(
    () => addressDisplay && address?.ID,
    [address?.ID, addressDisplay],
  );

  return (
    <Card variant="secondary">
      <CardHeader
        title={title}
        actions={
          onAddAddress || onSelectAddress || onEditAddress ? (
            <CardActions>
              {onAddAddress || onSelectAddress ? (
                <CaseInformationConsumerAddressActions
                  onAdd={onAddAddress}
                  onEdit={onEditAddress ? () => onEditAddress?.(address?.ID!) : undefined}
                  onSelect={onSelectAddress}
                />
              ) : (
                <>
                  {displayEditAction ? (
                    <EditActionButton onPress={() => onEditAddress?.(address?.ID!)} />
                  ) : null}
                </>
              )}
            </CardActions>
          ) : null
        }
      />
      <CardContent>
        {addressDisplay ? (
          <>
            <Text elementType="p">{addressName}</Text>
            {addressDisplay?.map((line, idx) => (
              <Text elementType="p" key={`${address?.ID}-line-${idx + 1}`}>
                {line}
              </Text>
            ))}
          </>
        ) : (
          <Text elementType="p">
            <FormattedMessage id="generic.label.na" defaultMessage="N/A" />
          </Text>
        )}
      </CardContent>
    </Card>
  );
};
