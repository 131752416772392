import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import { useHasFunctionality } from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const useTasksChapters = () => {
  const intl = useIntl();

  const { functionalityAllowed: customUserTasksAllowed } = useHasFunctionality(
    Functionalities.CUSTOM_USER_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: printTasksAllowed } = useHasFunctionality(
    Functionalities.PRINT_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: zonedCycleCountsSchedulesAllowed } = useHasFunctionality(
    Functionalities.ZONED_CYCLE_COUNTS_SCHEDULE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: cycleCountsZoneGroupsAllowed } = useHasFunctionality(
    Functionalities.CYCLE_COUNT_ZONE_GROUPS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: zonedCycleCountAllowed } = useHasFunctionality(
    Functionalities.ZONED_CYCLE_COUNTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrganizationUnitSets } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewRepairTasksAllowed } = useHasFunctionality(
    Functionalities.REPAIRS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: fullStockCountsAllowed } = useHasFunctionality(
    Functionalities.FULL_STOCK_COUNT,
    FunctionalityScope.View,
  );

  const tasksChapters = useMemo((): IChapter[] => {
    const chapters: IChapter[] = [];

    if (customUserTasksAllowed) {
      chapters.push(
        {
          title: intl.formatMessage({
            id: "generic.title.ship-from-store",
            defaultMessage: "Ship From Store",
          }),
          path: "/ship-from-store",
          iconName: "ship-from-store",
          items: [
            {
              title: intl.formatMessage({
                id: "generic.label.pick",
                defaultMessage: "Pick",
              }),
              path: "/ship-from-store/pick",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.pack",
                defaultMessage: "Pack",
              }),
              path: "/ship-from-store/pack",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.ship",
                defaultMessage: "Ship",
              }),
              path: "/ship-from-store/ship",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.print",
                defaultMessage: "print",
              }),
              path: "/ship-from-store/print",
            },
          ],
        },
        {
          title: intl.formatMessage({
            id: "generic.title.incoming-shipment",
            defaultMessage: "Incoming Shipment",
          }),
          path: "/incoming-shipment",
          iconName: "incoming-shipment",
        },
        {
          title: intl.formatMessage({
            id: "generic.title.reservation-cleanup",
            defaultMessage: "Reservations Cleanup",
          }),
          path: "/reservation-cleanup",
          iconName: "reservation-cleanup",
        },
        {
          title: intl.formatMessage({
            id: "module.tasks.chapters.reservations",
            defaultMessage: "Reservations",
          }),
          path: "/reservations",
          iconName: "reservations",
        },
      );
    }

    if (customUserTasksAllowed && viewRepairTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.repairs",
          defaultMessage: "Repairs",
        }),
        path: "/repairs",
        iconName: "repairs",
      });
    }

    if (printTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.tasks.chapters.print",
          defaultMessage: "Print",
        }),
        path: "/print",
        iconName: "print",
      });
    }

    //TODO: check functionality
    if (
      cycleCountsZoneGroupsAllowed ||
      (viewOrganizationUnitSets && cycleCountsZoneGroupsAllowed)
    ) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.zones-and-zone-groups",
          defaultMessage: "Zones and Zone Groups",
        }),
        path: "/zones-and-zone-groups",
        iconName: "cycle-count-zones",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.zones",
              defaultMessage: "Zones",
            }),
            path: "/zones-and-zone-groups/zones",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.zone-groups",
              defaultMessage: "Zone groups",
            }),
            path: "/zones-and-zone-groups/zone-groups",
          },
        ],
      });
    }

    if (zonedCycleCountsSchedulesAllowed || zonedCycleCountAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.zoned-cycle-counts-and-schedules",
          defaultMessage: "Zoned Cycle Counts and Schedules",
        }),
        path: "/zoned-cycle-counts-and-schedules",
        iconName: "zoned-cycle-counts",
        items: [
          {
            title: intl.formatMessage({
              id: "generic.label.cycle-counts",
              defaultMessage: "Cycle counts",
            }),
            path: "/zoned-cycle-counts-and-schedules/cycle-counts",
          },
          {
            title: intl.formatMessage({
              id: "generic.label.schedules",
              defaultMessage: "Schedules",
            }),
            path: "/zoned-cycle-counts-and-schedules/schedules",
          },
        ],
      });
    }

    if (fullStockCountsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.full-stock-count",
          defaultMessage: "Full Stock Count",
        }),
        path: "/full-stock-counts",
        iconName: "full-stock-count",
      });
    }

    return chapters;
  }, [
    customUserTasksAllowed,
    cycleCountsZoneGroupsAllowed,
    fullStockCountsAllowed,
    intl,
    printTasksAllowed,
    viewOrganizationUnitSets,
    viewRepairTasksAllowed,
    zonedCycleCountAllowed,
    zonedCycleCountsSchedulesAllowed,
  ]);

  return tasksChapters;
};
