import { isEmpty } from "lodash";
import { z } from "zod";
import { zfd } from "zod-form-data";

import { CustomFieldDataTypes } from "~/types/eva-core";
import { intlAccessor } from "~/util/intl-accessor";

export const singleCustomFieldFormSchema = z
  .object({
    DataType: zfd.numeric(z.number()),
    Name: z.string(),
    BackendID: z.string().optional(),
    IsArray: z
      .string()
      .optional()
      .transform((value) => (value === "true" ? true : value === "false" ? false : undefined)),
    EnumValues: zfd
      .json(z.record(z.string(), z.object({ key: z.string(), value: z.string() })).optional())
      // Check if EnumValues has duplicate Backend IDs
      .refine(
        (arg) => {
          const hasDuplicates = Object.values(arg ?? {})
            .map(({ key }) => key)
            .sort()
            .some((_, index, array) => {
              return array[index] === array[index + 1];
            });

          return !hasDuplicates;
        },
        intlAccessor.formatMessage({
          id: "custom-fields.validation.values-duplicate-backend-ids",
          defaultMessage: "The values cannot contain duplicate backend IDs.",
        }),
      )
      .transform((arg) => {
        const result = Object.values(arg ?? {}).reduce(
          (accumulator, current) =>
            current.key && current.value
              ? { ...accumulator, [current.key]: current.value }
              : accumulator,
          {} as Record<string, string>,
        );

        return result;
      })
      .optional(),
    TypeID: zfd.numeric(z.number()),
    DisplayName: z.string().optional(),
    Order: zfd.numeric(z.number().optional()),
  })
  // If the custom field is of type Enum, check if the enum values are set
  .refine(
    ({ DataType, EnumValues }) => {
      if (DataType === CustomFieldDataTypes.Enum && isEmpty(EnumValues)) {
        return false;
      }

      return true;
    },
    {
      message: intlAccessor.formatMessage({
        id: "custom-fields.validation.values-checkbox-radio-required",
        defaultMessage:
          "Values are required for custom fields of type radio buttons and checkboxes.",
      }),
      path: ["EnumValues"],
    },
  );

export type SingleCustomFieldFormValues = Partial<z.infer<typeof singleCustomFieldFormSchema>>;

export const SingleCustomFieldSchemaKeyNames = singleCustomFieldFormSchema
  .innerType()
  .keyof()?.Values;

export const singleCustomFieldFormSchemaErrors = z.record(
  singleCustomFieldFormSchema.innerType().keyof(),
  z.string().optional(),
);

export type TSingleCustomFieldFormSchemaErrors = z.infer<typeof singleCustomFieldFormSchemaErrors>;

export enum TCustomFieldAllowedArrayDataTypes {
  line = 0,
  number = 2,
  decimal = 3,
}
