import { useMemo } from "react";

import { listRoleSetsQuery, useGetRoleSetByIDQuery } from "~/models/roles";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const RoleSetsSearchListField = SearchListFieldGenerator({
  getItemFromResponse: (resp) => resp?.Result?.Page,
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.role-set",
    defaultMessage: "Role set",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: listRoleSetsQuery,
      initialRequest: {
        PageConfig: {
          Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          Filter: { Name: undefined },
        },
      },
      getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
      setQueryRequest: (req, value) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: { ...req?.PageConfig?.Filter, Name: value },
        },
      }),
      configureLoadMoreButton: (resp) => ({
        shouldShowLoadMoreButton:
          (resp?.Result?.Total ?? 0) > (resp?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
        onLoadMore: (req) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit:
              (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
              DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
    }),
  useItemByID: (id, itemsFromList) => {
    const resultFromList = useMemo(() => {
      if (!id) {
        return undefined;
      }
      return itemsFromList?.find((item) => item.ID === id);
    }, [id, itemsFromList]);
    const { data: resp, isFetching } = useGetRoleSetByIDQuery(
      id && !!resultFromList ? { ID: id } : undefined,
      {},
    );
    const result = useMemo(() => {
      if (!id) {
        return { data: undefined, isLoading: false };
      }
      if (resultFromList) {
        return { data: resultFromList, isLoading: false };
      }
      return { data: resp, isLoading: isFetching };
    }, [id, isFetching, resp, resultFromList]);
    return result;
  },
});
