import { Dispatch, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigation } from "react-router";

import { Button } from "@new-black/lyra";

import { LoginAction, LoginState } from "./login.state";
import { LoginActionData } from "./login.types";

import ErrorBoundary from "~/components/suite-ui/error-boundary";
import { ConfirmTwoFactorAuthenticationTokenFormFields } from "~/features/login/confirm-two-factor-authentication-token-form-fields";

type ConfirmTwoFactorViewProps = {
  chosenTwoFactorMethodName: string;
  loginState: LoginState;
  dispatchLoginState: Dispatch<LoginAction>;
  twoFactorAuthenticators?: EVA.Core.TwoFactorAuthenticatorData[] | undefined;
  actionData?: LoginActionData;
  onSendToken: () => Promise<void>;
};

export function ConfirmTwoFactorView({
  actionData,
  chosenTwoFactorMethodName,
  dispatchLoginState,
  loginState,
  onSendToken,
  twoFactorAuthenticators,
}: ConfirmTwoFactorViewProps) {
  const hasMultipleTwoFactorAuthenticators = useMemo(
    () => twoFactorAuthenticators && twoFactorAuthenticators.length > 1,
    [twoFactorAuthenticators],
  );

  const { state } = useNavigation();

  const [tokenSent, setTokenSent] = useState(false);

  useEffect(() => {
    if (!tokenSent) {
      onSendToken().then(() => {
        setTokenSent(true);
      });
    }
  }, [onSendToken, tokenSent]);

  return (
    <ErrorBoundary>
      <ConfirmTwoFactorAuthenticationTokenFormFields
        loginData={loginState?.loginData}
        authenticatorProviderName={chosenTwoFactorMethodName}
        errors={{ formErrors: actionData?.formErrors, serviceError: actionData?.serviceError }}
      />

      <div className="mt-4 flex flex-col gap-2">
        <Button
          variant="primary"
          type="submit"
          name="intent"
          fullWidth
          value="2faConfirmation"
          isLoading={state === "submitting"}
          isDisabled={state !== "idle"}
        >
          <FormattedMessage id="generic.label.confirm" defaultMessage="Confirm" />
        </Button>
        <Button
          variant="secondary"
          fullWidth
          onPress={() =>
            dispatchLoginState({
              type: "setView",
              view: hasMultipleTwoFactorAuthenticators ? "2faChooseMethod" : "main",
            })
          }
        >
          <FormattedMessage id="generic.label.back" defaultMessage="Back" />
        </Button>
      </div>
    </ErrorBoundary>
  );
}
