import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { ITableInlineControlProps } from "./types";

import NumberInput from "~/components/suite-ui/number-input";

interface ITableInlineNumberInputProps extends ITableInlineControlProps {
  float?: boolean;
  label?: string;
  helperText?: string;
  required?: boolean;
  placeholder?: string;
  percentage?: boolean;
  autoFocus?: boolean;
  precision?: number;
}

/** This component is used to render a number input for a table cell.
 * We need a separate component because inline we cannot use React Hooks.
 * ---
 * @param cell The cell to render the input for. (provided by the TableColumn editComponent prop)
 * @param onFieldUpdate A callback to update the cell value. (provided by the TableColumn editComponent prop)
 * @param label The label to display in the input.
 * @param helperText The helper text to use for the input (only displayed when the input is invalid).
 * Use it with the `required` prop to display a required error.
 * @param required Whether the input is required (and whether the value gets validated).
 * @param float Whether the input should be displayed as a float.
 */
const TableInlineNumberInput = ({
  autoFocus = false,
  cell,
  float = false,
  helperText,
  label,
  onFieldUpdate,
  percentage,
  placeholder,
  precision,
  required = false,
}: ITableInlineNumberInputProps) => {
  const [value, setValue] = useState<number | undefined>(cell.value);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  const handleChange = useCallback(
    (newValue: number) => {
      setValue(newValue);
      onFieldUpdate(newValue);
    },
    [onFieldUpdate],
  );

  return (
    <NumberInput
      small
      label={label}
      required={required}
      value={value ?? ""}
      onBlur={required ? () => setShouldValidate(true) : undefined}
      error={(!value || `${value}` === "") && shouldValidate && required}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        handleChange(float ? parseFloat(event.target.value) : parseInt(event.target.value, 10))
      }
      helperText={
        (!value || `${value}` === "") && shouldValidate && required ? helperText : undefined
      }
      placeholder={placeholder}
      percentage={percentage}
      autoFocus={autoFocus}
      precision={precision}
    />
  );
};

export default TableInlineNumberInput;
