export const SortDirection = {
  Ascending: 0 as EVA.Core.SortDirection.Ascending,
  Descending: 1 as EVA.Core.SortDirection.Descending,
};

export enum DeviceType {
  Pin = "Pin",
  ThermalPrinter = "ThermalPrinter",
  Printer = "Printer",
  CashDrawer = "CashDrawer",
  ZebraPrinter = "ZebraPrinter",
  CustomerDisplay = "CustomerDisplay",
  Safe = "Safe",
  Sidekick = "Sidekick",
  Sentinel = "Sentinel",
  FiscalThermalPrinter = "FiscalThermalPrinter",
  TalismanPrinter = "TalismanPrinter",
  Watchtower = "Watchtower",
  Void = "Void",
  Scanner = "Scanner",
}

export enum UserTaskTypes {
  ShipFromStore = "ShipFromStore",
  ReceiveShipment = "ReceiveShipment",
}

export enum OrderAddressFieldEnum {
  ShippingAddress = "ShippingAddress",
  BillingAddress = "BillingAddress",
  PickupAddress = "PickupAddress",
}

export enum UserIdentifier {
  Default = "Default",
  Custom = "Custom",
  RequestFromFrontend = "Request from frontend",
}

//TODO: ask BE to provide services for handling this dynamic enum
export enum TransputJobType {
  OrderExport = 1,
  ShipmentExport = 2,
  ReturnOrderExport = 3,
  FinancialPeriodExport = 4,
  InvoiceExport = 5,
  OrderFulfillment = 6,
}
