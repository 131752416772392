import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useFetcher, useNavigation, useRevalidator } from "react-router";

import { CardContent, CardHeader, Dialog, DialogCard, Text } from "@new-black/lyra";
import { z } from "zod";
import { zfd } from "zod-form-data";

import { FormSubmitDialogFooter } from "../../components/shared/form-submit-dialog-footer";

import {
  LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS,
  LanguageLyraSearchListField,
} from "~/components/suite-composite/languages-autocomplete";
import { languageTimeZoneFormKeys } from "~/features/profile/language-time-zone-form/language-time-zone-form.types";
import { mutateUpdateUser } from "~/features/profile/mutate-update-user";
import useLocalStorage from "~/hooks/suite-react-hooks/use-local-storage";
import useCurrentUser from "~/hooks/use-current-user";
import { useRootRouteData } from "~/routes/root";
import { isoLanguages } from "~/types/iso-languages";
import { localStorageKeys } from "~/types/local-storage";
import { DEFAULT_LOCALE, SUPPORTED_EXTRA_LANGUAGES } from "~/util/base-values";
import { getLocale } from "~/util/get-locale";
import { queryClient } from "~/util/query-client";

const formID = "edit-language-timezone-form";
const formKeys = languageTimeZoneFormKeys;
const allSupportedLocales = [DEFAULT_LOCALE.split("-")[0], ...SUPPORTED_EXTRA_LANGUAGES];

const formSchema = z.object({
  Language: z.string(),
  ID: zfd.numeric(),
});

export const LanguageDialog = (): ReactNode | null => {
  const intl = useIntl();
  const fetcher = useFetcher();
  const revalidator = useRevalidator();
  const navigation = useNavigation();
  const isUserLocaleSupported = getIsUserLocaleSupported();
  const [hasPreferredLanguage, setHasPreferredLanguage] = useLocalStorage(
    localStorageKeys.PreferredLanguageIsSet,
    false,
    z.boolean().optional(),
  );
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const currentUser = useCurrentUser();
  const isValidUser =
    currentUser && currentUser?.Nickname !== "anonymous" && currentUser?.LastName !== "anonymous";

  // Comparing the user's browser language with the saved language in the backend, when they are not the same there is a mismatch.
  const hasLanguageMismatch =
    currentUser?.LanguageID == "zh" // Chinese
      ? getLocale(currentUser?.LanguageID, currentUser?.CountryID)
      : currentUser?.LanguageID !== isUserLocaleSupported;

  useEffect(() => {
    if (
      !isValidUser ||
      !isUserLocaleSupported ||
      !hasLanguageMismatch ||
      hasPreferredLanguage ||
      navigation.state !== "idle"
    ) {
      return;
    }

    setLanguageDialogOpen(true);
  }, [
    hasLanguageMismatch,
    isValidUser,
    hasPreferredLanguage,
    isUserLocaleSupported,
    currentUser,
    navigation.state,
  ]);

  const onClose = useCallback(() => {
    setHasPreferredLanguage(true);
    setLanguageDialogOpen(false);
  }, [setHasPreferredLanguage]);

  const { applicationConfiguration } = useRootRouteData();
  const overrulingUserCulture = useMemo(
    (): string | undefined =>
      applicationConfiguration?.value?.Configuration?.["App:OverrulingUserLanguage"],
    [applicationConfiguration?.value?.Configuration],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget as HTMLFormElement);
      const formValues = Object.fromEntries(formData.entries());
      const parsedFormValues = formSchema.parse(formValues);

      const mutateUpdateUserService = mutateUpdateUser(queryClient, parsedFormValues.ID);

      const personalInfoUpdated = await mutateUpdateUserService({
        ID: parsedFormValues.ID,
        LanguageID: parsedFormValues.Language,
      });

      if (personalInfoUpdated) {
        onClose();
        revalidator.revalidate();
      }
    },
    [onClose, revalidator],
  );

  return (
    <Dialog maxWidth="sm" isOpen={languageDialogOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogCard>
        <fetcher.Form method="POST" onSubmit={handleSubmit} id={formID}>
          <CardHeader
            title={intl.formatMessage({
              id: "language-dialog.title",
              defaultMessage: "Update language",
            })}
          />
          <CardContent className="flex flex-col gap-4">
            <Text className="mb-2">
              {intl.formatMessage({
                id: "language-dialog.copy",
                defaultMessage:
                  "We&apos;ve detected you might be speaking a different language. Do you want to change?",
              })}
            </Text>

            <input type="hidden" name={formKeys.ID} value={currentUser?.ID} />
            <LanguageLyraSearchListField.SingleSearchListField.Uncontrolled
              name={formKeys.Language}
              hideHintLabel
              disableClearLogic
              defaultValue={
                currentUser?.LanguageID
                  ? {
                      Name:
                        intl.formatDisplayName(
                          currentUser?.LanguageID,
                          LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS,
                        ) ??
                        isoLanguages[currentUser?.LanguageID as keyof typeof isoLanguages]?.name ??
                        currentUser?.LanguageID,
                      ID: currentUser?.LanguageID,
                    }
                  : undefined
              }
              errorMessage={
                overrulingUserCulture &&
                intl.formatMessage({
                  id: "profile.timezone-language.dialog.language.helper-text.overruled",
                  defaultMessage: "Overruled by the 'App:OverrulingUserLanguage' setting",
                })
              }
            />
          </CardContent>
          <FormSubmitDialogFooter formID={formID} onCancel={onClose} />
        </fetcher.Form>
      </DialogCard>
    </Dialog>
  );
};

const getIsUserLocaleSupported = (): string | undefined => {
  const browserLocale = new Intl.Locale(navigator.language);
  // Check for Chinese variants (Chinese, Chinese Hong Kong)
  const locale = browserLocale.language === "zh" ? browserLocale.baseName : browserLocale.language;
  return allSupportedLocales.find((lang) => lang === locale);
};
