import { useMemo } from "react";

import { useListCustomOrderStatusQuery } from "~/models/orders";

export const useCustomOrderStatuses = () => {
  const { data: customOrderStatuses } = useListCustomOrderStatusQuery({}, {});

  const needsEmployeeVerification = useMemo(
    () =>
      customOrderStatuses?.Result?.find((status) => status.Name === "NeedsEmployeeVerification")?.ID,
    [customOrderStatuses?.Result],
  );

  const verifiedByEmployee = useMemo(
    () => customOrderStatuses?.Result?.find((status) => status.Name === "VerifiedByEmployee")?.ID,
    [customOrderStatuses?.Result],
  );

  return { customOrderStatuses, needsEmployeeVerification, verifiedByEmployee };
};
