import { CoreManagement } from "@springtree/eva-services-core-management";

import { listProductRequirementsQuery } from "~/models/product-requirements";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface IProductRequirementSearchListFieldItem {
  Name: string;
  ID: number;
}

export const generateProductRequirementSearchListField = (
  initialFilters?: Partial<EVA.Core.ListProductRequirementsFilter>,
  getItemOption?: (
    item: EVA.Core.Management.ProductRequirementDto,
  ) => IProductRequirementSearchListFieldItem,
) => {
  const {
    MultiSearchListField: ProductRequirementMultiSearchListField,
    SingleSearchListField: ProductRequirementSearchListField,
  } = SearchListFieldGenerator<
    CoreManagement.ListProductRequirements,
    IProductRequirementSearchListFieldItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map(
        (productRequirement) =>
          getItemOption?.(productRequirement) ?? {
            ID: productRequirement.ID,
            Name: productRequirement.Name,
          },
      ),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product-requirement",
      defaultMessage: "Product requirement",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listProductRequirementsQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton: resp?.Result
            ? (resp?.Result?.Limit ?? 0) < (resp?.Result?.Total ?? 0)
            : false,
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });

  return {
    ProductRequirementMultiSearchListField,
    ProductRequirementSearchListField,
  };
};
