import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Text as LyraText, TextProps as LyraTextProps } from "@new-black/lyra";

import Text, { TextProps as MaterialTextProps } from "~/components/suite-ui/text";

export type IYesNoColumnProps = {
  value: boolean;
  hideNoValue?: boolean;
  useTrueFalseLabels?: boolean;
} & (
  | { variant?: "material"; textProps?: Omit<MaterialTextProps, "children"> }
  | {
      variant?: "lyra";
      textProps?: Omit<LyraTextProps, "children">;
    }
);

export default function YesNoColumn({
  hideNoValue,
  textProps,
  useTrueFalseLabels,
  value,
  variant = "material",
}: IYesNoColumnProps) {
  const intl = useIntl();
  const yesNoLabels = useMemo(
    (): Record<"true" | "false", string> => ({
      true: intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" }),
      false: intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" }),
    }),
    [intl],
  );

  const trueFalseLabels = useMemo(
    (): Record<"true" | "false", string> => ({
      true: intl.formatMessage({ id: "generic.label.yes", defaultMessage: "Yes" }),
      false: intl.formatMessage({ id: "generic.label.no", defaultMessage: "No" }),
    }),
    [intl],
  );

  const labels = useMemo(
    () => (useTrueFalseLabels ? trueFalseLabels : yesNoLabels),
    [trueFalseLabels, useTrueFalseLabels, yesNoLabels],
  );

  if (!value && hideNoValue) {
    return null;
  }

  if (variant === "lyra") {
    return (
      <LyraText variant="body-medium" {...(textProps as LyraTextProps)}>
        {labels[value ? "true" : "false"]}
      </LyraText>
    );
  }

  return (
    <Text variant="body2" color="inherit" {...(textProps as MaterialTextProps)}>
      {labels[value ? "true" : "false"]}
    </Text>
  );
}
