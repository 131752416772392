import type { CSSProperties, ReactNode } from "react";

import { IconNames } from "@new-black/lyra";

import { Functionalities } from "~/types/functionalities";

export interface IChapter {
  title: string;
  path: string;
  functionality?: keyof typeof Functionalities;
  items?: Array<{ title: string; path: string; iconName?: IconNames }>;
  iconName: IconNames;
}

export interface IModule {
  chapters: IChapter[];
  code: string;
  icon: ReactNode;
  path: string;
  title: string;
  moduleColor: CSSProperties["color"];
}

export const enum EModuleCode {
  ORDERS = "ORDERS",
  PEOPLE = "CRM",
  FINANCIALS = "FINANCIALS",
  ORGANIZATIONS = "ORGANIZATIONS",
  PIM = "PIM",
  STOCKMANAGEMENT = "STOCKMANAGEMENT",
  TASKS = "TASKS",
  CONTROLROOM = "MONITORS",
  PROMOTIONS = "DISCOUNTS",
  COMPLIANCE = "COMPLIANCE",
}
