import { IntlShape } from "react-intl";

import DiscountConditions from "~/assets/config/config.discount-conditions-mapping";

export const getDiscountConditionTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<DiscountConditions, string> = {
    [DiscountConditions.COUPONVALIDITY]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.coupon-validity",
      defaultMessage: "Coupon validity",
    }),
    [DiscountConditions.CUSTOMER]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.customer",
      defaultMessage: "Customer",
    }),
    [DiscountConditions.CUSTOMERAGE]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.customer-age",
      defaultMessage: "Customer age",
    }),
    [DiscountConditions.ORDERAMOUNT]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.order-amount",
      defaultMessage: "Order amount",
    }),
    [DiscountConditions.PRODUCTS]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.products",
      defaultMessage: "Products",
    }),
    [DiscountConditions.RITUALSMEMBER]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.rituals-member",
      defaultMessage: "My Rituals member",
    }),
    [DiscountConditions.RITUALSWELCOMEGIFT]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.rituals-welcome-gift",
      defaultMessage: "Rituals welcome gift",
    }),
    [DiscountConditions.STOCKLABEL]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.stock-label",
      defaultMessage: "Stock label",
    }),
    [DiscountConditions.USERROLE]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.user-role",
      defaultMessage: "User role",
    }),
    [DiscountConditions.USERTYPE]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.user-type",
      defaultMessage: "User type",
    }),
    [DiscountConditions.USERCUSTOMFIELDS]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.user-custom-fields",
      defaultMessage: "User custom fields",
    }),
    [DiscountConditions.ORDERCUSTOMFIELDS]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.order-custom-fields",
      defaultMessage: "Order custom fields",
    }),
    [DiscountConditions.ORDERLINECUSTOMFIELDS]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.orderline-custom-fields",
      defaultMessage: "Orderline custom fields",
    }),
    [DiscountConditions.ORIGINALOU]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.original-ou",
      defaultMessage: "Original OU",
    }),
    [DiscountConditions.ORDERTYPE]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.order-type",
      defaultMessage: "Order type",
    }),
    [DiscountConditions.PRODUCTREQUIREMENT]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.product-requirement",
      defaultMessage: "Product requirement",
    }),
    [DiscountConditions.LOYALTYPOINTS]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.loyalty-points",
      defaultMessage: "Points",
    }),
    [DiscountConditions.ORDERPROPERTIES]: intl.formatMessage({
      id: "edit-discount.condition.tabslabels.order-properties",
      defaultMessage: "Order properties",
    }),
  };

  return (conditionType: DiscountConditions) => {
    return translationMap[conditionType];
  };
};
