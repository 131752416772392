import { CalendarDate, parseDate } from "@internationalized/date";

const isValidDate = (dateString: string): boolean => {
  try {
    // Attempt to parse the date
    const parsedDate: CalendarDate = parseDate(dateString);
    return !!parsedDate; // If parseDate succeeds, date is valid
  } catch {
    // If parseDate throws, date is invalid
    return false;
  }
};

export { isValidDate };
