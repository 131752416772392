import { LinearProgress } from "@material-ui/core";
import classNames from "clsx";

export interface ProgressBarProps {
  loading: boolean;
  position?: "top" | "bottom";
  top?: number;
  className?: string;
}

const ProgressBar = ({ className, loading, position = "top", top = 0 }: ProgressBarProps) => {
  if (loading) {
    return (
      <div
        className={classNames(
          "absolute left-0 right-0 w-full",
          position === "bottom" && "bottom-0",
          className,
        )}
        style={{
          top: position !== "bottom" ? top : undefined,
        }}
      >
        <LinearProgress />
      </div>
    );
  }

  return null;
};

export default ProgressBar;
