import { Core } from "@springtree/eva-services-core";

import { listOrderCustomTypesQuery } from "~/models/orders";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const {
  MultiSearchListField: CustomOrderTypesSingleSearchListField,
  SingleSearchListField: CustomOrderTypesMultiSearchListField,
} = SearchListFieldGenerator<
  Core.ListOrderCustomTypes,
  {
    ID: number;
    Name: string;
  },
  number
>({
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name ?? item.ID?.toString() ?? "",
  getItemFromResponse: (resp) =>
    resp?.Result?.Page?.map((result) => ({ ID: result.ID, Name: result.Name })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.custom-order-type",
    defaultMessage: "Custom order type",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      refetchOnFocus: false,
      query: listOrderCustomTypesQuery,
      initialRequest: { PageConfig: { Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT } },
      setQueryRequest: (req, newValue) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: { ...req?.PageConfig?.Filter, Name: newValue || undefined },
        },
      }),
    }),
});
