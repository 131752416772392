import { FormattedMessage } from "react-intl";

import { Checkbox, Separator, Text } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import type { IProductSearchModalResultBase } from "../types";

export const ProductSearchModalProductResultsHeader = <
  TProduct extends IProductSearchModalResultBase,
>() => (
  <>
    <div className="p-4">
      <ProductSearchModalProductResultsHeaderTitle<TProduct> />
    </div>
    <Separator />
  </>
);

function ProductSearchModalProductResultsHeaderTitle<
  TProduct extends IProductSearchModalResultBase,
>() {
  const {
    productsTotal,
    selectableProductResults,
    selectedProductResults,
    setSelectedProductResults,
    type,
  } = useProductSearchModalContext<TProduct>();

  if (type === "multi") {
    return (
      <Checkbox
        isSelected={
          selectedProductResults?.length === selectableProductResults?.length &&
          selectableProductResults?.length > 0
        }
        onChange={() => {
          setSelectedProductResults((curr) =>
            curr.length === selectableProductResults?.length ? [] : [...selectableProductResults],
          );
        }}
        isDisabled={selectableProductResults?.length === 0}
        isIndeterminate={
          selectedProductResults?.length > 0 &&
          selectedProductResults?.length < selectableProductResults?.length
        }
      >
        <Text color="primary">
          <FormattedMessage id="generic.label.select-all" defaultMessage="Select all" />
        </Text>
      </Checkbox>
    );
  }

  return (
    <Text color="primary">
      <FormattedMessage
        id="generic.label.total-results-found"
        defaultMessage="{total} results found"
        values={{ total: productsTotal }}
      />
    </Text>
  );
}
