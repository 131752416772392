import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import {
  productRequirementsForOrderServiceState,
  productRequirementsForOrderValuesSelector,
} from ".";

import { useCurrentOrder } from "~/features/orders/detail/use-current-order";
import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";
import useSetRequest from "~/hooks/suite-react-hooks/use-set-request";

export const useProductRequirementsForOrder = () => {
  const { currentOrderID } = useCurrentOrder();
  const request = useMemo((): EVA.Core.GetProductRequirementValuesForOrder | undefined => {
    if (currentOrderID) {
      return {
        OrderID: currentOrderID,
      };
    }
    return undefined;
  }, [currentOrderID]);

  useSetRequest(productRequirementsForOrderServiceState.request(currentOrderID), request);

  const productRequirementsForOrder = hooks.useGetState(
    productRequirementsForOrderValuesSelector(currentOrderID),
  );

  const productRequirementsForOrderLoading = hooks.useIsLoading({
    state: productRequirementsForOrderServiceState.response(currentOrderID),
  });

  return {
    productRequirementsForOrder,
    productRequirementsForOrderLoading,
  };
};

export const useResetProductRequirementsForOrder = () => {
  const { currentOrderID } = useCurrentOrder();
  useHandleSWRFamily(productRequirementsForOrderServiceState, currentOrderID);
};

export default useProductRequirementsForOrder;
