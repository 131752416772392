import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";

import { omit } from "lodash";

import { getTableColumnInputEditComponent } from "./table-column-helpers";
import { ColumnProps, DEFAULT_EXPANDABLE_TEXT_MAX_LENGTH } from "./table-columns.types";

import ExpandableText, { IExpandableTextProps } from "~/components/suite-ui/expandable-text";

export const idColumn = <T extends { ID?: number | string }>(
  columnProps?: Partial<Column<T>>,
): Column<T> => ({
  accessor: "ID",
  Header: <FormattedMessage id="generic.label.id" defaultMessage="ID" />,
  ...omit(columnProps, "columns"),
});

export const nameColumn = <T extends { Name?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "Name",
  Header: <FormattedMessage id="generic.label.name" defaultMessage="Name" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const backendIDColumn = <T extends { BackendID?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "BackendID",
  Header: <FormattedMessage id="generic.label.backend-id" defaultMessage="Backend ID" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const descriptionColumn = <T extends { Description?: string }>(
  columnProps?: Partial<Column<T>> & { maxLength?: number },
  options?: {
    expandableTextVariant?: IExpandableTextProps["variant"];
    expandableTextLinkButtonVariant?: IExpandableTextProps["linkButtonVariant"];
  },
): Column<T> => ({
  accessor: "Description",
  Header: <FormattedMessage id="generic.label.description" defaultMessage="Description" />,
  Cell: ({ value }: CellProps<T>) => (
    <ExpandableText
      text={value ?? ""}
      variant={options?.expandableTextVariant ?? "inherit"}
      linkButtonVariant={options?.expandableTextLinkButtonVariant}
      maxLength={columnProps?.maxLength ?? DEFAULT_EXPANDABLE_TEXT_MAX_LENGTH}
      spaceBeforeLessAction
      spaceBeforeMoreAction
    />
  ),
  ...omit(columnProps, "columns"),
});
