import { IntlShape } from "react-intl";

import { DiscountOrderTypes } from "../eva-core";

export const discountOrderTypeTranslator = (
  intl: IntlShape,
): ((triggerType: Partial<DiscountOrderTypes>) => string | undefined) => {
  const translationMap: Record<Partial<DiscountOrderTypes>, string> = {
    [DiscountOrderTypes.All]: intl.formatMessage({
      id: "generic.label.all",
      defaultMessage: "All",
    }),
    [DiscountOrderTypes.Purchase]: intl.formatMessage({
      id: "discounts.order-types.purchase",
      defaultMessage: "Purchase",
    }),
    [DiscountOrderTypes.Sales]: intl.formatMessage({
      id: "discounts.order-types.sales",
      defaultMessage: "Sales",
    }),
    [DiscountOrderTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
