import { CoreManagement } from "@springtree/eva-services-core-management";

import {
  listDiscountCampaignsQuery,
  useGetDiscountCampaignByIDQuery,
} from "~/models/discount-campaigns";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface IDiscountCampaignSearchListFieldItem {
  ID: number;
  Name: string;
}

export const generateDiscountCampaignSearchListField = () =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListDiscountCampaigns,
    IDiscountCampaignSearchListFieldItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((campaign) => ({
        ID: campaign.ID,
        Name: campaign.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.campaign",
      defaultMessage: "Campaign",
    }),
    useItemByID: (id) => {
      const { data, isFetching: isLoading } = useGetDiscountCampaignByIDQuery(
        id ? { ID: id } : undefined,
        { refetchOnWindowFocus: false },
      );
      return { data, isLoading };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listDiscountCampaignsQuery,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: {
          PageConfig: { Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { Name: undefined } },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
