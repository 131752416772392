import { IntlShape } from "react-intl";

import { LoyaltyProgramStatus } from "~/types/eva-core";

export const getLoyaltyProgramStatusTranslation = (intl: IntlShape) => {
  const translationMap: Record<LoyaltyProgramStatus, string> = {
    [LoyaltyProgramStatus.Inactive]: intl.formatMessage({
      id: "generic.label.disabled",
      defaultMessage: "Disabled",
    }),
    [LoyaltyProgramStatus.Active]: intl.formatMessage({
      id: "generic.label.enabled",
      defaultMessage: "Enabled",
    }),
  };

  return (status: LoyaltyProgramStatus) => {
    return translationMap[status];
  };
};
