import { IOrganizationUnitStructure } from "./organization-unit-selector.types";

export const getFlattenedOrganizationUnits = (
  organizationUnits: EVA.Core.OrganizationUnitHierarchy[],
  baseParentId?: number,
) => {
  const result: Set<IOrganizationUnitStructure> = new Set();

  const flatten = (ou: EVA.Core.OrganizationUnitHierarchy, parentId?: number) => {
    result.add({
      ID: ou.ID,
      Name: ou.Name,
      ParentID: parentId ?? baseParentId,
      BackendID: ou?.BackendID,
    });

    for (const child of ou.Children ?? []) {
      flatten(child, ou.ID);
    }
  };

  for (const ou of organizationUnits) {
    flatten(ou);
  }

  return Array.from(result);
};
