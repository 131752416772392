import { useMemo } from "react";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { countriesState, languagesState } from "../state";

/**
 * Hook that handles fetching all possible ISO codes of countries and languages.
 */
const useGetISOCodeLists = () => {
  const intl = useIntl();
  const countriesResponse = hooks.useGetState(countriesState.response);
  const countriesResponseLoading = hooks.useIsLoading({
    state: countriesState.response,
  });
  const countryISOCodeList = useMemo(
    () =>
      countriesResponse?.Result?.map((country) => ({
        label: `${intl.formatDisplayName(country.ID, { type: "region" })} (${country.ID})`,
        value: country.ID,
      })),
    [countriesResponse?.Result, intl],
  );

  const languagesResponse = hooks.useGetState(languagesState.response);
  const languageResponseLoading = hooks.useIsLoading({
    state: languagesState.response,
  });
  const languageISOCodeList = useMemo(
    () =>
      languagesResponse?.Languages.map((language) => ({
        label: `${intl.formatDisplayName(language, {
          type: "language",
        })} (${language})`,
        value: language,
      })),
    [intl, languagesResponse?.Languages],
  );

  return {
    countryISOCodeList,
    countriesResponseLoading,
    languageISOCodeList,
    languageResponseLoading,
  };
};

export default useGetISOCodeLists;
