import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getDiscountCampaignByIDLoaderQuery,
  serviceQuery: getDiscountCampaignByIDQuery,
  serviceQueryKeys: GetDiscountCampaignByIDQueryKeys,
  useServiceQueryHook: useGetDiscountCampaignByIDQuery,
} = createServiceQuery(CoreManagement.GetDiscountCampaignByID, true);

export const {
  serviceLoaderQuery: listDiscountCampaignsLoaderQuery,
  serviceQuery: listDiscountCampaignsQuery,
  useServiceQueryHook: useListDiscountCampaignsQuery,
} = createServiceQuery(CoreManagement.ListDiscountCampaigns);
