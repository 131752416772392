import { FormattedMessage } from "react-intl";
import { useNavigation } from "react-router";

import { Button, ButtonGroup, Separator } from "@new-black/lyra";

import { IConfirmationModalProps, IConfirmationModalWithSubmitProps } from ".";

import Text, { TextProps } from "~/components/suite-ui/text";

export const ConfirmationModalContent = ({
  cancelButtonVariant,
  confirmButtonVariant,
  form,
  isLoading,
  messages,
  name,
  onCancel,
  onConfirm,
  textDescriptionVariant = "subtitle1",
  translations,
  type = "callback",
  value,
}: Omit<
  IConfirmationModalProps | IConfirmationModalWithSubmitProps,
  "isOpen" | "maxWidth" | "fullWidth" | "variant"
> & { textDescriptionVariant?: TextProps["variant"] }) => {
  const { state } = useNavigation();

  return (
    <>
      <div className="p-4">
        <div className="mb-4">
          <Text variant="h2">
            {messages?.titleMessage ?? (
              <FormattedMessage
                id={translations?.titleKey ?? "generic.question.are-you-sure"}
                defaultMessage="Are you sure?"
              />
            )}
          </Text>
        </div>
        {translations?.descriptionKey || messages?.descriptionMessage ? (
          <Text variant={textDescriptionVariant}>
            {messages?.descriptionMessage ?? (
              <FormattedMessage
                id={translations?.descriptionKey ?? "generic.confirmation-modal.description"}
                defaultMessage="Are you sure you want to proceed?"
              />
            )}
          </Text>
        ) : null}
      </div>
      <Separator />
      <ButtonGroup className="justify-end p-4">
        <Button
          onPress={onCancel}
          isDisabled={isLoading}
          variant={cancelButtonVariant ?? "secondary"}
        >
          {messages?.cancelButtonMessage ?? (
            <FormattedMessage
              id={translations?.cancelButtonKey ?? "generic.label.cancel"}
              defaultMessage="Cancel"
            />
          )}
        </Button>
        <Button
          onPress={onConfirm}
          type={type === "submit" ? "submit" : "button"}
          name={name}
          value={value}
          form={form}
          isLoading={type === "submit" ? state !== "idle" : isLoading}
          variant={confirmButtonVariant ?? "primary"}
        >
          {messages?.confirmButtonMessage ?? (
            <FormattedMessage
              id={translations?.confirmButtonKey ?? "generic.action.confirm"}
              defaultMessage="Confirm"
            />
          )}
        </Button>
      </ButtonGroup>
    </>
  );
};
