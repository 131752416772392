import { CoreManagement } from "@springtree/eva-services-core-management";
import { useQuery } from "@tanstack/react-query";

import {
  getPersonalizedPromotionByIDQuery,
  listPersonalizedPromotionsQuery,
} from "~/models/personalized-promotions";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface IPersonalizedPromotionSearchListFieldItem {
  ID: number;
  Name: string;
}

export const generatePersonalizedPromotionSearchListField = () => {
  const { SingleIDSearchListField: PersonalizedPromotionSingleIDSearchListField } =
    SearchListFieldGenerator<
      CoreManagement.ListPersonalizedPromotions,
      IPersonalizedPromotionSearchListFieldItem,
      number
    >({
      getItemId: (item) => item.ID,
      getLabel: (item) => item.Name,
      getItemFromResponse: (response) => response?.Results,
      defaultLabel: intlAccessor.formatMessage({
        id: "generic.label.personalized-promotion",
        defaultMessage: "Personalized promotion",
      }),
      useItemByID: (ID) => {
        const { data, isFetching: isLoading } = useQuery({
          ...getPersonalizedPromotionByIDQuery(ID && typeof ID === "number" ? { ID } : undefined),
          refetchOnWindowFocus: false,
          enabled: !!ID,
        });

        return { data, isLoading };
      },
      useServiceQuery: () =>
        SearchListFieldGenerator.useSearchListFieldService({
          refetchOnFocus: false,
          query: listPersonalizedPromotionsQuery,
          initialRequest: { InitialPageConfig: { Limit: 10 } },
          getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
          setQueryRequest: (req, newValue) => ({
            ...req,
            InitialPageConfig: {
              ...req?.InitialPageConfig,
              Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
              Filter: {
                ...req?.InitialPageConfig?.Filter,
                Name: newValue === "" ? undefined : newValue,
              },
            },
          }),
          configureLoadMoreButton: (response) => ({
            shouldShowLoadMoreButton: !!response?.NextPageToken,
            onLoadMore: (request) => ({
              ...request,
              InitialPageConfig: {
                ...request?.InitialPageConfig,
                Limit:
                  (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                  DEFAULT_SEARCH_LIST_FIELD_LIMIT,
              },
            }),
          }),
        }),
    });

  return {
    PersonalizedPromotionSingleIDSearchListField,
  };
};
