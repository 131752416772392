import { TextField, TextFieldProps } from "@new-black/lyra";
import { useField } from "formik";

import Input, { InputProps } from "~/components/suite-ui/input";

export interface FormikInputProps extends Omit<InputProps, "value" | "onChange"> {
  name: string;
}

interface IFormikInputExportProps {
  ({ error, helperText, name, ...props }: FormikInputProps): JSX.Element;
  /** @deprecated Use the Lyra TextField wrapped in a Formik Field component directly instead */
  Lyra: ({ errorMessage, name, ...props }: LyraFormikInputProps) => JSX.Element;
}

export const FormikInput: IFormikInputExportProps = ({
  error,
  helperText,
  name,
  ...props
}: FormikInputProps) => {
  const [field, meta] = useField<string | undefined>(name);
  return (
    <Input
      {...props}
      {...field}
      value={field?.value ?? ""}
      error={error || (!!meta.error && meta.touched)}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      inputProps={{
        ...props.inputProps,
        onBlur: (event) => {
          props.inputProps?.onBlur?.(event);
          props.onBlur?.(event);
        },
      }}
    />
  );
};

export interface LyraFormikInputProps
  extends Omit<TextFieldProps, "value" | "onChange" | "defaultValue" | "name"> {
  name: string;
}

/** @deprecated Use the Lyra TextField wrapped in a Formik Field component directly instead */
export function LyraFormikInput({ errorMessage, name, ...props }: LyraFormikInputProps) {
  const [field, meta, { setValue }] = useField<string | undefined>(name);

  return (
    <TextField
      {...props}
      {...field}
      value={field?.value ?? ""}
      onChange={(newValue) => setValue(newValue)}
      errorMessage={meta.error && meta.touched ? meta.error : errorMessage}
      onBlur={(event) => {
        props.onBlur?.(event);
        field.onBlur(event);
      }}
    />
  );
}

FormikInput.Lyra = LyraFormikInput;
