import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getDiscountTemplateByIDLoaderQuery,
  serviceQuery: getDiscountTemplateByIDQuery,
  serviceQueryKeys: GetDiscountTemplateByIDQueryKeys,
  useServiceQueryHook: useGetDiscountTemplateByIDQuery,
} = createServiceQuery(CoreManagement.GetDiscountTemplateByID, true);
