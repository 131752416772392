import { forwardRef, useMemo } from "react";
import { Link as RouterLink, LinkProps, useSearchParams } from "react-router";

import { usePrompt } from "./provider";

export interface Props extends LinkProps {
  keepSearchParams?: boolean;
  force?: boolean;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      force,
      keepSearchParams,
      onClick,
      prefetch = "intent",
      replace = false,
      state,
      target,
      to,
      ...props
    },
    ref,
  ) => {
    const { setConfirmRoute, when } = usePrompt();
    const [searchParams] = useSearchParams();

    const newRoute = useMemo(() => {
      let route = to;
      if (keepSearchParams) {
        route = route + "?" + searchParams.toString();
      }
      return route;
    }, [keepSearchParams, searchParams, to]);

    return (
      <RouterLink
        ref={ref}
        to={newRoute}
        prefetch={prefetch}
        replace={replace}
        state={state}
        target={target}
        onClick={(e) => {
          if (when && !force) {
            e?.preventDefault();
            setConfirmRoute(newRoute);
          } else {
            onClick?.(e);
          }
        }}
        {...props}
      >
        {children}
      </RouterLink>
    );
  },
);

Link.displayName = "Link";
