import { useMemo } from "react";
import { useIntl } from "react-intl";

import { adminSuiteConfig } from "../../../../admin-suite.config";
import { IChapter } from "../chapter-finder.types";

import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const useFinancialsChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: currenciesChapterAllowed } = useHasFunctionality(
    Functionalities.CURRENCIES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: exportLogsChapterAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_PERIOD_EXPORTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: generalLedgerAccountsChapterAllowed } = useHasFunctionality(
    Functionalities.ACCOUNTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: paymentMethodsChapterAllowed } = useHasFunctionality(
    Functionalities.PAYMENT_METHODS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: paymentTypesChapterAllowed } = useHasFunctionality(
    Functionalities.PAYMENT_TYPES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: priceListsChapterAllowed } = useHasFunctionality(
    Functionalities.PRICING,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: invoicesChapterAllowed } = useHasFunctionality(
    Functionalities.INVOICES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: taxRatesChapterAllowed } = useHasFunctionality(
    Functionalities.TAX_RATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: paymentSettingsChapterAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SET_PAYMENT_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: incomeExpensesChapterAllowed } = useHasFunctionality(
    Functionalities.CASH_EXPENSE_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: giftcardsChaterAllowed } = useHasFunctionality(
    Functionalities.GIFT_CARD_CONFIGURATIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: financialPeriodsAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_PERIODS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: paymentSettlementsAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.PAYMENT_SETTLEMENTS,
  );

  const { functionalityAllowed: cookbookAllowed } = useHasFunctionality(
    Functionalities.COOKBOOK,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: cookbookRecipesV2Allowed } = useHasFunctionality(
    Functionalities.SCRIPTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: financialEventsAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_EVENTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: generalLedgersAllowed } = useHasFunctionality(
    Functionalities.GENERAL_LEDGERS,
    FunctionalityScope.View,
  );

  const financialsChapters = useMemo((): IChapter[] => {
    let chapters: IChapter[] = [];

    if (invoicesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.invoices",
            defaultMessage: "Invoices",
          }),
          path: "/invoices",
          iconName: "invoices",
        },
      ];
    }

    if (
      (paymentMethodsChapterAllowed && paymentTypesChapterAllowed) ||
      paymentSettingsChapterAllowed
    ) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.payment-configuration",
            defaultMessage: "Payment Configuration",
          }),
          path: "/payment",
          iconName: "payment-methods",
          items: [
            {
              title: intl.formatMessage({
                id: "generic.label.payment-methods",
                defaultMessage: "Payment methods",
              }),
              path: "/payment/payment-methods",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.payment-types",
                defaultMessage: "Payment types",
              }),
              path: "/payment/payment-types",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.settings",
                defaultMessage: "Settings",
              }),
              path: "/payment/payment-settings",
            },
          ],
        },
      ];
    }

    if (priceListsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.price-lists",
            defaultMessage: "Price Lists",
          }),
          path: "/price-lists",
          iconName: "price-lists",
        },
      ];
    }

    if (currenciesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.currencies",
            defaultMessage: "Currencies",
          }),
          path: "/currencies",
          iconName: "currencies",
        },
      ];
    }

    if (exportLogsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.export-logs",
            defaultMessage: "Export Logs",
          }),
          path: "/export-logs",
          iconName: "export-logs",
          items: [
            {
              title: intl.formatMessage({
                id: "generic.label.financial-period-exports",
                defaultMessage: "Financial period exports",
              }),
              path: "/export-logs/financial-period-exports",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.invoice-exports",
                defaultMessage: "Invoice exports",
              }),
              path: "/export-logs/invoice-exports",
            },
          ],
        },
      ];
    }

    if (taxRatesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.tax-management",
            defaultMessage: "Tax Management",
          }),
          path: "/tax-management",
          iconName: "tax-management",
          items: [
            {
              title: intl.formatMessage({
                id: "generic.label.rates",
                defaultMessage: "Rates",
              }),
              path: "/tax-management/tax-rates",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.providers",
                defaultMessage: "Providers",
              }),
              path: "/tax-management/tax-providers",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.codes",
                defaultMessage: "Codes",
              }),
              path: "/tax-management/tax-codes",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.code-mapping",
                defaultMessage: "Code mapping",
              }),
              path: "/tax-management/tax-code-mapping",
            },
          ],
        },
      ];
    }

    if (generalLedgerAccountsChapterAllowed || generalLedgersAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.general-ledgers-and-accounts",
            defaultMessage: "General Ledgers and Accounts",
          }),
          path: "/general-ledgers-and-accounts",
          iconName: "general-ledgers",
          items: [
            {
              title: intl.formatMessage({
                id: "generic.label.general-ledgers",
                defaultMessage: "General Ledgers",
              }),
              path: "/general-ledgers-and-accounts/general-ledgers",
            },
            {
              title: intl.formatMessage({
                id: "generic.label.accounts",
                defaultMessage: "Accounts",
              }),
              path: "/general-ledgers-and-accounts/accounts",
            },
          ],
        },
      ];
    }

    if (financialPeriodsAllowed) {
      // Financial periods
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.financial-periods",
            defaultMessage: "Financial Periods",
          }),
          path: "/financial-periods",
          iconName: "financial-periods",
        },
      ];
    }

    if (incomeExpensesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.income-and-expense",
            defaultMessage: "Income and Expense",
          }),
          path: "/income-expense",
          iconName: "income-and-expense",
        },
      ];
    }

    if (giftcardsChaterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.giftcard-configuration",
            defaultMessage: "Giftcard Configuration",
          }),
          path: "/giftcards",
          iconName: "giftcards",
        },
      ];
    }

    if (paymentSettlementsAllowed) {
      // Settlements (Admin 1.0 redirect)
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.settlements",
            defaultMessage: "Settlements",
          }),
          path: "/settlements",
          iconName: "settlements",
        },
      ];
    }

    if (cookbookAllowed && adminSuiteConfig.cookbookV1) {
      chapters = [
        ...chapters,
        {
          title: `${intl.formatMessage({
            id: "generic.title.cookbook-recipes",
            defaultMessage: "Cookbook Recipes",
          })} (1.0)`,
          path: "/cookbook-recipes-v1",
          iconName: "cookbook",
        },
      ];
    }

    if (cookbookRecipesV2Allowed && adminSuiteConfig.cookbookV2) {
      chapters = [
        ...chapters,
        {
          title: `${intl.formatMessage({
            id: "generic.title.cookbook-recipes",
            defaultMessage: "Cookbook Recipes",
          })} (2.0)`,
          path: "/cookbook-recipes",
          iconName: "cookbook",
        },
      ];
    }

    if (financialEventsAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.cookbook-events",
            defaultMessage: "Cookbook Events",
          }),
          path: "/cookbook-events",
          iconName: "cookbook-events",
        },
      ];
    }

    return chapters;
  }, [
    invoicesChapterAllowed,
    paymentMethodsChapterAllowed,
    paymentTypesChapterAllowed,
    paymentSettingsChapterAllowed,
    priceListsChapterAllowed,
    currenciesChapterAllowed,
    exportLogsChapterAllowed,
    taxRatesChapterAllowed,
    generalLedgerAccountsChapterAllowed,
    generalLedgersAllowed,
    financialPeriodsAllowed,
    incomeExpensesChapterAllowed,
    giftcardsChaterAllowed,
    paymentSettlementsAllowed,
    cookbookAllowed,
    cookbookRecipesV2Allowed,
    financialEventsAllowed,
    intl,
  ]);

  return financialsChapters;
};
