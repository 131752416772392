import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const { useServiceQueryHook: useListDiscountUserUsageReloadStrategies } = createServiceQuery(
  CoreManagement.ListDiscountUserUsageReloadStrategies,
);

export const {
  serviceLoaderQuery: listDiscountTemplatesLoaderQuery,
  serviceQuery: listDiscountTemplatesQuery,
  useServiceQueryHook: useListDiscountTemplatesQuery,
} = createServiceQuery(CoreManagement.ListDiscountTemplates);

export const {
  serviceLoaderQuery: getDiscountTemplateByIDLoaderQuery,
  serviceQuery: getDiscountTemplateByIDQuery,
  useServiceQueryHook: useGetDiscountTemplateByIDQuery,
} = createServiceQuery(CoreManagement.GetDiscountTemplateByID);
