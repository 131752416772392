import { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import { UseServiceErrorResult } from "./suite-react-hooks/use-service-error";

import { useNavigate } from "~/components/routing";

const useHandleStartTaskError = <T extends IEvaServiceDefinition>(
  error?: UseServiceErrorResult<T>,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Navigate back to overview
  useEffect(() => {
    if (error) {
      const errorMessage = (error as any)?.Error?.Message;
      if (errorMessage) {
        toast.error(errorMessage);
      }
      if (location.pathname) {
        navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")), { replace: true });
      }
    }
  }, [error, location.pathname, navigate]);
};

export default useHandleStartTaskError;
