import { useNavigation } from "react-router";

import { cva } from "class-variance-authority";

import ProgressBar from "../progress-bar";

import { PaneHeaderProps } from "./pane.types";

const paneHeaderStyles = cva(
  ["sticky top-0 left-0 z-30", "flex items-center justify-between", "p-4 min-h-[70px] w-full"],
  {
    variants: {
      isScrolled: {
        true: [
          "border-0 border-b border-solid border-default bg-surface-tertiary/90 backdrop-blur-[50px]",
        ],
      },
    },
  },
);

export const PaneHeader = ({ children, end, isLoading, isScrolled }: PaneHeaderProps) => {
  const { state } = useNavigation();
  if (!children) return null;

  return (
    <header className={paneHeaderStyles({ isScrolled })}>
      <ProgressBar loading={isLoading ?? state === "loading"} position="bottom" />
      {children}
      {end}
    </header>
  );
};
