import { useMemo } from "react";

import { Select } from "@new-black/lyra";
import { isNumber } from "lodash";

import { IControlledEnumSelectProps, IControlledLyraEnumSelectProps } from "./enum-select.types";

import SuiteUISelect from "~/components/suite-ui/select";

/**
 * A select component that uses an enum as its options.
 * @param value The value of the select.
 * @param onChange The callback to call when the value changes.
 * @param enum The enum to use as the options.
 * @param name The name of the select. (optional)
 * @param getOptionLabel A function to get the label for an option. (optional)
 * @param getOptionDisabled A function to get whether an option is disabled. (optional)
 * @param rest The rest of the props to pass to the select (`Omit<SelectProps, "value" | "onChange" | "items">`)
 *
 * ### Example:
```tsx
import { useState } from "react";
import { EnumSelect } from "~/components/shared/enum-select";

// replicating EVA.Core.Management.ListSettings.TypedFilters
enum TypedFilters {
  ShowAll = 0,
  ShowTyped = 1,
  ShowUntyped = 2,
}

const labelLookup = {
  [TypedFilters.ShowAll]: "Show all",
  [TypedFilters.ShowTyped]: "Show typed",
  [TypedFilters.ShowUntyped]: "Show untyped",
};

export const TypedFilter = () => {
  const [typedFilter, setTypedFilter] = useState(TypedFilters.ShowAll);
  return (
    <EnumSelect.Controlled
      value={typedFilter}
      enum={TypedFilters}
      label="Setting type"
      getOptionLabel={(value) => labelLookup[value]}
      onChange={(newValue) => setTypedFilter(newValue)}
    />
  );
};
```
 */
export const ControlledEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  enum: enumProp,
  getOptionDisabled,
  getOptionLabel,
  name,
  onChange,
  value,
  ...rest
}: IControlledEnumSelectProps<EnumType, EnumValue>) => {
  const selectOptions = SuiteUISelect.useMapArrayToSelectItems(
    Object.entries(enumProp).filter(([, value]) => isNumber(value)) as [
      keyof EnumType,
      EnumValue,
    ][],
    ([key, value]) => ({
      value,
      label: getOptionLabel ? getOptionLabel(value) : key.toString(),
      disabled: getOptionDisabled ? getOptionDisabled(value) : false,
    }),
  );

  return (
    <SuiteUISelect
      {...rest}
      value={value ?? ""}
      items={selectOptions}
      name={name ?? "enum-select"}
      onChange={(e) => onChange(e.target.value as EnumValue | undefined)}
    />
  );
};

export const ControlledLyraEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  enum: enumProp,
  getOptionDisabled,
  getOptionLabel,
  isValidValue,
  onChange,
  value,
  ...rest
}: IControlledLyraEnumSelectProps<EnumType, EnumValue>) => {
  const items = useMemo(
    () =>
      Object.entries(enumProp)
        .filter(([, value]) => (isValidValue ? isValidValue(value) : isNumber(value)))
        .map(([key, value]) => ({
          value,
          label: getOptionLabel ? getOptionLabel(value) : key.toString(),
        })),
    [enumProp, getOptionLabel, isValidValue],
  );

  const disabledValues = useMemo(
    () => items.map((item) => item.value).filter((value) => getOptionDisabled?.(value)),
    [items, getOptionDisabled],
  );

  return (
    <Select
      {...rest}
      value={items.find((option) => option.value === value)}
      items={items}
      getItemId={(item) => item.value}
      selectRenderElements={(item) => ({
        label: item.label,
      })}
      getLabel={(item) => item.label}
      onChange={(item) => onChange(item?.value)}
      disabledItemIdKeys={disabledValues}
    />
  );
};
