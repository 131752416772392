import { useMemo } from "react";

import { useGetRefundCorrectionReasonQuery } from "~/models/reasons";

export const useRefundReasonByID = (id: number | undefined) => {
  const { data, isFetching } = useGetRefundCorrectionReasonQuery(
    id && typeof id === "number" ? { ID: id } : undefined,
    { loaderKey: id ? [id.toString()] : undefined },
  );
  const item = useMemo(() => {
    if (!id || typeof id !== "number" || !data) return undefined;
    return { ID: id, Name: data.Name };
  }, [data, id]);
  return { data: item, isLoading: isFetching };
};
