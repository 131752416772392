import { builders } from "@springtree/eva-sdk-react-recoil";
import { Admin } from "@springtree/eva-services-admin";
import { selector } from "recoil";

const modulesListState = builders.buildServiceState({
  service: Admin.AdminListModules,
  key: "modulesListState",
  requireEmployee: true,
  allowEmptyRequest: true,
  defaultRequest: {
    PageConfig: {
      Filter: {
        IsActive: true,
      },
    },
  },
});

export const modulesListSelector = selector({
  key: "modulesListState:modulesListSelector",
  get: ({ get }) => {
    const modulesListResponse = get(modulesListState.response);
    return modulesListResponse?.Result?.Page;
  },
});
