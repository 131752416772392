import { PhoneNumberTypes } from "~/types/eva-core";

export const phoneNumberTypesDefaultMessages: Record<PhoneNumberTypes, string> = {
  [PhoneNumberTypes.Fax]: "Fax",
  [PhoneNumberTypes.Home]: "Home",
  [PhoneNumberTypes.Mobile]: "Mobile",
  [PhoneNumberTypes.Other]: "Other",
  [PhoneNumberTypes.Unspecified]: "General",
  [PhoneNumberTypes.Work]: "Work",
};
