import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  useGenerateLyraOUSetSearchListField,
  useGenerateOUSetSearchListField,
} from "../../organization-unit-set-search-list-field";
import { IOuAndSetSelectorFormikProps } from "../types";

import { IOUSetMultiSelect, IOUSetSelectorCommonProps, IOUSetSingleSelect } from "./types";

interface ISingleOUSetSingleSelectorProps
  extends IOuAndSetSelectorFormikProps,
    IOUSetSingleSelect {}
interface ISingleOUSetMultiSelectorProps extends IOuAndSetSelectorFormikProps, IOUSetMultiSelect {}

const SingleOUSetSelector = ({
  disableSearchListFieldPopoverPortal,
  error,
  filters,
  helperText,
  label,
  multi,
  passive,
  required,
  selectedOUSetID,
  selectedOUSetIDs,
  setSelectedOUSetID,
  setSelectedOUSetIDs,
}: IOUSetSelectorCommonProps &
  (ISingleOUSetSingleSelectorProps | ISingleOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const searchListFieldGeneratorProps = useMemo(() => {
    return {
      filters: { ...filters, Types: [0] },
    };
  }, [filters]);

  const {
    GeneratedMultiOUSetIDSearchListFieldWithFilters: MultiOuSetSearchListField,
    GeneratedSingleOUSetIDSearchListFieldWithFilters: SingleOuSetSearchListField,
  } = useGenerateOUSetSearchListField(searchListFieldGeneratorProps);

  return multi ? (
    <MultiOuSetSearchListField.Controlled
      values={selectedOUSetIDs}
      onChange={(newValue) => {
        setSelectedOUSetIDs(newValue?.filter((id): id is number => id !== undefined));
      }}
      label={
        label ?? passive
          ? intl.formatMessage({
              id: "generic.label.organization-unit",
              defaultMessage: "Organization unit",
            })
          : intl.formatMessage({
              id: "ouset-selector.single.autocomplete.label",
              defaultMessage: "Select single organization unit",
            })
      }
      required={required}
      error={error}
      helperText={helperText}
      disabled={passive}
      disablePopoverPortal={disableSearchListFieldPopoverPortal}
    />
  ) : (
    <SingleOuSetSearchListField.Controlled
      value={selectedOUSetID}
      onChange={setSelectedOUSetID}
      label={
        label ?? passive
          ? intl.formatMessage({
              id: "generic.label.organization-unit",
              defaultMessage: "Organization unit",
            })
          : intl.formatMessage({
              id: "ouset-selector.single.autocomplete.label",
              defaultMessage: "Select single organization unit",
            })
      }
      required={required}
      error={error}
      helperText={helperText}
      disabled={passive}
      disablePopoverPortal={disableSearchListFieldPopoverPortal}
    />
  );
};

export const SingleLyraOUSetSelector = ({
  disableClearLogic,
  disabledItemIdKeys,
  error,
  filters,
  helperText,
  hideHintLabel,
  includeAdHocSetsAsCurrentValue,
  label,
  multi,
  passive,
  required,
  selectedOUSetID,
  selectedOUSetIDs,
  setSelectedOUSetID,
  setSelectedOUSetIDs,
}: IOUSetSelectorCommonProps & {
  hideHintLabel?: boolean;
  disabledItemIdKeys?: string[];
  disableClearLogic?: boolean;
} & (ISingleOUSetSingleSelectorProps | ISingleOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const Components = useGenerateLyraOUSetSearchListField({
    filters: {
      ...filters,
      Types: [0],
    },
    includeAdHocSetsAsCurrentValue,
  });

  return multi ? (
    <Components.MultiIDSearchListField.Controlled
      values={selectedOUSetIDs}
      onChange={(newValue) => {
        setSelectedOUSetIDs(newValue?.filter((id): id is number => id !== undefined));
      }}
      hideHintLabel={hideHintLabel}
      label={
        label ??
        intl.formatMessage({
          id: "generic.label.organization-unit",
          defaultMessage: "Organization unit",
        })
      }
      isRequired={required}
      description={error ? undefined : helperText}
      errorMessage={error ? helperText : undefined}
      isDisabled={passive}
      disabledItemIdKeys={disabledItemIdKeys}
      disableClearLogic={disableClearLogic}
    />
  ) : (
    <Components.SingleIDSearchListField.Controlled
      value={selectedOUSetID}
      onChange={setSelectedOUSetID}
      hideHintLabel={hideHintLabel}
      label={
        label ??
        intl.formatMessage({
          id: "generic.label.organization-unit",
          defaultMessage: "Organization unit",
        })
      }
      isRequired={required}
      description={error ? undefined : helperText}
      errorMessage={error ? helperText : undefined}
      isDisabled={passive}
      disabledItemIdKeys={disabledItemIdKeys}
      disableClearLogic={disableClearLogic}
    />
  );
};

export default SingleOUSetSelector;
