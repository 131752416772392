import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  TextField,
} from "@new-black/lyra";
import { Field, FieldProps, FormikHelpers } from "formik";
import * as yup from "yup";

import useResendUserInteractionCommunication from "./hooks/use-resend-user-interaction-communication";
import { IResendFormValues } from "./types";

import { EVAFormik } from "~/components/suite-composite/eva-formik";
import ErrorBoundary from "~/components/suite-ui/error-boundary";
import { emptyStringToUndefined } from "~/util/helper";

interface IResendInteractionEmailModalProps {
  open: boolean;
  onSuccess?: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedInteractionLog: EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined;
  setSelectedInteractionLog: Dispatch<
    SetStateAction<EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined>
  >;
}

export const ResendInteractionEmailModal = ({
  onSuccess,
  open,
  selectedInteractionLog,
  setOpen,
  setSelectedInteractionLog,
}: IResendInteractionEmailModalProps) => {
  const intl = useIntl();

  const initialFormValues = useMemo(
    () =>
      ({
        ID: selectedInteractionLog?.ID,
        EmailAddress: selectedInteractionLog?.User?.EmailAddress,
      } as IResendFormValues),
    [selectedInteractionLog],
  );

  const { isLoading, resendUnserInteractionCommunication } =
    useResendUserInteractionCommunication(onSuccess);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedInteractionLog(undefined);
  }, [setOpen, setSelectedInteractionLog]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        EmailAddress: yup
          .string()
          .email(
            intl.formatMessage({
              id: "validation.email",
              defaultMessage: "This field must be a valid email address",
            }),
          )
          .required(
            intl.formatMessage({
              id: "validation.required",
              defaultMessage: "This field is required",
            }),
          ),
      }),
    [intl],
  );

  const handleSubmit = useCallback(
    (
      { EmailAddress, ID }: IResendFormValues,
      { setSubmitting }: FormikHelpers<IResendFormValues>,
    ) => {
      if (ID !== undefined && EmailAddress !== undefined) {
        resendUnserInteractionCommunication({
          ID,
          EmailAddress,
        })
          .then(() => {
            setSubmitting(false);
          })
          .then(handleClose);
      }
    },
    [handleClose, resendUnserInteractionCommunication],
  );

  return (
    <Dialog
      maxWidth="xl"
      isOpen={open}
      onOpenChange={(newIsOpen) => {
        if (!newIsOpen) {
          handleClose();
        }
      }}
    >
      <DialogCard>
        <ErrorBoundary>
          <CardHeader
            title={intl.formatMessage({
              id: "generic.label.resend-email",
              defaultMessage: "Resend email",
            })}
          />

          <EVAFormik<IResendFormValues>
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, submitForm }) => (
              <>
                <CardContent>
                  <Field name="EmailAddress">
                    {({
                      field,
                      form,
                      meta,
                    }: FieldProps<IResendFormValues["EmailAddress"], IResendFormValues>) => (
                      <TextField
                        name={field.name}
                        value={field.value ?? ""}
                        isInvalid={meta.touched && !!meta.error}
                        description={intl.formatMessage({
                          id: "orders.detail.interaction-logs.resend-email-modal.email-input.helper-text",
                          defaultMessage:
                            "Modifying the recipient will resend the email to the new recipient, but it will not change the customer's email address.",
                        })}
                        errorMessage={meta.touched ? meta.error : undefined}
                        onChange={(newValue) =>
                          form.setFieldValue(field.name, emptyStringToUndefined(newValue))
                        }
                        label={intl.formatMessage({
                          id: "generic.label.recipient",
                          defaultMessage: "Recipient",
                        })}
                      />
                    )}
                  </Field>
                </CardContent>

                <CardFooter>
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      onPress={handleClose}
                      isDisabled={isSubmitting || isLoading}
                    >
                      <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                    </Button>

                    <Button
                      onPress={submitForm}
                      isLoading={isLoading}
                      isDisabled={isSubmitting || isLoading}
                    >
                      <FormattedMessage id="generic.label.send" defaultMessage="Send" />
                    </Button>
                  </ButtonGroup>
                </CardFooter>
              </>
            )}
          </EVAFormik>
        </ErrorBoundary>
      </DialogCard>
    </Dialog>
  );
};
