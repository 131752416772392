import { useMemo, useState } from "react";
import { Controlled as ControlledImageZoom } from "react-medium-image-zoom";

import clsx from "clsx";

import { IProductSummaryProps } from "./lyra";

import NoImage from "~/assets/images/no_image.svg?react";
import useGetAssetsUrl from "~/hooks/use-get-assets-url";

export const ProductImage = ({
  classes,
  disabled,
  enlargedImageSize = 500,
  enlargeImage,
  imageSize = 50,
  placeholderImageUrl,
  productImageBlobID,
  strikeTrough,
}: Pick<
  IProductSummaryProps,
  | "imageSize"
  | "enlargeImage"
  | "enlargedImageSize"
  | "productImageBlobID"
  | "placeholderImageUrl"
  | "strikeTrough"
  | "disabled"
> & {
  classes?: {
    root?: string;
    imageWrapper?: string;
  };
}) => {
  const assetsUrl = useGetAssetsUrl();

  const [productImageError, setProductImageError] = useState(false);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);

  const imageUrl = useMemo(() => {
    if (!!assetsUrl && !!productImageBlobID) {
      return `${assetsUrl}/image/${imageSize}/${imageSize}/${productImageBlobID}.png`;
    }
    return placeholderImageUrl;
  }, [assetsUrl, imageSize, placeholderImageUrl, productImageBlobID]);

  const enlargedImageUrl = useMemo(() => {
    if (!!assetsUrl && !!productImageBlobID) {
      return `${assetsUrl}/image/${enlargedImageSize}/${enlargedImageSize}/${productImageBlobID}.png`;
    }
    return undefined;
  }, [assetsUrl, enlargedImageSize, productImageBlobID]);

  return (
    <div
      className={clsx("rounded", classes?.root)}
      style={{
        width: isNaN(imageSize) ? 50 : imageSize,
        height: isNaN(imageSize) ? 50 : imageSize,
        backgroundColor: imageUrl ? undefined : "var(--legacy-eva-color-transparent-50)",
      }}
    >
      {imageUrl && !productImageError ? (
        <div
          role="presentation"
          className={clsx(
            "overflow-hidden rounded border border-solid border-default",
            classes?.imageWrapper,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <ControlledImageZoom
            isZoomed={isImageEnlarged}
            onZoomChange={(value) => {
              if (enlargeImage) {
                setIsImageEnlarged(value);
              }
            }}
            zoomImg={{
              src: enlargedImageUrl,
              alt: "product",
            }}
            zoomMargin={100}
          >
            <img
              className={clsx({
                "!cursor-auto": !enlargeImage,
                "opacity-30": strikeTrough || disabled,
              })}
              src={imageUrl}
              alt="product"
              style={{
                width: isNaN(imageSize) ? 50 : imageSize,
                height: isNaN(imageSize) ? 50 : imageSize,
              }}
              onError={() => setProductImageError(true)}
            />
          </ControlledImageZoom>
        </div>
      ) : (
        <NoImage
          width={isNaN(imageSize) ? 50 : imageSize}
          height={isNaN(imageSize) ? 50 : imageSize}
        />
      )}
    </div>
  );
};
