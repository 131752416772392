import { memo } from "react";

import { CardHeader } from "@new-black/lyra";

export interface IInfoHeader {
  title: string;
}

const InfoHeader = memo(({ title }: IInfoHeader) => <CardHeader title={title} />);
InfoHeader.displayName = "InfoHeader";

export default InfoHeader;
