import { IntlShape } from "react-intl";

import { InvoiceTypes } from "../eva-core";

export const getInvoiceTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.InvoiceTypes, string> = {
    [InvoiceTypes.Sales]: intl.formatMessage({
      id: "generic.label.sales",
      defaultMessage: "Sales",
    }),
    [InvoiceTypes.Purchase]: intl.formatMessage({
      id: "generic.label.purchase",
      defaultMessage: "Purchase",
    }),
    [InvoiceTypes.ControlDocument]: intl.formatMessage({
      id: "generic.label.control-document",
      defaultMessage: "Control document",
    }),
    [InvoiceTypes.InitializationDocument]: intl.formatMessage({
      id: "generic.label.initialization-document",
      defaultMessage: "Initialization document",
    }),
    [InvoiceTypes.ClosingDocument]: intl.formatMessage({
      id: "generic.label.closing-document",
      defaultMessage: "Closing document",
    }),
    [InvoiceTypes.MonthlyControlDocument]: intl.formatMessage({
      id: "generic.label.monthly-control-document",
      defaultMessage: "Monthly control document",
    }),
    [InvoiceTypes.YearlyControlDocument]: intl.formatMessage({
      id: "generic.label.yearly-control-document",
      defaultMessage: "Yearly control document",
    }),
    [InvoiceTypes.IntegrityCheckDocument]: intl.formatMessage({
      id: "generic.label.integrity-check-document",
      defaultMessage: "Integrity check document",
    }),
    [InvoiceTypes.Offer]: intl.formatMessage({
      id: "generic.label.offer",
      defaultMessage: "Offer",
    }),
  };

  return (status: EVA.Core.InvoiceTypes) => {
    return translationMap[status];
  };
};
