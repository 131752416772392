import { CoreManagement } from "@springtree/eva-services-core-management";

import { listPriceListsQuery, useGetPriceListByIDQuery } from "~/models/price-lists";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "~/util/lyra-search-list-field-generator/search-list-field-generator.types";

export type PriceListSearchListFieldItem = Pick<EVA.Core.Management.PriceListDto, "Name" | "ID"> &
  Partial<Pick<EVA.Core.Management.PriceListDto, "CurrencyID">>;

export const generatePriceListSearchListField = (
  options?: Pick<
    ISearchListFieldGeneratorProps<
      CoreManagement.ListPriceLists,
      PriceListSearchListFieldItem,
      number
    >,
    "frontendFilter"
  >,
) =>
  SearchListFieldGenerator<CoreManagement.ListPriceLists, PriceListSearchListFieldItem, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listPriceListsQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue,
            },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton:
            (resp?.Result?.Total ?? 0) > (resp?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.price-list",
      defaultMessage: "Price list",
    }),
    useItemByID: (id) => {
      const { data, isFetching } = useGetPriceListByIDQuery(
        id
          ? {
              ID: id,
            }
          : undefined,
        {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        },
      );

      return {
        data,
        isLoading: isFetching,
      };
    },
    ...options,
  });
