import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { searchUsersQuery, useSearchUsersQuery } from "~/models/users";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateSearchUsersSearchListField = (
  initialRequest?: EVA.Core.SearchUsers,
  ouID?: number,
  showSecondaryLabel?: boolean,
) =>
  SearchListFieldGenerator<
    Core.SearchUsers,
    { ID: number; Name?: string; EmailAddress?: string },
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: searchUsersQuery,
        refetchOnFocus: false,
        ouId: ouID,
        initialRequest: {
          ...initialRequest,
          PageConfig: {
            ...initialRequest?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            SortDirection: 0,
            ...initialRequest?.PageConfig,
          },
        },
        getQueryRequest: (req) => req?.SearchQuery,
        setQueryRequest: (req, newValue) => ({
          ...req,
          SearchQuery: newValue,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getDescription: showSecondaryLabel ? (item) => item.EmailAddress ?? "" : undefined,
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.filter((user) => !!user.ID).map((user) => ({
        ID: user.ID!,
        Name: user.FullName,
        EmailAddress: user.EmailAddress,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.assignee",
      defaultMessage: "Assignee",
    }),
    useItemByID: (id) => {
      const { data: userResponse, isFetching: isLoading } = useSearchUsersQuery(
        id ? { UserIDs: [id] } : undefined,
        { denyEmptyRequest: true },
      );

      const user = useMemo(() => {
        if (
          id &&
          userResponse?.Result?.Page?.length === 1 &&
          userResponse?.Result?.Page?.[0]?.ID === id
        ) {
          return {
            ID: userResponse?.Result?.Page?.[0].ID ?? id,
            Name: userResponse?.Result?.Page?.[0].FullName,
            EmailAddress: userResponse?.Result?.Page?.[0].EmailAddress,
          };
        }
        return undefined;
      }, [id, userResponse?.Result?.Page]);

      return { data: user, isLoading };
    },
  });
